import { IOrderDirectionEnum, ISalesInfoParamsDTO } from '../repository/jago/model/output/RequestParamsDTOs';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';

export function encode(page: number, pageSize: number, sortData?: GridSortModel, filterData?: GridFilterModel): ISalesInfoParamsDTO {
    const formattedData: ISalesInfoParamsDTO = {
      page,
      pageSize,
      orderDir: getOrderDirFromSort(sortData && sortData.length > 0 ? sortData[0].sort : null),
      orderField: sortData && sortData.length > 0 ? sortData[0].field : undefined,
    };

    const filterField = filterData?.items && filterData?.items[0]; 
    if (!filterField) {
      return formattedData;
    }

    switch (filterField.columnField) {
      case 'iseoSellOutInvoiceDate':
        return {
          ...formattedData,
          iseoSellOutInvoiceDateFrom: filterField.value?.length ? filterField.value[0] : undefined,
          iseoSellOutInvoiceDateTo: filterField.value?.length ? filterField.value[1] : undefined,
        };
      case 'iseoSellOutOrderDate':
        return {
          ...formattedData,
          iseoSellOutOrderDateFrom: filterField.value?.length ? filterField.value[0] : undefined,
          iseoSellOutOrderDateTo: filterField.value?.length ? filterField.value[1] : undefined,
        };
      default:
        return {
          ...formattedData,
          [filterField.columnField]: filterField.value,
        };
    }
}


function getOrderDirFromSort(sort?: string | null) {
  switch (sort) {
    case 'asc':
      return IOrderDirectionEnum.ASC;
    case 'desc':
      return IOrderDirectionEnum.DESC;
    default:
      return undefined;
  }
}