import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, WithStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import { GridColumns } from '@mui/x-data-grid';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as UserDecoder from '../../codec/userInDTODecoder';
import UserForm from '../../components/Forms/UserForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import UsersSearchBar from '../../components/SearchBar/UsersSearchBar';
import UsersTable from '../../components/Users/UsersTable';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as UserActions from '../../redux/user/user.actions';
import * as VarsActions from '../../redux/vars/vars.actions';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IUserRoleInDTO } from '../../repository/jago/model/input/IUserRoleInDTO';
import { IUsersSearchDTO } from '../../repository/jago/model/input/IUsersSearchDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IUserRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { getRoleReadableName } from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
});
export interface IUsersPageState {
  searchValues: Record<keyof IUsersSearchDTO, string>;
  usersRowsPerPage: number;
  isLoading: boolean;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IUsersPageProps extends WithStyles<typeof styles> {
  language: string;
}

export type ComponentProps = IUsersPageProps & IReduxProps;

class UsersPage extends React.Component<ComponentProps, IUsersPageState> {
  private usersColumns: GridColumns<IUserInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      usersRowsPerPage: 100,
      searchValues: {
        search: '',
        roleIds: '',
        clusterIds: '',
        varId: '',
      },
      isLoading: false
    }
  }


  public async componentDidMount() {
    const { dispatch } = this.props;
    this.setState({ isLoading: true })
    try {
      await dispatch<any>(VarsActions.fetchVars({ page:0, pageSize: 500 }));
      await dispatch<any>(UserActions.fetchUsersRoles({ page: 0, pageSize: 1000 }));
      await dispatch<any>(CreditsActions.fetchClusters());
      await this.onFetchUsers()
      this.setState({ isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false })
    }
  }

  async onFetchUsers(page = 0) {
    const { dispatch } = this.props;
    const { searchValues, usersRowsPerPage } = this.state;
    try {
      const params: IUserRequestParamsDTO = { 
        search: searchValues.search, roleIds: searchValues.roleIds, 
        clusterIds: searchValues.clusterIds, varId: searchValues.varId,
        page,
        pageSize: usersRowsPerPage,
      };
      const filteredParams: IUserRequestParamsDTO = _.pickBy(params, param => param !== '') as IUserRequestParamsDTO;
      this.setState({ isLoading: true })
      await dispatch<any>(UserActions.fetchUsers(filteredParams));
      this.setState({ isLoading: false })
    } catch (error) {
      this.setState({ isLoading: false })
      console.log(error)
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  handleChangeUserInfoRowsPerPage(pageSize: number) {
    this.setState({ usersRowsPerPage: pageSize }, () => {
      this.onFetchUsers(0);
    });
  }

  onDeleteUserRequest(user: IUserInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deleteUserConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteUserConfirm(user),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteUserConfirm(user: IUserInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.deleteUser(user.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_USER_CONFIRM_MODAL'));
      this.onFetchUsers()
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteUserError',
        }
      }));
    }
  }

  public async onRowClick(user: IUserInDTO) {
    const { dispatch, usersRoles, clusters, vars } = this.props
    try {
      const usersRolesData = (usersRoles as PaginatedDataDTO<IUserRoleInDTO>)
      const varsData = (vars as PaginatedDataDTO<IVarInDTO>);
      const usersRolesOptions = _.orderBy(_.map(usersRolesData.content, role => ({...role, name: getRoleReadableName(role.name), value: role.name })), 'name');
      const varsOptions = _.orderBy(_.map(varsData.content, VAR => ({...VAR, name: VAR.name, value: VAR.id })), 'name');
      const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>)
      const clustersOptions = clustersData.content
      const currentUser = UserDecoder.decodeUserContentToForm(user)
      dispatch<any>(UserActions.setSelectedUser(user));
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_USER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(UserActions.setSelectedUser({})),
          content: (
            <UserForm
              user={currentUser}
              onCreateEditUser={(newUserData: IUserFormDTO) => this.onEditUserRequest(user.id, newUserData)}
              clustersOptions={clustersOptions}
              rolesOptions={usersRolesOptions}
              varsOptions={varsOptions}
            />
          ),
          titleMessageKey: 'users.editUserForm',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  public onEditUserRequest(userId: number, newUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editUser',
        successMessageKey: 'credits.editCreationUserConfirm',
        confirmMessageKey: 'credits.editUser',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditUserConfirm(userId, newUserData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditUserConfirm(userId: number, newUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.editUser(userId, newUserData));
      await this.onFetchUsers()
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_USER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createUserError',
        }
      }));
    }
  }

  async onOpenNewUserForm() {
    const { dispatch, usersRoles, clusters, vars } = this.props;
    const usersRolesData = (usersRoles as PaginatedDataDTO<IUserRoleInDTO>);
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>);
    const usersRolesOptions = _.orderBy(_.map(usersRolesData.content, role => ({...role, name: getRoleReadableName(role.name), value: role.name })), 'name');
    const varsOptions = _.orderBy(_.map(varsData.content, VAR => ({...VAR, name: VAR.name, value: VAR.id })), 'name');
    const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>)
    const clustersOptions = clustersData.content
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_USER_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <UserForm
            onCreateEditUser={(newUserData: IUserFormDTO) => this.onCreateNewUserRequest(newUserData)}
            clustersOptions={clustersOptions}
            rolesOptions={usersRolesOptions}
            varsOptions={varsOptions}
          />
        ),
        titleMessageKey: 'users.newUserForm',
      }
    }));
  }

  public onCreateNewUserRequest(newUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_USER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newUser',
        successMessageKey: 'credits.userCreationConfirm',
        confirmMessageKey: 'credits.newUser',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewUserConfirm(newUserData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewUserConfirm(newUserData: IUserFormDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_USER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.createNewUser(newUserData));
      await this.onFetchUsers()
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_USER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_USER_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createUserError',
        }
      }));
    }
  }

  public async onSearchReset() {
    await this.setState({ searchValues: { search: '', roleIds: '', clusterIds: '', varId: '' } });
    this.onFetchUsers()
  }
  
  public onUpdateSearchValues(values: Record<keyof IUsersSearchDTO, string>) {
    this.setState({ searchValues: values });
  }

  onVarClick(VAR) {
    const { history } = this.props
    history.push(`/vars?varId=${VAR.id}`);
  }

  render() {
    const { classes, vars, users, usersRoles, clusters, dispatch } = this.props;
    const { usersRowsPerPage } = this.state;
    const usersData = (users as PaginatedDataDTO<IUserInDTO>)
    
    const varsData = (vars as PaginatedDataDTO<IVarInDTO>)
    const varsOptions = varsData.content
    
    const usersRolesData = (usersRoles as PaginatedDataDTO<IUserRoleInDTO>)
    const usersRolesOptions = _.orderBy(_.map(usersRolesData.content, role => ({...role, name: getRoleReadableName(role.name), value: role.name })), 'name');
    
    const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>)
    const clustersOptions = clustersData.content
    
    const usersPagination = !_.isEmpty(usersData.pagination) ? (usersData.pagination as PaginationDTO) : null;
    
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <UsersSearchBar
            searchValues={this.state.searchValues}
            clustersOptions={clustersOptions}
            varsOptions={varsOptions}
            rolesOptions={usersRolesOptions}
            totalElements={usersPagination ? usersPagination.totalElements : ''}
            onSearch={() => {
              this.onFetchUsers()
            }}
            onResetSearch={() => this.onSearchReset()}
            onUpdateSearchValues={values => this.onUpdateSearchValues(values)}
            onCreateNewEntityButtonClick={() => this.onOpenNewUserForm()}
          />
        </div>
        <div className={classes.tableContainer}>
          <UsersTable
            vars={varsData}
            users={usersData}
            isLoading={this.state.isLoading}
            rowsPerPage={usersRowsPerPage}
            onFetchElementsOnPage={page => this.onFetchUsers(page)}
            onPageSizeChange={pageSize => this.handleChangeUserInfoRowsPerPage(pageSize)}
            onRowClick={user => this.onRowClick(user)}
            onDeleteUserRequest={(user) => this.onDeleteUserRequest(user)}
            onVarClick={(VAR) => this.onVarClick(VAR)}
          />
          </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    vars: state.vars.vars.data,
    clusters: state.credits.clusters.data,
    users: state.user.users.data,
    usersRoles: state.user.usersRoles.data,
    selectedUser: state.user.selectedUser,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(UsersPage)));