import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";
import ListEmptyView from "../ListEmptyView";
import { IPlantThemeInDTO } from '../../repository/jago/model/input/IPlantThemeInDTO';
import PlantThemeRow from './PlantThemeRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IPlantThemesListProps extends WithStyles<typeof styles>{
  plantThemes: PaginatedDataDTO<IPlantThemeInDTO>;
  onOpen: (VAR: IPlantThemeInDTO) => void;
  onDelete: (VAR: IPlantThemeInDTO) => void;
  onFetchMoreItems: (page: number) => void;
}

class PlantThemesList extends React.Component<IPlantThemesListProps, any> {

  public render() {
    const { plantThemes, onOpen, onDelete, onFetchMoreItems } = this.props;
    
    const plantThemesData = plantThemes.content;
    const plantThemesPagination = !_.isEmpty(plantThemes.pagination) ? (plantThemes.pagination as PaginationDTO) : null;
    const currentPage = plantThemesPagination ? plantThemesPagination.number : 0;
    const isLastPage = plantThemesPagination ? plantThemesPagination.last : true;
    return (
      <div style={{ zIndex: 100 }}>
        {!plantThemes || !plantThemesData ||(plantThemes && plantThemes.content && _.isEmpty(plantThemes.content)) ? (
          <ListEmptyView
            icon={<AssignmentIndIcon style={{ fontSize: 40 }} />}
            titleKeyMessage="vars.noPlantThemeFindTitle"
            descriptionKeyMessage="vars.noPlantThemeFindDescription"
          />
        ): (
          <div>
          <InfiniteScroll
            dataLength={_.size(plantThemesData)} //This is important field to render the next data
            next={() => onFetchMoreItems(currentPage + 1)}
            hasMore={!isLastPage}
            height={800}
            loader={<h4>Loading...</h4>}
          >
            {_.map(plantThemesData, plantTheme => (
              <PlantThemeRow key={plantTheme.id} plantTheme={plantTheme} onOpen={() => onOpen(plantTheme)} onDelete={() => onDelete(plantTheme)} />
            ))}
          </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PlantThemesList);