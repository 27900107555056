import { Action, applyMiddleware, combineReducers, createStore, Middleware, Reducer } from 'redux';
import { routerReducer as routing, RouterState } from 'react-router-redux';
import thunk, { ThunkAction } from 'redux-thunk';
import logger from 'redux-logger';
import settingsReducers, { ISettingsInterface } from './settings/settings.reducers';
import domainsReducers, { IDomainsInterface } from './domains/domains.reducers';
import modalsReducers, { IModalsInterface } from './modals/modals.reducers';
import productsReducers, { IProductsInterface } from './products/products.reducers';
import userReducers, { IUsersInterface } from './user/user.reducers';
import creditsReducers, { ICreditsInterface } from './credits/credits.reducers';
import salesReducers, { ISalesInterface } from './sales/sales.reducers';
import gatewaysReducers, { IGatewaysInterface } from './gateways/gateways.reducers';
import activationsReducers, { IActivationsInterface } from './activations/activations.reducers';
import rechargesReducers, { IRechargesInterface } from './activations/recharges.reducers';
import varsReducers, { IVarsInterface } from './vars/vars.reducers';
import productInfoReducers, { IProductInfoInterface } from './productinfo/productinfo.reducers';

const rootReducer = combineReducers({
  routing: routing as Reducer<RouterState>,
  modals: modalsReducers,
  settings: settingsReducers,
  domains: domainsReducers,
  credits: creditsReducers,
  products: productsReducers,
  sales: salesReducers,
  user: userReducers,
  gateways: gatewaysReducers,
  activations: activationsReducers,
  recharges: rechargesReducers,
  vars: varsReducers,
  productInfo: productInfoReducers,
});


export interface IState {
  settings: ISettingsInterface;
  domains: IDomainsInterface;
  credits: ICreditsInterface;
  products: IProductsInterface;
  modals: IModalsInterface;
  sales: ISalesInterface;
  user: IUsersInterface;
  gateways: IGatewaysInterface;
  activations: IActivationsInterface;
  recharges: IRechargesInterface;
  vars: IVarsInterface;
  productInfo: IProductInfoInterface;
}

const middlewares: Middleware[] = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

export function configureStore(initialState?: IState) {
  return createStore(rootReducer, applyMiddleware(...middlewares));
}

export function configureProductionStore(initialState?: IState) {
  return createStore(rootReducer, applyMiddleware(thunk));
}

export type ThunkResult<ReturnType, IState, A extends Action> = ThunkAction<ReturnType, IState, undefined, A>;
export type IThunkAction<ReturnType, IState, A extends Action> = ThunkResult<ReturnType, IState, A>;
export type IThunkAsyncAction<ReturnType, IState, A extends Action> = ThunkResult<Promise<ReturnType>, IState, A>;

export type IAppThunkAsync<R> = IThunkAsyncAction<R, IState, Action>;
export type IAppThunk<R> = IThunkAction<R, IState, Action>;


