import SeamlessImmutable from 'seamless-immutable';
import SalesActionTypesEnum from './model/SalesActionTypesEnum';
import { SalesActionsType } from './sales.actions';
import { IHardwareInDTO, ISalesInfoInDTO } from '../../repository/jago/model/input/ISalesInfoInDTO';
import { PaginatedDataDTO } from '@bottega52/commons-pagination';


export interface ISalesInterface {
  salesInfo?: PaginatedDataDTO<ISalesInfoInDTO>;
  unreconciledHardware?: IHardwareInDTO[];
}

const initialState = SeamlessImmutable<ISalesInterface>({

});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<ISalesInterface> = initialState, action: SalesActionsType) {
  switch (action.type) {
    case SalesActionTypesEnum.SAVE_SALES_INFO:
      return state.set('salesInfo', action.payload);
    case SalesActionTypesEnum.SAVE_UNRECONCILED_HARDWARE:
      return state.set('unreconciledHardware', action.payload);
    default:
      return state;
  }
}
