import * as JagoAxiosInstance from '../repository/jago/JagoAxiosInstance';
import { getDefaultGoogleClientId } from '../repository/utils';

let interceptorRef: number | null;
let googleClientId: string | null;


export function setRequestInterceptor(token: string): void {
  ejectRequestInterceptor();
  interceptorRef = JagoAxiosInstance.setRequestInterceptor((config) => {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  });
}

export function ejectRequestInterceptor(): void {
  if (interceptorRef !== null) {
    JagoAxiosInstance.ejectRequestInterceptor(interceptorRef);
  }
}

export function setGoogleClientId(clientId: string): void {
  googleClientId = clientId;
}

export function getGoogleClientId(): string {
  if (!googleClientId) {
    // Defaults to staging app
    return getDefaultGoogleClientId();
  }
  return googleClientId;
}