import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';
import _ from 'lodash';
import * as React from "react";
import { IDomainSubscriptionInDTO } from '../../../repository/jago/model/input/IDomainConfigInDTO';
import ListEmptyView from '../../ListEmptyView';
import DomainSubscriptionRow from './DomainSubscriptionRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
  warningContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  warningIcon: {
    color: 'red',
    marginRight: 5,
    fontSize: 20
  },
  warningText: {
    fontWeight: 100
  }
});

export interface IDomainSubscriptionListProps extends WithStyles<typeof styles>{
  subscriptions?: IDomainSubscriptionInDTO[];
}

class DomainSubscriptionList extends React.Component<IDomainSubscriptionListProps, any> {

  public render() {
    const { subscriptions } = this.props;
    return (
      <div style={{ width: '100%'}}>
      {_.map(subscriptions, (subscription, key) => (
        <DomainSubscriptionRow key={key} subscription={subscription} />
      ))}
      {_.isEmpty(subscriptions) ? (
        <ListEmptyView
          icon={<Warning style={{ fontSize: 30, color: 'red' }} />}
          titleKeyMessage="domains.domainSubsriptionsEmptyTitle"
          descriptionKeyMessage="domains.domainSubsriptionsEmptyDescription"
        />
        ): null}
      </div>
    );
  }
}

export default withStyles(styles)(DomainSubscriptionList);