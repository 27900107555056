import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import SubscriptionsIcon from '@material-ui/icons/ReceiptOutlined';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ICreditsInDTO } from '../../../repository/jago/model/input/ICreditsInDTO';
import ListEmptyView from '../../ListEmptyView';
import WalletCreditRow from './WalletCreditRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IWalletCreditsListProps extends WithStyles<typeof styles>{
  credits: PaginatedDataDTO<ICreditsInDTO> | undefined;
  onFetchMoreItems: (page: number) => void;
}

class WalletCreditsList extends React.Component<IWalletCreditsListProps, any> {

  public render() {
    const { credits, onFetchMoreItems } = this.props;
    
    const creditsData = credits?.content;
    const subscriptionsPagination = !_.isEmpty(credits?.pagination) ? (credits?.pagination as PaginationDTO) : null;
    const currentPage = subscriptionsPagination ? subscriptionsPagination.number : 0;
    const isLastPage = subscriptionsPagination ? subscriptionsPagination.last : true;
    return (
      <div style={{ zIndex: 100, marginTop: 20 }}>
        {!credits || (credits && credits.content && _.isEmpty(credits.content)) ? (
          <ListEmptyView
            icon={<SubscriptionsIcon style={{ fontSize: 40 }} />}
            titleKeyMessage="credits.noSystemSubscriptionFoundTitle"
            descriptionKeyMessage="credits.noSystemSubscriptionFoundDescription"
          />
        ): (
          <InfiniteScroll
            dataLength={_.size(creditsData)} //This is important field to render the next data
            next={() => onFetchMoreItems(currentPage + 1)}
            hasMore={!isLastPage}
            loader={<h4>Loading...</h4>}
          >
            {_.map(creditsData, credit => (
              <WalletCreditRow key={credit.id} credit={credit} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(WalletCreditsList);