import SeamlessImmutable from 'seamless-immutable';
import UsersActionTypesEnum from './model/UserActionTypesEnum';
import { UserActionsType } from './user.actions';
import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IUserRoleInDTO } from '../../repository/jago/model/input/IUserRoleInDTO';


export interface IUsersInterface {
  userData: any | {};
  isAuthenticated: boolean;
  users: {
    data: PaginatedDataDTO<IUserInDTO> | {};
  },
  usersRoles: {
    data: PaginatedDataDTO<IUserRoleInDTO> | {};
  },
  selectedUser: {
    data: IUserInDTO | {};
  },
}

const initialState = SeamlessImmutable<IUsersInterface>({
  userData: {},
  isAuthenticated: false,
  users: {
    data: {},
  },
  usersRoles: {
    data: {},
  },
  selectedUser: {
    data: {}
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IUsersInterface> = initialState, action: UserActionsType) {
  switch (action.type) {
    case UsersActionTypesEnum.SAVE_USER_DATA:
      return state.set('userData', action.payload);
    case UsersActionTypesEnum.SET_USER_AUTHENTICATION:
      return state.set('isAuthenticated', action.payload);
    case UsersActionTypesEnum.SAVE_USERS:
      return state.setIn(['users', 'data'], action.payload);
    case UsersActionTypesEnum.SAVE_USERS_ROLES:
      return state.setIn(['usersRoles', 'data'], action.payload);
    case UsersActionTypesEnum.SET_SELECTED_USER:
      return state.setIn(['selectedUser', 'data'], action.payload);
    case UsersActionTypesEnum.RESET_USER_UDATA:
      return state.set('users', initialState.users);
    default:
      return state;
  }
}
