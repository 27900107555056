import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import { IModalViewProps } from './ModalTypes';
import { Button } from '@material-ui/core';
import ReduxLanguage from '../ReduxLanguage';
import { RED } from '../../style/StyleConsts';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ConfirmModalProps {
  titleMessageKey?: string;
  successMessageKey: string;
  confirmMessageKey?: string;
  cancelMessageKey?: string;
  confirmationBody?: JSX.Element;
  icon?: JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export default class ConfirmModal extends React.Component<IModalViewProps<ConfirmModalProps>> {

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { successMessageKey, confirmationBody, cancelMessageKey, titleMessageKey, onConfirm, confirmMessageKey, icon } = this.props.modalProps;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <div style={{ padding: 20, height: 250, width: 450, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {icon ? icon : <SuccessIcon style={{ color: '#5AC0B1', fontSize: 50 }}/>}
          <h3 style={{ color: '#3f3f3f' }}>{<ReduxLanguage languageKey={titleMessageKey || 'forms.success'} />}</h3>
          <h4 style={{ fontWeight: 100, marginTop: 0, textAlign: 'center' }}>{<ReduxLanguage languageKey={successMessageKey} />}</h4>
          {confirmationBody}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {onConfirm ? (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20, marginRight: 20, backgroundColor: '#5AC0B1', width: 200, borderRadius: 100 }}
              onClick={() => onConfirm()}
            >
            <ReduxLanguage languageKey={confirmMessageKey || 'forms.save'}/>
          </Button>
        ) : null}
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, backgroundColor: RED, width: 200, borderRadius: 100 }}
            onClick={() => this.handleClose()}
          >
            <ReduxLanguage languageKey={cancelMessageKey || 'forms.close'}/>
          </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const {hideModal, modalProps} = this.props;
    hideModal();
    if (modalProps && modalProps.onCancel) {
      modalProps.onCancel();
    }
  }
}
