import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { IGatewaysArtifactsRequestParamsDTO, IGatewaysDeploymentsRequestParamsDTO, IGatewaysRequestParamsDTO } from './model/output/RequestParamsDTOs';
import { PaginationQueryParamsDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import IGatewayInDTO from './model/input/IGatewayInDTO';
import { IGatewayArtifactInDTO } from './model/input/IGatewayArtifactInDTO';
import { IGatewayDeploymentInDTO } from './model/input/IGatewayDeploymentInDTO';
import { IGatewayDeploymentOutDTO } from './model/output/IGatewayDeploymentOutDTO';
import { IGatewayDeploymentStatusInDTO } from './model/input/IGatewayDeploymentStatusInDTO';

export function fetchGateways(params?: IGatewaysRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IGatewayInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/gateways', config);
}

export function fetchGatewaysArtifats(params?: IGatewaysArtifactsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IGatewayArtifactInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/gateways/artifacts', config);
}

export function fetchGatewaysDeployments(params?: IGatewaysDeploymentsRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IGatewayDeploymentInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/gateways/deployments', config);
}

export function createGatewaysDeployment(deployment: IGatewayDeploymentOutDTO): Promise<AxiosResponse<IGatewayDeploymentInDTO>> {
  return JagoAxiosInstance.post('api/v2/support/gateways/deployments', deployment);
}

export function fetchDeploymentDetails(deploymentId: number, params: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IGatewayDeploymentStatusInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/support/gateways/deployments/${deploymentId}`, config);
}