import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as React from "react";
import { IWalletUsageInDTO } from '../../../repository/jago/model/input/IWalletUsageInDTO';
import Utils from '../../../utils/Utils';
import ReduxLanguage from "../../ReduxLanguage";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    padding: 15,
    backgroundColor: 'white',
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowBodyText: {
    fontWeight: 'normal',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    fontSize: 15
  },
  accordionDetailsBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

export interface IWalletUsageRowProps extends WithStyles<typeof styles>{
  walletUsage?: IWalletUsageInDTO;
}

class WalletUsageRow extends React.Component<IWalletUsageRowProps, any> {
  public render() {
    const { walletUsage, classes } = this.props;
    return (
      <div className={classes.rowContainer}>
        <div className={classes.rowHeader}>
          <h3 className={classes.rowTitle}>{<ReduxLanguage languageKey="credits.consumptionRowTitle" />}</h3>
          <h4 className={classes.rowBodyText} style={{ marginLeft: 3 }}>{moment(walletUsage?.createdAt).format('LL')}</h4>
        </div>
        <h4 className={classes.rowBodyText} style={{ marginLeft: 3 }}>{<ReduxLanguage languageKey="credits.consumptionCreditsDescription" languageProps={{ credits:  Utils.formatCreditsIn(walletUsage?.quantity) }}/>}</h4>
        <h4 className={classes.rowBodyText} style={{ marginLeft: 3 }}><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="credits.creditConsumptionType" /></span>: <ReduxLanguage languageKey={`credits.${walletUsage?.usageType}`} /></h4>
        {/* POTENZIALMENTE QUESTO DATO E' INUTILE */}
        {/* {walletUsage && walletUsage && walletUsage.walletCredit.card ? (
          <Accordion style={{ marginTop: 10 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h4 className={classes.rowBodyText} style={{ margin: 0 }}>{<ReduxLanguage languageKey="credits.rechargeCardDetails" />}</h4>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetailsBody}>
              <div>
                <h4 style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="credits.rechargeCardCode" /></h4>
                <h4>{walletUsage.walletCredit.card.code}</h4>
              </div>
              <div>
                <h4 style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.cardOrderForm" /></h4>
                <h4>{walletUsage.walletCredit.card.orderForm}</h4>
              </div>
              <div>
                <h4 style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.cardNotes" /></h4>
                <h4>{walletUsage.walletCredit.card.notes}</h4>
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null} */}
      </div>
    );
  }
}

export default withStyles(styles)(WalletUsageRow);