import { Avatar, Card, CardActionArea, Button } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import { IPlantThemeInDTO } from "../../repository/jago/model/input/IPlantThemeInDTO";
import ReduxLanguage from "../ReduxLanguage";
import { Delete, Edit, ExpandLess, ExpandMore } from "@material-ui/icons";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    width: '100%',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 40,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  statusCard: {
    paddingTop: 8,
    paddingBottom: 8,
    marginLeft: 40,
    textAlign:'center',
    fontWeight: 'bolder',
    width: 'fit-content'
  },
});

export interface IPlantThemeRowProps extends WithStyles<typeof styles>{
  plantTheme?: IPlantThemeInDTO;
  onOpen: () => void;
  onDelete: () => void;
}

class PlantThemeRow extends React.Component<IPlantThemeRowProps, any> {
  public render() {
    const { plantTheme, classes, onOpen, onDelete } = this.props;
    return (
      <Card className={classes.rowContainer}>
        <div className={classes.rowBodyContainer}>
          <Avatar className={classes.avatar} style={{color:'transparent',backgroundColor:plantTheme.colour?plantTheme.colour:'black'}} variant="rounded"></Avatar>
          <div>
            <h3 className={classes.rowTitle}>{plantTheme?.name}</h3>
          </div>
          <span style={{flexGrow:1}}></span>
          <Button
            variant="contained"
            className={classes.actionButton}
            style={{ backgroundColor:'darkcyan', flexShrink:0}}
            onClick={() => {onOpen()}}
          >
            {<Edit className={classes.icon}/>}
            <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"forms.edit"} /></h5>
          </Button>
          
          {plantTheme.varDefault?(
            <Card className={classes.statusCard}
              style={{backgroundColor:'greenyellow', flexShrink:0, width: 150}}>
              <h4 style={{ textTransform: 'uppercase', margin: 0, fontSize: 14, fontWeight:'bolder' }}>DEFAULT</h4>
            </Card>
          ):(
            <Button
              variant="contained"
              className={classes.actionButton}
              style={{ backgroundColor:'darkred', flexShrink:0, width: 150}}
              onClick={() => {onDelete()}}
            >
              {<Delete className={classes.icon}/>}
              <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"forms.delete"} /></h5>
            </Button>
          )}
          
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(PlantThemeRow);