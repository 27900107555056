import {  Button, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { HelpOutline } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import ReduxLanguage from './ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  title: {
    color: '#3f3f3f',
    fontWeight: 'bold'
  },
  description: {
    color: '#3f3f3f',
  },
  icon: {
    color: '#3f3f3f',
    fontSize: 35,
  }
});



export interface IListEmptyViewProps extends WithStyles<typeof styles> {
  titleKeyMessage: string;
  descriptionKeyMessage?: string;
  icon?: JSX.Element;
  onNewEntity?: () => void;
}


const ListEmptyView: FunctionComponent<IListEmptyViewProps> = ({ classes, titleKeyMessage, descriptionKeyMessage, icon, onNewEntity }) => {
  return (
    <div className={classes.container}>
      {icon || <HelpOutline className={classes.icon} />}
      <h3 className={classes.title}>{<ReduxLanguage languageKey={titleKeyMessage} />}</h3>
      {descriptionKeyMessage ? <h4 className={classes.description}>{<ReduxLanguage languageKey={descriptionKeyMessage} />}</h4> : null}
      {onNewEntity ? (
        <Button
          onClick={() => onNewEntity()}
          variant="contained"
          style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', marginTop: 10, width: 150, fontSize: 11, color: 'white' }}>
            <ReduxLanguage languageKey="forms.add" />
        </Button>
      ) : null}
    </div>
  );
}

export default withStyles(styles)(ListEmptyView);
