//
// ────────────────────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: A U T H E N T I C A T E D   R O U T E   C O M P O N E N T : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────────────────────────────
//


import { requireAuthentication } from '@bottega52/authenticated-route';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import * as UserActions from '../../redux/user/user.actions';
import { JagoAxiosInstance } from '../../repository/jago/JagoAxiosInstance';
import AppRoute from './AppRoute';

const roleNotAllowed = (props: any) => {
  const { user, allowedRoles } = props;
  if (!allowedRoles) {
    return false;
  }

  return user.data.role && !_.includes(allowedRoles, user.data.role);
};

const permissionNotAllowed = (props: any) => {
  const { user, allowedPermissions } = props;
  if (!allowedPermissions) {
    return false;
  }
  return user.data.authorities && _.difference(allowedPermissions, user.data.permissions).length;
};

const handleInterceptorErrors = (error: any, props: any) => {
  const { response } = error;
  return new Promise((resolve, reject) => {
    if (response && response.status === 401) {
      const { dispatch, history } = props;
      dispatch(UserActions.userLogout());
      history.push('/login');
    }
    reject(error);
  });
}

// This funcion check if user is authenticated and push it to login otherwise
const checkAuth = async (props: any) => {
  const { dispatch, history } = props;
  try {
    JagoAxiosInstance.interceptors.response.use(
      response => response,
      error => handleInterceptorErrors(error, props),
    )
    const user = await dispatch(UserActions.setUserInfoFromLocalStorage());
    // Check if Route it's forbidden for some role o set of permission
    if (!user.isAuthenticated || roleNotAllowed(props) || permissionNotAllowed(props)) {
      history.push('/login');
      return false;
    }
    return true;
  } catch (error) {
    history.push('/login');
    return false;
  }
};

export default withRouter(requireAuthentication(checkAuth)(AppRoute as any)) as any;
