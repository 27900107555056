import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { RechargeStatusOptions } from '../../codec/rechargeInDTODecoder';
import { IRechargesSearchDTO } from '../../repository/jago/model/input/IRechargesSearchDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import translations from '../../translations/i18next';
import Permission from '../Permission/Permission';
import ReduxLanguage from '../ReduxLanguage';
import { CancelOutlined } from '@material-ui/icons';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems:'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});

export interface IRechargeStatusOption {
  label: string;
  value: string;
  original: string;
}

export interface IRechargeSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IRechargesSearchDTO) => void;
  onResetSearch: () => void;
  totalElements?: string | number;
  onCreateNewEntityButtonClick: () => void;
  onExportCSV: () => void;
}

export interface IRechargeSearchBarState {
  searchValues: Record<keyof IRechargesSearchDTO, string>;
  statusSelected: IRechargeStatusOption | undefined;
}

class RechargesSearchBar extends React.Component<IRechargeSearchBarProps, IRechargeSearchBarState> {

  constructor(props: IRechargeSearchBarProps) {
    super(props);
    this.state = {
      searchValues: {
        search: "",
        status: "",
      },
      statusSelected: undefined,
    }
  }

  public onSetSearchValue(field: keyof IRechargesSearchDTO, value: string) {
    const { searchValues } = this.state;
    searchValues[field] = value;
    this.setState({ searchValues });
    this.onSearch()
  }

  public onSearch() {
    const { onSearch } = this.props;
    onSearch(this.state.searchValues);
  }

  public onSelectStatus(statusOption: IRechargeStatusOption) {
    this.onSetSearchValue('status', statusOption && statusOption.original ? statusOption.original : '');
    this.setState({ statusSelected: statusOption });
  }

  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({
        searchValues:{
          search: "",
          status: "",
        },
        statusSelected: undefined,
    });
    onResetSearch();
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick, onExportCSV } = this.props;
    const { searchValues, statusSelected } = this.state;
    const statusOptionsFormatted = _.map(RechargeStatusOptions, value => ({
      label: <ReduxLanguage languageKey={`recharges.status${value}`} />,
      value: translations.t(`recharges.status${value}`),
      original: value
    }));
    return (
      <div className={classes.container}>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey="recharges.search" /> }
          variant="outlined"
          type="search"
          style={{marginLeft: 10, marginRight: 10}}
          value={searchValues.search}
          onChange={e => this.onSetSearchValue('search', e.target.value)}
        />
        <Select
          isClearable
          key={JSON.stringify(searchValues)}
          value={statusSelected}
          options={statusOptionsFormatted}
          placeholder={<ReduxLanguage languageKey={'recharges.statusSelection'} />}
          onChange={(e) => {this.onSelectStatus(e)}}
          styles={{
            menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
            control: (styles) => ({ ...styles, minHeight: 55, minWidth: 250, marginLeft: 10, marginRight: 10 }),
          }} 
        />
        <Button
          variant="contained"
          style={{ backgroundColor: 'red', height: 40, color: 'white', fontWeight: 'bold'  }}
          onClick={() => this.onResetFields()}
        >
          <CancelOutlined style={{ color: 'white' }} />
        </Button>
        <span style={{flexGrow:'1'}}></span>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_CREATE}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#5AC0B1', color: 'white', marginRight: 20, fontWeight: 'bold'  }}
            onClick={() => onCreateNewEntityButtonClick()}
          >
            <ReduxLanguage languageKey="forms.add" />
          </Button>
        </Permission>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.RECHARGE_REQUEST_EXPORT}>
          <Button
            variant="contained"
            style={{ backgroundColor: 'cornflowerblue', color: 'white', marginRight: 20, fontWeight: 'bold'  }}
            onClick={() => onExportCSV()}
          >
            <ReduxLanguage languageKey="recharges.exportRechargesCSV" />
          </Button>
        </Permission>
      </div>
    );
  }
}

export default withStyles(styles)(RechargesSearchBar);
