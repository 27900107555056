import { Button } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import _ from 'lodash';
import * as React from "react";
import { BLUE } from "../../style/StyleConsts";

const styles = createStyles({
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  selectionButtonContainer: {
    width: 'fit-conter',
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    border: '1px #1B97C2 solid',
    borderRadius: 200,
    marginLeft: 5,
    marginRight: 5,
    minWidth: 53,
  },
  selectionButtonText: {
    color: 'white',
    margin: 0,
  }
});

export interface IButtonSelectionGroupOption {
  title: string;
  value: string | number;
}

export interface ISelectionButtonProps {
  classes: ClassNameMap;
  isSelected: boolean;
  title: string;
  onSelect: () => void;
}


const SelectionButton: React.FunctionComponent<ISelectionButtonProps> = ({ isSelected, title, classes, onSelect }) => (
  <Button
    variant={isSelected ? 'contained' : 'outlined'}
    style={{ backgroundColor: isSelected ? BLUE : 'white'}}
    className={classes.selectionButtonContainer}
    onClick={() => onSelect()}
  >
    <h5 className={classes.selectionButtonText} style={{ color: isSelected ? 'white' : BLUE }}>{title}</h5>
  </Button>
)

export interface IButtonSelectionGroupProps extends WithStyles<typeof styles>{
  options: IButtonSelectionGroupOption[];
  onSelectOption: (option: IButtonSelectionGroupOption) => void;
  onResetOptionSelection: () => void;
}

export interface IIButtonSelectionGroupState {
  selectedIndex?: number;
}

class ButtonSelectionGroup extends React.Component<IButtonSelectionGroupProps, IIButtonSelectionGroupState> {

  constructor(props: IButtonSelectionGroupProps) {
    super(props);
    this.state = {
      selectedIndex: undefined,
    }
  }


  public onSelectOption(index: number, option: IButtonSelectionGroupOption) {
    const { selectedIndex } = this.state;
    if (selectedIndex === index) {
      const { onResetOptionSelection } = this.props;
      this.setState({ selectedIndex: undefined });
      onResetOptionSelection()
    } else {
      this.setState({ selectedIndex: index });
      const { onSelectOption } = this.props;
      onSelectOption(option);
    }
  }

  public render() {
    const { options, classes } = this.props;
    const { selectedIndex } = this.state;
    return (
      <div className={classes.buttonGroupContainer}>
        {_.map(options, (option, index) => (
          <SelectionButton
            classes={classes}
            title={option.title}
            isSelected={index === selectedIndex}
            onSelect={() => this.onSelectOption(index, option)}
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(ButtonSelectionGroup);