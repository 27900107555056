import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { BlurCircular, SearchOutlined } from '@material-ui/icons';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import SearchBar from '../../components/SearchBar/SearchBar';
import * as GatewaysActions from '../../redux/gateways/gateways.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { IGatewayArtifactInDTO } from '../../repository/jago/model/input/IGatewayArtifactInDTO';
import { IGatewaysSearchDTO } from '../../repository/jago/model/input/IGatewaysSearchDTO';
import { IGatewaysArtifactsRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import translations from '../../translations/i18next';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 155px)',
    width: '100%'
  },
});


export interface IGatewaysArtifactsPageState {
  searchValues: Record<keyof IGatewaysSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
  rowsPerPage: number;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IGatewaysArtifactsPageProps extends WithStyles<typeof styles> {
  language: string;
  artifacts: PaginatedDataDTO<IGatewayArtifactInDTO>;
}

export type ComponentProps = IGatewaysArtifactsPageProps & IReduxProps;

export class GatewaysArtifactsPage extends React.Component<ComponentProps, IGatewaysArtifactsPageState> {
  private tableColumns: GridColumns<IGatewayArtifactInDTO>;
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchValues: {
        deviceId: '',
        deviceType: '',
        swVersion: '',
        name: '',
        hostname: '',
      },
      domainsOptions: [],
      rowsPerPage: 50,
    }
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    const params: IGatewaysArtifactsRequestParamsDTO = { page: 0, pageSize: rowsPerPage };
    dispatch<any>(GatewaysActions.fetchSofiaGatewaysArtifacts(params));
  }

  public onFetchArtifactsOnPage(page: number) {
    const { dispatch } = this.props;
    const { rowsPerPage } = this.state;
    const params: IGatewaysArtifactsRequestParamsDTO = { page, pageSize: rowsPerPage };
    dispatch<any>(GatewaysActions.fetchSofiaGatewaysArtifacts(params));
  }



  public async onRowClick(artifact: IGatewayArtifactInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_GATEWAY_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getGatewayDetailsError',
        }
      }));
    }
  }


  public handleChangeRowsPerPage(pageSize: number) {
    const { dispatch } = this.props;
    this.setState({ rowsPerPage: pageSize }, () => {
      const params: IGatewaysArtifactsRequestParamsDTO = { page: 0, pageSize };
      dispatch<any>(GatewaysActions.fetchSofiaGatewaysArtifacts(params));
    });
  }

  render() {
    const { classes, artifacts } = this.props
    const { rowsPerPage } = this.state;
    const artifactsPagination = !_.isEmpty(artifacts.pagination) ? (artifacts.pagination as PaginationDTO) : null;

    this.tableColumns = [
      {
        field: 'name',
        headerName: translations.t('gateways.gatewaysArtifact'),
        type: 'string',
        sortable: false,
        filterable: false,
        flex: 1,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<any>) => {
          const artifact = params.row;
          return <h3 style={{ marginLeft: 0, fontWeight: 'bold' }}>{artifact?.name}</h3>
        }
      },
      {
        field: 'createdAt',
        headerName: translations.t('forms.createdAt'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const artifact = params.row;
          return (
            <div>
              {artifact.createdAt ? (
                <h4>{moment(artifact?.createdAt).format('LLL')}</h4>
              ) : <h4>{'/NA'}</h4>}
            </div>
          )
        }
      }
    ];
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <SearchBar
            headerTitleTranslationKey="gateways.gatewaysArtifacts"
            searchDebounceDelay={200}
            icon={<BlurCircular style={{ alignSelf: 'center', marginRight: 15, fontSize: 30  }} />}
          />
        </div>
        <div className={classes.tableContainer}>
          <DataGrid
            getRowId={(d) => d.id}
            rows={artifacts.content || []}
            columns={this.tableColumns}
            rowHeight={50}
            onPageSizeChange={(pageSize) => this.handleChangeRowsPerPage(pageSize)}
            onPageChange={page => this.onFetchArtifactsOnPage(page)}
            page={(artifacts?.pagination as PaginationDTO)?.number}
            rowCount={(artifacts?.pagination as PaginationDTO)?.totalElements}
            pageSize={rowsPerPage}
            paginationMode="server"
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            components={{
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <SearchOutlined style={{ alignSelf: 'center' }} />
                    <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='gateways.noArtifactFound' /></h3>
                    <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='gateways.noArtifactDescription' /></h4>
                  </div>
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='gateways.noArtifactFound' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='gateways.noArtifactDescription' /></h4>
                </Stack>
              )
            }}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    artifacts: state.gateways.artifacts,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(GatewaysArtifactsPage)));