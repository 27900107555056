import i18next from "i18next";
import en from './languages/en';
import it from './languages/it';

i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "it",
    resources: {
      en: {
        translation: en,
      },
      it: {
        translation: it,
      },
    },
  });

export default i18next;
