import MomentUtils from '@date-io/moment';
import { Button, Card, IconButton, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import _ from 'lodash';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import { Edit } from "@material-ui/icons";
import * as ModalsActions from '../../redux/modals/modals.actions';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import { IState } from '../../redux/store';
import translations from '../../translations/i18next';
import Select from 'react-select';
import { Autocomplete } from '@material-ui/lab';
import { IVarOpportunityFormDTO } from '../../repository/jago/model/input/IVarOpportunityFormDTO';
import ReduxLanguage from "../ReduxLanguage";
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import * as VarsActions from '../../redux/vars/vars.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import UserIcon from '@material-ui/icons/PersonOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import { ICustomerRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RedoIcon from '@material-ui/icons/Redo';
import Permission from '../Permission/Permission';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import { VAR_USERS_ROLES } from '../../utils/Utils';
import { VarOpportunityStatusOptions } from '../../codec/varOpportunityInDTODecoder';
import { IVarOptionSelectDTO } from '../../repository/jago/model/input/IVarsSearchDTO';
import { ISegmentTypeEnum } from '../../repository/jago/model/output/ISegmentTypeEnum';
import VarOpportunityHistoryList from '../Vars/VarOpportunityHistoryList';
import { BookOutlined } from '@material-ui/icons';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { IVarOpportunityInDTO } from '../../repository/jago/model/input/IVarOpportunityInDTO';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  iconCustomer: {
    marginRight: 5,
    fontSize: 25,
  },
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
  tableCellStyle: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
  },
});

export interface ICurrencyOption {
  label: string;
  value: string;
}

const currencyOptions: ICurrencyOption[] = [
  {
    label: "€",
    value: "€",
  },
  {
    label: "GBP",
    value: "GBP",
  },
]

export interface ISegmentTypeOption {
  label: string;
  value: string;
}

export interface IOption {
  id: number;
  value: string;
}

const segmentTypeOptions: ISegmentTypeOption[] = _.map(ISegmentTypeEnum, (v) => ({label:v, value:v}))

export interface IVarOpportunityFormState {
  opportunity: IVarOpportunityFormDTO;
  errors: Record<keyof IVarOpportunityFormDTO, boolean>;
  customerSearchInput: string;
  customerSelected: ICustomerInDTO;
  customersLoading: boolean;
  selectedFileName: string;
  selectedVar: IVarOptionSelectDTO;
};

export interface IVarOpportunityFormProps extends WithStyles<typeof styles>{
  onCreateEditVarOpportunity: (opportunity: IVarOpportunityFormDTO) => void;
  onSyncSfdcidVarOpportunityRequest?: () => void;
  onReloadOpportunities?: () => void;
  opportunity?: IVarOpportunityFormDTO;
  onOpenCustomerInfo: (customerId: number) => void;
  varId: number;
  hideShowCustomerInfo?: boolean;
  forcedCustomer?: ICustomerInDTO;
  varsOptions?: IVarOptionSelectDTO[];
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = IVarOpportunityFormProps & IReduxProps;
class VarOpportunityForm extends React.Component<ComponentProps, IVarOpportunityFormState> {
  inputFileRef = null;

  constructor(props: ComponentProps) {
    super(props);
    this.inputFileRef = React.createRef();

    this.state = {
      selectedFileName: null,
      customerSearchInput: "",
      customerSelected: !props.opportunity&&props.forcedCustomer?props.forcedCustomer:null,
      customersLoading: false,
      opportunity: props.opportunity ? props.opportunity : {
        name: '',
        description: '',
        notes: '',
        value: null,
        currency: currencyOptions[0].value,
        iseoCrmId: '',
        status: VarOpportunityStatusOptions.DRAFT,
        varId: props.varId,
        varUserId: null,
        varUserEmail: '',
        customerId: props.forcedCustomer?props.forcedCustomer.id:null,
        customerName: '',
        varName: '',
        documents: [],
        documentsOUT: {},
        segmentType: '',
      },
      errors: {
        id: false,
        name: false,
        description: false,
        notes: false,
        value: props.opportunity?false:true,
        currency: false,
        iseoCrmId: false,
        status: false,
        varId: false,
        varUserId: false,
        varUserEmail: false,
        customerId: false,
        customerName: false,
        varName: false,
        documents: false,
        documentsOUT: false,
        segmentType: false,
        history: false,
      },
      selectedVar: null,
    };
  }

  async componentDidMount() {
    const { VAR, dispatch, opportunity } = this.props;
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const varId = isVar ? (VAR as IVarInDTO).id : (opportunity ? opportunity.varId : undefined)
    if (varId && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.VAR_USER_READ))
      await dispatch<any>(VarsActions.fetchVarUsers(varId, { page: 0, pageSize: 500}));
  }

  async onCustomerSearchInputChanged(newInputValue: string) {
    const { dispatch } = this.props;
    this.setState({ customerSearchInput: newInputValue })
    if (!newInputValue) {
      await dispatch<any>(VarsActions.resetCustomersSearch());
      this.setState({ customersLoading: false })
      return;
    }
    this.setState({ customersLoading: true })
    const params: ICustomerRequestParamsDTO = { name: newInputValue, varId: String(this.state.opportunity.varId) };
    await dispatch<any>(VarsActions.fetchCustomersSearch(params));
    this.setState({ customersLoading: false })
  }

  toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:application/pdf;base64,"
      let resultAsString = (reader.result as string);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve(resultSplitted[1])
    };
    reader.onerror = reject;
  });

  async onNewInputFile(newFile: File) {
    const { dispatch } = this.props;
    var fileName: string = "";
    var fileExtension: string = "";
    var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
    if (fileExtSearch>0&&fileExtSearch<newFile.name.length) {
      fileName = newFile.name.substring(0,fileExtSearch);
      fileExtension = newFile.name.substring(fileExtSearch+1);
    }
    else
      return;
    this.onNewInputFileConfirmed(newFile,fileName,fileExtension)
  }

  async onNewInputFileConfirmed(newFile: File, newName: string, newExtension: string) {
    const { dispatch } = this.props;
    const { selectedFileName, opportunity } = this.state;

    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    if (selectedFileName!==null) {
      opportunity.documents = this.state.opportunity.documents.map((item,i) => {
        if (selectedFileName===item.name) return {name:item.name,contentBase64:encodedFile};
        else return item;
      });
    }
    else {
      if (newName!==null&&newExtension!==null) {
        // Controllo che il nome dato non sia già presente, altrimenti puppa
        let tempSearch = opportunity.documents.filter(e=>e.name===(newName+"."+newExtension))
        if (tempSearch.length>0) {
          dispatch<any>(ModalsActions.showModal(`NEWFILE_DUPLICATE_NAME_ERROR_MODAL`, {
            modalType: ModalTypes.ERROR_MODAL,
            modalProps: {
              titleMessageKey: 'errors.error',
              errorMessageKey: 'activations.newFileDuplicateNameError',
            }
          }));
          return;
        }
        opportunity.documents = [...opportunity.documents, {name:(newName+"."+newExtension),contentBase64:encodedFile}]
      }
    }
    this.setState({ opportunity })
  }

  onChangeValue(field: keyof IVarOpportunityFormDTO, mandatory: boolean, value?: any) {
    const { opportunity, errors } = this.state;
    let customError = false;
    if (field==="value") {
      if (value===null||value<=0) customError = true;
    }
    this.setState({
      errors: { ...errors, [field]: (!value && mandatory) || customError ? true : false }
    });
    (opportunity as any)[field] = value;
    this.setState({ opportunity });
  }

  editOwnerRequest() {
    const { dispatch, varUsers } = this.props;
    const varUsersData = (varUsers as PaginatedDataDTO<IUserInDTO>).content
    const options: IOption[] = varUsersData.map(user => ({id: user.id, value: user.username}))
    dispatch<any>(ModalsActions.showModal(`OPPORTUNITYOWNER_CONFIRM_MODAL`, {
      modalType: ModalTypes.SELECT_MODAL,
      modalProps: {
        initialID: null,
        options: options,
        fieldLabel: null,
        placeHolder: null,
        successMessageKey: 'vars.opportunityChangeOwnerMessage',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: (value: IOption|{}) => {if (!(value as IOption) || !(value as IOption).id) return; this.editOwnerConfirm((value as IOption).id)},
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`OPPORTUNITYOWNER_CONFIRM_MODAL`)),
      }
    }));
  }

  async editOwnerConfirm(varUserId: number) {
    const { dispatch, opportunity, onReloadOpportunities } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.changeVarOpportunityOwner(opportunity.customerId, opportunity.id, varUserId));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      await dispatch<any>(ModalsActions.hideModal('OPPORTUNITYOWNER_CONFIRM_MODAL'));
      if (onReloadOpportunities)
        onReloadOpportunities()
      dispatch<any>(ModalsActions.hideModal('OPPORTUNITY_FORM_VIEW'));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPPORTUNITYOWNER_EDIT`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.editVarOpportunityError',
        }
      }));
    }
  }

  onSubmitForm() {
    const { opportunity } = this.state;
    if (!this.canSubmitForm())
      return;
    // Piazzo i documents che sono stati aggiunti || aggiornati
    let newDocuments = {};
    for (let i=0; i<opportunity.documents.length; i++) {
      if (opportunity.documents[i].name && opportunity.documents[i].contentBase64) {
        newDocuments[opportunity.documents[i].name] = opportunity.documents[i].contentBase64;
      }
    }
    opportunity.documentsOUT = newDocuments;

    const { onCreateEditVarOpportunity } = this.props;
    onCreateEditVarOpportunity(opportunity);
  }

  canSubmitForm() {
    const { opportunity } = this.state;
    return opportunity.name && opportunity.varId && opportunity.customerId && opportunity.value;
  }

  onOpenOpportunityHistory(opportunity: IVarOpportunityInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPPORTUNITY_HISTORY_VIEW`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <VarOpportunityHistoryList
            history={opportunity.history}
          />
        ),
        titleMessageKey: 'vars.opportunityHistory',
      }
    }));
  }

  public render() {
    const { classes, customersSearch, onSyncSfdcidVarOpportunityRequest, onOpenCustomerInfo, varsOptions, varUsers } = this.props;
    const { opportunity, errors, customersLoading, customerSearchInput, customerSelected, selectedFileName, selectedVar } = this.state;

    const isEdit = Boolean(this.props.opportunity)
    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
    const isSofiaAdmin = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN]);
    const canEdit = (opportunity.status === VarOpportunityStatusOptions.DRAFT && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.OPPORTUNITY_EDIT)) || 
      AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.OPPORTUNITY_UPDATE_ADMIN);
    const customersSearchData = customersSearch&&customersSearch.data&&(customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content?
      (customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content:[]

    const varUsersData = varUsers ? (varUsers as PaginatedDataDTO<IUserInDTO>).content : undefined;

    const opportunityLastUpdate = isEdit && opportunity && opportunity.history && !_.isEmpty(opportunity.history) ? moment(_.last(_.orderBy(opportunity.history, 'time')).time).format('LLL') : null;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:10, marginBottom:20}}>
            <h2 className={classes.formTitle} style={{margin:0, marginRight:40}}>
              {<ReduxLanguage languageKey={'vars.opportunityManagement'} />}
            </h2>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_SYNC_SFDCID}>
              {opportunity.iseoCrmId || !onSyncSfdcidVarOpportunityRequest || !(
                opportunity.status===VarOpportunityStatusOptions.APPROVED || opportunity.status===VarOpportunityStatusOptions.WON || opportunity.status===VarOpportunityStatusOptions.LOST
              ) ? null :(
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor:'darkcyan', flexShrink:0}}
                  onClick={() => {onSyncSfdcidVarOpportunityRequest()}}
                >
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"vars.opportunitySyncSFDC"} /></h5>
                </Button>
              )}
            </Permission>
            {isEdit ? (
              <div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}>
                <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_HISTORY_READ}>
                  {opportunityLastUpdate ? (
                    <h4><ReduxLanguage languageKey={'forms.lastUpdate'} languageProps={{ time: opportunityLastUpdate }} /></h4>
                  ): null}
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor: 'cornflowerblue', flexShrink:0 }}
                    onClick={() => this.onOpenOpportunityHistory((opportunity as IVarOpportunityInDTO))}
                  >
                    {<BookOutlined className={classes.icon}/>}
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"vars.opportunityHistory"} /></h5>
                  </Button>
                </Permission>
            </div>
            ) : null}
          </div>
          {isEdit ? (
            <div style={{ flex: 0, marginRight: 10, marginBottom: 10 }}>
              <Card style={{display:'flex',flexDirection:'column',padding:10}}>
                <div style={{display:'flex',flexDirection:'row',padding:10, marginTop:0}}>
                  <UserIcon className={classes.iconCustomer} />
                  <h3 style={{fontWeight: 'bold'}}>{opportunity.customerName}</h3>
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor:'darkcyan', flexShrink:0}}
                    onClick={() => {if (opportunity.customerId) onOpenCustomerInfo(opportunity.customerId)}}
                  >
                    {<VisibilityIcon className={classes.icon}/>}
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"recharges.seeCustomer"} /></h5>
                  </Button>
                </div>
                {!isVar && 
                  <div style={{display:'flex',flexDirection:'row', marginLeft: 10, marginTop: 5 }}>
                    <h3 style={{fontStyle:'italic',fontWeight:'bold',fontSize:'1.0em',display:'inline',border:'1px solid grey',borderRadius:5,marginLeft:0,paddingRight:3}}>
                      {"VAR "}
                    </h3>
                    <h3 style={{fontWeight: 'bold'}}>{opportunity.varName}</h3>
                  </div>
                }
              </Card>
            </div>
          ): null}
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'vars.opportunityName'} />}
            variant="outlined"
            fullWidth
            required
            disabled={!canEdit}
            error={errors.name}
            value={opportunity.name}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeValue('name', true, e.target.value)}
          />
          <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginTop:10}}>
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.opportunityValue'} />}
              variant="outlined"
              type='number'
              onWheel={(e) => {if(e.target&&('blur' in e.target)&&(typeof e.target.blur === 'function')) e.target.blur()}}
              fullWidth
              required
              disabled={!canEdit}
              error={errors.value}
              value={opportunity.value}
              style={{ marginBottom: 10, marginTop:10, maxWidth:200, textAlign:'right' }}
              onChange={e => this.onChangeValue('value', true, e.target.value===null||isNaN(parseInt(e.target.value))?null:parseInt(e.target.value)) }
            />
            <Select
              value={currencyOptions.filter(e=>e.value===opportunity.currency).length?currencyOptions.filter(e=>e.value===opportunity.currency)[0]:null}
              options={currencyOptions}
              formatOptionLabel={(o) => <>{o.label}</>}
              isOptionSelected={(o) => opportunity.currency === o.value}
              placeholder={""}
              isDisabled={!canEdit}
              onChange={(e) => {this.onChangeValue('currency', true, e?e.value:null)}}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 100, maxWidth:100, marginLeft: 10, marginRight: 10 }),
              }} 
            />
          </div>
          {!isEdit && isSofiaAdmin ? (
            <Autocomplete
              id="actionsPlugins"
              options={varsOptions}
              style={{ 
                marginTop: 20, marginBottom: 10, 
              }}
              value={selectedVar}
              getOptionLabel={(o) => `${o.name}`}
              disabled={false}
              onChange={(e, value) => {
                const selectedVar = value ? value as IVarOptionSelectDTO : null;
                this.onChangeValue('varId', true, selectedVar?.id);
                this.setState({ selectedVar })
              }}
              renderInput={(params) => <TextField {...params} placeholder='VAR' variant="outlined" />}
            />
          ) : null}
          <div style={{ flex: 0, marginRight: 10 }}>
            {!isEdit ? (
              <div style={{display:'flex',flexDirection:'column',marginTop:0}}>
                {this.props.forcedCustomer?null:(
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.customerSearch'} />}</h5>
                )}
                <Autocomplete
                  id="customer-search"
                  style={{ width: 400 }}
                  getOptionLabel={(option) => option.name}
                  filterOptions={(x) => (x as ICustomerInDTO[])}
                  options={customersSearchData}
                  autoComplete
                  loading={customersLoading}
                  disabled={Boolean(this.props.forcedCustomer) || (isSofiaAdmin && !selectedVar)}
                  includeInputInList
                  filterSelectedOptions
                  value={customerSelected}
                  noOptionsText={<ReduxLanguage languageKey={customerSearchInput?'activations.customerSearchNoMatch':'activations.customerSearchEnterName'} />}
                  onChange={(event, newValue) => {
                    this.setState({ customerSelected: !newValue?null:(newValue as ICustomerInDTO) });
                    this.onChangeValue('customerId', true, !newValue?null:(newValue as ICustomerInDTO).id)
                  }}
                  onInputChange={(event, newInputValue) => {this.onCustomerSearchInputChanged(newInputValue)}}
                  renderInput={(params) => (
                    <TextField
                      autoComplete='off'
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {customersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            ) : null}
            {isEdit && opportunity.varUserEmail ? (
              <div style={{marginTop:10, marginLeft:10, fontSize:'0.9em'}}>
                <span>
                  {`${translations.t('vars.opportunityOwner')}: `}
                </span>
                <span style={{textDecoration:'underline', marginLeft:2}}>
                  {opportunity.varUserEmail}
                </span>
                {varUsersData && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.OPPORTUNITY_CHANGE_OWNER) && canEdit && 
                  <IconButton onClick={() => this.editOwnerRequest()}>
                    <Edit />
                  </IconButton>
                }
              </div>
            ):null}
            <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.selectSegmentType'} />}<span style={{ color: 'red' }}>*</span></h4>
            <Select
              value={segmentTypeOptions.filter(e=>e.value===opportunity.segmentType).length?segmentTypeOptions.filter(e=>e.value===opportunity.segmentType)[0]:null}
              options={segmentTypeOptions}
              isClearable
              required
              isDisabled={!canEdit}
              placeholder={<ReduxLanguage languageKey={'forms.selectSegmentType'} />}
              onChange={(e) => this.onChangeValue('segmentType', true, e?.value)}
              formatOptionLabel={(v) => v?(
                <>
                  <b>{`${translations.t('segmentTypes.'+v.value+"_name")}`}</b> 
                  {translations.t('segmentTypes.'+v.value+"_description")!==('segmentTypes.'+v.value+"_description") ? 
                    ` [${translations.t('segmentTypes.'+v.value+"_description")}]`:""
                  }
                </>
              ):""}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55 }),
              }}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.opportunityDescription'} />}
              variant="outlined"
              fullWidth
              multiline={true}
              disabled={!canEdit}
              maxRows={3}
              minRows={2}
              error={errors.description}
              value={opportunity.description}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('description', false, e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.opportunityNotes'} />}
              variant="outlined"
              fullWidth
              multiline={true}
              disabled={!canEdit}
              maxRows={5}
              minRows={3}
              error={errors.notes}
              value={opportunity.notes}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('notes', false, e.target.value)}
            />
            <input type='file' style={{display: 'none'}} ref={this.inputFileRef} 
              onChange={(e) => {
                if(e.target.files&&e.target.files.length>0) {
                  if (selectedFileName===null)
                    this.onNewInputFile(e.target.files[0])
                  else
                    this.onNewInputFileConfirmed(e.target.files[0],null,null)
                  e.target.value = null
                }
              }}
            />
            <h3 style={{ fontWeight: 'bold', marginTop:20 }}>{<ReduxLanguage languageKey={'vars.opportunityFiles'} />}</h3>
            {opportunity.documents.map((document,index) => (
              <Card key={index}
                style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10,marginBottom:10}} elevation={2}>
                <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                  {document.name}
                </div>
                <span style={{flexGrow:'1'}}/>
                {document.url?(
                  <a href={document.url}>
                    <Button
                      variant="contained"
                      style={{backgroundColor:'darkcyan'}}
                      className={classes.actionButton}
                    >
                      <CloudDownloadIcon className={classes.icon}/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesDownload" /></h5>
                    </Button>
                  </a>
                ):null}
                {canEdit && (
                  <Button
                    variant="contained"
                    style={{backgroundColor:'#282c34'}}
                    className={classes.actionButton}
                    onClick={(e) => {
                      this.setState({selectedFileName:document.name})
                      if (this.inputFileRef&&this.inputFileRef.current)
                        this.inputFileRef.current.click();
                    }}
                  >
                    <RedoIcon className={classes.icon}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesReplace" /></h5>
                  </Button>
                )}
              </Card>
            ))}
            {canEdit && (
              <Button
                variant="contained"
                style={{backgroundColor:'#282c34', marginTop:10}}
                className={classes.actionButton}
                onClick={(e) => {
                  this.setState({selectedFileName:null})
                    if (this.inputFileRef&&this.inputFileRef.current)
                      this.inputFileRef.current.click();
                }}
              >
                <CloudUploadIcon className={classes.icon}/>
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="activations.selloutFilesAdd" /></h5>
              </Button>
            )}
          </div>
          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
          <Button
            variant="contained"
            disabled={!canEdit||!this.canSubmitForm()}
            style={{ width: '100%', opacity: canEdit&&this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    customersSearch: state.vars.customersSearch,
    varUsers: state.vars.users.data,
    VAR: state.vars.VAR.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(VarOpportunityForm));