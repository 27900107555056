import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import ErrorIcon from '@material-ui/icons/Report';
import React from 'react';
import { IModalViewProps } from './ModalTypes';
import { Button } from '@material-ui/core';
import ReduxLanguage from '../ReduxLanguage';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ErrorModalProps {
  titleMessageKey: string;
  errorCode: number;
  errorMessageKey: string;
};

export default class ErrorModal extends React.Component<IModalViewProps<ErrorModalProps>> {

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { errorMessageKey, titleMessageKey } = this.props.modalProps;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <div style={{ padding: 20, height: 300, width: 400, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ErrorIcon style={{ color: 'red', fontSize: 50 }}/>
          <h3 style={{ color: '#3f3f3f' }}>{<ReduxLanguage languageKey={titleMessageKey || 'forms.warning'} />}</h3>
          <h4 style={{ fontWeight: 100, marginTop: 0, textAlign: 'center' }}>{<ReduxLanguage languageKey={errorMessageKey} />}</h4>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, width: 250, borderRadius: 100 }}
            onClick={() => this.handleClose()}
            >
              <ReduxLanguage languageKey="forms.close" />
            </Button>
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const {hideModal} = this.props;
    hideModal();
  }
}
