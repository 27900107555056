import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import SeamlessImmutable from 'seamless-immutable';
import { IPlantThemeInDTO } from '../../repository/jago/model/input/IPlantThemeInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import { IUserInDTO } from '../../repository/jago/model/input/IUserInDTO';
import { ICustomerInDTO } from './../../repository/jago/model/input/ICustomerInDTO';
import VarsActionTypesEnum from './model/VarsActionTypesEnum';
import { VarsActionsType } from './vars.actions';
import { IVarOpportunityInDTO } from '../../repository/jago/model/input/IVarOpportunityInDTO';

export interface IVarsInterface {
  vars: {
    data: PaginatedDataDTO<IVarInDTO> | {};
  },
  users: {
    data: PaginatedDataDTO<IUserInDTO> | {};
  },
  selectedVar: {
    data: IVarInDTO | {};
  },
  varCustomers: {
    data: PaginatedDataDTO<ICustomerInDTO> | {};
  },
  VAR: {
    data: IVarInDTO | {};
  },
  plantThemes: {
    data: PaginatedDataDTO<IPlantThemeInDTO> | {};
  },
  selectedPlantTheme: {
    data: IPlantThemeInDTO | {};
  },
  themePlants: {
    data: PaginatedDataDTO<IVarInDTO> | {};
  },
  opportunities: {
    data: PaginatedDataDTO<IVarOpportunityInDTO> | {};
  },
  customersSearch: {
    data: PaginatedDataDTO<ICustomerInDTO> | {};
  },
}

const initialState = SeamlessImmutable<IVarsInterface>({
  vars: {
    data: {},
  },
  selectedVar: {
    data: {}
  },
  users: {
    data: {},
  },
  varCustomers: {
    data: {},
  },
  VAR: {
    data: {},
  },
  plantThemes: {
    data: {},
  },
  selectedPlantTheme: {
    data: {}
  },
  themePlants: {
    data: {},
  },
  opportunities: {
    data: {},
  },
  customersSearch: {
    data: {},
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IVarsInterface> = initialState, action: VarsActionsType) {
  switch (action.type) {
    case VarsActionTypesEnum.SAVE_VARS:
      return state.setIn(['vars', 'data'], action.payload);
    case VarsActionTypesEnum.SET_SELECTED_VAR:
      return state.setIn(['selectedVar', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_VAR_CUSTOMERS:
      return state.setIn(['varCustomers', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_VAR:
      return state.setIn(['VAR', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_PLANT_THEMES:
      return state.setIn(['plantThemes', 'data'], action.payload);
    case VarsActionTypesEnum.SET_SELECTED_PLANT_THEME:
      return state.setIn(['selectedPlantTheme', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_THEME_PLANTS:
      return state.setIn(['themePlants', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_VAR_USERS:
      return state.setIn(['users', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_VAR_OPPORTUNITIES:
      return state.setIn(['opportunities', 'data'], action.payload);
    case VarsActionTypesEnum.SAVE_CUSTOMERS_SEARCH:
      return state.setIn(['customersSearch', 'data'], action.payload);
    default:
      return state;
  }
}
