import MomentUtils from '@date-io/moment';
import { Button, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import * as GatewaysActions from '../../redux/gateways/gateways.actions';
import * as DomainsActions from '../../redux/domains/domains.actions';
import { IGatewayArtifactOptionDTO } from '../../repository/jago/model/input/IGatewayArtifactOptionDTO';
import IGatewayOptionDTO from '../../repository/jago/model/input/IGatewayOptionDTO';
import { IGatewayDeploymentOutDTO } from '../../repository/jago/model/output/IGatewayDeploymentOutDTO';
import { IDomainRequestParamsDTO, IGatewaysRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { getArtifactsOptions, getDomainsOptions, getGatewaysOptions } from "../../repository/utils";
import translations from '../../translations/i18next';
import ReduxLanguage from "../ReduxLanguage";
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import IGatewayInDTO, { IGatewayDeviceTypeEnum } from '../../repository/jago/model/input/IGatewayInDTO';
import Utils from '../../utils/Utils';
import GatewayTableFilters from '../SearchBar/GatewayTableFilters';
import { IGatewaysSearchDTO } from '../../repository/jago/model/input/IGatewaysSearchDTO';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { GREEN, RED } from '../../style/StyleConsts';



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface INewDeploymentFormState {
  deployment: IGatewayDeploymentOutDTO;
  artifactSelected?: IGatewayArtifactOptionDTO;
  gatewayIdsValue: number[];
  selectedScheduledAt?: number;
  gatewaysOptions: IGatewayOptionDTO[];
  artifactsOptions: IGatewayArtifactOptionDTO[];
  selectAllGateways: boolean;
  gateways: IGatewayInDTO[];
  searchValues: Record<keyof IGatewaysSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
};

type IReduxProps = ConnectedProps<typeof connector>;

export interface INewDeploymentFormProps extends WithStyles<typeof styles>{
  onCreateNewDeployment: (deployment: IGatewayDeploymentOutDTO) => void;
}

export type ComponentProps = INewDeploymentFormProps & IReduxProps;

class NewDeploymentForm extends React.Component<ComponentProps, INewDeploymentFormState> {
  private gatewaysColumns: GridColumns<any>;
  
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      deployment: {
        artifactId: 0,
        gatewayIds: [],
        scheduledAt: moment().valueOf(),
      },
      searchValues: {
        name: '',
        hostname: '',
        deviceType: IGatewayDeviceTypeEnum.HYPERGATE_ISEO,
        deviceId: '',
        swVersion: ''
      },
      domainsOptions: [],
      artifactSelected: undefined,
      gatewayIdsValue: [],
      selectedScheduledAt: moment().valueOf(),
      gatewaysOptions: [],
      gateways: [],
      artifactsOptions: [],
      selectAllGateways: false,
    };
    this.gatewaysColumns = [
      {
        field: 'deviceId',
        headerName: translations.t('gateways.deviceId'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        field: 'lastConnectionDate',
        headerName: translations.t('gateways.lastConnectionDate'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 250,
        renderCell: (params: GridRenderCellParams<any, Date>) => {
          if (params && params.value) {
            const difference = moment(moment().valueOf()).diff(moment(params.value), 'minutes');
            const isWarning = difference > 5;
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h4 style={{ marginLeft: 0 }}>{moment(params.value).format('LLL')}</h4>
                <div style={{ width: 10, height: 10, borderRadius: 40, backgroundColor: isWarning ? RED : GREEN }} />
              </div>
            )
          }
          return 'N/A';
        }
      },
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
      {
        field: 'hostName',
        headerName: translations.t('sales.domain'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 250,
      },
      {
        field: 'deviceVersion',
        valueGetter: (params) => params.row?.swVersion ?  params.row?.swVersion : Utils.getGatewayVersion(params.row?.deviceVersion),
        headerName: translations.t('gateways.gatewayVersion'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 100,
      },
      {
        field: 'iseoSdkVersion',
        headerName: translations.t('gateways.iseoSdkVersion'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 100,
      },
      {
        field: 'kernelVersion',
        headerName: translations.t('gateways.kernelVersion'),
        type: 'string',
        sortable: false,
        filterable: false,
        width: 150,
      },
    ];
  }



  componentDidMount(): void {
    this.onSearch(this.state.searchValues);
    this.onFetchArtifacts('');
  }
  
  public onSelectArtifact(artifact: IGatewayArtifactOptionDTO) {
    this.setState({ artifactSelected: artifact });
    const { deployment } = this.state;
    if (artifact && artifact.value && artifact.value.id) {
      deployment.artifactId = artifact.value.id;
    } else {
      deployment.artifactId = 0;
    }
    this.setState({ deployment });
  }

  public async onSearch(values: IGatewaysSearchDTO) {
    const { dispatch } = this.props;
    this.setState({ searchValues: { name: values.name || '', hostname: values.hostname || '', deviceType: IGatewayDeviceTypeEnum.HYPERGATE_ISEO, deviceId: values.deviceId || '', swVersion: values.swVersion || '' }});
    const params: IGatewaysRequestParamsDTO = { pageSize: 200, hostname: values.hostname, deviceId: values.deviceId, swVersion: values.swVersion, deviceType: IGatewayDeviceTypeEnum.HYPERGATE_ISEO };
    //TODO MAKE A UTILS
    const filteredParams: IGatewaysRequestParamsDTO = _.pickBy(params, param => param !== '') as IGatewaysRequestParamsDTO;
    const gateways = await dispatch<any>(GatewaysActions.fetchSofiaGatewaysSearch(filteredParams));
    const gatewaysOptions = getGatewaysOptions(gateways);
    this.setState({ gatewaysOptions, gateways });
  }

  public async onSearchReset() {
    const { dispatch } = this.props;
    const params: IGatewaysRequestParamsDTO = { page: 0, deviceType: IGatewayDeviceTypeEnum.HYPERGATE_ISEO };
    this.setState({ searchValues: { name: '', hostname: '', deviceType: IGatewayDeviceTypeEnum.HYPERGATE_ISEO, deviceId: '', swVersion: '' } });
    const gateways = await dispatch<any>(GatewaysActions.fetchSofiaGatewaysSearch(params));
    const gatewaysOptions = getGatewaysOptions(gateways);
    this.setState({ gatewaysOptions, gateways });
  }

  async onFetchDomains(value: string) {
    const { dispatch } = this.props;
    const params: IDomainRequestParamsDTO = { serviceName: 'LUCKEY', hostName: value };
    const domains = await dispatch<any>(DomainsActions.fetchSofiaDomainsSearch(params));
    const domainsOptions = getDomainsOptions(domains);
    this.setState({ domainsOptions });
  }

  public onUpdateSearchValues(values: Record<keyof IGatewaysSearchDTO, string>) {
    this.setState({ searchValues: values });
  }

  public onSelectDate(date: MaterialUiPickersDate) {
    const { deployment } = this.state;
    if (date) {
      this.setState({ selectedScheduledAt: moment(date).valueOf() });
      deployment.scheduledAt =  moment(date).valueOf();
    } else {  
      deployment.scheduledAt = moment().valueOf();
    }
    this.setState({ deployment });
  }

  public onSubmitForm() {
    const { deployment } = this.state;
    if (!deployment.artifactId) {
      return;
    }
    if (!deployment.scheduledAt) {
      return;
    }
    if (!deployment.gatewayIds) {
      return;
    }
    const { onCreateNewDeployment } = this.props;
    onCreateNewDeployment(deployment);
  }

  async onFetchArtifacts(value: string) {
    const { dispatch } = this.props;
    const params: IGatewaysRequestParamsDTO = { name: value };
    const artifacts = await dispatch<any>(GatewaysActions.fetchSofiaArtifactsSearch(params));
    const artifactsOptions = getArtifactsOptions(artifacts);
    this.setState({ artifactsOptions });
  }


  onSelectGatewayIds(gatewayIds: number[]) {
    const { deployment } = this.state;
    deployment.gatewayIds = gatewayIds;
    this.setState({ gatewayIdsValue: gatewayIds, deployment });
  }

  public render() {
    const { classes } = this.props;
    const { gatewayIdsValue, domainsOptions, selectedScheduledAt, artifactSelected, artifactsOptions, gateways } = this.state;
    const canSubmitForm = artifactSelected && gatewayIdsValue && !_.isEmpty(gatewayIdsValue) && selectedScheduledAt;
    const debouncedArtifactsSearch = _.debounce((text) => this.onFetchArtifacts(text), 500);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <div className={classes.inputTitleContainer}>
            <h4 className={classes.inputTitle}>{<ReduxLanguage languageKey='gateways.artifactName' />}</h4>
            <h5 style={{color: 'red' }}>*</h5>
          </div>
          <Autocomplete
            options={artifactsOptions}
            autoComplete
            value={artifactSelected}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} variant="outlined" required style={{ width: '100%' }} />}
            onInputChange={(event, newInputValue) => {
              debouncedArtifactsSearch(newInputValue);
            }}
            onChange={(e, newValue) => newValue ? this.onSelectArtifact(newValue) : null}
            // style={{
            //   menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
            //   control: (styles) => ({ ...styles, minHeight: 55 }),
            // }} 
          />
          <div className={classes.inputTitleContainer}>
            <h4 className={classes.inputTitle}>{<ReduxLanguage languageKey='gateways.deploymentScheduledAt' />}</h4>
            <h5 style={{color: 'red' }}>*</h5>
          </div>
          <KeyboardDatePicker
            inputVariant="outlined"
            variant="dialog"
            margin="normal"
            autoOk
            clearable
            format="DD/MM/YYYY"
            value={moment(selectedScheduledAt)}
            onChange={(value) => this.onSelectDate(value)}
            style={{ width: '100%', margin: 0 }}
          />
          <div className={classes.inputTitleContainer}>
            <h4 className={classes.inputTitle}>{<ReduxLanguage languageKey='gateways.selectHyperGates' />}</h4>
            <h5 style={{color: 'red' }}>*</h5>
          </div>
          {/* <Autocomplete
            options={gatewaysOptions}
            autoComplete
            multiple
            disabled={selectAllGateways}
            disableCloseOnSelect
            value={gatewayIdsValue}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} variant="outlined" required style={{ width: '100%' }} />}
            onInputChange={(event, newInputValue) => {
              debouncedGatewaysSearch(newInputValue);
            }}
            onChange={(e, newValue) => newValue ? this.onSelectGatewayId(newValue) : null}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55 }),
            }} 
          /> */}
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center'}}>
          {/* <Checkbox
            checked={selectAllGateways}
            onChange={(e, checked) => this.onToggleSelectAllGateways(checked)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <h4>{<ReduxLanguage languageKey={'gateways.allGateways'} />}</h4> */}
          </div>
          <GatewayTableFilters
            searchValues={this.state.searchValues}
            domainsOptions={domainsOptions}
            onSearch={(values: IGatewaysSearchDTO) => this.onSearch(values)}
            onResetSearch={() => this.onSearchReset()}
            onUpdateSearchValues={values => this.onUpdateSearchValues(values)}
            onFilterDomains={value => this.onFetchDomains(value)}
          />
          <DataGrid
            getRowId={(d) => d.id}
            rows={gateways || []}
            columns={this.gatewaysColumns}
            rowHeight={38}
            checkboxSelection
            componentsProps={{
              pagination: {
                labelRowsPerPage: translations.t('forms.rowsPerPage')
              }
            }}
            onSelectionModelChange={(ids) => this.onSelectGatewayIds(ids as number[])}
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', height: 50, opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="gateways.createNewDeployment" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

const connector = connect();

export default connector(withStyles(styles)(NewDeploymentForm));