import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import translations from '../../translations/i18next';
import ReduxLanguage from '../ReduxLanguage';
import { IVarOpportunitySearchDTO } from '../../repository/jago/model/input/IVarOpportunitySearchDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { VAR_USERS_ROLES } from '../../utils/Utils';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import Permission from '../Permission/Permission';
import { CancelOutlined } from '@material-ui/icons';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems:'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IActivationStatusOption {
  label: string;
  value: string;
  original: string;
}
export interface IActivationClusterOption {
  label: string;
  value: string;
}

export interface IVarOpportunitySearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IVarOpportunitySearchDTO) => void;
  onResetSearch: () => void;
  totalElements: string | number;
  onCreateNewEntityButtonClick: () => void;
  varsOptions: IVarInDTO[];
}

export interface IVarOpportunitySearchBarState {
  searchValues: Record<keyof IVarOpportunitySearchDTO, string>;
  varSelected: IVarInDTO | undefined;
}

class VarOpportunitySearchBar extends React.Component<IVarOpportunitySearchBarProps, IVarOpportunitySearchBarState> {

  constructor(props: IVarOpportunitySearchBarProps) {
    super(props);
    this.state = {
      searchValues: {
        search: "",
        varId: undefined,
      },
      varSelected: undefined,
    }
  }

  public onSetSearchValue(field: keyof IVarOpportunitySearchDTO, value: string) {
    const { searchValues } = this.state;
    searchValues[field] = value;
    this.setState({ searchValues });
    this.onSearch()
  }

  public onSelectVar(VAR: IVarInDTO) {
    this.onSetSearchValue('varId', VAR?.id.toString());
    this.setState({ varSelected: VAR });
  }

  public onSearch() {
    const { onSearch } = this.props;
    onSearch(this.state.searchValues);
  }

  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({
        searchValues:{
          search: "",
          varId: undefined,
        },
        varSelected: undefined,
    });
    onResetSearch();
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick, totalElements, varsOptions } = this.props;
    const { searchValues, varSelected } = this.state;

    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))

    return (
      <div className={classes.container}>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey="forms.search" /> }
          variant="outlined"
          type="search"
          style={{ marginLeft: 0, marginRight: 10 }}
          value={searchValues.search}
          onChange={e => this.onSetSearchValue('search', e.target.value)}
        />
        {!isVar && 
          <Select
            key={`${JSON.stringify(varSelected?varSelected:-3)}`}
            isClearable
            isSearchable={false}
            value={varSelected}
            options={varsOptions}
            placeholder={<><ReduxLanguage languageKey={'users.varSelection'} /></>}
            formatOptionLabel={(o) => <>{o.name}</>}
            isOptionSelected={(o) => varSelected?.id === o.id}
            onChange={(e) => this.onSelectVar(e)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10, maxWidth:200 }),
            }} 
          />
        }
        <Button
          variant="contained"
          style={{ backgroundColor: 'red', height: 40, color: 'white', fontWeight: 'bold'  }}
          onClick={() => this.onResetFields()}
        >
          <CancelOutlined style={{ color: 'white' }} />
        </Button>
        <span style={{flexGrow:'1'}}></span>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.OPPORTUNITY_CREATE}>
          <Button
            variant="contained"
            style={{ backgroundColor: '#5AC0B1', color: 'white', marginRight: 20, marginLeft:40, fontWeight: 'bold'  }}
            onClick={() => onCreateNewEntityButtonClick()}
          >
            <ReduxLanguage languageKey="forms.add" />
          </Button>
        </Permission>
      </div>
    );
  }
}

export default withStyles(styles)(VarOpportunitySearchBar);
