import SeamlessImmutable from 'seamless-immutable';
import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';
import { ProductsActionsType } from './products.actions';
import ProductsActionTypesEnum from './model/ProductsActionTypesEnum';


export interface IProductsInterface {
  products: PaginatedDataDTO<IProductInDTO> | {};
}

const initialState = SeamlessImmutable<IProductsInterface>({
  products: [],
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IProductsInterface> = initialState, action: ProductsActionsType) {
  switch (action.type) {
    case ProductsActionTypesEnum.SAVE_PRODUCTS:
      return state.set('products', action.payload);
    default:
      return state;
  }
}
