import MomentUtils from '@date-io/moment';
import { Button } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DateRangePicker, FocusedInputShape } from 'react-dates';

import { Save } from '@material-ui/icons';
import moment from 'moment';
import * as React from "react";
import { BLUE, RED } from '../../style/StyleConsts';
import ButtonSelectionGroup, { IButtonSelectionGroupOption } from '../ButtonSelectionGroup/ButtonSelectionGroup';
import ReduxLanguage from "../ReduxLanguage";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import Permission from '../Permission/Permission';



const styles = createStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '99%',
  },
  button: {
    width: 'fit-content',
    color: 'white',
    fontWeight: 'bold',
    marginRight: 20,
    fontSize: 11
  }
});

export interface IWalletCreditsSearchFilterDTO  {
  fromDate: number | null;
  toDate: number | null;
}

export interface IWalletCreditsSearchBarState {
  searchData: IWalletCreditsSearchFilterDTO;
  focusedInput: FocusedInputShape | null;
};

export interface IWalletCreditsSearchBarProps extends WithStyles<typeof styles>{
  onSearch: (searchData: IWalletCreditsSearchFilterDTO) => void;
  onExportValues: () => void;
}

const filtersOptions = [
  {
    title: 'Q1',
    value: 1,
  },
  {
    title: 'Q2',
    value: 2
  },
  {
    title: 'Q3',
    value: 3
  },
  {
    title: 'Q4',
    value: 4
  },
  {
    title: moment().year().toString(),
    value: 0
  },
]
class WalletCreditsSearchBar extends React.Component<IWalletCreditsSearchBarProps, IWalletCreditsSearchBarState> {

  constructor(props: IWalletCreditsSearchBarProps) {
    super(props);
    this.state = {
      searchData: {
        fromDate: moment().startOf('M').valueOf(),
        toDate: moment().endOf('M').valueOf(),
      },
      focusedInput: null,
    };
    
  }


  public canSubmitForm() {
    const { searchData } = this.state;
    if (!searchData.fromDate || !searchData.toDate) {
      return false;
    }
    return true;
  }

  public onSubmitForm() {
    const { searchData } = this.state;
    const { onSearch } = this.props;
    onSearch(searchData);
  }

  public onResetForm() {
    const searchData = {
      fromDate: moment().startOf('M').valueOf(),
      toDate: moment().endOf('M').valueOf(),
    };
    this.setState({ searchData });
    const { onSearch } = this.props;
    onSearch(searchData);
  }

  public onDatesChange(startDateMoment: moment.Moment | null, endDateMoment: moment.Moment | null) {
    if (startDateMoment && endDateMoment) {
      this.setState({ searchData: { fromDate: moment(startDateMoment).valueOf(), toDate: moment(endDateMoment).valueOf() }});
    }
  }

  public onSelectDateFilterOption(option: IButtonSelectionGroupOption) {
    if (option.value === 0) {
      const fromDate = moment().startOf('year').valueOf();
      const toDate = moment().endOf('year').valueOf();
      this.setState({ searchData: { fromDate, toDate }}, () => this.onSubmitForm());
    } else {
      const fromDate = moment().quarter(Number(option.value)).startOf('month').valueOf();
      const toDate = moment().quarter(Number(option.value)).endOf('quarter').valueOf();
      this.setState({ searchData: { fromDate, toDate }}, () => this.onSubmitForm());
    }
  }

  public render() {
    const { searchData } = this.state;
    const { onExportValues, classes } = this.props;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={classes.headerContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <DateRangePicker
                  onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                  startDate={moment(searchData.fromDate)} 
                  endDate={moment(searchData.toDate)}
                  startDateId="start_date_id"
                  displayFormat="DD/MM/YYYY"
                  endDateId="end_date_id"
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => this.setState({ focusedInput })}
                  showDefaultInputIcon
                  small
                  hideKeyboardShortcutsPanel
                  isOutsideRange={() => false}
                />
              </div>
              <Button
                variant="contained"
                disabled={!this.canSubmitForm()}
                className={classes.button}
                style={{ backgroundColor: RED }}
                onClick={() => this.onResetForm()}
              >
                <ReduxLanguage languageKey="forms.reset" />
              </Button>
              <Button
                variant="contained"
                disabled={!this.canSubmitForm()}
                className={classes.button}
                style={{ opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1' }}
                onClick={() => this.onSubmitForm()}
              >
                <ReduxLanguage languageKey="forms.search" />
              </Button>
            </div>
            
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_EXPORT}>
              <Button
                variant="contained"
                className={classes.button}
                style={{ backgroundColor: BLUE }}
                onClick={() => onExportValues()}
              >
                <Save style={{ fontSize: 15, marginRight: 10 }} />
                <ReduxLanguage languageKey="forms.export" />
              </Button>
            </Permission>
            
          </div>
          <ButtonSelectionGroup
            options={filtersOptions}
            onSelectOption={option => this.onSelectDateFilterOption(option)}
            onResetOptionSelection={() => this.onResetForm()}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(WalletCreditsSearchBar);