import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import { ISubscriptionInDTO } from "../../repository/jago/model/input/ISubscriptionInDTO";
import { SubscriptionLogicEnum } from '../../repository/jago/model/input/ISubscriptionLogicEnum';
import Utils from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  creditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
});

export interface ISubscriptionCostDetailsProps extends WithStyles<typeof styles>{
  subscription: ISubscriptionInDTO;
}

class SubscriptionCostDetails extends React.Component<ISubscriptionCostDetailsProps, any> {
  public render() {
    const { subscription, classes } = this.props;
    return (
      <>
        <div className={classes.creditsContainer}>
          <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.subscriptionFixedPrice" /></span>: {Utils.formatCreditsIn(subscription?.fixedPrice)}</h4>
          <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.subscriptionBurnRate" /></span>: {Utils.formatCreditsIn(subscription?.burnRate)}</h4>
        </div>
        <h4>
          <span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.subscriptionLogic" /></span>: <ReduxLanguage languageKey={`forms.${subscription.logic}`} />
        </h4>
        {subscription.logic === SubscriptionLogicEnum.COUNT_ELEMENTS ? (
          <>
            <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxElements" /></span>: {subscription?.maxElements}</h4>
          </>
        ) : null}
        {(subscription.logic === SubscriptionLogicEnum.COUNT_USERS_LOCKS) ? (
          <>
            <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxUsers" /></span>: {subscription?.maxUsers}</h4>
            <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxLocks" /></span>: {subscription?.maxLocks}</h4>
          </>
        ) : null}
        {(subscription.logic === SubscriptionLogicEnum.COUNT_LOCKS) ? (
          <>
            <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.maxLocks" /></span>: {subscription?.maxLocks}</h4>
          </>
        ) : null} 
      </>
    );
  }
}

export default withStyles(styles)(SubscriptionCostDetails);