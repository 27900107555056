import { Avatar, Button, Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import { IDomainUserInDTO } from '../../../repository/jago/model/input/IDomainUserInDTO';
import ReduxLanguage from "../../ReduxLanguage";
import AbilityProvider from '../../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    justifyContent: 'space-between',
    padding: 20,
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowBodyContainerOuter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  rowTitle: {
    fontWeight: 'bold',
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
    fontSize: 15,
  },
  icon: {
    color: 'lightskyblue',
    fontSize: 20,
  },
});

export interface IDomainUserRowProps extends WithStyles<typeof styles> {
  user?: IDomainUserInDTO;
  onClick?: () => void;
}

class DomainUserRow extends React.Component<IDomainUserRowProps, any> {
  public render() {
    const { user, classes, onClick } = this.props;
    return (
      <Card className={classes.rowContainer}>
        {onClick ? (
          <div className={classes.rowBodyContainerOuter}>
            <div className={classes.rowBodyContainer}>
              <Avatar className={classes.avatar}>
                {`${user?.firstname[0].toUpperCase()}${user?.lastname[0].toUpperCase()}`}
              </Avatar>
              <div>
                <h4 className={classes.rowTitle}>
                  {`${user?.firstname} ${user?.lastname}`}
                </h4>
                <h4>{user?.email}</h4>
              </div>
              {user?.enabled ? (
                <h5 style={{ color: 'green', marginLeft: 20, fontWeight: 'bold' }}>
                  <ReduxLanguage languageKey={'forms.active'} />
                </h5>
              ) : (
                <h5 style={{ color: 'red', marginLeft: 20, fontWeight: 'bold' }}>
                  <ReduxLanguage languageKey={'forms.disabled'} />
                </h5>
              )}
            </div>
            {user && user.enabled && (
              AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_IMPERSONATE_SUPPORT) || user.email!=="support@sofialocks.com")?(
              <Button
                onClick={() => onClick()}
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#FFA726', fontSize: 11, color: 'white' }}>
                <ReduxLanguage languageKey="domains.impersonate" />
              </Button>
            ) : null}
          </div>
        ) : (
          <div className={classes.rowBodyContainerOuter} style={{ padding: 15 }}>
            <div className={classes.rowBodyContainer}>
              <Avatar className={classes.avatar}>
                {`${user?.firstname[0].toUpperCase()}${user?.lastname[0].toUpperCase()}`}
              </Avatar>
              <div>
                <h3 className={classes.rowTitle} style={{ fontSize: 16 }}>
                  {`${user?.firstname} ${user?.lastname}`}
                </h3>
                <h4 style={{ fontSize: 14 }}>{user?.email}</h4>
              </div>
              {user?.enabled ? (
                <h4 style={{ fontSize: 14, color: 'green', marginLeft: 20, fontWeight: 'bold' }}>
                  <ReduxLanguage languageKey={'forms.active'} />
                </h4>
              ) : (
                <h4 style={{ fontSize: 14, color: 'red', marginLeft: 20, fontWeight: 'bold' }}>
                  <ReduxLanguage languageKey={'forms.disabled'} />
                </h4>
              )}
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(DomainUserRow);