import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import ReduxLanguage from '../ReduxLanguage';
import { IModalViewProps } from './ModalTypes';


export interface SuccessBarModalProps {
  titleMessageKey: string;
};

export default class SuccessSnackBar extends React.Component<IModalViewProps<SuccessBarModalProps>> {

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { titleMessageKey } = this.props.modalProps;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal:  'center',
        }}
        open
        autoHideDuration={6000}
        onClose={() => this.handleClose()}
      >
        <Alert onClose={() => this.handleClose()} severity="success">
          <ReduxLanguage languageKey={titleMessageKey || 'forms.confirm'} />
        </Alert>
      </Snackbar>
    );
  }

  private handleClose = (): void => {
    const {hideModal} = this.props;
    hideModal();
  }
}
