import PermissionsParser from './PermissionsParser';

class  PermissionsParserProvider {
  permissionsParser = new  PermissionsParser();

  getPermissionsParser() {
    return this.permissionsParser;
  }
}

export default new PermissionsParserProvider();
