import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';

export enum IOrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
};

interface OrderDirQueryParamsDTO {
  orderDir?: IOrderDirectionEnum;
}

export interface IWalletUsageRequestParamsDTO extends PaginationQueryParamsDTO, OrderDirQueryParamsDTO {
  walletId: number;
  fromDate?: number;
  toDate?: number;
}

export interface IWalletSubscriptionsRequestParamsDTO extends PaginationQueryParamsDTO, OrderDirQueryParamsDTO {
  walletId: number;
  includeDeleted?: boolean;
}

export interface IWalletPlantsRequestParamsDTO extends PaginationQueryParamsDTO {
  walletId: number;
  customerId?: number;
  hostname?: string;
}

export interface IWalletCreditsRequestParamsDTO extends PaginationQueryParamsDTO, OrderDirQueryParamsDTO {
  walletId: number;
}

export interface ISubscriptionsRequestParamsDTO extends PaginationQueryParamsDTO {
  mainTier?: number;
  resource?: string;
  version?: number;
}

export interface IMarketplacesRequestParamsDTO extends PaginationQueryParamsDTO {
  name?: string;
};

export interface IVarOpportunitiesRequestParamsDTO extends PaginationQueryParamsDTO {
  search?: string;
  varId?: string;
  customerId?: string;
};

export interface IGatewaysRequestParamsDTO extends PaginationQueryParamsDTO {
  name?: string;
  hostname?: string;
  deviceType?: string;
  deviceId?: string;
  swVersion?: string;
};

export interface IGatewaysArtifactsRequestParamsDTO extends PaginationQueryParamsDTO {
};

export interface IGatewaysDeploymentsRequestParamsDTO extends PaginationQueryParamsDTO {
};

export interface IDomainUsersRequestParamsDTO extends PaginationQueryParamsDTO {
  roleIds?: [number];
}

export enum SubscriptionAggregationTypes {
  LIST = 'LIST',
  AGGREGATED = 'AGGREGATED',
}

export enum SubscriptionStatus {
  VALID =  'VALID',
  EXPIRED = 'EXPIRED',
};

export enum SubscriptionResourceTypes {
  BOOKEY =  'BOOKEY',
  DOMAIN = 'DOMAIN',
  LOCKS = 'LOCKS',
  USERS = 'USERS',
  'USERS+LOCKS' = 'USERS+LOCKS',
};

export interface IDomainSubscriptionParamsDTO {
  mode: SubscriptionAggregationTypes;
  status: SubscriptionStatus;
}

export interface IDomainRequestParamsDTO extends PaginationQueryParamsDTO {
  hostName?: string;
  serviceName?: string;
  pageSize?: number;
}

export interface ICustomerRequestParamsDTO extends PaginationQueryParamsDTO {
  name?: string;
  hostname?: string;
  varId?: string;
  clusterId?: string;
}

export interface IUserRequestParamsDTO extends PaginationQueryParamsDTO {
  search?: string;
  roleIds?: string;
  clusterIds?: string;
  varId?: string;
}

export interface ICustomerPlantsRequestParamsDTO extends PaginationQueryParamsDTO {
  customerId?: number;
}

export interface IThemePlantsRequestParamsDTO extends PaginationQueryParamsDTO {
  plantThemeId?: number;
}

export interface IClusterRequestParamsDTO extends PaginationQueryParamsDTO {
  name?: string;
  market?: string;
}

export interface IExportWalletUsageCSVParamsDTO {
  fromDate?: number;
  toDate?: number;
}


export interface ISalesInfoParamsDTO extends PaginationQueryParamsDTO, OrderDirQueryParamsDTO {
  hostname?: string;
  sfdcId?: string;
  iseoSellOutOrderId?: string;
  iseoSellOutInvoiceld?: string;
  customerReference?: string;
  iseoSellOutOrderDateFrom?: number;
  iseoSellOutOrderDateTo?: number;
  iseoSellOutInvoiceDateFrom?: number;
  iseoSellOutInvoiceDateTo?: number;
  orderField?: string;
}

export interface ICardRequestParamsDTO extends PaginationQueryParamsDTO, OrderDirQueryParamsDTO {
}