import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import SeamlessImmutable from 'seamless-immutable';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { ICustomerInDTO } from './../../repository/jago/model/input/ICustomerInDTO';
import { RechargesActionsType } from './recharges.actions';
import RechargesActionTypesEnum from './model/RechargesActionTypesEnum';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IRechargeInDTO } from '../../repository/jago/model/input/IRechargeInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';

export interface IMarketplaceInterface {
  data: PaginatedDataDTO<IMarketplaceInDTO> | {};
  selectedMarketplace?: IMarketplaceInDTO;
}

export interface IClusterInterface {
  data: PaginatedDataDTO<IClusterInDTO> | {};
}

export interface IMarketplaceSubscriptionsInterface {
  data: PaginatedDataDTO<ISubscriptionInDTO> | {};
  selectedMainTier?: ISubscriptionInDTO;
  selectedMainTierSubcriptions: PaginatedDataDTO<ISubscriptionInDTO> | {};
}

export interface IRechargesInterface {
  recharges: {
    data: PaginatedDataDTO<IRechargeInDTO> | {};
  }
  rechargeDetailed: {
    data: IRechargeInDTO | {};
  },
  customers: {
    data: PaginatedDataDTO<ICustomerInDTO> | {};
  },
  wallets: {
    data: PaginatedDataDTO<IWalletInDTO> | {};
  },
  customersSearch: {
    data: PaginatedDataDTO<ICustomerInDTO> | {};
  },
  customerPlants: {
    data: PaginatedDataDTO<IPlantInDTO> | {};
  },
  varsSearch: {
    data: PaginatedDataDTO<IVarInDTO> | {};
  },
}

const initialState = SeamlessImmutable<IRechargesInterface>({
  recharges: {
    data: {},
  },
  rechargeDetailed: {
    data: {}
  },
  customers: {
    data: {},
  },
  wallets: {
    data: {},
  },
  customersSearch: {
    data: {},
  },
  customerPlants: {
    data: {},
  },
  varsSearch: {
    data: {},
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IRechargesInterface> = initialState, action: RechargesActionsType) {
  switch (action.type) {
    case RechargesActionTypesEnum.SAVE_RECHARGES:
      return state.setIn(['recharges', 'data'], action.payload);
    case RechargesActionTypesEnum.SAVE_RECHARGE_DETAILED:
      return state.setIn(['rechargeDetailed', 'data'], action.payload);
    case RechargesActionTypesEnum.SAVE_CUSTOMERS:
      return state.setIn(['customers', 'data'], action.payload);
    case RechargesActionTypesEnum.SAVE_WALLETS_ALL:
      return state.setIn(['wallets', 'data'], action.payload);
    case RechargesActionTypesEnum.SAVE_CUSTOMERS_SEARCH:
      return state.setIn(['customersSearch', 'data'], action.payload);
    case RechargesActionTypesEnum.SAVE_CUSTOMER_PLANTS:
      return state.setIn(['customerPlants', 'data'], action.payload);
    case RechargesActionTypesEnum.SAVE_VARS_SEARCH:
      return state.setIn(['varsSearch', 'data'], action.payload);
    default:
      return state;
  }
}
