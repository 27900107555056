import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import { IDomainAdditionalInfoOutDTO } from "../../repository/jago/model/input/IDomainAdditionalInfoInDTO";
import ReduxLanguage from "../ReduxLanguage";
import AbilityProvider from "../../services/PermissionService/AbilityProvider";
import { PERMISSIONS, Roles } from "../../services/PermissionService/PermissionConstants";
import Permission from "../Permission/Permission";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 0,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});


export interface IDomainAdditionalInfoFormState {
  additionalInfo: string;
  textInputError: boolean;
};

export interface IDomainAdditionalInfoFormProps extends WithStyles<typeof styles>{
  onUpdateAddionalInfo: (note: string) => void;
  note: string | IDomainAdditionalInfoOutDTO;
}

class DomainAdditionalInfo extends React.Component<IDomainAdditionalInfoFormProps, IDomainAdditionalInfoFormState> {

  constructor(props: IDomainAdditionalInfoFormProps) {
    super(props);
    this.state = {
      additionalInfo: props.note.toString(),
      textInputError: false,
    };
  }
  
  public onSetValue(value: string) {
    if (!value) {
      this.setErrorForField(true);
    } else {
      this.setErrorForField(false);
    }
    this.setState({ additionalInfo: value });
    
  }

  public setErrorForField(hasError: boolean) {
    this.setState({ textInputError: hasError });
  }

  public canSubmitForm() {
    const { additionalInfo } = this.state;
    if (!additionalInfo) {
      return false;
    }
    return true;
  }

  public onSubmitForm() {
    const { additionalInfo } = this.state;
    const { onUpdateAddionalInfo } = this.props;
    onUpdateAddionalInfo(additionalInfo);
  }

  public render() {
    const { classes } = this.props;
    const { additionalInfo, textInputError } = this.state;
    const canSubmitForm = this.canSubmitForm();

    return (
      <div className={classes.container}>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'domains.domainAdditionalInfo'} />}
          variant="outlined"
          fullWidth
          rows={4}
          multiline
          required
          disabled={!AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE)}
          error={textInputError}
          value={additionalInfo}
          style={{ marginTop: 20 }}
          onChange={e => this.onSetValue(e.target.value)}
        />
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.DOMAIN_ADDITIONAL_INFO_UPDATE}>
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </Permission>
      </div>
    );
  }
}

export default withStyles(styles)(DomainAdditionalInfo);