import React from 'react';

export default class Permission extends React.Component {
  render() {
    const {
      children,
      roles,
      abilityHelper, 
      permission,
    } = this.props;

    let isAllowed = false;

    isAllowed = permission ? abilityHelper.hasPermission(permission) : abilityHelper.hasRoles(roles?roles:[])
    return (isAllowed ? children : null);
  }
}
