
import _ from 'lodash';
import { PaginationRestDTO, PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import IMarketplaceInDTO from '../repository/jago/model/input/IMarketplaceInDTO';
import { IMarketplaceFormDTO } from '../repository/jago/model/input/IMarketplaceFormDTO';

export function decode(data: PaginationRestDTO<IMarketplaceInDTO>): PaginatedDataDTO<IMarketplaceInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodeContentToForm(data: IMarketplaceInDTO): IMarketplaceFormDTO {
  return {
    name: data.name,
  }
}