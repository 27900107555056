import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { DomainDisabled } from "@material-ui/icons";
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';
import ListEmptyView from "../ListEmptyView";
import ProductRow from './ProductRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IProductsListProps extends WithStyles<typeof styles>{
  products: IProductInDTO[];
}

class ProductsList extends React.Component<IProductsListProps, any> {

  public render() {
    const { products } = this.props;
    const isLastPage = true;
    return (
      <div style={{ zIndex: 100 }}>
        {!products || _.isEmpty(products) ? (
          <ListEmptyView
            icon={<DomainDisabled style={{ fontSize: 40 }} />}
            titleKeyMessage="credits.noSystemSubscriptionFoundTitle"
            descriptionKeyMessage="credits.noSystemSubscriptionFoundDescription"
          />
        ): (
          <div>
          <InfiniteScroll
            dataLength={_.size(products)} //This is important field to render the next data
            next={() => null}
            hasMore={!isLastPage}
            loader={<h4>Loading...</h4>}
          >
            {_.map(products, product => (
              <ProductRow key={product.id} product={product} />
            ))}
          </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ProductsList);