import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const JagoAxiosInstanceConfig: AxiosRequestConfig = {
  timeout: 30000,
}
export const JagoAxiosInstance: AxiosInstance = axios.create(JagoAxiosInstanceConfig);

export function setBaseUrl(baseURL: string) {
  JagoAxiosInstance.defaults.baseURL = baseURL;
}

export function getBaseUrl() {
  return JagoAxiosInstance.defaults.baseURL;
}

export function setRequestInterceptor(interceptor: (config: AxiosRequestConfig) => AxiosRequestConfig): number {
  return JagoAxiosInstance.interceptors.request.use(interceptor);
}

export function ejectRequestInterceptor(key: number): void {
  return JagoAxiosInstance.interceptors.request.eject(key);
}
