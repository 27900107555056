import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { Button, IconButton, Snackbar, Tooltip, WithStyles } from '@material-ui/core';
import { Delete, DeleteOutline, Edit, SearchOutlined } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Close from '@material-ui/icons/Close';
import { createStyles, withStyles } from '@material-ui/styles';
import { Stack } from '@mui/material';
import { DataGrid, GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ReduxLanguage from '../../components/ReduxLanguage';
import { IState } from '../../redux/store';
import { CardStatusOptions, ICardInDTO } from '../../repository/jago/model/input/ICardInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import { GREEN, ORANGE } from '../../style/StyleConsts';
import translations from '../../translations/i18next';
import { VAR_USERS_ROLES } from '../../utils/Utils';
import CardRow, { ICardStatusProps } from './CardRow';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  logo: {
    width: '20%',
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 160px)',
    width: '100%'
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
});

const CardStatusView: React.FunctionComponent<ICardStatusProps> = ({ isUsed, isDisabled, isVar, classes }) => (
  <Tooltip title={isDisabled && isVar ? <ReduxLanguage languageKey="credits.disabledCardTooltip" /> : ""} placement="left" arrow>
    <h4 style={{ color: isUsed ? 'red' : isDisabled ? ORANGE: GREEN , fontWeight: 'bold', margin: 0 }}>{translations.t(isUsed ? 'credits.cardStatusUsed' : isDisabled? 'credits.cardStatusInactive' : 'credits.cardStatusUnused').toUpperCase()}</h4>
  </Tooltip>
)

export interface IRechargeCardsTableState {  
  snackIsOpen: boolean;
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IRechargeCardsTableProps extends WithStyles<typeof styles> {
  rowsPerPage: number;
  cards: PaginatedDataDTO<ICardInDTO>;
  onDeleteCardRequest: (card: ICardInDTO) => void;
  onEnableCardRequest: (card: ICardInDTO) => void;
  onEditCard: (card: ICardInDTO) => void;
  onChangePageSize: (pageSize: number) => void; 
  onFetchItemsPerPage: (page: number) => void;
  onRowClick?: (card: ICardInDTO) => void;
  isLoading?: boolean;
}

export type ComponentProps = IRechargeCardsTableProps & IReduxProps;

class RechargeCardsTable extends React.Component<ComponentProps, IRechargeCardsTableState> {
  private tableColumns: GridColumns<ICardInDTO>;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      snackIsOpen: false
    }
  }
  render() {
    const { classes, cards, onChangePageSize, rowsPerPage, onFetchItemsPerPage, onEditCard, onEnableCardRequest, onDeleteCardRequest } = this.props;
    const { snackIsOpen } = this.state;
    const canEditRechargeCards = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.CARD_UPDATE);
    this.tableColumns = [
      {
        field: 'id',
        headerName: 'Id',
        type: 'string',
        sortable: false,
        filterable: false,
        width: 60,
        renderCell: (params) => {
          const card = params.row;
          return (
            <div>
              <h3 style={{ margin: 0, fontSize: 13, whiteSpace: 'normal' }}>{card.id}</h3>
            </div>
        )}
      },
      {
        field: 'code',
        headerName: translations.t('forms.cardDetails'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        flex: 1,
        renderCell: (params: any) => {
          const card = params.row;
          return (
            <CardRow
              card={card}
              onCopyCode={() => this.setState({ snackIsOpen: true })}
            />
          )
        }
      },
      {
        field: 'status',
        headerName: translations.t('forms.status'),
        renderCell: (params: any) => {
          const card = params.row;
          const isUsed =  card && card.used ? card.used : false;
          const isDisabled =  card && card.status && card.status !== CardStatusOptions.ACTIVE;
          const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
          return (
            <CardStatusView isUsed={isUsed} isDisabled={isDisabled} isVar={isVar} classes={classes} />
          )
        }
      },
      {
        field: 'createdAt',
        headerName: translations.t('forms.createdAt'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 180,
        renderCell: (params: GridRenderCellParams<any>) => {
          const card = params.row;
          return (
            <div>
              {card.createdAt ? (
                <h4>{moment(card?.createdAt).format('LLL')}</h4>
              ) : <h4>{'/NA'}</h4>}
            </div>
          )
        }
      }];
      if (canEditRechargeCards) {
        this.tableColumns.push({
          field: 'actions',
          headerName: translations.t('forms.actions'),
          sortable: false,
          minWidth: 350,
          filterable: false,
          renderCell: (params: any) => {
            const card = params.row;
            const isUsed =  card && card.used ? card.used : false;
            const isDisabled =  card && card.status && card.status !== CardStatusOptions.ACTIVE;
            return (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  style={{ backgroundColor: 'green', marginLeft: 0 }}
                  onClick={() => onEditCard(card)}
                >
                  <Edit className={classes.icon} />
                  <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.edit" /></h5>
                </Button>
                {isDisabled ? (
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor: 'orange', marginLeft: 10 }}
                    onClick={() => onEnableCardRequest(card)}
                  >
                    <CheckCircleOutlineIcon className={classes.icon} />
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="credits.enableCard" /></h5>
                  </Button>
                ): null}
                {!isUsed ? (
                  <Button
                    variant="contained"
                    className={classes.actionButton}
                    style={{ backgroundColor: 'darkred', marginLeft: 10 }}
                    onClick={() => onDeleteCardRequest(card)}
                  >
                    <DeleteOutline className={classes.icon} />
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.delete" /></h5>
                  </Button>
                ): null}
              </div>
            )
          }
        });
      }
    return (
      <div className={classes.tableContainer}>
        <DataGrid
          getRowId={(d) => d.id}
          rows={cards.content || []}
          columns={this.tableColumns}
          disableSelectionOnClick
          loading={this.props.isLoading}
          getRowHeight={() => 'auto'}
          onPageSizeChange={(pageSize) => onChangePageSize(pageSize)}
          onPageChange={page => onFetchItemsPerPage(page)}
          page={(cards?.pagination as PaginationDTO)?.number}
          rowCount={(cards?.pagination as PaginationDTO)?.totalElements}
          pageSize={rowsPerPage}
          paginationMode="server"
          componentsProps={{
            pagination: {
              labelRowsPerPage: translations.t('forms.rowsPerPage')
            }
          }}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <SearchOutlined style={{ alignSelf: 'center' }} />
                  <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='credits.noSystemCardFoundTitle' /></h3>
                  <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='credits.noSystemCardFoundDescription' /></h4>
                </div>
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <h3 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 0 }}><ReduxLanguage languageKey='credits.noSystemCardFoundTitle' /></h3>
                <h4 style={{ textAlign: 'center', marginTop: 0 }}><ReduxLanguage languageKey='credits.noSystemCardFoundDescription' /></h4>
              </Stack>
            )
          }}
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={snackIsOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ snackIsOpen: false })}
          message={<ReduxLanguage languageKey="forms.copiedSuccess" />}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({ snackIsOpen: false })}>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    marketplaces: state.credits.marketplaces.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(RechargeCardsTable as any));