import { IAppThunk } from '../store';
import { ModalProps, ModalDataProps } from "../../components/Modals/ModalTypes";
import ModalsActionTypesEnum from './model/ModalsActionTypesEnum';
import { ActionsUnion, createAction } from '../utils';

export const ModalsActions = {
  showModal: createAction<typeof ModalsActionTypesEnum.SHOW_MODAL, ModalProps<Object>>(ModalsActionTypesEnum.SHOW_MODAL),
  hideModal: createAction<typeof ModalsActionTypesEnum.HIDE_MODAL, string>(ModalsActionTypesEnum.HIDE_MODAL),
};


export function showModal<T>(id: string, data: ModalDataProps<T>): IAppThunk<void> {
  return (dispatch, getState) => {
    dispatch(ModalsActions.showModal({ id, data }));
  }
}
  
export function hideModal(id: string): IAppThunk<void> {
  return (dispatch, getState) => {
    dispatch(ModalsActions.hideModal(id));
  }
}
  
export type ModalsActionsType = ActionsUnion<typeof ModalsActions>;