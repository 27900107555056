import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import { Button, TextField, Checkbox } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import Select from 'react-select';
import { IWalletPlantOutDTO } from '../../repository/jago/model/output/IWalletPlantOutDTO';
import ReduxLanguage from "../ReduxLanguage";
import translations from '../../translations/i18next';
import { ISegmentTypeEnum } from '../../repository/jago/model/output/ISegmentTypeEnum';
import { DomainIrisStatusOptions } from '../../repository/jago/model/input/IDomainInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { Roles } from '../../services/PermissionService/PermissionConstants';


const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
  }
});

export interface IWalletRechargeFormState {
  plantData: IWalletPlantOutDTO;
  textInputErrors: { [key: string]: boolean; };
  irisCheckboxState: boolean;
};

export interface IWalletPlantFormProps extends WithStyles<typeof styles>{
  onSaveWalletPlatData: (plant: IWalletPlantOutDTO) => void;
  walletId: number;
}

class WalletPlantForm extends React.Component<IWalletPlantFormProps, IWalletRechargeFormState> {

  constructor(props: IWalletPlantFormProps) {
    super(props);
    this.state = {
      plantData: {
        name: '',
        emailEnabled: true,
        walletId: props.walletId,
        enable1ris: DomainIrisStatusOptions.NOT_REQUIRED,
      },
      textInputErrors: {
        name: false,
        emailEnabled: false,
      },
      irisCheckboxState: false,
    };
  }

  public onChangeDomainNameText(text:string) {
    const { plantData } = this.state;
    if (text && text.match(/[a-z0-9-]+$/)) {
      this.setErrorForField('name', false);
    } else {
      this.setErrorForField('name', true);
    }
    (plantData as any)['name'] = text;
      this.setState({ plantData });
  }
  
  public onSetValue(field: keyof IWalletPlantOutDTO, value: any) {
    const { plantData } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (value && value < 0) {
      this.setErrorForField(field, true);
    }
    (plantData as any)[field] = value;
    this.setState({ plantData });
  }

  public onSetIrisCheckbox(value: boolean) {
    const { plantData } = this.state;
    (plantData as any)['enable1ris'] = value?DomainIrisStatusOptions.REQUIRED:DomainIrisStatusOptions.NOT_REQUIRED;
    this.setState({ irisCheckboxState: value });
  }  

  public setErrorForField(field: string, hasError: boolean) {
    const { textInputErrors } = this.state;
    textInputErrors[field] = hasError;
    this.setState({ textInputErrors });
  }

  public canSubmitForm() {
    const { plantData } = this.state;
    if (!plantData.name || plantData.emailEnabled === undefined || !plantData.segmentType) {
      return false;
    }
    return true;
  }
    

  public onSubmitForm() {
    const { plantData } = this.state;
    const { onSaveWalletPlatData } = this.props;
    if (this.canSubmitForm()) {
      onSaveWalletPlatData(plantData);
    }
  }

  public render() {
    const { classes } = this.props;
    const { plantData, textInputErrors, irisCheckboxState } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h4 style={{ marginTop: 0, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.domainName'} />}<span style={{ color: 'red' }}>*</span></h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            error={textInputErrors.name}
            value={plantData?.name}
            onChange={e => this.onChangeDomainNameText(e.target.value)}
          />
          {textInputErrors && textInputErrors.name ? <h4 className={classes.errorText}>{`${translations.t('forms.invalidDomainName')}`}</h4> : null}
          {plantData && plantData.name.length ? <h4 style={{ fontWeight: 'bold' }}>{`${translations.t('forms.domainNamePreview')}: https://${plantData.name}.jago.cloud`}</h4> : null}
          
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.selectSegmentType'} />}<span style={{ color: 'red' }}>*</span></h4>
          <Select
            options={_.map(ISegmentTypeEnum, (v) => ({ value: v }))}
            isClearable
            required
            placeholder={<ReduxLanguage languageKey={'forms.selectSegmentType'} />}
            onChange={(e) => this.onSetValue('segmentType', e?.value)}
            formatOptionLabel={(v) => v.value?(
              <>
                <b>{`${translations.t('segmentTypes.'+v.value+"_name")}`}</b> 
                {translations.t('segmentTypes.'+v.value+"_description")!==('segmentTypes.'+v.value+"_description") ? 
                  ` [${translations.t('segmentTypes.'+v.value+"_description")}]`:""
                }
              </>
            ):""}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55 }),
            }}
          />
          <div className={classes.checkboxContainer}>
            <Checkbox
              checked={plantData?.emailEnabled}
              onChange={(e, checked) => this.onSetValue('emailEnabled', checked)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div>
              <h4 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.enableEmailForPlant'} />}</h4>
              <h5>{<ReduxLanguage languageKey={'forms.enableEmailForPlantDescription'} />}</h5>
            </div>
          </div>
          
          <div className={classes.checkboxContainer}>
            <Checkbox
              checked={irisCheckboxState}
              onChange={(e, checked) => this.onSetIrisCheckbox(checked)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <div>
              <h4 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.enableIrisRequiredForPlant'} />}</h4>
              <h5>{<ReduxLanguage languageKey={'forms.enableIrisRequiredForPlantDescription'} />}</h5>
            </div>
          </div>
          
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(WalletPlantForm);