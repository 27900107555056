import { AppBar, Button, Card, Tab, Tabs, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { BusinessCenterOutlined, ContactSupportOutlined, DevicesOutlined, LockOpenOutlined, QuestionAnswerOutlined } from '@material-ui/icons';
import 'moment/locale/it';
import React, { ReactElement } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as ProductInfoActions from '../../redux/productinfo/productinfo.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import * as UserActions from '../../redux/user/user.actions';
import * as VarActions from '../../redux/vars/vars.actions';
import { IState } from '../../redux/store';
import { IProductInfoPostInDTO } from '../../repository/jago/model/input/IProductInfoPostInDTO';
import { decodeUnicode } from '../../repository/utils';
import translations from "../../translations/i18next";
import ReduxLanguage from '../../components/ReduxLanguage';
import LearningTicketForm from '../../components/Forms/LearningTicketForm';
import { ILearningTicketFormDTO } from '../../repository/jago/model/input/ILearningTicketFormDTO';

const styles = createStyles({
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0,
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IProductInfoProps extends WithStyles<typeof styles> {
  language: string;
}

export type ComponentProps = IProductInfoProps & IReduxProps;


export interface IProductInfoCategory {
  tabIndex: number;
  tagId: number;
  label: string;
  icon: ReactElement<any,any>;
};

export const productInfoCategories: IProductInfoCategory[] = [
  {
    tabIndex: 0,
    tagId: 95,
    label: translations.t('productInfo.software'),
    icon: <DevicesOutlined style={{ fontSize: 25 }} />,
  },
  {
    tabIndex: 1,
    tagId: 101,
    label: translations.t('productInfo.hardware'),
    icon: <LockOpenOutlined style={{ fontSize: 25 }} />,
  },
  {
    tabIndex: 2,
    tagId: 107,
    label: translations.t('productInfo.salesMaterial'),
    icon: <BusinessCenterOutlined style={{ fontSize: 25 }} />,
  },
]

export interface IProductInfoState {
  activeTab: number;
};

class ProductInfo extends React.Component<ComponentProps,IProductInfoState> {

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  async componentDidMount() {
    const { dispatch, language } = this.props;
    try {
      await dispatch<any>(ProductInfoActions.fetchPosts(language,productInfoCategories[0].tagId));
      dispatch<any>(VarActions.fetchVar());
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  public onTabChange(index: number) {
    const { dispatch, language } = this.props;
    this.setState({ activeTab: index });
    const tagId = productInfoCategories.filter(e=>e.tabIndex===index).length>0?productInfoCategories.filter(e=>e.tabIndex===index)[0].tagId:0;
    dispatch<any>(ProductInfoActions.fetchPosts(language,tagId));
  }

  public async onRowClick(post: IProductInfoPostInDTO) {
    const { dispatch } = this.props;
    try {
      await dispatch<any>(ProductInfoActions.setSelectedPost(post));
      this.onOpenPost()
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PRODUCTINFOPOST_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getProductInfoPostDetailsError',
        }
      }));
    }
  }
  
  async onOpenPost() {
    const { dispatch, selectedPost } = this.props;
    const post = (selectedPost as IProductInfoPostInDTO)
    const postTitle = decodeUnicode(post.title.rendered);
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EDIT_PLANTTHEME_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_WP,
      modalProps: {
        content: (
          <div style={{ paddingBottom:50 }} >
            <div className="productInfoContentContainer" 
              style={{ maxHeight: 600, padding: 25, paddingLeft: 20, paddingTop: 10, marginBottom: 0 }} 
              dangerouslySetInnerHTML={{ __html: post.content.rendered }} 
            />
          </div>
        ),
        titleMessageKey: postTitle,
      }
    }));
  }

  async onOpenLearningTicket(ticketData: ILearningTicketFormDTO) {
    
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.openLearingTicket(ticketData.description));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'forms.ticketOpenSuccessfully',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'forms.ticketOpenError',
        }
      }));
    }
  }

  render() {
    const { classes, posts } = this.props;
    const { activeTab } = this.state;
    return (
      <div className={classes.container}>
        <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            centered
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            {productInfoCategories.map((category,index) => (
              <Tab key={index} icon={category.icon} label={<h5 style={{ margin: 0 }}>{category.label}</h5>} />
            ))}
            <Tab key={3} icon={<ContactSupportOutlined />} label={<h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey='forms.askQuestion' />}</h5>} />
          </Tabs>
        </AppBar>
        {activeTab !== 3 ? (
        <div>
          {posts.map((post,index) => (
            <Card key={post.id} style={{ padding: 20, margin: 10, cursor:'pointer'}} onClick={()=>{this.onRowClick(post)}}>
              <h3 key={post.id} className="productTitle" 
                style={{ maxHeight: 600, marginBottom: 0, fontWeight: 'bold', fontSize: 20 }} 
                dangerouslySetInnerHTML={{ __html: post.title.rendered }} 
              />
              <h3 key={index} className="productDescription" 
                style={{ maxHeight: 600, marginBottom: 0, marginTop: 0 }} 
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} 
              />
            </Card>
          ))}
        </div>
        ) : null}
        {activeTab === 3 ? (
          <div>
            <LearningTicketForm
              onCreateTicket={ticketData => this.onOpenLearningTicket(ticketData)}
            />
            </div>
        ): null}
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    posts: state.productInfo.posts.data,
    selectedPost: state.productInfo.selectedPost.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(ProductInfo as any));