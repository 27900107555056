import { Card } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/PersonOutline';
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import ReduxLanguage from '../../components/ReduxLanguage';
import { IState } from "../../redux/store";

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  sectionHeader: {
    fontWeight: 'bold'
  },
  sectionContent: {

  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface ICustomerInfoDetailsViewProps extends WithStyles<typeof styles>{
}

export interface ICustomerInfoDetailsViewState {
}

export type ComponentProps = ICustomerInfoDetailsViewProps & IReduxProps;
class CustomerInfoDetailsView extends React.Component<ComponentProps, ICustomerInfoDetailsViewState> {


  public render() {
    const { selectedCustomer, classes } = this.props;
    return (
      <div className={classes.container}>
        <Card className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <UserIcon className={classes.icon} />
              <div className={classes.headerTextContainer}>
                <h2 className={classes.title}>{selectedCustomer && selectedCustomer.name ? `${selectedCustomer.id} - ${selectedCustomer.name}` : ''}</h2>
              </div>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.email'} />}</h3>
            <h3 className={classes.sectionContent}>{selectedCustomer.email || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varCluster'} />}</h3>
            {selectedCustomer && selectedCustomer.cluster ? <h3><>[{selectedCustomer.cluster.market}] - {selectedCustomer.cluster.name}</></h3> : '---'}
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varVat'} />}</h3>
            <h3 className={classes.sectionContent}>{selectedCustomer.vat || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.customerCompany'} />}</h3>
            <h3 className={classes.sectionContent}>{selectedCustomer.company || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'forms.customerCountry'} />}</h3>
            <h3>{selectedCustomer.country || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varPhone'} />}</h3>
            <h3 className={classes.sectionContent}>{selectedCustomer.phone || '---'}</h3>
          </div>
          <div style={{ marginTop: 15 }}>
            <h3 className={classes.sectionHeader}>{<ReduxLanguage languageKey={'vars.varAddress'} />}</h3>
            <h3 className={classes.sectionContent}>{selectedCustomer.address || '---'} {selectedCustomer.postalCode} {selectedCustomer.city}</h3>
          </div>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedCustomer: state.credits.customers.selectedDetailedCustomer,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(CustomerInfoDetailsView as any));