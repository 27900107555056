import { Card, CardActionArea, Avatar, Button } from "@material-ui/core";
import * as React from "react";
import { DomainIrisStatusOptions, IDomainInDTO } from '../../repository/jago/model/input/IDomainInDTO';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { getDomainNameFromHostname } from "../../repository/utils";
import ReduxLanguage from "../ReduxLanguage";
import { Business } from "@material-ui/icons";
import { BLUE, ORANGE } from "../../style/StyleConsts";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    position: 'relative',
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 15
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  domainTypeContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  domainTypeInnerContainer: {
    padding: 5,
    backgroundColor: BLUE,
    borderRadius: 5,
  },
  domainIrisContainer: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
  domainIrisInnerContainer: {
    padding: 5,
    borderRadius: 5,
  },
  domainTypeText: {
    color: 'white',
    fontWeight: 'bold'
  }
});
export interface IDomainRowProps extends WithStyles<typeof styles>{
  domain?: IDomainInDTO;
  onClick: () => void;
  onEnableIrisRequest: () => void;
}

class DomainRow extends React.Component<IDomainRowProps, any> {
  public render() {
    const { domain, classes, onClick, onEnableIrisRequest } = this.props;
    const federatedMasterDomain = domain?.subscriptionDomainHostname ? getDomainNameFromHostname(domain?.subscriptionDomainHostname) : false;
    const isMasterDomainOfFederation = federatedMasterDomain && federatedMasterDomain === domain?.name;
    return (
      <Card className={classes.rowContainer}>
        <CardActionArea className={classes.rowActionBodyContainer} onClick={() => {onClick()}}>
          <div className={classes.rowBodyContainer}>
            <Avatar className={classes.avatar}>{domain?.name[0].toUpperCase()}</Avatar>
            <div>
              <h3 className={classes.rowTitle}>#{domain?.appId} - {domain?.name}</h3>
              <h4>{domain?.hostname}</h4>
              {federatedMasterDomain ? (
                <div className={classes.federatedContainer}>
                  <Business className={classes.icon}/>
                  {isMasterDomainOfFederation ? (
                    <h4><ReduxLanguage languageKey="domains.federatedMasterDomain" /></h4>
                  ) : (
                    <h4><ReduxLanguage languageKey="domains.federatedDomain" languageProps={{ domain: federatedMasterDomain }}/></h4>
                  )}
                </div>
              ): null}
              <div className={classes.domainTypeContainer}>
                <div className={classes.domainTypeInnerContainer}>
                  <h5 className={classes.domainTypeText}><ReduxLanguage languageKey={domain?.cloudCredits ? 'domains.domainWithCloudCredits' : 'domains.domainStandard'} /></h5>
                </div>
              </div>
              {domain&&(domain.enable1ris===DomainIrisStatusOptions.REQUIRED||domain.enable1ris===DomainIrisStatusOptions.ENABLED)?(
                <div className={classes.domainIrisContainer}>
                  {domain.enable1ris===DomainIrisStatusOptions.REQUIRED && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_IRIS_ENABLE)?(
                    <Button className={classes.domainIrisInnerContainer} style={{backgroundColor:ORANGE}}
                      onClick={(e)=>{e.stopPropagation();onEnableIrisRequest();}}>
                      <h5 className={classes.domainTypeText}>
                        <ReduxLanguage languageKey={'domains.domainIrisEnable'} />
                      </h5>
                    </Button>
                  ):(
                    <div className={classes.domainIrisInnerContainer} style={{backgroundColor:domain.enable1ris===DomainIrisStatusOptions.REQUIRED?ORANGE:BLUE}}>
                      <h5 className={classes.domainTypeText}>
                        <ReduxLanguage languageKey={domain.enable1ris===DomainIrisStatusOptions.REQUIRED ? 'domains.domainIrisRequired' : 'domains.domainIrisEnabled'} />
                      </h5>
                    </div>
                  )}
                </div>
              ):null}
            </div>
          </div>
        </CardActionArea>
      </Card>
    );
  }
}

export default withStyles(styles)(DomainRow);