import React from 'react';
import fetchConfig from '../../hoc/fetchConfig';
import * as configAPI from '../../repository/config/configAPI';
import { IConfigFrontendInDTO } from '../../repository/config/model/input/IConfigFrontendInDTO';
import * as jagoAxiosInstance from '../../repository/jago/JagoAxiosInstance';
import { getDefaultAPIBaseURL, getDefaultGoogleClientId } from '../../repository/utils';
import * as authService from '../../services/AuthService';

async function fetchConfigFunction(): Promise<void> {
  let config: IConfigFrontendInDTO = {
    baseUrl: getDefaultAPIBaseURL(),
    googleClientId: getDefaultGoogleClientId(),
  };

  if (process.env.NODE_ENV === 'production') {
    const response = await configAPI.fetchConfiguration();
    config = response.data;
  }
  
  authService.setGoogleClientId(config.googleClientId);
  jagoAxiosInstance.setBaseUrl(config.baseUrl);
}

class ConfigComponent extends React.Component {
  render() {
    return (this.props.children);
  }
}

export default fetchConfig(fetchConfigFunction)(ConfigComponent);
