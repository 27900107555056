import { Button, Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { ISubscriptionOutDTO } from '../../repository/jago/model/output/ISubscriptionOutDTO';
import { ISubscriptionsRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import NewPluginSubscriptionForm from '../Forms/NewPluginSubscriptionForm';
import { ModalTypes } from '../Modals/ModalTypes';
import ReduxLanguage from '../ReduxLanguage';
import SystemSubscriptionsList from './SystemSubscriptionsList';
import { SubscriptionLogicEnum } from '../../repository/jago/model/input/ISubscriptionLogicEnum';

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

export interface ICustomerWalletSectionState {
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IMainTierSubscriptionsSectionProps extends WithStyles<typeof styles> {
  language: string;
  marketplace?: IMarketplaceInDTO;
  mainTier?: ISubscriptionInDTO;
  onEditSubscription: (subscription: ISubscriptionInDTO) => void;
}

export type ComponentProps = IMainTierSubscriptionsSectionProps & IReduxProps;

class MainTierSubscriptionsSection extends React.Component<ComponentProps, ICustomerWalletSectionState> {

  public onAppendSubscriptions(page: number) {
    const { dispatch, mainTier, marketplace } = this.props;
    const params: ISubscriptionsRequestParamsDTO = { page };
    if (!marketplace || !mainTier) {
      return;
    }
    dispatch<any>(CreditsActions.appendMarketplaceMainTierSubscriptions(marketplace.id, mainTier.id, params));
  }

  public async openNewPluginSubscriptionForm() {
    const { dispatch, marketplace } = this.props;
    if (!marketplace) {
      return;
    }

    try {
      dispatch<any>(ModalsActions.showModal(`PLUGIN_SUBSCRIPTION_FORM_VIEW`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <NewPluginSubscriptionForm
              onCreatePluginSubscription={subscription => this.onCreatePluginSubscriptionRequest(subscription)}
            />
          ),
          titleMessageKey: 'credits.newPluginSubscription',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  public onCreatePluginSubscriptionRequest(subscription: ISubscriptionOutDTO) {
    const { dispatch } = this.props;
    const formattedSubscription: ISubscriptionOutDTO = {
      ...subscription,
      logic: SubscriptionLogicEnum.FIXED,
    }
    dispatch<any>(ModalsActions.showModal(`CREATE_PLUGIN_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newPluginSubscription',
        successMessageKey: 'forms.createPluginSubscriptionConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreatePluginSubscriptionConfirm(formattedSubscription),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_PLUGIN_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreatePluginSubscriptionConfirm(subscription: ISubscriptionOutDTO) {
    const { dispatch, marketplace, mainTier } = this.props;
    if (!marketplace || !mainTier) {
      return;
    }

    const formattedSubscription: ISubscriptionOutDTO = {
      ...subscription,
      mainTier: mainTier.id,
    }

    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createMarketplaceSubscription(marketplace.id, formattedSubscription));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('PLUGIN_SUBSCRIPTION_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_PLUGIN_CONFIRM_MODAL'));
      dispatch<any>(CreditsActions.fetchMarketplaceMainTierSubscriptions(marketplace.id, mainTier.id, { page: 0, pageSize: 50 }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createSubscriptionError',
        }
      }));
    }
  }
  
  public render() {
    const { subscriptions, classes, onEditSubscription } = this.props;
    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <WalletIcon className={classes.icon} />
              <h3 className={classes.title}>{<ReduxLanguage languageKey="forms.pluginAndService" />}</h3>
            </div>
            <Button
              variant="contained"
              style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', fontSize: 11, color: 'white' }}
              onClick={() => this.openNewPluginSubscriptionForm()}
            >
              <ReduxLanguage languageKey="credits.newPluginSubscription" />
            </Button>
          </div>
          <SystemSubscriptionsList
            subscriptions={subscriptions}
            onEditSubcription={onEditSubscription}
            onFetchMoreItems={page => this.onAppendSubscriptions(page)}
          />
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    subscriptions: state.credits.marketplaceSubscriptions.selectedMainTierSubcriptions,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(MainTierSubscriptionsSection));