import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CancelOutlined, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { IGatewaysSearchDTO } from '../../repository/jago/model/input/IGatewaysSearchDTO';
import { BLUE } from '../../style/StyleConsts';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IGatewayTableFiltersProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IGatewaysSearchDTO) => void;
  onResetSearch: () => void;
  onFilterDomains: (value: string) => void;
  onUpdateSearchValues: (values: Record<keyof IGatewaysSearchDTO, string>) => void;
  searchValues: Record<keyof IGatewaysSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
}

export interface IGatewayTableFiltersState {
  domainSelected: IDomainOptionDTO | undefined;
  searchText: string;
}


class GatewayTableFilters extends React.Component<IGatewayTableFiltersProps, IGatewayTableFiltersState> {
  
  constructor(props: IGatewayTableFiltersProps) {
    super(props);
    this.state = {
      domainSelected: undefined,
      searchText: '',
    }
  }
  public onSetSearchValue(field: keyof IGatewaysSearchDTO, value: string) {
    const { onUpdateSearchValues, searchValues } = this.props;
    searchValues[field] = value;
    onUpdateSearchValues(searchValues)
  }

  public onSearch() {
    const { onSearch, searchValues } = this.props;
    onSearch(searchValues);
  }


  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({ domainSelected: undefined, searchText: '' });
    onResetSearch();
  }

  public onSelectDomain(domain: IDomainOptionDTO) {
    if (domain) {
      this.onSetSearchValue('hostname', domain.value.hostname);
      this.setState({ domainSelected: domain });
    } else {
      this.onSetSearchValue('hostname', '');
      this.setState({ domainSelected: undefined });
    }
  }


  onFetchValues(value: string) {
    const { onFilterDomains } = this.props;
    onFilterDomains(value)
  }

  public render() {
    const { classes, searchValues, domainsOptions } = this.props;
    const { domainSelected, searchText } = this.state;
    const debouncedSearch = _.debounce((text) => this.onFetchValues(text), 500);
    return (
      <div className={classes.container}>
        <div style={{ marginLeft: 0, display: 'flex' }}>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey="gateways.deviceId" /> }
            variant="outlined"
            fullWidth
            type="search"
            style={{ marginRight: 10, height: 50 }}
            value={searchValues.deviceId}
            onChange={e => this.onSetSearchValue('deviceId', e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey="gateways.gatewayVersion" /> }
            variant="outlined"
            fullWidth
            type="search"
            style={{ marginRight: 10, height: 50 }}
            value={searchValues.swVersion}
            onChange={e => this.onSetSearchValue('swVersion', e.target.value)}
          />
          <Autocomplete
            options={domainsOptions}
            isClearable
            autoComplete
            inputValue={domainSelected ? domainSelected.label : searchText}
            value={domainSelected}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} variant="outlined" style={{ width: 250 }} label={<ReduxLanguage languageKey="forms.domainName" />}/>}
            placeholder={<ReduxLanguage languageKey={'forms.selectProduct'} />}
            onInputChange={(event, newInputValue) => { this.setState({ searchText: newInputValue }); debouncedSearch(newInputValue); }}
            onChange={(e, newValue) => this.onSelectDomain(newValue)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55 }),
            }} 
          />
          <Button
            variant="contained"
            style={{ backgroundColor: BLUE, color: 'white', marginLeft: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSearch()}
          >
            <Search style={{ color: 'white' }} />
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: 'red', color: 'white', marginLeft: 20, fontWeight: 'bold'  }}
            onClick={() => this.onResetFields()}
          >
            <CancelOutlined style={{ color: 'white' }} />
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GatewayTableFilters);
