import SeamlessImmutable from 'seamless-immutable';
import ModalsActionTypesEnum from './model/ModalsActionTypesEnum';
import * as _ from 'lodash';
import { ModalProps } from '../../components/Modals/ModalTypes';
import { ModalsActionsType } from './modals.actions';


export interface IModalsInterface {
  data?: ModalProps<Object>[];
}

const initialState = SeamlessImmutable<IModalsInterface>({
  data: [],
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IModalsInterface> = initialState, action: ModalsActionsType) {
  switch (action.type) {
    case ModalsActionTypesEnum.SHOW_MODAL: 
    return state.set('data', state.data!.concat([{ id: action.payload.id, data: action.payload.data }]));
    case ModalsActionTypesEnum.HIDE_MODAL: {
      const updatedState = _.filter(state.data, d => d.id !== action.payload);
      return state.set('data', updatedState);
    }
    default:
      return state;
  }
}
