import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import { RED } from '../../style/StyleConsts';
import ReduxLanguage from '../ReduxLanguage';
import { IModalViewProps } from './ModalTypes';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface TextfieldModalState {
  text: string;
}

export interface TextfieldModalProps {
  icon?: JSX.Element;
  titleMessageKey?: string;
  successMessageKey: string;
  initialText: string;
  fieldLabel: string;
  confirmMessageKey?: string;
  cancelMessageKey?: string;
  onConfirm?: (text: string) => void;
  onCancel?: () => void;
};

interface ComponentProps extends IModalViewProps<TextfieldModalProps> {}

export default class TextfieldModal extends React.Component<ComponentProps, TextfieldModalState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      text: props.modalProps.initialText?props.modalProps.initialText:"",
    };
  }

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { cancelMessageKey, fieldLabel, onConfirm, confirmMessageKey, icon, titleMessageKey, successMessageKey } = this.props.modalProps;
    const { text } = this.state;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <div style={{ padding: 20, height: 250, width: 450, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {icon ? icon : null}
          <h3 style={{ color: '#3f3f3f' }}>{titleMessageKey?<ReduxLanguage languageKey={titleMessageKey} />:""}</h3>
          <h4 style={{ fontWeight: 100, marginTop: 0, textAlign: 'center' }}>{<ReduxLanguage languageKey={successMessageKey} />}</h4>
          <TextField
            id="outlined-basic"
            label={fieldLabel?fieldLabel:""}
            multiline
            maxRows={4}
            variant="outlined"
            fullWidth
            required
            value={text}
            style={{}}
            onChange={e => this.setState({text:e.target.value})}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {onConfirm ? (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20, marginRight: 20, backgroundColor: '#5AC0B1', width: 200, borderRadius: 100 }}
              onClick={() => onConfirm(text)}
            >
            <ReduxLanguage languageKey={confirmMessageKey || 'forms.save'}/>
          </Button>
        ) : null}
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, backgroundColor: RED, width: 200, borderRadius: 100 }}
            onClick={() => this.handleClose()}
          >
            <ReduxLanguage languageKey={cancelMessageKey || 'forms.close'}/>
          </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const {hideModal, modalProps} = this.props;
    hideModal();
    if (modalProps && modalProps.onCancel) {
      modalProps.onCancel();
    }
  }
}
