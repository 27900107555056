import { Card } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import * as React from "react";
import gatewayIseoImg from '../../assets/hypergate_iseo.png';
import gatewayProImg from '../../assets/hypergate_pro.png';
import { IGatewayDeploymentStatusInDTO } from "../../repository/jago/model/input/IGatewayDeploymentStatusInDTO";
import { IGatewayDeviceTypeEnum } from "../../repository/jago/model/input/IGatewayInDTO";
import { BLUE } from "../../style/StyleConsts";
import { IGatewayDeploymentStatusEnum } from "../../repository/jago/model/input/IGatewayDeploymentStatusEnum";
import ReduxLanguage from "../ReduxLanguage";
import { getGatewayDeploymentStatusColor } from "../../utils/Utils";

export interface IDeploymentStatusProps {
  status: IGatewayDeploymentStatusEnum | undefined;
}

const DeploymentStatusView: React.FunctionComponent<IDeploymentStatusProps> = ({ status }) => (
  <div>
    {status ? (
      <div style={{ backgroundColor: getGatewayDeploymentStatusColor(status), padding: 10, borderRadius: 5 }} >
        <h5 style={{ color: 'white', fontWeight: 'bold', margin: 0 }}><ReduxLanguage languageKey={`gateways.${status}`} /></h5>
      </div>
    ): null}
  </div>
)

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'row',
    position: 'relative',
    minHeight: 50,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 15
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  domainTypeContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  domainTypeInnerContainer: {
    padding: 5,
    backgroundColor: BLUE,
    borderRadius: 5,
  },
  domainTypeText: {
    color: 'white',
    fontWeight: 'bold'
  },
  gatewayImage: {
    width: 60,
    height: 50,
    marginRight: 10,
    marginTop: 5
  },
});
export interface IDeploymentStatusRowProps extends WithStyles<typeof styles>{
  deploymentGateway?: IGatewayDeploymentStatusInDTO;
}

class DeploymentStatusRow extends React.Component<IDeploymentStatusRowProps, any> {
  public render() {
    const { deploymentGateway, classes } = this.props;
    return (
      <Card className={classes.rowContainer}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <img alt="gateway" src={deploymentGateway?.gateway?.deviceType === IGatewayDeviceTypeEnum.HYPERGATE_ISEO ? gatewayIseoImg : gatewayProImg} className={classes.gatewayImage} /> 
          <div>
            <h2 className={classes.rowTitle}>{deploymentGateway?.gateway?.name}</h2>
            <h2 className={classes.rowTitle}>{deploymentGateway?.gateway?.deviceId}</h2>
          </div>
        </div>
        <DeploymentStatusView status={deploymentGateway?.status} />
      </Card>
    );
  }
}

export default withStyles(styles)(DeploymentStatusRow);