import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Spinner from './Spinner';
import { IState } from '../../redux/store';

export interface ISpinnerOverlayProps {
  isSpinnerVisible: boolean;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = ISpinnerOverlayProps & IReduxProps;

const SpinnerOverlay: FunctionComponent<ComponentProps> = ({ isSpinnerVisible }) => (
  <span>
    {isSpinnerVisible && (
      <div className="spinner-overlay">
        <Spinner />
      </div>
    )}
  </span>
);

function mapStateToProps(state: IState) {
  return {
    isSpinnerVisible: state.settings.isSpinnerVisible,
  };
}

const connector = connect(mapStateToProps);

export default connector(SpinnerOverlay);
