import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import SeamlessImmutable from 'seamless-immutable';
import _ from 'lodash';
import { ICardInDTO } from '../../repository/jago/model/input/ICardInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { ICustomerInDTO } from './../../repository/jago/model/input/ICustomerInDTO';
import { IWalletCustomerDTO } from './../../repository/jago/model/input/IWalletCustomerDTO';
import { IWalletDetailedDTO } from './../../repository/jago/model/input/IWalletDetailedDTO';
import { CreditsActionsType } from './credits.actions';
import CreditsActionTypesEnum from './model/CreditsActionTypesEnum';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IDomainInDTO } from '../../repository/jago/model/input/IDomainInDTO';

export interface IMarketplaceInterface {
  data: PaginatedDataDTO<IMarketplaceInDTO> | {};
  selectedMarketplace?: IMarketplaceInDTO;
}

export interface IClusterInterface {
  data: PaginatedDataDTO<IClusterInDTO> | {};
}

export interface IMarketplaceSubscriptionsInterface {
  data: PaginatedDataDTO<ISubscriptionInDTO> | {};
  selectedMainTier?: ISubscriptionInDTO;
  selectedMainTierSubcriptions: PaginatedDataDTO<ISubscriptionInDTO> | {};
}

export interface ICreditsInterface {
  clusters: IClusterInterface,
  marketplaces: IMarketplaceInterface,
  customers: {
    data: PaginatedDataDTO<ICustomerInDTO> | {};
    selectedDetailedCustomer?: ICustomerInDTO;
  },
  wallets: {
    data: PaginatedDataDTO<IWalletCustomerDTO> | {};
    selectedWallet: IWalletDetailedDTO | {};
  },
  marketplaceSubscriptions: IMarketplaceSubscriptionsInterface,
  systemCards: {
    data: PaginatedDataDTO<ICardInDTO> | {};
    wallets: {
      data: PaginatedDataDTO<IWalletCustomerDTO> | {};
    },
    customersSearch: {
      data: PaginatedDataDTO<ICustomerInDTO> | {};
    },
    plants: {
      data: PaginatedDataDTO<IPlantInDTO> | {};
    },
    selectedCustomer: ICustomerInDTO | {};
  },
  domains: {
    data: PaginatedDataDTO<IDomainInDTO> | {};
  },
}

const initialState = SeamlessImmutable<ICreditsInterface>({
  clusters: {
    data: {},
  },
  marketplaces: {
    data: {},
  },
  customers: {
    data: {},
    selectedDetailedCustomer: undefined,
  },
  wallets: {
    data: {},
    selectedWallet: {},
  },
  marketplaceSubscriptions: {
    data: {},
    selectedMainTierSubcriptions: {},
  },
  systemCards: {
    data: {},
    wallets: {
      data: {}
    },
    customersSearch: {
      data: {}
    },
    plants: {
      data: {}
    },
    selectedCustomer: {}
  },
  domains: {
    data: {}
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<ICreditsInterface> = initialState, action: CreditsActionsType) {
  switch (action.type) {
    case CreditsActionTypesEnum.SAVE_CLUSTERS:
      return state.setIn(['clusters', 'data'], action.payload);
    case CreditsActionTypesEnum.SAVE_MARKETPLACES:
      return state.setIn(['marketplaces', 'data'], action.payload);
    case CreditsActionTypesEnum.SET_SELECTED_MARKETPLACE:
      return state.setIn(['marketplaces', 'selectedMarketplace'], action.payload);
    case CreditsActionTypesEnum.SAVE_CUSTOMERS:
      return state.setIn(['customers', 'data'], action.payload);
    case CreditsActionTypesEnum.SET_SELECTED_DETAILED_CUSTOMER:
      return state.setIn(['customers', 'selectedDetailedCustomer'], action.payload);
    case CreditsActionTypesEnum.SAVE_WALLETS:
      return state.setIn(['wallets', 'data'], action.payload);
    case CreditsActionTypesEnum.APPEND_WALLET: {
      const wallets = state.wallets.data.asMutable() as PaginatedDataDTO<IWalletCustomerDTO>;
      if (!wallets || !wallets.content) {
        return state;
      }
      return state.setIn(['wallets', 'data', 'content'], [...wallets.content, action.payload]);
    }
    case CreditsActionTypesEnum.UPDATE_WALLET: {
      const wallets = state.wallets.data.asMutable() as PaginatedDataDTO<IWalletCustomerDTO>;
      if (wallets && wallets.content) {
        const walletToUpdateIndex = _.findIndex(wallets.content, { id: action.payload.id });
        if (walletToUpdateIndex > -1) {
          return state.setIn(['wallets', 'data', 'content', walletToUpdateIndex as unknown as string], action.payload);
        }
      }
      return state;
    }
    case CreditsActionTypesEnum.SET_SELECTED_WALLET:
      return state.setIn(['wallets', 'selectedWallet'], action.payload);
    case CreditsActionTypesEnum.SAVE_MARKETPLACE_SUBSCRIPTIONS:
      return state.setIn(['marketplaceSubscriptions', 'data'], action.payload);
    case CreditsActionTypesEnum.SET_SELECTED_MARKETPLACE_MAIN_TIER:
      return state.setIn(['marketplaceSubscriptions', 'selectedMainTier'], action.payload);
    case CreditsActionTypesEnum.SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS:
      return state.setIn(['marketplaceSubscriptions', 'selectedMainTierSubcriptions'], action.payload);
    case CreditsActionTypesEnum.SAVE_SYSTEM_CARDS:
      return state.setIn(['systemCards', 'data'], action.payload);
    case CreditsActionTypesEnum.SAVE_WALLETS_ALL:
      return state.setIn(['systemCards', 'wallets', 'data'], action.payload);
    case CreditsActionTypesEnum.SAVE_CUSTOMERS_SEARCH:
      return state.setIn(['systemCards', 'customersSearch', 'data'], action.payload);
    case CreditsActionTypesEnum.SAVE_CUSTOMER_PLANTS:
      return state.setIn(['systemCards', 'plants', 'data'], action.payload);
    case CreditsActionTypesEnum.SAVE_ALL_DOMAINS:
      return state.setIn(['domains', 'data'], action.payload);
    default:
      return state;
  }
}
