enum ActivationsActionTypesEnum {
  SAVE_CLUSTERS = 'SAVE_CLUSTERS',
  SAVE_ACTIVATIONS = 'SAVE_ACTIVATIONS',
  SAVE_ACTIVATION_DETAILED = 'SAVE_ACTIVATION_DETAILED',
  SAVE_MARKETPLACES = 'SAVE_MARKETPLACES',
  SAVE_MARKETPLACE_SUBSCRIPTIONS = 'SAVE_MARKETPLACE_SUBSCRIPTIONS',
  SET_SELECTED_MARKETPLACE_MAIN_TIER= 'SET_SELECTED_MARKETPLACE_MAIN_TIER',
  SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS = 'SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS',
  SAVE_CUSTOMERS_SEARCH = 'SAVE_CUSTOMERS_SEARCH',
  SAVE_VARS_SEARCH = 'SAVE_VARS_SEARCH',
  SAVE_KAMS = 'SAVE_KAMS',
}

export default ActivationsActionTypesEnum;
