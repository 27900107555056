import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ProductsList from '../../components/Parameters/ProductsList';
import ReduxLanguage from '../../components/ReduxLanguage';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as ProductActions from '../../redux/products/products.actions';
import { IState } from '../../redux/store';
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0,
  },
  titleText: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  }
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IProductsPageProps extends WithStyles<typeof styles> {
  language: string;
  products: IProductInDTO[];
}


export type ComponentProps = IProductsPageProps & IReduxProps;

class ProductsPage extends React.Component<ComponentProps> {


  public async componentDidMount() {
    const { dispatch } = this.props;
    try {
      await dispatch<any>(ProductActions.fetchSofiaProducts());
    } catch (error) {
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  render() {
    const { products, classes } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.header}>
          <h2 className={classes.titleText}>{<ReduxLanguage languageKey='credits.products' />}</h2>
        </div>
        <ProductsList products={products}/>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    products: state.products.products,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(ProductsPage as any));