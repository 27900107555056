import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CancelOutlined, Search } from '@material-ui/icons';
import React from 'react';
import { ICardSearchDTO } from '../../repository/jago/model/input/ICardSearchDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import { BLUE } from '../../style/StyleConsts';
import _ from 'lodash';
import Permission from '../Permission/Permission';
import Select from 'react-select';
import ReduxLanguage from '../ReduxLanguage';
import { CardStatusOptions } from '../../repository/jago/model/input/ICardInDTO';
import translations from '../../translations/i18next';
import { VAR_USERS_ROLES } from '../../utils/Utils';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface ICardSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: ICardSearchDTO) => void;
  onResetSearch: () => void;
  totalElements: string | number;
  onCreateNewEntityButtonClick: () => void;
}

export interface ICardStatusOption {
  label: string;
  value: string;
  original: string;
}

export interface ICardSearchBarState {
  searchValues: Record<keyof ICardSearchDTO, string>;
  statusSelected: ICardStatusOption | undefined;
}

class CardSearchBar extends React.Component<ICardSearchBarProps, ICardSearchBarState> {
  constructor(props: ICardSearchBarProps) {
    super(props);
    this.state = {
      searchValues: {
        crmId: '',
        sofiaProjectId: '',
        status: undefined,
        used: undefined,
        invoiceId: '',
        code: '',
      },
      statusSelected: undefined,
    }
  }

  public onSetSearchValue(field: keyof ICardSearchDTO, value: string) {
    const { searchValues } = this.state;
    searchValues[field] = value;
    this.setState({ searchValues });
  }

  public onSearch() {
    const { onSearch } = this.props;
    onSearch(this.state.searchValues);
  }

  public async onSelectStatus(statusOption?: ICardStatusOption) {
    const selectedValue = statusOption?statusOption.original:undefined;
    const status = selectedValue?(
      selectedValue==='DISABLED'?'CREATED':
      selectedValue==='ENABLED'||selectedValue==='USED'?'ACTIVE':
      undefined):undefined
    const used = selectedValue?(
      selectedValue==='DISABLED'||selectedValue==='ENABLED'?'false':
      selectedValue==='USED'?'true':
      undefined):undefined
    this.onSetSearchValue('status', status);
    this.onSetSearchValue('used', used);
    this.setState({ statusSelected: statusOption });
    setTimeout(() => this.onSearch(), 200);
  }

  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({ searchValues: { crmId: '', sofiaProjectId: '', status: undefined, used: undefined, invoiceId: '' }, statusSelected: undefined });
    onResetSearch();
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick, totalElements } = this.props;
    const { searchValues, statusSelected } = this.state;

    const statusOptionsFormatted: ICardStatusOption[] = [
      {
        label: translations.t('credits.cardStatusUnused'),
        value: translations.t('credits.cardStatusUnused'),
        original: 'ENABLED'
      },
      {
        label: translations.t('credits.cardStatusInactive'),
        value: translations.t('credits.cardStatusInactive'),
        original: 'DISABLED'
      },
      {
        label: translations.t('credits.cardStatusUsed'),
        value: translations.t('credits.cardStatusUsed'),
        original: 'USED'
      },
    ]

    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))

    return (
      <div className={classes.container}>
        <div style={{ marginLeft: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {isVar ? null : (
            <TextField
              id="outlined-basic"
              label="Code"
              variant="outlined"
              type="search"
              style={{ marginRight: 10 }}
              value={searchValues.code}
              onChange={e => this.onSetSearchValue('code', e.target.value)}
            />
          )}
          {isVar ? null : (
            <TextField
              id="outlined-basic"
              label="CRM ID"
              variant="outlined"
              type="search"
              style={{ marginRight: 10 }}
              value={searchValues.crmId}
              onChange={e => this.onSetSearchValue('crmId', e.target.value)}
            />
          )}
          {isVar?null:(
            <TextField
              id="outlined-basic"
              label="Sofia Project ID"
              variant="outlined"
              type="search"
              style={{ marginRight: 10 }}
              value={searchValues.sofiaProjectId}
              onChange={e => this.onSetSearchValue('sofiaProjectId', e.target.value)}
            />
          )}
          {isVar?null:(
            <TextField
              id="outlined-basic"
              label="Invoice"
              variant="outlined"
              type="search"
              style={{ marginRight: 10 }}
              value={searchValues.invoiceId}
              onChange={e => this.onSetSearchValue('invoiceId', e.target.value)}
            />
          )}
          <Select
            isClearable
            key={`my_unique_select_key__${searchValues.status}__${searchValues.used}`}
            value={statusSelected}
            options={statusOptionsFormatted}
            placeholder={<ReduxLanguage languageKey={'recharges.statusSelection'} />}
            onChange={(e) => {this.onSelectStatus(e)}}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200 }),
            }} 
          />
          <Button
            variant="contained"
            style={{ backgroundColor: BLUE, color: 'white', marginLeft: 10, fontWeight: 'bold'  }}
            onClick={() => this.onSearch()}
          >
            <Search style={{ color: 'white' }} />
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: 'red', color: 'white', marginLeft: 10, fontWeight: 'bold'  }}
            onClick={() => this.onResetFields()}
          >
            <CancelOutlined style={{ color: 'white' }} />
          </Button>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.CARD_CREATE}>
            <Button
              variant="contained"
              style={{ backgroundColor: '#5AC0B1', color: 'white', marginLeft: 10, marginRight: 10, fontWeight: 'bold'  }}
              onClick={() => onCreateNewEntityButtonClick()}
            >
              <ReduxLanguage languageKey="forms.add" />
            </Button>
          </Permission>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CardSearchBar);
