import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Share } from "@material-ui/icons";
import moment from "moment";
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import { IState } from "../../redux/store";
import { IGatewayDeploymentDetailedInDTO } from "../../repository/jago/model/input/IGatewayDeploymentDetailedInDTO";
import ReduxLanguage from "../ReduxLanguage";
import DeploymentStatusList from "./DeploymentStatusList";

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IDeploymentDetailsViewProps extends WithStyles<typeof styles>{
  deployment: IGatewayDeploymentDetailedInDTO;
}

export type ComponentProps = IDeploymentDetailsViewProps & IReduxProps;
class DeploymentDetailsView extends React.Component<ComponentProps, any> {
  public render() {
    const { deployment, classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerInner}>
            <Share className={classes.icon} />
            <div className={classes.headerTextContainer}>
              <h1 className={classes.title}>{deployment.artifact && deployment.artifact.name ? deployment.artifact.name : ''}</h1>
              {deployment?.scheduledAt ? <h4><ReduxLanguage languageKey="gateways.deploymentScheduledAt" />: {moment(deployment?.scheduledAt).format('LLL')}</h4> : null}
            </div>
          </div>
        </div>
        <DeploymentStatusList
          deploymentGateways={deployment.gateways}
          onFetchMoreItems={(page) => console.log(page)}
          
        />
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(DeploymentDetailsView as any));