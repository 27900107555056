

import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { IWalletStatusEnum } from '../../repository/jago/model/input/IWalletStatusEnum';
import { getWalletStatusColor } from '../../utils/Utils';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  title: {
    color: '#3f3f3f',
    fontWeight: 'bold'
  },
  description: {
    color: '#3f3f3f',
  },
  icon: {
    color: '#3f3f3f',
    fontSize: 35,
  }
});



export interface IWalletStatusProps {
  status: IWalletStatusEnum | undefined;
}


const WalletStatusView: React.FunctionComponent<IWalletStatusProps> = ({ status }) => (
  <div>
    {status ? (
      <div style={{ backgroundColor: getWalletStatusColor(status), padding: 5, marginLeft: 5, borderRadius: 4 }} >
        <h5 style={{ color: 'white', fontWeight: 'bold', margin: 0 }}><ReduxLanguage languageKey={`credits.${status}`} /></h5>
      </div>
    ): null}
  </div>
)

export default withStyles(styles)(WalletStatusView);
