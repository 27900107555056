import axios from 'axios';

const luckeyWebSiteInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://sofialocks.com/',
  auth: {
    username: 'giovanni@sofialocks.com',
    password: 'Z8AKeRoUz*NX_i.b',
  },
});

export function fetchWhatsNewArticles(languagePrexif) {
  const restParams = {
    
  };
  return luckeyWebSiteInstance.get(`/wp-json/wp/v2/posts?_fields=id,date,link,excerpt,title,content,_embedded,_links&_embed&categories=54&per_page=100&lang=${languagePrexif}`, restParams);
}