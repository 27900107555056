
export enum IWalletStatusEnum {
  PENDING = 'PENDING',
  READY_TO_ACTIVATE = 'READY_TO_ACTIVATE',
  ACTIVE = 'ACTIVE',
};

export const WalletStatusMap = {
  PENDING: ['PENDING', 'READY_TO_ACTIVATE', 'ACTIVE'],
  READY_TO_ACTIVATE: ['READY_TO_ACTIVATE', 'ACTIVE'],
  ACTIVE: []
}