import { Button, Card, Divider } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import LicenseIcon from "@material-ui/icons/BookmarkBorder";
import moment from "moment";
import * as React from "react";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IWalletSubscriptionInDTO } from "../../../repository/jago/model/input/IWalletSubscriptionInDTO";
import { GREEN } from "../../../style/StyleConsts";
import ReduxLanguage from "../../ReduxLanguage";
import SubscriptionCostDetails from '../../MarketplaceSubscriptions/SubscriptionCostDetails';
import AbilityProvider from "../../../services/PermissionService/AbilityProvider";
import { PERMISSIONS, Roles } from "../../../services/PermissionService/PermissionConstants";
import Permission from "../../Permission/Permission";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    padding: 15,
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowHeaderInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    fontSize: 15
  },
  creditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  }
});

export interface IWalletSubscriptionRowProps extends WithStyles<typeof styles>{
  walletSubscription?: IWalletSubscriptionInDTO;
  onDeleteWalletSubscription?: () => void;
}

class WalletSubscriptionRow extends React.Component<IWalletSubscriptionRowProps, any> {
  public render() {
    const { walletSubscription, classes, onDeleteWalletSubscription } = this.props;
    const isDomainRow = walletSubscription && walletSubscription.subscription && walletSubscription.subscription?.resource === 'DOMAIN';

    return (
      <Card className={classes.rowContainer}>
        <div className={classes.rowHeaderContainer}>
          <div className={classes.rowHeaderInnerContainer}>
            <h3 className={classes.rowTitle}>{walletSubscription?.subscription?.name}</h3>
            <h4 className={classes.rowTitle} style={{ marginLeft: 3 }}>({walletSubscription?.subscription?.resource})</h4>
          </div>
          {isDomainRow ? (
            <div className={classes.rowHeaderInnerContainer}>
              <LicenseIcon style={{ color: GREEN }} />
              <h4 style={{ margin: 0 }}>{<ReduxLanguage languageKey="credits.tierSubscription" />}</h4>
            </div>
          ) : null}
          {!walletSubscription?.subscription.visible ? 
            <VisibilityOffIcon className={classes.icon} />
          : null}
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_SUBSCRIPTION_DELETE}>
            {!isDomainRow && onDeleteWalletSubscription ? (
              <Button
                variant="contained"
                className={classes.actionButton}
                style={{ backgroundColor: 'red' }}
                onClick={() => onDeleteWalletSubscription()}
              >
                <Delete className={classes.icon} />
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="forms.delete" /></h5>
              </Button>
            ) : null}
          </Permission>
        </div>
        <Divider style={{ marginBottom: 10, marginTop: 3 }} />
        {walletSubscription?.subscription ? (
          <SubscriptionCostDetails
            subscription={walletSubscription.subscription}
          />
          ) : null}
        <h4><span style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.createdAt" /></span>: {moment(walletSubscription?.createdAt).format('LL')}</h4>
      </Card>
    );
  }
}

export default withStyles(styles)(WalletSubscriptionRow);