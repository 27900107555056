import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import SeamlessImmutable from 'seamless-immutable';
import { IActivationInDTO } from '../../repository/jago/model/input/IActivationInDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { ICustomerInDTO } from './../../repository/jago/model/input/ICustomerInDTO';
import { ActivationsActionsType } from './activations.actions';
import ActivationsActionTypesEnum from './model/ActivationsActionTypesEnum';
import { IKamInDTO } from '../../repository/jago/model/input/IKamInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';

export interface IMarketplaceInterface {
  data: PaginatedDataDTO<IMarketplaceInDTO> | {};
  selectedMarketplace?: IMarketplaceInDTO;
}

export interface IClusterInterface {
  data: PaginatedDataDTO<IClusterInDTO> | {};
}

export interface IMarketplaceSubscriptionsInterface {
  data: PaginatedDataDTO<ISubscriptionInDTO> | {};
  selectedMainTier?: ISubscriptionInDTO;
  selectedMainTierSubcriptions: PaginatedDataDTO<ISubscriptionInDTO> | {};
}

export interface IActivationsInterface {
  clusters: IClusterInterface,
  marketplaces: IMarketplaceInterface,
  marketplaceSubscriptions: IMarketplaceSubscriptionsInterface,
  activations: {
    data: PaginatedDataDTO<IActivationInDTO> | {};
  }
  activationDetailed: {
    data: IActivationInDTO | {};
  },
  customersSearch: {
    data: PaginatedDataDTO<ICustomerInDTO> | {};
  },
  varsSearch: {
    data: PaginatedDataDTO<IVarInDTO> | {};
  },
  kams: {
    data: PaginatedDataDTO<IKamInDTO> | {};
  }
}

const initialState = SeamlessImmutable<IActivationsInterface>({
  clusters: {
    data: {},
  },
  marketplaces: {
    data: {},
  },
  marketplaceSubscriptions: {
    data: {},
    selectedMainTierSubcriptions: {},
  },
  activations: {
    data: {},
  },
  activationDetailed: {
    data: {}
  },
  customersSearch: {
    data: {},
  },
  varsSearch: {
    data: {},
  },
  kams: {
    data: {},
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IActivationsInterface> = initialState, action: ActivationsActionsType) {
  switch (action.type) {
    case ActivationsActionTypesEnum.SAVE_CLUSTERS:
      return state.setIn(['clusters', 'data'], action.payload);
    case ActivationsActionTypesEnum.SAVE_ACTIVATIONS:
      return state.setIn(['activations', 'data'], action.payload);
    case ActivationsActionTypesEnum.SAVE_ACTIVATION_DETAILED:
      return state.setIn(['activationDetailed', 'data'], action.payload);
    case ActivationsActionTypesEnum.SAVE_MARKETPLACES:
      return state.setIn(['marketplaces', 'data'], action.payload);
    case ActivationsActionTypesEnum.SAVE_MARKETPLACE_SUBSCRIPTIONS:
      return state.setIn(['marketplaceSubscriptions', 'data'], action.payload);
    case ActivationsActionTypesEnum.SET_SELECTED_MARKETPLACE_MAIN_TIER:
      return state.setIn(['marketplaceSubscriptions', 'selectedMainTier'], action.payload);
    case ActivationsActionTypesEnum.SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS:
      return state.setIn(['marketplaceSubscriptions', 'selectedMainTierSubcriptions'], action.payload);
    case ActivationsActionTypesEnum.SAVE_CUSTOMERS_SEARCH:
      return state.setIn(['customersSearch', 'data'], action.payload);
    case ActivationsActionTypesEnum.SAVE_VARS_SEARCH:
      return state.setIn(['varsSearch', 'data'], action.payload);
    case ActivationsActionTypesEnum.SAVE_KAMS:
      return state.setIn(['kams', 'data'], action.payload);
    default:
      return state;
  }
}
