export interface IDomainFormDTO {
  name: string,
  productId: number,
  emailEnabled?: boolean,
  openAppLinkAndroid?: string,
  openAppLinkIos?: string,
  externalRef?: string,
  startDate?: string,
};

export interface IDomainInDTO {
  id?: number;
  appId: number;
  companyId: number;
  hostname: string;
  installationMode: boolean;
  name: string;
  serviceName: string;
  subscriptionDomainHostname?: string;
  subscriptionDomainCompanyId?: number;
  createdAt?: number;
  deleted?: boolean;
  cloudCredits?: boolean;
  enable1ris?: string;
}

export enum DomainIrisStatusOptions {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  ENABLED = 'ENABLED',
}