import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { Button, Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CustomersIcon from '@material-ui/icons/GroupOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import Visibility from '@material-ui/icons/Visibility';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import * as CustomerDecoder from '../../codec/customerDTOCodec';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import IClusterInDTO from "../../repository/jago/model/input/IClusterInDTO";
import { ICustomerFormDTO } from "../../repository/jago/model/input/ICustomerFormDTO";
import { ICustomerInDTO } from "../../repository/jago/model/input/ICustomerInDTO";
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import CustomersTable from "../Customers/CustomersTable";
import CustomerForm from "../Forms/CustomerForm";
import { ModalTypes } from "../Modals/ModalTypes";
import ReduxLanguage from "../ReduxLanguage";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import translations from "../../translations/i18next";
import { Edit } from "@material-ui/icons";

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: 'white',
    height: 'calc(100vh - 350px)',
    width: '100%',
    marginTop: 10
  }
});

export interface IVarCustomersSectionState {
  pageSize: number;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IVarCustomersSectionProps extends WithStyles<typeof styles> {
  language: string;
  customers: PaginatedDataDTO<ICustomerInDTO>;
  VAR: IVarInDTO;
  onGoToCustomerDetailsPage?: (customerId: number) => void;
}

export type ComponentProps = IVarCustomersSectionProps & IReduxProps;

class VarCustomersSection extends React.Component<ComponentProps, IVarCustomersSectionState> {

  private tableColumns: GridColumns<ICustomerInDTO>;

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      pageSize: 100,
    }
  }

  async onOpenEditCustomerForm() {
    const { dispatch, selectedCustomer, vars } = this.props;
    if (selectedCustomer) {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.fetchMarketplaces());
      await dispatch<any>(CreditsActions.fetchClusters());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      const customerFormData = CustomerDecoder.decodeCustomerContentToForm(selectedCustomer,(vars as PaginatedDataDTO<IVarInDTO>).content);
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_CUSTOMER_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <CustomerForm
              customer={customerFormData}
              onCreateNewCustomer={(newCustomerData: ICustomerFormDTO) => this.onEditCustomerRequest(selectedCustomer.id, newCustomerData)}
              isVar={false}
            />
          ),
          titleMessageKey: 'forms.customerFormTitle',
        }
      }));
    }
  }

  public onEditCustomerRequest(customerId: number, newCustomerData: ICustomerFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editCustomer',
        successMessageKey: 'credits.editCreationConfirm',
        confirmMessageKey: 'credits.editCustomer',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditCustomerConfirm(customerId, newCustomerData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_DOMAIN_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditCustomerConfirm(customerId: number, newCustomerData: ICustomerFormDTO) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const customerUpdated = await dispatch<any>(CreditsActions.editCustomer(customerId, newCustomerData));
      dispatch<any>(CreditsActions.setSelecteCustomer(customerUpdated));
      await dispatch<any>(VarsActions.fetchVarCustomers(VAR.id, { page: 0, pageSize: pageSize }));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_CUSTOMER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerError',
        }
      }));
    }
  }

  public onCreateNewCustomerRequest(newCustomerData: ICustomerFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_CUSTOMER_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newCustomer',
        successMessageKey: 'credits.customerCreationConfirm',
        confirmMessageKey: 'credits.createCustomer',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewCustomerConfirm(newCustomerData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_DOMAIN_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateNewCustomerConfirm(newCustomerData: ICustomerFormDTO) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_CUSTOMER_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createNewCustomer(newCustomerData,undefined));
      await dispatch<any>(VarsActions.fetchVarCustomers(VAR.id, { page: 0, pageSize: pageSize }));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_CUSTOMER_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCustomerError',
        }
      }));
    }
  }

  public async onRowClick(customer: ICustomerInDTO) {
    const { dispatch } = this.props;
    try {
      
      dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_EDIT_VAR_MODAL`));
      this.props.onGoToCustomerDetailsPage(customer.id)
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_VAR_CUSTOMER_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getCustomerDetailsError',
        }
      }));
    }
  }

  async onOpenNewCustomerForm() {
    const { dispatch, VAR, clusters } = this.props;
    dispatch<any>(SettingsActions.setSpinnerVisible(true));
    await dispatch<any>(CreditsActions.fetchMarketplaces());
    await dispatch<any>(CreditsActions.fetchClusters());
    dispatch<any>(SettingsActions.setSpinnerVisible(false));
    const clustersData = (clusters as PaginatedDataDTO<IClusterInDTO>).content
    let foundCluster = clustersData.filter(e=>e.id===VAR.clusterId).length>0?clustersData.filter(e=>e.id===VAR.clusterId)[0]:null
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_CUSTOMER_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
      modalProps: {
        content: (
          <CustomerForm
            onCreateNewCustomer={(newCustomerData: ICustomerFormDTO) => this.onCreateNewCustomerRequest(newCustomerData)}
            forcedVar={VAR}
            forcedCluster={foundCluster}
            isVar={false}
          />
        ),
        titleMessageKey: 'forms.customerFormTitle',
      }
    }));
  }

  async onFetchCustomersOnPage (page) {
    const { dispatch, VAR } = this.props;
    const { pageSize } = this.state;
    await dispatch<any>(VarsActions.fetchVarCustomers(VAR.id, { page, pageSize: pageSize }));
  }

  async onPageSizeChange(pageSize: number) {
    const { dispatch, VAR } = this.props;
    this.setState({ pageSize }, async () => {
      await dispatch<any>(VarsActions.fetchVarCustomers(VAR.id, { page: 0, pageSize }));
    });
  }
  
  public render() {
    const { customers, dispatch, classes } = this.props;
    const { pageSize } = this.state;
    const canCreateVarCustomer = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.VAR_UPDATE) && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.CUSTOMER_CREATE)

    this.tableColumns = [
      {
        field: 'name',
        headerName: translations.t('gateways.name'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 350,
        flex: 1,
        renderCell: (params: GridRenderCellParams<any>) => {
          const customer = params.row;
          return (
            <div>
              <h3 style={{ margin: 0,  fontWeight: 'bold' }}>{customer.name}</h3>
            </div>
        )}
      },
      {
        field: 'actions',
        headerName: translations.t('forms.actions'),
        type: 'string',
        sortable: false,
        filterable: false,
        minWidth: 350,
        flex: 1,
        renderCell: (params: any) => {
          return (
            <div>
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'darkcyan',
                  flexShrink: 0,
                  marginRight: 20,
                  width: 'fit-content',
                  color: 'white',
                  padding: 4,
                  paddingLeft: 10,
                  paddingRight: 10,
                  fontWeight: 'bold'}}
                onClick={(e) => {
                  e.stopPropagation();
                  this.onRowClick(params)
                }}
              >
                <Visibility style={{ fontSize: 15 }}/>
                <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5 }} ><ReduxLanguage languageKey={"forms.open"} /></h5>
              </Button>
              {canCreateVarCustomer ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: 'royalblue',
                    flexShrink: 0,
                    marginRight: 20,
                    width: 'fit-content',
                    color: 'white',
                    padding: 4,
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontWeight: 'bold'}}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await dispatch<any>(CreditsActions.setSelecteCustomer(params.row));
                    this.onOpenEditCustomerForm()
                  }}
                >
                  <Edit style={{ fontSize: 15 }}/>
                  <h5 style={{ fontWeight: 'bold', margin: 0, marginLeft: 5 }} ><ReduxLanguage languageKey={"forms.edit"} /></h5>
                </Button>
              ) : null}
            </div>
          )
        },
      },
    ];
    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <CustomersIcon className={classes.icon} />
              <h3 className={classes.title}>{<ReduxLanguage languageKey="vars.varCustomers"/>}</h3>
            </div>
            {canCreateVarCustomer && 
              <Button
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', fontSize: 11, color: 'white' }}
                onClick={() => {this.onOpenNewCustomerForm()}}
              >
                <ReduxLanguage languageKey="forms.createNewCustomer" />
              </Button>
            }
          </div>
          <div className={classes.tableContainer}>
            <CustomersTable
              customers={customers}
              disableSelectionOnClick
              rowsPerPage={pageSize}
              tableColumns={this.tableColumns}
              //onRowClick={customer => this.onRowClick(customer)}
              onFetchCustomersOnPage={page => this.onFetchCustomersOnPage(page)}
              onPageSizeChange={pageSize => this.onPageSizeChange(pageSize)}
            />
          </div>
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    vars: state.vars.vars.data,
    selectedCustomer: state.credits.customers.selectedDetailedCustomer,
    clusters: state.credits.clusters.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(VarCustomersSection)));