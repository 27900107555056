
import _ from 'lodash';
import { PaginationRestDTO, PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import IGatewayInDTO from '../repository/jago/model/input/IGatewayInDTO';
import { IGatewayArtifactInDTO } from '../repository/jago/model/input/IGatewayArtifactInDTO';
import { IGatewayDeploymentInDTO } from '../repository/jago/model/input/IGatewayDeploymentInDTO';

export function decode(data: PaginationRestDTO<IGatewayInDTO>): PaginatedDataDTO<IGatewayInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodeArtifacts(data: PaginationRestDTO<IGatewayArtifactInDTO>): PaginatedDataDTO<IGatewayArtifactInDTO> {
  const pagination: PaginationDTO = _.omit(data, 'content');
  return {
    content: data.content,
    pagination,
  };
}

export function decodeDeployments(data: PaginationRestDTO<IGatewayDeploymentInDTO>): PaginatedDataDTO<IGatewayDeploymentInDTO> {
  const pagination: PaginationDTO = _.omit(data, 'content');
  return {
    content: data.content,
    pagination,
  };
}