import { Collapse, Hidden } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { AccountBalanceWalletOutlined, Apps, BlurCircular, CastOutlined, ExpandLess, ExpandMore, GridOnOutlined, InfoOutlined, NoteAddOutlined, PersonAddOutlined, SettingsOutlined, Share } from '@material-ui/icons';
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import ContactSupportIcon from '@material-ui/icons/BookOutlined';
import SubscriptionsIcon from '@material-ui/icons/BookmarkBorder';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CardIcon from '@material-ui/icons/CreditCard';
import DomainsIcon from '@material-ui/icons/Domain';
import CustomersIcon from '@material-ui/icons/GroupOutlined';
import ProductIcons from '@material-ui/icons/LibraryBooksOutlined';
import PriceListIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MarketplacesIcon from '@material-ui/icons/Storefront';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { WithStyles } from '@material-ui/styles';
import 'moment/locale/it';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Permission from '../../components/Permission/Permission';
import ReduxLanguage from '../../components/ReduxLanguage';
import { isMobileBrowser } from '../../repository/utils';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import AppHeader from './AppHeader';

const drawerWidth = 220;

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 400,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
  },
  drawerIcon: {
    minWidth: 'fit-content',
    marginRight: 10,
  },
  drawerText: {
    margin: 0,
    fontSize: 15
  }
});

type IReduxProps = WithStyles<typeof styles> & ConnectedProps<typeof connector>;

export interface IDrawerLayoutState {
  mobileOpen: boolean;
  priceListMenuOpen: boolean;
  gatewayListMenuOpen: boolean;
}

export interface IDrawerLayoutProps extends IReduxProps, RouteComponentProps<any> {
  height:number;
}

export class DrawerLayout extends React.Component<IDrawerLayoutProps, IDrawerLayoutState> {

  constructor(props: IDrawerLayoutProps) {
    super(props);
    this.state = {
      mobileOpen: false,
      priceListMenuOpen: false,
      gatewayListMenuOpen: false,
    };
  }

  handleDrawerToggle() {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  }


  onItemClick(page: string) {
    const { history } = this.props;
    if (history.location.pathname.startsWith('/activation')) {
      history.push('/'+page)
      return
    }
    history.push(page)
  }

  onTogglePriceListMenu() {
    const { priceListMenuOpen } = this.state;
    this.setState({ priceListMenuOpen: !priceListMenuOpen });
  }

  onToggleGatewaysListMenu() {
    const { gatewayListMenuOpen } = this.state;
    this.setState({ gatewayListMenuOpen: !gatewayListMenuOpen });
  }

  render() {
    const { children, classes, history } = this.props;
    const { mobileOpen, priceListMenuOpen, gatewayListMenuOpen } = this.state;
    const currentLocation = (history.location.pathname).replace('/', '');

    const drawerContent = (
      <div className={classes.drawerContainer}>
        <List>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_CUSTOMERS}>
            <ListItem selected={currentLocation === 'customers'} button key={'customers'} onClick={() => this.onItemClick('customers')}>
              <ListItemIcon className={classes.drawerIcon}><CustomersIcon style={{ fontSize: 22, color: currentLocation === 'customers' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'customers' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='login.customers' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_VAR_MANAGEMENT}>
            <ListItem selected={currentLocation === 'vars'} button key={'vars'} onClick={() => this.onItemClick('vars')}>
              <ListItemIcon className={classes.drawerIcon}><AssignmentIndIcon style={{ fontSize: 20, color: currentLocation === 'vars' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'vars' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='vars.varsManagement' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_DOMAINS}>
            <ListItem selected={currentLocation === 'domains'} button key={'domains'} onClick={() => this.onItemClick('domains')}>
              <ListItemIcon className={classes.drawerIcon}><DomainsIcon style={{ fontSize: 20, color: currentLocation === 'domains' ? '#3f3f3f' : 'inherit' }}/></ListItemIcon>
              <ListItemText
                  primary={
                    <h3
                      className={classes.drawerText}
                      style={{ fontWeight: currentLocation === 'domains' ? 'bold' : 'normal'}}
                    >
                      <ReduxLanguage languageKey='domains.luckeyDomainsTitle' />
                    </h3>
                  }
                />
            </ListItem>
          </Permission>

          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_PLANT_ACTIVATIONS}>
            <ListItem selected={currentLocation === 'activationRequests'} button key={'activationRequests'} onClick={() => this.onItemClick('activationRequests')}>
              <ListItemIcon className={classes.drawerIcon}><NoteAddOutlined style={{ fontSize: 20, color: currentLocation === 'activationRequests' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'activationRequests' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='activations.activationRequests' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_RECHARGE_REQUESTS}>
            <ListItem selected={currentLocation === 'rechargeRequests'} button key={'rechargeRequests'} onClick={() => this.onItemClick('rechargeRequests')}>
              <ListItemIcon className={classes.drawerIcon}><CardGiftcardIcon style={{ fontSize: 20, color: currentLocation === 'rechargeRequests' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'rechargeRequests' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='activations.rechargeRequests' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_CARDS}>
            <ListItem style={{}} selected={currentLocation === 'rechargeCards'} button key={'rechargeCards'} onClick={() => this.onItemClick('rechargeCards')}>
              <ListItemIcon className={classes.drawerIcon}><CardIcon style={{ fontSize: 20, color: currentLocation === 'rechargeCards' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'rechargeCards' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='credits.rechargeCards' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_OPPORTUNITIES}>
            <ListItem selected={currentLocation === 'opportunities'} button key={'opportunities'} onClick={() => this.onItemClick('opportunities')}>
              <ListItemIcon className={classes.drawerIcon}><MonetizationOnOutlinedIcon style={{ fontSize: 22, color: currentLocation === 'opportunities' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'opportunities' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='vars.opportunities' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_ORDERS_STATUS}>
            <ListItem selected={currentLocation === 'activation/wallet'} button key={'activation/wallet'} onClick={() => this.onItemClick('activation/wallet')}>
              <ListItemIcon className={classes.drawerIcon}><AccountBalanceWalletOutlined style={{ fontSize: 20, color: currentLocation === 'activation/wallet' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'activation/wallet' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='activations.walletStatus' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_GATEWAYS}>
            <ListItem button key={'parameters'} onClick={() => this.onToggleGatewaysListMenu()}>
              <ListItemIcon className={classes.drawerIcon}><CastOutlined style={{ fontSize: 20 }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                  >
                    {'HyperGates'}
                  </h3>
                }
              />
              {gatewayListMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Permission>
          <Collapse in={gatewayListMenuOpen} timeout="auto" unmountOnExit>
            <ListItem style={{ paddingLeft: 25 }} selected={currentLocation === 'gateways'} button key={'gateways'} onClick={() => this.onItemClick('gateways')}>
              <ListItemIcon className={classes.drawerIcon}><Apps style={{ fontSize: 18, color: currentLocation === 'gateways' ? '#3f3f3f' : 'inherit' }}/></ListItemIcon>
              <ListItemText
                  primary={
                    <h3
                      className={classes.drawerText}
                      style={{ fontWeight: currentLocation === 'gateways' ? 'bold' : 'normal'}}
                    >
                      <ReduxLanguage languageKey='gateways.gatewaysDevices' />
                    </h3>
                  }
                />
            </ListItem>
            <ListItem style={{ paddingLeft: 25 }} selected={currentLocation === 'gatewaysArtifacts'} button key={'gatewaysArtifacts'} onClick={() => this.onItemClick('gatewaysArtifacts')}>
              <ListItemIcon className={classes.drawerIcon}><BlurCircular style={{ fontSize: 20, color: currentLocation === 'gatewaysArtifacts' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'gatewaysArtifacts' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='gateways.gatewaysArtifacts' />
                  </h3>
                }
              />
            </ListItem>
            <ListItem style={{ paddingLeft: 25 }} selected={currentLocation === 'gatewaysDeployments'} button key={'gatewaysDeployments'} onClick={() => this.onItemClick('gatewaysDeployments')}>
              <ListItemIcon className={classes.drawerIcon}><Share style={{ fontSize: 20, color: currentLocation === 'gatewaysDeployments' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'gatewaysDeployments' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='gateways.gatewaysDeployments' />
                  </h3>
                }
              />
            </ListItem>
          </Collapse>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_PRICELIST}>
            <ListItem button key={'parameters'} onClick={() => this.onTogglePriceListMenu()}>
              <ListItemIcon className={classes.drawerIcon}><PriceListIcon style={{ fontSize: 20 }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                  >
                    <ReduxLanguage languageKey='credits.systemParameters' />
                  </h3>
                }
              />
              {priceListMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </Permission>
          <Collapse in={priceListMenuOpen} timeout="auto" unmountOnExit>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_MARKETPLACES}>
                <ListItem style={{ paddingLeft: 25 }} selected={currentLocation === 'marketplaces'} button key={'marketplaces'} onClick={() => this.onItemClick('marketplaces')}>
                  <ListItemIcon className={classes.drawerIcon}><MarketplacesIcon style={{ fontSize: 20, color: currentLocation === 'marketplaces' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
                  <ListItemText
                    primary={
                      <h3
                        className={classes.drawerText}
                        style={{ fontWeight: currentLocation === 'marketplaces' ? 'bold' : 'normal'}}
                      >
                        <ReduxLanguage languageKey='credits.marketplaces' />
                      </h3>
                    }
                  />
                </ListItem>
            </Permission>
            <ListItem style={{ paddingLeft: 25 }} selected={currentLocation === 'subscriptions'} button key={'subscriptions'} onClick={() => this.onItemClick('subscriptions')}>
              <ListItemIcon className={classes.drawerIcon}><SubscriptionsIcon style={{ fontSize: 20, color: currentLocation === 'subscriptions' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'subscriptions' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='credits.systemSubscriptions' />
                  </h3>
                }
              />
            </ListItem>
            <ListItem style={{ paddingLeft: 25 }} selected={currentLocation === 'products'} button key={'products'} onClick={() => this.onItemClick('products')}>
              <ListItemIcon className={classes.drawerIcon}><ProductIcons style={{ fontSize: 20, color: currentLocation === 'products' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'products' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='credits.products' />
                  </h3>
                }
              />
            </ListItem>
          </Collapse>
          {/* <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_SALESINFO}>
            <ListItem selected={currentLocation === 'hardwareSalesInfo'} button key="hardwareSalesInfo" onClick={() => this.onItemClick('hardwareSalesInfo')}>
              <ListItemIcon className={classes.drawerIcon}><GridOnOutlined style={{ fontSize: 18, color: currentLocation === 'hardwareSalesInfo' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'hardwareSalesInfo' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='sales.hardwareSalesInfo' />
                  </h3>
                }
              />
            </ListItem>
          </Permission> */}
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_PRODUCTINFO}>
            <ListItem selected={currentLocation === 'productInfo'} button key={'productInfo'} onClick={() => this.onItemClick('productInfo')}>
              <ListItemIcon className={classes.drawerIcon}><ContactSupportIcon style={{ fontSize: 20, color: currentLocation === 'productInfo' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'productInfo' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='vars.varInfoPage' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_VAR_USERS}>
            <ListItem selected={currentLocation === 'varusers'} button key={'varusers'} onClick={() => this.onItemClick('varusers')}>
              <ListItemIcon className={classes.drawerIcon}><VerifiedUserOutlinedIcon style={{ fontSize: 20, color: currentLocation === 'varusers' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'varusers' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='users.usersManagement' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_VAR_SETTINGS}>
            <ListItem selected={currentLocation === 'varSettings'} button key={'varSettings'} onClick={() => this.onItemClick('varSettings')}>
              <ListItemIcon className={classes.drawerIcon}><SettingsOutlined style={{ fontSize: 20, color: currentLocation === 'varSettings' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'varSettings' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='vars.varSettings' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.NAVIGATION_PLATFORM_USERS}>
            <ListItem selected={currentLocation === 'users'} button key={'users'} onClick={() => this.onItemClick('users')}>
              <ListItemIcon className={classes.drawerIcon}><VerifiedUserOutlinedIcon style={{ fontSize: 20, color: currentLocation === 'users' ? '#3f3f3f' : 'inherit' }} /></ListItemIcon>
              <ListItemText
                primary={
                  <h3
                    className={classes.drawerText}
                    style={{ fontWeight: currentLocation === 'users' ? 'bold' : 'normal'}}
                  >
                    <ReduxLanguage languageKey='users.usersManagement' />
                  </h3>
                }
              />
            </ListItem>
          </Permission>
          <ListItem selected={currentLocation === 'status'} button key={'status'} onClick={() => this.onItemClick('status')}>
            <ListItemIcon className={classes.drawerIcon}><InfoOutlined style={{ fontSize: 20 }}/></ListItemIcon>
            <ListItemText primary={<ReduxLanguage languageKey='domains.verifyServiceStatus' />} />
          </ListItem>
        </List>
      </div>
    );
    return (
    <div>
      <AppHeader
        handleDrawerToggle={() => this.handleDrawerToggle()}
      />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={() => this.handleDrawerToggle()}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Toolbar />
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      <div style={{ backgroundColor: '#f1f1f1', height: 'calc(100vh - 64px)', paddingTop: 63, marginLeft: isMobileBrowser() ? 0 : drawerWidth, overflowX: 'hidden' }}>
        {children}
      </div>
    </div>
    );
  }
}

const connector = connect();
export default connector(withRouter(withStyles(styles)(DrawerLayout)));