import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as CustomerCodec from '../../codec/customerDTOCodec';
import * as RechargeDecoded from '../../codec/rechargeInDTODecoder';
import * as WalletDecoder from '../../codec/walletInDTODecoder';
import * as CreditsAPI from '../../repository/jago/creditsAPI';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IRechargeFormDTO } from '../../repository/jago/model/input/IRechargeFormDTO';
import { IRechargeInDTO } from '../../repository/jago/model/input/IRechargeInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import { IRechargeNewOutDTO } from '../../repository/jago/model/output/IRechargeNewOutDTO';
import * as VarsAPI from '../../repository/jago/varsAPI';
import * as VarCodec from '../../codec/varInDTODecoder';
import { IRechargeOutDTO } from '../../repository/jago/model/output/IRechargeOutDTO';
import { IWalletRequestParamsDTO } from '../../repository/jago/model/output/IWalletRequestParamsDTO';
import { ICustomerPlantsRequestParamsDTO, ICustomerRequestParamsDTO, IMarketplacesRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import * as RechargesAPI from '../../repository/jago/rechargesAPI';
import { IState } from '../store';
import { ActionsUnion, IThunkAction, createAction } from "../utils";
import RechargesActionTypesEnum from "./model/RechargesActionTypesEnum";
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';


export const RechargesActions = {
  saveRecharges: createAction<typeof RechargesActionTypesEnum.SAVE_RECHARGES, PaginatedDataDTO<IRechargeInDTO>>(RechargesActionTypesEnum.SAVE_RECHARGES),
  saveRechargeDetailed: createAction<typeof RechargesActionTypesEnum.SAVE_RECHARGE_DETAILED, IRechargeInDTO>(RechargesActionTypesEnum.SAVE_RECHARGE_DETAILED),
  saveCustomers: createAction<typeof RechargesActionTypesEnum.SAVE_CUSTOMERS, PaginatedDataDTO<ICustomerInDTO> | {}>(RechargesActionTypesEnum.SAVE_CUSTOMERS),
  saveWallets: createAction<typeof RechargesActionTypesEnum.SAVE_WALLETS_ALL, PaginatedDataDTO<ICustomerInDTO> | {}>(RechargesActionTypesEnum.SAVE_WALLETS_ALL),
  saveCustomersSearch: createAction<typeof RechargesActionTypesEnum.SAVE_CUSTOMERS_SEARCH, PaginatedDataDTO<ICustomerInDTO> | {}>(RechargesActionTypesEnum.SAVE_CUSTOMERS_SEARCH),
  saveCustomerPlants: createAction<typeof RechargesActionTypesEnum.SAVE_CUSTOMER_PLANTS, PaginatedDataDTO<ICustomerInDTO> | {}>(RechargesActionTypesEnum.SAVE_CUSTOMER_PLANTS),
  saveVarsSearch: createAction<typeof RechargesActionTypesEnum.SAVE_VARS_SEARCH, PaginatedDataDTO<IVarInDTO> | {}>(RechargesActionTypesEnum.SAVE_VARS_SEARCH),
};

export type RechargesActionsType = ActionsUnion<typeof RechargesActions>;

export function fetchRecharges(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IRechargeInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await RechargesAPI.fetchRecharges(params);
      if (response && response.data) {
        const decodedData = RechargeDecoded.decode(response.data);
        dispatch(RechargesActions.saveRecharges(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchRecharge(rechargeId: number): IThunkAction<Promise<IRechargeInDTO>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await RechargesAPI.fetchRecharge(rechargeId);
      if (response && response.data) {
        dispatch(RechargesActions.saveRechargeDetailed(response.data));
        return response.data;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendRecharges(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { recharges: { data } } = getState().recharges;
      if (_.isEmpty(data)) return;
      const stateRecharges = data as PaginatedDataDTO<IRechargeInDTO>;
      const rechargesResponse = await RechargesAPI.fetchRecharges(params);
      if (rechargesResponse && rechargesResponse.data) {
        const decodedData = RechargeDecoded.decode(rechargesResponse.data);
        const rechargesToSave : PaginatedDataDTO<IRechargeInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateRecharges.content,
            ...decodedData.content
          ],
        }
        dispatch(RechargesActions.saveRecharges(rechargesToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function deleteRecharge(rechargeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteRechargeResponse = await RechargesAPI.deleteRecharge(rechargeId);
      if (deleteRechargeResponse && deleteRechargeResponse.data) {
        return deleteRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteRechargeAdmin(rechargeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteRechargeResponse = await RechargesAPI.deleteRechargeAdmin(rechargeId);
      if (deleteRechargeResponse && deleteRechargeResponse.data) {
        return deleteRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function takeChargeRecharge(rechargeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const promoteRechargeResponse = await RechargesAPI.takeChargeRecharge(rechargeId);
      if (promoteRechargeResponse && promoteRechargeResponse.data) {
        return promoteRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function promoteRecharge(rechargeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const promoteRechargeResponse = await RechargesAPI.promoteRecharge(rechargeId);
      if (promoteRechargeResponse && promoteRechargeResponse.data) {
        return promoteRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function invoicedRecharge(rechargeId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const invoicedRechargeResponse = await RechargesAPI.invoicedRecharge(rechargeId);
      if (invoicedRechargeResponse && invoicedRechargeResponse.data) {
        return invoicedRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function promoteRechargeAdmin(rechargeId: number, newStatus: string): IThunkAction<void, IState> {
  return async () => {
    try {
      const promoteRechargeResponse = await RechargesAPI.promoteRechargeAdmin(rechargeId,newStatus);
      if (promoteRechargeResponse && promoteRechargeResponse.data) {
        return promoteRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewRecharge(wallet: IWalletInDTO, VAR: IVarInDTO|undefined): IThunkAction<void, IState> {
  return async () => {
    try {
      const newRecharge: IRechargeNewOutDTO = {
        walletId: wallet.id,
        walletRegistry: VAR?{
          varId: VAR.id,
          varName: VAR.name,
        }:undefined,
      }
      const createRechargeResponse = await RechargesAPI.createNewRecharge(newRecharge);
      if (createRechargeResponse && createRechargeResponse.data) {
        return createRechargeResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editRecharge(rechargeId: number, newRechargeData: IRechargeOutDTO|null, newRechargeDataFromForm: IRechargeFormDTO|null): IThunkAction<void, IState> {
  return async () => {
    try {
      const newRecharge: IRechargeOutDTO = newRechargeDataFromForm!==null?RechargeDecoded.encodeRechargeFromForm(newRechargeDataFromForm):
      newRechargeData!==null?newRechargeData:null;
      if (newRecharge===null) return null;
      const createCustomerResponse = await RechargesAPI.editRecharge(rechargeId, newRecharge);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editRechargeAdmin(rechargeId: number, newRechargeData: IRechargeOutDTO|null, newRechargeDataFromForm: IRechargeFormDTO|null): IThunkAction<void, IState> {
  return async () => {
    try {
      const newRecharge: IRechargeOutDTO = newRechargeDataFromForm!==null?RechargeDecoded.encodeRechargeFromForm(newRechargeDataFromForm):
      newRechargeData!==null?newRechargeData:null;
      if (newRecharge===null) return null;
      const createCustomerResponse = await RechargesAPI.editRechargeAdmin(rechargeId, newRecharge);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportRechargesCSV(params: IMarketplacesRequestParamsDTO = {}) {
  return async () => {
    const response = await RechargesAPI.exportRechargesCSV(params);
    FileSaver.saveAs(response.data, `export-recharges-${moment().toISOString()}.csv`);
  }
}

export function fetchCustomersSearch(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersResponse = await CreditsAPI.fetchCustomers(params);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        dispatch(RechargesActions.saveCustomersSearch(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetCustomersSearch(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(RechargesActions.saveCustomersSearch({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchVarsSearch(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const varsResponse = await VarsAPI.fetchVars(params);
      if (varsResponse && varsResponse.data) {
        const decodedData = VarCodec.decode(varsResponse.data);
        dispatch(RechargesActions.saveVarsSearch(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetVarsSearch(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(RechargesActions.saveVarsSearch({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomerPlants(params?: ICustomerPlantsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const plantsResponse = await CreditsAPI.fetchCustomerPlants(params);
      if (plantsResponse && plantsResponse.data) {
        const pagination: PaginationDTO = _.omit(plantsResponse.data, 'content');
        const paginatedData: PaginatedDataDTO<IPlantInDTO> = {
          content: plantsResponse.data.content,
          pagination,
        };
        dispatch(RechargesActions.saveCustomerPlants(paginatedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetCustomerPlants(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(RechargesActions.saveCustomerPlants({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomers(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      var isLast = false;
      var pageCounter = 0;
      const pageSize = 1000;
      var params: ICustomerRequestParamsDTO = { page: pageCounter, pageSize: pageSize }
      while (!isLast && pageCounter<20) {
        params.page = pageCounter;
        const { customers: { data } } = getState().recharges;
        const customersResponse = await CreditsAPI.fetchCustomers(params);
        if (customersResponse && customersResponse.data) {
          const decodedData = CustomerCodec.decode(customersResponse.data);
          isLast = (decodedData.pagination as PaginationDTO).last===true?true:false
          const customersToSave : PaginatedDataDTO<ICustomerInDTO> = _.isEmpty(data)?(decodedData):(
            {
              pagination: decodedData.pagination,
              content: [
                ...((data) as PaginatedDataDTO<ICustomerInDTO>).content,
                ...decodedData.content
              ],
            }
          )
          dispatch(RechargesActions.saveCustomers(customersToSave));
        } else {
          throw new Error();
        }
        pageCounter+=1
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchWallets(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      var isLast = false;
      var pageCounter = 0;
      const pageSize = 200;
      var params: IWalletRequestParamsDTO = { page: pageCounter, pageSize: pageSize }
      while (!isLast && pageCounter<20) {
        params.page = pageCounter;
        const { wallets: { data } } = getState().recharges;
        const walletsResponse = await CreditsAPI.fetchWallets(params);
        if (walletsResponse && walletsResponse.data) {
          const decodedData = WalletDecoder.decode(walletsResponse.data);
          isLast = (decodedData.pagination as PaginationDTO).last===true?true:false
          const walletsToSave : PaginatedDataDTO<IWalletInDTO> = _.isEmpty(data)?(decodedData):(
            {
              pagination: decodedData.pagination,
              content: [
                ...((data) as PaginatedDataDTO<IWalletInDTO>).content,
                ...decodedData.content
              ],
            }
          )
          dispatch(RechargesActions.saveWallets(walletsToSave));
        } else {
          throw new Error();
        }
        pageCounter+=1
      }
      
    } catch (error) {
      throw error;
    }
  };
}