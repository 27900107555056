import MomentUtils from '@date-io/moment';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import Select from 'react-select';
import CreditsIcon from '@material-ui/icons/MonetizationOn';
import { SubscriptionLogicEnum } from '../../repository/jago/model/input/ISubscriptionLogicEnum';
import { ISubscriptionOutDTO } from '../../repository/jago/model/output/ISubscriptionOutDTO';
import Utils from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});


export interface INewTierSubscriptionFormState {
  subscriptionData: ISubscriptionOutDTO;
  textInputErrors: { [key: string]: boolean; };
};

export interface INewTierSubscriptionFormProps extends WithStyles<typeof styles>{
  onCreateTierSubscription: (subscription: ISubscriptionOutDTO) => void;
}

class NewTierSubscriptionForm extends React.Component<INewTierSubscriptionFormProps, INewTierSubscriptionFormState> {

  constructor(props: INewTierSubscriptionFormProps) {
    super(props);
    this.state = {
      subscriptionData: {
        name: '',
        sku: '',
        fixedPrice: 0,
        burnRate: 0,
        logic: SubscriptionLogicEnum.FIXED,
        maxLocks: 0,
        maxElements: 0,
        maxUsers: 0,
        nextTier: 0,
        prevTier: 0,
        resource: 'DOMAIN',
        visible: true,
      },
      textInputErrors: {
        name: false,
        fixedPrice: false,
        burnRate: false,
        sku: false,
        logic: false,
        maxLocks: false,
        maxElements: false,
        maxUsers: false,
      },
    };
  }
  
  public onSetValue(field: keyof ISubscriptionOutDTO, value: any) {
    const { subscriptionData } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (value && value < 0) {
      this.setErrorForField(field, true);
    }
    (subscriptionData as any)[field] = value;
    this.setState({ subscriptionData });
  }

  public onSelectLogic(logic: SubscriptionLogicEnum) {
    const { subscriptionData } = this.state;
    subscriptionData.logic = logic;
    this.setState({ subscriptionData });
  }

  public setErrorForField(field: string, hasError: boolean) {
    const { textInputErrors } = this.state;
    textInputErrors[field] = hasError;
    this.setState({ textInputErrors });
  }

  public canSubmitForm() {
    const { subscriptionData } = this.state;
    if (!subscriptionData.name || !subscriptionData.sku || !subscriptionData.logic) {
      return false;
    }
    return true;
  }
    

  public onSubmitForm() {
    const { subscriptionData } = this.state;
    const { onCreateTierSubscription } = this.props;
    if (this.canSubmitForm()) {
      onCreateTierSubscription({
        ...subscriptionData,
        fixedPrice: Utils.formatCreditsOut(subscriptionData.fixedPrice),
        burnRate: Utils.formatCreditsOut(subscriptionData.burnRate),
      });
    }
  }

/* Se logic è:
  - Fixed non mostrare maxLock, eleement e users
  - Count users -> mostra solo maxUsers
  - Count Locks -> mostra solo maxLocks
  - Count Elements -> mostra solo maxElements
- Fixed price è il costo di attivazione
- Burn rate è il costo ricorrente
*/

  public render() {
    const { classes } = this.props;
    const { subscriptionData, textInputErrors } = this.state;
    const subscriptionLogicOption = _.map(SubscriptionLogicEnum, logic => ({
      label: <ReduxLanguage languageKey={`forms.${logic}`} />,
      value: logic
    }));
    const selectedLogicOption = subscriptionData && subscriptionData.logic ? _.find(subscriptionLogicOption, logic => logic.value === subscriptionData.logic) : null;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h4 style={{ marginTop: 0, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionName'} />}<span style={{ color: 'red' }}>*</span></h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            error={textInputErrors.name}
            value={subscriptionData?.name}
            onChange={e => this.onSetValue('name', e.target.value)}
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sku'} />}<span style={{ color: 'red' }}>*</span></h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10, marginBottom: 20 }}
            error={textInputErrors.sku}
            value={subscriptionData?.sku}
            onChange={e => this.onSetValue('sku', e.target.value)}
          />
          
          <FormControlLabel
            control={<Checkbox checked={subscriptionData?.visible} onChange={(e, checked) => this.onSetValue('visible', checked)} name="subscriptionVisible" />}
            label={<ReduxLanguage languageKey={'forms.subscriptionVisible'} />}
          />

          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionFixedPrice'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionFixedPriceDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            type="number"
            error={textInputErrors.fixedPrice}
            value={subscriptionData?.fixedPrice}
            onChange={e => this.onSetValue('fixedPrice', e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionBurnRate'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionBurnRateDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            type="number"
            style={{ marginTop: 10 }}
            error={textInputErrors.burnRate}
            value={subscriptionData?.burnRate}
            onChange={e => this.onSetValue('burnRate', e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionLogic'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionLogicDescription'} />}</h5>
          <Select
            options={subscriptionLogicOption}
            isClearable
            placeholder={<ReduxLanguage languageKey={'forms.selectLogic'} />}
            onChange={(e) => this.onSelectLogic(e!.value)}
            value={selectedLogicOption}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, marginTop: 10 }),
            }} 
          />
          {subscriptionData?.logic === SubscriptionLogicEnum.COUNT_LOCKS || subscriptionData?.logic === SubscriptionLogicEnum.COUNT_USERS_LOCKS ? (
            <div>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.maxLocks'} />}<span style={{ color: 'red' }}>*</span></h4>
              <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionMaxLocksDescription'} />}</h5>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                type={"number"}
                style={{ marginTop: 10 }}
                error={textInputErrors.maxLocks}
                value={subscriptionData?.maxLocks}
                onChange={e => this.onSetValue('maxLocks', e.target.value)}
              />
            </div>
          ) : null}
          {subscriptionData?.logic === SubscriptionLogicEnum.COUNT_USERS_LOCKS ? (
            <div>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.maxUsers'} />}<span style={{ color: 'red' }}>*</span></h4>
              <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionMaxUsersDescription'} />}</h5>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                type={"number"}
                style={{ marginTop: 10 }}
                error={textInputErrors.maxUsers}
                value={subscriptionData?.maxUsers}
                onChange={e => this.onSetValue('maxUsers', e.target.value)}
              />
            </div>
          ) : null}
          {subscriptionData?.logic === SubscriptionLogicEnum.COUNT_ELEMENTS ? (
            <div>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.maxElements'} />}<span style={{ color: 'red' }}>*</span></h4>
              <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionMaxElementsDescription'} />}</h5>
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                type={"number"}
                style={{ marginTop: 10 }}
                error={textInputErrors.maxElements}
                value={subscriptionData?.maxElements}
                onChange={e => this.onSetValue('maxElements', e.target.value)}
              />
            </div>
          ) : null}
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(NewTierSubscriptionForm);