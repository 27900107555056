import { Card, CardActionArea, Divider, IconButton } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import LicenseIcon from "@material-ui/icons/BookmarkBorder";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as React from "react";
import { ISubscriptionInDTO } from "../../repository/jago/model/input/ISubscriptionInDTO";
import { GREEN } from "../../style/StyleConsts";
import ReduxLanguage from "../ReduxLanguage";
import { Edit } from '@material-ui/icons';
import SubscriptionCostDetails from './SubscriptionCostDetails';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    minWidth: 430,
  },
  rowActionBodyContainer: {
    padding: 15,
    paddingTop: 6,
    minHeight: 100,
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowHeaderInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    color: '#3f3f3f',
  },
  creditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  noOnClickContainer: {
    width: '100%',
  },
});

export interface ISubscriptionRowProps extends WithStyles<typeof styles>{
  subscription: ISubscriptionInDTO;
  onEditSubcription?: (subscription: ISubscriptionInDTO) => void;
  onClick?: (subscription: ISubscriptionInDTO) => void;
}

class SubscriptionRow extends React.Component<ISubscriptionRowProps, any> {
  public render() {
    const { subscription, classes, onClick, onEditSubcription } = this.props;
    const isDomainRow = subscription.resource === 'DOMAIN';

    const innerContent = (
      <div className={classes.rowActionBodyContainer}>
        <div className={classes.rowHeaderContainer}>
          <div className={classes.rowHeaderInnerContainer}>
            <h3 className={classes.rowTitle}>{subscription?.sku}</h3>
            <h4 className={classes.rowTitle}>{subscription?.name}</h4>
            <h4 className={classes.rowTitle} style={{ marginLeft: 3 }}>({subscription?.resource})</h4>
            {onEditSubcription ?
              <IconButton onClick={(e) => { e.stopPropagation(); onEditSubcription(subscription) }}>
                <Edit />
              </IconButton> : null
            }
          </div>
          <div className={classes.rowHeaderInnerContainer}>
            {isDomainRow ? (
              <div className={classes.rowHeaderInnerContainer}>
                <LicenseIcon style={{ color: GREEN }} />
                <h4 style={{ margin: 0, marginRight: 10 }}>{<ReduxLanguage languageKey="credits.tierSubscription" />}</h4>
              </div>
            ) : null}
            {!subscription.visible ? 
              <VisibilityOffIcon className={classes.icon} />
            : null}
          </div>
        </div>
        <Divider style={{ marginBottom: 10, marginTop: 3 }} />
        <SubscriptionCostDetails
          subscription={subscription}
        />
      </div>
    );
    return (
      <Card className={classes.rowContainer}>
        {onClick ?
          <CardActionArea onClick={() => onClick(subscription)}>
            {innerContent}
          </CardActionArea>
          : 
          <div className={classes.noOnClickContainer}>
            {innerContent}
          </div>
        }
      </Card>
    );
  }
}

export default withStyles(styles)(SubscriptionRow);