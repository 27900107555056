import { ISubscriptionInDTO } from './ISubscriptionInDTO';

export enum CardStatusOptions {
  CREATED = 'CREATED',
  ACTIVE = 'ACTIVE',
}

export interface ICardInDTO {
  id: number;
  varId: number;
  plantRequestId: number;
  walletRequestId: number;
  customerId: number;
  walletId: number;
  code: string;
  used: boolean;
  status: string;
  deleted: boolean;
  createdAt: number;
  actions: Array<{subscription: ISubscriptionInDTO}>;
  iseoSalesOrderReference: string;
  iseoSalesOrderDate: number;
  iseoPurchaseOrderReference: string;
  iseoPurchaseOrderDate: number;
  sofiaSalesOrderReference: string;
  sofiaSalesOrderDate: number;
  crmSource: string;
  crmId: string;
  sofiaProjectId: string;
  crmLink: string;
  quantity: number;
  notes: string;
  kamReference: string;
  sofiaInvoiceNumber: string;
  sofiaInvoiceDate: number;
  sofiaInvoiceRecipient: string;
  sofiaInvoiceAmount: number;
  iseoInvoiceNumber: string;
  iseoInvoiceCurrency: string;
  iseoInvoiceDate: number;
  iseoInvoiceRecipient: string;
  iseoInvoiceAmount: number;
  iseoInvoiceIssuer: string;
  freeOfCharge: boolean;
  freeOfChargeReason: string;
  serviceSupplementaryInvoiceNumber: string;
  serviceSupplementaryInvoiceAmount: number;
  serviceSupplementaryInvoiceDate: number;
  serviceSupplementaryInvoiceRecipient: string;
}