import { Button, Checkbox, IconButton, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import RandomIcon from '@material-ui/icons/CasinoOutlined';
import * as React from "react";
import { IDomainUserOutDTO } from "../../repository/jago/model/output/IDomainUserOutDTO";
import ReduxLanguage from "../ReduxLanguage";

var RandExp = require('randexp');

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});


export interface INewDomainUserFormState {
  userData: IDomainUserOutDTO;
  textInputErrors: { [key: string]: boolean; };
  avoidPassword: boolean;

};

export interface INewDomainUserFormProps extends WithStyles<typeof styles>{
  onCreateNewUser: (user: IDomainUserOutDTO) => void;
  roleId: number;
}

class NewDomainUserForm extends React.Component<INewDomainUserFormProps, INewDomainUserFormState> {

  constructor(props: INewDomainUserFormProps) {
    super(props);
    this.state = {
      userData: {
        email: '',
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        roleIds: [props.roleId],
      },
      textInputErrors: {
        email: false,
        firstname: false,
        lastname: false,
        username: false,
        password: false,
      },
      avoidPassword: false,
    };
  }
  
  public onSetValue(field: keyof IDomainUserOutDTO, value: any) {
    const { userData, avoidPassword } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (field === 'email' && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      this.setErrorForField('email', true);
    }
    if (avoidPassword === false && field === 'password' && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[_0-9a-zA-Z\W]{8,}$/.test(value)) {
      this.setErrorForField('password', true);
    }
    userData[field] = value;
    this.setState({ userData });
  }

  public onSelectRole(value: number | undefined) {
    const { userData } = this.state;
    if (value) {
      userData.roleIds = [value];
      this.setState({ userData })
    }
  }

  public setErrorForField(field: string, hasError: boolean) {
    const { textInputErrors } = this.state;
    textInputErrors[field] = hasError;
    this.setState({ textInputErrors });
  } 

  public canSubmitForm() {
    const { userData } = this.state;
    if (!userData.firstname || !userData.lastname || !userData.username || (!userData.roleIds || userData.roleIds.length === 0)) {
      return false;
    }
    return true;
  }

  public onSubmitForm() {
    const { userData } = this.state;
    const { onCreateNewUser } = this.props;
    onCreateNewUser(userData);
  }

  onGeneratePassword() {
    const pwd = new RandExp(/^[_0-9a-zA-Z]{8,12}/).gen();
    this.onSetValue('password', pwd);
  }
  
  onToggleAvoidPassword(checked: boolean) {
    this.setState({ avoidPassword: checked });
    if (checked) {
      this.onSetValue('password', '');
      this.setErrorForField('password', false);
    }
  }

  onBlurEmailField() {
    const { userData } = this.state;
    if (userData && userData.email) {
      const splittedEmail = userData.email.split('@');
      if (splittedEmail && splittedEmail.length) {
        userData.username = splittedEmail[0];
        this.setState({ userData });
      }
    }
  }


  public render() {
    const { classes, roleId } = this.props;
    const { userData, avoidPassword, textInputErrors } = this.state;
    const canSubmitForm = this.canSubmitForm();
    const isInstaller = roleId === 2;
    return (
      <div className={classes.container}>
        <h2 className={classes.formTitle}>
          {isInstaller ? (
            <ReduxLanguage languageKey={'forms.createInstallerForDomain'} />
            ): (
            <ReduxLanguage languageKey={'forms.createUserForDomain'} />
          )}</h2>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'forms.name'} />}
          variant="outlined"
          fullWidth
          required
          error={textInputErrors.firstname}
          value={userData?.firstname}
          style={{ marginTop: 20 }}
          onChange={e => this.onSetValue('firstname', e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'forms.lastname'} />}
          variant="outlined"
          fullWidth
          required
          error={textInputErrors.lastname}
          value={userData?.lastname}
          style={{ marginTop: 20 }}
          onChange={e => this.onSetValue('lastname', e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'forms.email'} />}
          variant="outlined"
          fullWidth
          required
          error={textInputErrors.email}
          value={userData?.email}
          style={{ marginTop: 20 }}
          onBlur={() => this.onBlurEmailField()}
          onChange={e => this.onSetValue('email', e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey={'forms.username'} />}
          variant="outlined"
          fullWidth
          required
          error={textInputErrors.username}
          value={userData?.username}
          style={{ marginTop: 20 }}
          onChange={e => this.onSetValue('username', e.target.value)}
        />
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, alignItems: 'center'}}>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'forms.password'} />}
            variant="outlined"
            fullWidth
            required
            error={textInputErrors.password}
            value={userData?.password}
            style={{ marginRight: 10 }}
            onChange={e => this.onSetValue('password', e.target.value)}
          />
          <IconButton style={{ marginRight: 10 }} onClick={() => this.onGeneratePassword()}>
            <RandomIcon/>
          </IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center'}}>
        <Checkbox
          checked={avoidPassword}
          onChange={(e, checked) => this.onToggleAvoidPassword(checked)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <h4>{<ReduxLanguage languageKey={'forms.letUserChoosePassword'} />}</h4>
        </div>
        <Button
          variant="contained"
          disabled={!canSubmitForm}
          style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
          onClick={() => this.onSubmitForm()}
        >
          <ReduxLanguage languageKey="forms.save" />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(NewDomainUserForm);