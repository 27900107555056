import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { AppBar, Button, Card, Divider, Tab, Tabs, WithStyles, createStyles } from '@material-ui/core';
import { Domain, Edit } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import 'moment/locale/it';
import qs from 'query-string';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as PlantThemeDecoder from '../../codec/plantThemeInDTODecoder';
import * as VarDecoder from '../../codec/varInDTODecoder';
import PlantThemeForm from '../../components/Forms/PlantThemeForm';
import VarForm from '../../components/Forms/VarForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import ReduxLanguage from '../../components/ReduxLanguage';
import VarHomepageSearchbar from '../../components/SearchBar/VarHomepageSearchBar';
import PlantThemesList from '../../components/Vars/PlantThemesList';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IPlantThemeFormDTO } from '../../repository/jago/model/input/IPlantThemeFormDTO';
import { IPlantThemeInDTO } from '../../repository/jago/model/input/IPlantThemeInDTO';
import { IVarFormDTO } from '../../repository/jago/model/input/IVarFormDTO';
import { IVarHomepageSearchDTO } from '../../repository/jago/model/input/IVarHomepageSearchDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import Permission from '../../components/Permission/Permission';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';
import NewspaperIconCustom from '../../components/NewspaperIconCustom';
import { GREEN } from '../../style/StyleConsts';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0,
  },
  text: {
    margin: 0,
    marginBottom: 15,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  }
});
export interface IVarSettingsState {
  searchValuesCurrent: IVarHomepageSearchDTO;
  activeTab: number;
}

type IReduxProps = ConnectedProps<typeof connector> & RouteComponentProps<any>;

export interface IVarSettingsProps extends WithStyles<typeof styles> {
  language: string;
  plantThemes: PaginatedDataDTO<IPlantThemeInDTO>;
}

export type ComponentProps = IVarSettingsProps & IReduxProps;

class VarHomepage extends React.Component<ComponentProps, IVarSettingsState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchValuesCurrent: {},
      activeTab: 0,
    }
  }

  public async componentDidMount() {
    const { dispatch, history } = this.props;
    const parsed = qs.parse(history.location.search);
    await dispatch<any>(VarsActions.fetchVar());
    const plantThemes = await dispatch<any>(VarsActions.fetchPlantThemes({page: 0, pageSize: 500, ...this.state.searchValuesCurrent}));
    if (parsed && parsed.plantThemeId) {
      try {
        const plantThemeId = Number(parsed.plantThemeId);
        const plantTheme = plantThemes.filter((e: IPlantThemeInDTO)=>e.id===plantThemeId).length>0?plantThemes.filter((e: IPlantThemeInDTO)=>e.id===plantThemeId)[0]:undefined
        if (plantTheme) {
          this.onRowClick(plantTheme);
        } else {
          dispatch<any>(ModalsActions.showModal(`ERROR_PLANTTHEME_DETAILS`, {
            modalType: ModalTypes.ERROR_MODAL,
            modalProps: {
              titleMessageKey: 'errors.error',
              errorMessageKey: 'errors.getPlantThemeDetailsError',
            }
          }));
        }
      } catch (error) {
        dispatch<any>(ModalsActions.showModal(`ERROR_PLANTTHEME_DETAILS`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.getPlantThemeDetailsError',
          }
        }));
      }
    }
  }
  
  async onOpenNewPlantThemeForm() {
    const { dispatch } = this.props;
    await dispatch<any>(VarsActions.resetThemePlants())
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_CREATE_PLANTTHEME_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <PlantThemeForm
          onCreateEditPlantTheme={(newPlantThemeData: IPlantThemeFormDTO) => this.onCreateNewPlantThemeRequest(newPlantThemeData)}
          />
        ),
        titleMessageKey: 'forms.plantThemeFormTitle',
      }
    }));
  }
  
  async onOpenEditPlantThemeForm() {
    const { dispatch, selectedPlantTheme } = this.props;
    const plantTheme = selectedPlantTheme?(selectedPlantTheme as IPlantThemeInDTO):null
    if (plantTheme) {
      const plantThemeData = PlantThemeDecoder.decodePlantThemeContentToForm(plantTheme);
      await dispatch<any>(VarsActions.fetchThemePlants({plantThemeId:plantTheme.id}))
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EDIT_PLANTTHEME_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <PlantThemeForm
              plantTheme={plantThemeData}
              onCreateEditPlantTheme={(newPlantThemeData: IPlantThemeFormDTO) => this.onEditPlantThemeRequest(newPlantThemeData.id, newPlantThemeData)}
            />
          ),
          titleMessageKey: 'forms.plantThemeFormTitle',
        }
      }));
    }
  }
  
  public onEditPlantThemeRequest(plantThemeId: number, newPlantThemeData: IPlantThemeFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_PLANTTHEME_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.editPlantTheme',
        successMessageKey: 'vars.editPlantThemeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditPlantThemeConfirm(plantThemeId, newPlantThemeData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_PLANTTHEME_CONFIRM_MODAL`)),
      }
    }));
  }
  
  public async onEditPlantThemeConfirm(plantThemeId: number, newPlantThemeData: IPlantThemeFormDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`EDIT_PLANTTHEME_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const plantThemeUpdated = await dispatch<any>(VarsActions.editPlantTheme(plantThemeId, newPlantThemeData));
      dispatch<any>(VarsActions.setSelectedPlantTheme(plantThemeUpdated));
      await dispatch<any>(VarsActions.fetchPlantThemes({page: 0, pageSize: 500, ...this.state.searchValuesCurrent}));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_EDIT_PLANTTHEME_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PLANTTHEME_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createPlantThemeError',
        }
      }));
    }
  }
  
  public onCreateNewPlantThemeRequest(newPlantThemeData: IPlantThemeFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_PLANTTHEME_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.newPlantTheme',
        successMessageKey: 'vars.plantThemeCreationConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateNewPlantThemeConfirm(newPlantThemeData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_PLANTTHEME_CONFIRM_MODAL`)),
      }
    }));
  }
  
  public async onCreateNewPlantThemeConfirm(newPlantThemeData: IPlantThemeFormDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`CREATE_PLANTTHEME_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(VarsActions.createNewPlantTheme(newPlantThemeData));
      await dispatch<any>(VarsActions.fetchPlantThemes({page: 0, pageSize: 500, ...this.state.searchValuesCurrent}));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_CREATE_PLANTTHEME_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createPlantThemeError',
        }
      }));
    }
  }

  onDeletePlantThemeRequest(plantTheme: IPlantThemeInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_PLANTTHEME_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'vars.deletePlantThemeConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeletePlantThemeConfirm(plantTheme),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_PLANTTHEME_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeletePlantThemeConfirm(plantTheme: IPlantThemeInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const themePlants = (await dispatch<any>(VarsActions.fetchThemePlants({plantThemeId:plantTheme.id})) as IPlantInDTO[]);
      if (themePlants.length>0) {
        dispatch<any>(ModalsActions.hideModal('DELETE_PLANTTHEME_CONFIRM_MODAL'));
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_PLANTTHEME_DELETE`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'vars.cannotDeleteAssociatedTheme',
          }
        }));
      }
      else {
        await dispatch<any>(VarsActions.deletePlantTheme(plantTheme.id));
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.hideModal('DELETE_PLANTTHEME_CONFIRM_MODAL'));
        await dispatch<any>(VarsActions.fetchPlantThemes({page: 0, pageSize: 500, ...this.state.searchValuesCurrent}));
      }
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PLANTTHEME_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deletePlantThemeError',
        }
      }));
    }
  }

  async onOpenEditVarForm() {
    const { dispatch, VAR } = this.props;
    const currentVar = (VAR as IVarInDTO)
    if (currentVar.id) {
      const varFormData = VarDecoder.decodeVarContentToForm(currentVar,[],[],[]);
      dispatch<any>(ModalsActions.showModal(`OPERATIONAL_EDIT_VAR_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL_BACKDROP,
        modalProps: {
          content: (
            <VarForm
              VAR={varFormData}
              onCreateEditVar={(newVarData: IVarFormDTO) => this.onEditVarRequest(newVarData)}
              isVar={true}
            />
          ),
          titleMessageKey: 'forms.varFormTitleVar',
        }
      }));
    }
  }

  public onEditVarRequest(newVarData: IVarFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_VAR_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'vars.editVarPersonal',
        successMessageKey: 'vars.editVarPersonalConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditVarConfirm(newVarData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_VAR_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEditVarConfirm(newVarData: IVarFormDTO) {
    const { dispatch, VAR } = this.props;
    const currentVar = (VAR as IVarInDTO)
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(ModalsActions.hideModal(`EDIT_VAR_CONFIRM_MODAL`));
      await dispatch<any>(VarsActions.editVar(currentVar.id,newVarData));
      await dispatch<any>(VarsActions.fetchVar());
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_EDIT_VAR_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CREATION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.updateVarError',
        }
      }));
    }
  }
  
  public async onRowClick(plantTheme: IPlantThemeInDTO) {
    const { dispatch } = this.props;
    try {
      await dispatch<any>(VarsActions.setSelectedPlantTheme(plantTheme));
      this.onOpenEditPlantThemeForm()
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PLANTTHEME_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getPlantThemeDetailsError',
        }
      }));
    }
  }

  onResetSearch() {
    const { dispatch } = this.props;
    this.setState({searchValuesCurrent:{}});
    dispatch<any>(VarsActions.fetchPlantThemes({ page: 0, pageSize: 500 }));
  }

  public onSearch(searchValues: IVarHomepageSearchDTO) {
    const { dispatch } = this.props;
    this.setState({searchValuesCurrent:searchValues});
    dispatch<any>(VarsActions.fetchPlantThemes({ page: 0, pageSize: 500, ...searchValues }));
  }

  public onAppendPlantThemes(page: number) {
    const { dispatch } = this.props;
    const params: PaginationQueryParamsDTO = { page };
    dispatch<any>(VarsActions.appendPlantThemes(params));
  }

  public onTabChange(index: number) {
    this.setState({ activeTab: index });
  }

  public onOpenVarCertificate() {
    const { dispatch, VAR } = this.props;
    const currentVar = (VAR as IVarInDTO)
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_VAR_CERTIFICATE`, {
      modalType: ModalTypes.CONTENT_MODAL,
      modalProps: {
        content: (
          <iframe
            title="Var certificate"
            width="100%"
            height="100%"
            frameBorder="0"
            id="serviceFrameSend"
            style={{ objectPosition: 'center'}}
            src={`/varCertificateGenerator/generate_certificate.html?iseo_entity=${currentVar.company}&var_name=${currentVar.name}&var_address=${currentVar.address}&cert_number=YYYY-MM&cert_date=VAR_CREATION_DATE`}
          />

        ),
        title: 'VAR Certificate',
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`OPERATIONAL_VAR_CERTIFICATE`)),
        fullScreen: true,
        titleMessageKey: 'forms.varFormTitle',
      }
    }));
  }

  render() {
    const { classes, plantThemes, VAR } = this.props;
    const { activeTab } = this.state;
    const currentVar = (VAR as IVarInDTO)
    const plantThemesPagination = !_.isEmpty(plantThemes.pagination) ? (plantThemes.pagination as PaginationDTO) : null;
    return (
      <div className={classes.container}>
        <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            centered
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            <Tab icon={<Domain style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="forms.varFormTitleVar" /></h5>} />
            {/* <Tab icon={<PaletteOutlined style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="vars.plantThemes" /></h5>} /> */}
          </Tabs>
        </AppBar>
        {activeTab === 0 ? (
          <div>
            <Card style={{ padding: 20, margin: 10}}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Domain style={{ fontSize: 30, marginRight: 10 }}/>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <div style={{marginLeft:0, marginRight:20}}>
                    <h3>{currentVar.name}</h3>
                    <h3>{currentVar.address}</h3>
                    <h3>{currentVar.contactEmail}</h3>
                    <h3>{currentVar.contactPhone}</h3>
                  </div>
                  <Divider orientation='vertical' style={{height:60}}></Divider>
                  {currentVar.commercialRefEmail ? (
                    <div style={{marginLeft:40, marginRight:20}}>
                      <h3 style={{fontWeight:'bold'}}><ReduxLanguage languageKey="vars.varCommercialContact" /></h3>
                      <h3>{currentVar.commercialRefEmail}</h3>
                      <h3>{currentVar.commercialRefEmail}</h3>
                      <h3>{currentVar.commercialRefPhone}</h3>
                    </div>
                  ) : null}
                  {currentVar.techRefEmail ? (
                    <div style={{marginLeft:20, marginRight:20}}>
                    <h3 style={{fontWeight:'bold'}}><ReduxLanguage languageKey="vars.varTechContact" /></h3>
                      <h3>{currentVar.techRefName}</h3>
                      <h3>{currentVar.techRefEmail}</h3>
                      <h3>{currentVar.techRefPhone}</h3>
                    </div>
                  ) : null}
                </div>
              </div>
              <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_UPDATE}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: 'cornflowerblue', color: 'white', fontSize: 16, marginTop: 10, marginLeft: 40, fontWeight: 'bold'  }}
                  onClick={() => {this.onOpenEditVarForm()}}
                  startIcon={<Edit/>}
                >
                <h4><ReduxLanguage languageKey="vars.editVarPersonal" /></h4>
                </Button>
              </Permission>
              {/* <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_READ}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: GREEN, flexShrink:0, marginTop: 10, marginBottom: 5 }}
                  onClick={() => this.onOpenVarCertificate()}
                >
                  <NewspaperIconCustom className="" style={{ color: 'white', width: 20 }} />
                  <h5 style={{ color: 'white'}}><ReduxLanguage languageKey={"vars.downloadCertificate"} /></h5>
                </Button>
              </Permission> */}
          </Card>
          </div>
          ) : null}
        {activeTab === 1 ? (
          <div>
            <div className={classes.header}>
              <VarHomepageSearchbar
                totalElements={plantThemesPagination?plantThemesPagination.totalElements:''}
                onCreateNewEntityButtonClick={() => this.onOpenNewPlantThemeForm()}
                onSearch={(searchValues: IVarHomepageSearchDTO) => this.onSearch(searchValues)}
                onResetSearch={() => this.onResetSearch()}
              />
            </div>
            <PlantThemesList
              plantThemes={plantThemes}
              onOpen={(plantTheme) => this.onRowClick(plantTheme)}
              onDelete={(plantTheme) => this.onDeletePlantThemeRequest(plantTheme)}
              onFetchMoreItems={(page) => this.onAppendPlantThemes(page)}
            />
        </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    VAR: state.vars.VAR.data,
    plantThemes: state.vars.plantThemes.data,
    selectedPlantTheme: state.vars.selectedPlantTheme.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(withStyles(styles)(VarHomepage)));