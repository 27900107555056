import 'moment/locale/it';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AbilityProvider from '../services/PermissionService/AbilityProvider';
import { Roles } from '../services/PermissionService/PermissionConstants';
import { VAR_USERS_ROLES } from '../utils/Utils';

type IReduxProps = ConnectedProps<typeof connector>;

export interface IHomePageState {
  activeTab: number;
}
export interface IHomePageProps extends IReduxProps, RouteComponentProps<any> {}
class HomePage extends React.Component<IHomePageProps, IHomePageState> {

  public componentDidMount() {
    const { history } = this.props;
    /*
    const isODS = AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SALES_OPERATION_MANAGER, Roles.ROLE_VASHO_GLOBAL_SALES_OPERATION_MANAGER]);
    if (isODS)
      history.push('activation/new')
    else
    */
    history.push('customers')
  }

  render() {
    return (
      <div></div>
    )
  }
}

const connector = connect();
export default connector(withRouter(HomePage));