enum GatewaysActionTypesEnum {
  SAVE_GATEWAYS = 'SAVE_GATEWAYS',
  APPEND_GATEWAYS = 'APPEND_GATEWAYS',
  SAVE_GATEWAYS_ARTIFACTS = 'SAVE_GATEWAYS_ARTIFACTS',
  SAVE_GATEWAYS_DEPLOYMENTS = 'SAVE_GATEWAYS_DEPLOYMENTS',
  SET_SELECTED_DETAILED_GATEWAY = 'SET_SELECTED_DETAILED_GATEWAY',
  SET_SELECTED_DETAILED_DEPLOYMENT = 'SET_SELECTED_DETAILED_DEPLOYMENT',
}

export default GatewaysActionTypesEnum;
