import { Button, Paper } from '@material-ui/core';
import UserIcon from '@material-ui/icons/VerifiedUser';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import Select from 'react-select';
import { IDomainUserInDTO } from '../../../repository/jago/model/input/IDomainUserInDTO';
import { BLUE, GREEN } from '../../../style/StyleConsts';
import ReduxLanguage from '../../ReduxLanguage';
import AbilityProvider from '../../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../../services/PermissionService/PermissionConstants';
import Permission from '../../Permission/Permission';

export interface UserImpersonateFormState {
  timeValue?: number;
};

export interface UserImpersonateFormProps {
  onImpersonateReadyOnly: (timeValue?: number) => void;
  user: IDomainUserInDTO;
  onImpersonateUser: (timeValue?: number) => void;
};

const timeOptions = [
  {
    label: <ReduxLanguage languageKey="forms.minutes" languageProps={{ minutes: 30 }}/>,
    value: 30,
  },
  {
    label: <ReduxLanguage languageKey="forms.minutes" languageProps={{ minutes: 60 }}/>,
    value: 60,
  },
  {
    label: <ReduxLanguage languageKey="forms.minutes" languageProps={{ minutes: 120 }}/>,
    value: 120,
  }
]
export default class UserImpersonateForm extends React.Component<UserImpersonateFormProps, UserImpersonateFormState> {
  
  constructor(props: UserImpersonateFormProps) {
    super(props);
    this.state = {
      timeValue: undefined,
    };
  }
  onSelectOption(value: number) {
    this.setState({ timeValue: value });
  }

  render(): React.ReactNode {
    const { onImpersonateReadyOnly, user, onImpersonateUser } = this.props;
    const { timeValue } = this.state;

    return (
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        
        <WarningIcon style={{ color: 'orange', alignSelf: 'center', fontSize: 50 }} />
        <h3 style={{ marginTop: 0, fontWeight: 'bold', textAlign: 'center' }}>{<ReduxLanguage languageKey="forms.warning" />}</h3>
        <div style={{ marginBottom: 20 }}>
          <h4 style={{ fontWeight: 'bold', marginTop: 15 }}>{<ReduxLanguage languageKey="forms.selectSessionDuration" />}<span style={{ color: 'red'}}>*</span></h4>
          <Select
            options={timeOptions}
            placeholder={<ReduxLanguage languageKey={'forms.selectSessionDurationPlaceholder'} />}
            onChange={(e) => this.onSelectOption(e!.value)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55 }),
            }} 
            />
        </div>
        <Paper style={{ padding: 10, marginTop: 0 }}>
          <h3 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.impersonateReadOnly'} />}</h3>
          <h4 style={{ fontWeight: 100, marginTop: 10 }}>{<ReduxLanguage languageKey="forms.impersonateReadOnlyDescription" />}</h4>
          <h5 style={{ fontWeight: 'bold', marginTop: 10, textAlign: 'left', color: 'red' }}>{<ReduxLanguage languageKey="forms.impersonateReadOnlyWarning" />}</h5>
          <Button
            color="primary"
            variant="contained"
            disabled={!timeValue}
            style={{ marginTop: 10, alignSelf: 'center', backgroundColor: BLUE, width: '100%', borderRadius: 100 }}
            onClick={() => onImpersonateReadyOnly(timeValue)}
          >
            <ReduxLanguage languageKey={'domains.impersonateReadOnly'}/>
          </Button>
        </Paper>
        <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.DOMAIN_IMPERSONATE_WRITE}>
          <Paper style={{ padding: 10, marginTop: 20 }}>
            <h3 style={{ color: '#3f3f3f', fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'domains.impersonateUser'} />}</h3>
            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <UserIcon style={{ fontSize: 20, color: GREEN, marginLeft: 10, marginRight: 5 }} />
              <div>
                <h5>{`${user.firstname} ${user.lastname}`}</h5>
                <h5>{`${user.email}`}</h5>
              </div>
            </div>
            <h4 style={{ fontWeight: 100, marginTop: 10 }}>{<ReduxLanguage languageKey="forms.impersonateUserDescription" />}</h4>
            <h4 style={{ fontWeight: 100, marginTop: 5, marginLeft: 15 }}>• {<ReduxLanguage languageKey="forms.impersonateUserDescriptionOne" />}</h4>
            <h4 style={{ fontWeight: 100, marginTop: 5, marginLeft: 15 }}>• {<ReduxLanguage languageKey="forms.impersonateUserDescriptionTwo" />}</h4>
            <h4 style={{ fontWeight: 100, marginTop: 5, marginLeft: 15 }}>• {<ReduxLanguage languageKey="forms.impersonateUserDescriptionThree" />}</h4>
              <Button
                color="primary"
                variant="contained"
                disabled={!timeValue}
                style={{ marginTop: 10, alignSelf: 'center', backgroundColor: GREEN, width: '100%', borderRadius: 100 }}
                onClick={() => onImpersonateUser(timeValue)}
              >
              <ReduxLanguage languageKey={'domains.impersonateUser'}/>
              </Button>
          </Paper>
        </Permission>
      </div>
    );
  }
}
