
import axios from 'axios';

const config = {
  auth: {
    username: 'EjSo30qFq0mJ9DJv9hL',
    password: 'X',
  },
};

export const axiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://sofialocks.freshdesk.com/api/v2',
});

export function createFreshdeskTicket(ticketDTO) {
  return axiosInstance.post('/tickets', ticketDTO, config);
}