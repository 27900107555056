import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { WarningOutlined } from '@material-ui/icons';
import React from 'react';
import ReduxLanguage from '../ReduxLanguage';
import { IModalViewProps } from './ModalTypes';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface WarningModalProps {
  titleMessageKey: string;
  bodyMessageKey: string;
  confirmMessageKey?: string;
  onConfirm?:() => void;

};

export default class WarningModal extends React.Component<IModalViewProps<WarningModalProps>> {

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { bodyMessageKey, titleMessageKey, confirmMessageKey } = this.props.modalProps;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <div style={{ padding: 20, height: 300, width: 400, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <WarningOutlined style={{ color: 'orange', fontSize: 50 }}/>
          <h3 style={{ color: '#3f3f3f' }}>{<ReduxLanguage languageKey={titleMessageKey || 'forms.warning'} />}</h3>
          <h4 style={{ fontWeight: 100, marginTop: 0, textAlign: 'center' }}>{<ReduxLanguage languageKey={bodyMessageKey} />}</h4>
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, width: 250, borderRadius: 100 }}
            onClick={() => this.handleClose()}
            >
              <ReduxLanguage languageKey={confirmMessageKey || "forms.close"} />
            </Button>
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const { hideModal } = this.props;
    const { onConfirm } = this.props.modalProps;
    if (onConfirm) onConfirm();
    hideModal();
  }
}
