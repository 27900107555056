import { Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as React from "react";
import { IDomainSubscriptionInDTO } from '../../../repository/jago/model/input/IDomainConfigInDTO';
import { SubscriptionResourceTypes } from '../../../repository/jago/model/output/RequestParamsDTOs';
import translations from '../../../translations/i18next';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
  },
  
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15
  },
  rowBodyContainerOuter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  icon: {
    color: 'lightskyblue',
    fontSize: 20,
  },
  warningContainer: {
    backgroundColor: 'red',
    borderRadius: 10,
    padding: 5,
    marginTop: 10,
  },
  warningText: {
    color: 'white',
    fontWeight: 'bold'
  },
  subscriptionText: {
    fontWeight: 'bold'
  }
});

export interface IDomainSubscriptionRowProps extends WithStyles<typeof styles>{
  subscription?: IDomainSubscriptionInDTO;
}

class DomainSubscriptionRow extends React.Component<IDomainSubscriptionRowProps, any> {
  public render() {
    const { subscription, classes } = this.props;
    const hasMoreElementThanTotal = subscription?.used && subscription?.quantity ? subscription?.used > subscription?.quantity : false;
    let validationColor = subscription?.expired ? 'red' : 'darkgreen';
    validationColor = hasMoreElementThanTotal ? 'red ': validationColor;
    return (
      <Card className={classes.rowContainer} style={{ borderLeft: `4px solid ${validationColor}`}}>
        <div className={classes.rowBodyContainerOuter}>
          <div className={classes.rowBodyContainer}>
            <div>
              <h3 className={classes.rowTitle}>{`${subscription?.resourceType}`}</h3>
              <h4>{`${translations.t('domains.expiration')}: ${moment(subscription?.expirationDate).format('LLL')}`}</h4>
              {subscription?.resourceType === SubscriptionResourceTypes["USERS+LOCKS"] || subscription?.resourceType === SubscriptionResourceTypes.USERS ||  subscription?.resourceType === SubscriptionResourceTypes.LOCKS ? (
                <div>
                  <h3 className={classes.subscriptionText} style={{ color: validationColor }}>{`${translations.t('domains.used').toUpperCase()}: ${subscription?.used} / ${subscription?.quantity}`}</h3>
                  {hasMoreElementThanTotal ? 
                  <Card className={classes.warningContainer}>
                    <h5 className={classes.warningText}>{translations.t('domains.tooMuchElements')}</h5>
                  </Card>
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(DomainSubscriptionRow);