enum RechargesActionTypesEnum {
  SAVE_RECHARGES = 'SAVE_RECHARGES',
  SAVE_RECHARGE_DETAILED = 'SAVE_RECHARGE_DETAILED',
  SAVE_CUSTOMERS = 'SAVE_CUSTOMERS',
  SAVE_WALLETS_ALL = 'SAVE_WALLETS_ALL',
  SAVE_CUSTOMERS_SEARCH = 'SAVE_CUSTOMERS_SEARCH',
  SAVE_CUSTOMER_PLANTS = 'SAVE_CUSTOMER_PLANTS',
  SAVE_VARS_SEARCH = 'SAVE_VARS_SEARCH',
}

export default RechargesActionTypesEnum;
