import { PaginatedDataDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as DomainDecoder from '../../codec/domainInDTODecoder';
import * as DomainUsersDecoder from '../../codec/domainUsersInDTODecoder';
import * as CreditsAPI from '../../repository/jago/creditsAPI';
import * as DomainsAPI from '../../repository/jago/domainsAPI';
import { IDomainConfigParsedDTO } from '../../repository/jago/model/input/IDomainConfigInDTO';
import { IDomainDetailedDTO } from '../../repository/jago/model/input/IDomainDetailedDTO';
import { DomainIrisStatusOptions, IDomainFormDTO, IDomainInDTO } from '../../repository/jago/model/input/IDomainInDTO';
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';
import { IDomainConfigOutDTO } from '../../repository/jago/model/output/IDomainConfigOutDTO';
import { IDomainOutDTO, ServiceType } from '../../repository/jago/model/output/IDomainOutDTO';
import { IDomainUserOutDTO } from '../../repository/jago/model/output/IDomainUserOutDTO';
import { IProductOutDTO } from '../../repository/jago/model/output/IProductOutDTO';
import { IDomainRequestParamsDTO, IDomainSubscriptionParamsDTO, SubscriptionAggregationTypes, SubscriptionStatus } from '../../repository/jago/model/output/RequestParamsDTOs';
import { IState } from '../store';
import { ActionsUnion, IThunkAction, createAction } from '../utils';
import { IDomainAdditionalInfoOutDTO } from './../../repository/jago/model/input/IDomainAdditionalInfoInDTO';
import { elaborateDomainType } from './domain.utils';
import DomainsActionTypesEnum from './model/DomainsActionTypesEnum';

export const DomainsActions = {
  saveDomains: createAction<typeof DomainsActionTypesEnum.SAVE_DOMAINS, PaginatedDataDTO<IDomainInDTO>>(DomainsActionTypesEnum.SAVE_DOMAINS),
  setSelectedDetailedDomain: createAction<typeof DomainsActionTypesEnum.SET_SELECTED_DETAILED_DOMAIN, IDomainDetailedDTO | {}>(DomainsActionTypesEnum.SET_SELECTED_DETAILED_DOMAIN),
};

export function fetchSofiaDomains(params?: IDomainRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const domainsResponse = await DomainsAPI.fetchSofiaDomains(params);
      if (domainsResponse && domainsResponse.data) {
        const decodedData = DomainDecoder.decode(domainsResponse.data);
        dispatch(DomainsActions.saveDomains(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendSofiaDomains(params?: IDomainRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { domains } = getState().domains;
      if (_.isEmpty(domains)) return;
      const stateDomains = domains as PaginatedDataDTO<IDomainInDTO>;
      const domainsResponse = await DomainsAPI.fetchSofiaDomains(params);
      if (domainsResponse && domainsResponse.data) {
        const decodedData = DomainDecoder.decode(domainsResponse.data);
        const domainsToSave : PaginatedDataDTO<IDomainInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateDomains.content,
            ...decodedData.content
          ],
        }
        dispatch(DomainsActions.saveDomains(domainsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaDomainConfiguration(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const configResponse = await DomainsAPI.fetchSofiaDomainConfiguration(domain.hostname);
      if (configResponse && configResponse.data) {
        const domainConfig: IDomainConfigParsedDTO = JSON.parse(configResponse.data.data);
        const detailedDomain: IDomainDetailedDTO = {
          ...domain,
          config: domainConfig,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}


export function fetchSofiaDomainSubscription(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const params: IDomainSubscriptionParamsDTO = {
        mode: SubscriptionAggregationTypes.AGGREGATED,
        status: SubscriptionStatus.VALID,
      };
      const configResponse = await DomainsAPI.fetchSofiaDomainSubscriptions(domain.hostname, params);
      if (configResponse && configResponse.data) {
        const detailedDomain: IDomainDetailedDTO = {
          ...domain,
          subscriptions: configResponse.data,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}


export function fetchSofiaDomainUsers(params?: PaginationQueryParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const domainUsersResponse = await DomainsAPI.fetchSofiaDomainUsers(domain.hostname, { ...params, roleIds: [1] });
      if (domainUsersResponse && domainUsersResponse.data) {
        const decodedData = DomainUsersDecoder.decode(domainUsersResponse.data);
        const detailedDomain = {
          ...domain,
          users: decodedData,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaDomainInstallers(params?: PaginationQueryParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const domainUsersResponse = await DomainsAPI.fetchSofiaDomainUsers(domain.hostname, { ...params, roleIds: [2] });
      if (domainUsersResponse && domainUsersResponse.data) {
        const decodedData = DomainUsersDecoder.decode(domainUsersResponse.data);
        const detailedDomain = {
          ...domain,
          installers: decodedData,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaDomainAdditionalInfo(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const domainInfoResponse = await DomainsAPI.fetchSofiaDomainAdditionalInfo(domain.hostname);
      if (domainInfoResponse && domainInfoResponse.data) {
        const detailedDomain = {
          ...domain,
          note: domainInfoResponse.data.note,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      }
    } catch (error) {
    }
  };
}

export function fetchSofiaDomainInfo(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const domainInfoResponse = await DomainsAPI.fetchSofiaDomainInfo(domain.hostname);
      if (domainInfoResponse && domainInfoResponse.data) {
        const domainTypeDTO = elaborateDomainType(domainInfoResponse.data);
        const detailedDomain = {
          ...domain,
          info: domainInfoResponse.data,
          ...domainTypeDTO,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      }
    } catch (error) {
    }
  };
}


export function fetchSofiaDomainCustomer(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const domainCustomerResponse = await CreditsAPI.fetchCustomers({ hostname: domain.hostname });
      if (domainCustomerResponse && domainCustomerResponse.data && domainCustomerResponse.data.content) {
        const customer = _.first(domainCustomerResponse.data.content);
        const detailedDomain = {
          ...domain,
          customer,
        };
        dispatch(DomainsActions.setSelectedDetailedDomain(detailedDomain));
      }
    } catch (error) {
    }
  };
}

export function updateSofiaDomainAdditionalInfo(domain: IDomainInDTO, note: IDomainAdditionalInfoOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      await DomainsAPI.updateSofiaDomainAdditionalInfo(domain.hostname, note);
    } catch (error) {
      throw error;
    }
  };
}

export function updateSofiaDomainConfiguration(domain: IDomainInDTO, config: IDomainConfigOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      await DomainsAPI.updateSofiaDomainConfiguration(domain.hostname, config);
    } catch (error) {
      throw error;
    }
  };
}

export function enableIris(plantId: number): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      await DomainsAPI.enableIris(plantId, DomainIrisStatusOptions.ENABLED);
    } catch (error) {
      throw error;
    }
  };
}

export function impersonateDomainUser(domainHostname: string, userEmail: string, duration?: number): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const clientId = 'luckeyfrontend';
      const impersonateResponse = await DomainsAPI.impersonateDomainUser(domainHostname, userEmail, clientId, duration);
      if(impersonateResponse.data && impersonateResponse.data.token) {
        return impersonateResponse.data.token;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createNewDomain(newDomainData: IDomainFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newDomain: IDomainOutDTO = {
        name: newDomainData.name,
        productId: newDomainData.productId,
        serviceType: ServiceType.LUCKEY,
        externalRef: newDomainData.externalRef,
      }
      const createDomainResponse = await DomainsAPI.createNewDomain(newDomain);
      if (createDomainResponse && createDomainResponse.data) {
        return createDomainResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewUserForDomain(newUserData: IDomainUserOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const createUserResponse = await DomainsAPI.createUserForDomain(domain.hostname, newUserData);
      if (createUserResponse && createUserResponse.data) {
        return createUserResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function addProductToDomain(product: IProductInDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { selectedDetailedDomain } = getState().domains;
      const domain = selectedDetailedDomain as IDomainDetailedDTO;
      const productOut: IProductOutDTO = {
        productId: product.id,
        externalRef: `${product.code}-${moment().valueOf()}`,
      }
      const createPluginResponse = await DomainsAPI.addProductToDomain(domain.hostname, productOut);
      if (createPluginResponse && createPluginResponse.data) {
        return createPluginResponse;
      }
    } catch (error) {
      throw error;
    }
  }
}

export type DomainsActionsType = ActionsUnion<typeof DomainsActions>;


export function fetchSofiaDomainsSearch(params?: IDomainRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const domainsResponse = await DomainsAPI.fetchSofiaDomains(params);
      if (domainsResponse && domainsResponse.data) {
        const decodedData = DomainDecoder.decode(domainsResponse.data);
        return decodedData.content;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function exportManagersAndSubscriptionsCSV() {
  return async () => {
    const response = await DomainsAPI.fetchManagersAndSubscriptionCSV();
    FileSaver.saveAs(response.data, `managers-list${moment().toISOString()}.csv`);
  }
}