import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as AuthService from '../services/AuthService';
import OrdersStatus from './Activations/OrdersStatus';
import RechargeRequestsPage from './Activations/RechargeRequestsPage';
import CustomersPage from './Credits/CustomersPage';
import MarketplacesPage from './Credits/MarketplacesPage';
import ProductsPage from './Credits/ProductsPage';
import RechargeCardsPage from './Credits/RechargeCardsPage';
import SubscriptionsPage from './Credits/SubscriptionsPage';
import DomainsPage from './DomainsPage';
import GatewaysArtifactsPage from './Gateways/GatewaysArtifactsPage';
import GatewaysDeploymentsPage from './Gateways/GatewaysDeploymentsPage';
import GatewaysPage from './Gateways/GatewaysPage';
import HomePage from './HomePage';
import DrawerLayout from './Layouts/DrawerLayout';
import LoginPage from './LoginPage';
import AppRoute from './Routes/AppRoute';
import AuthRoute from './Routes/AuthRoute';
import HardwareSalesInfoPage from './Sales/HardwareSalesInfoPage';
import ServiceStatus from './ServiceStatus';
import UsersPage from './Users/UsersPage';
import OpportunitiesPage from './Vars/OpportunitiesPage';
import ProductInfo from './Vars/ProductInfo';
import VarSettings from './Vars/VarSettings';
import VarsPage from './Vars/VarsPage';
import VarUsersPage from './Vars/VarUsersPage';
import ActivationRequestsPage from './Activations/ActivationRequestsPage';



export default class AppRouterWithConfig extends React.Component<{}> {
  private clientId;

  constructor(props: {}) {
    super(props);
    this.clientId = AuthService.getGoogleClientId();
  }

  render() {
    return (
      <GoogleOAuthProvider clientId={this.clientId}>
          <Router>
            <div>
              <AppRoute path="/login" component={LoginPage} />
              <Switch>
                <AuthRoute path="/" exact layout={DrawerLayout} component={HomePage} />
                <AuthRoute path="/home" layout={DrawerLayout} component={HomePage} />
                <AuthRoute path="/vars" layout={DrawerLayout} component={VarsPage} />
                <AuthRoute path="/varSettings" layout={DrawerLayout} component={VarSettings} />
                <AuthRoute path="/opportunities" layout={DrawerLayout} component={OpportunitiesPage} />
                <AuthRoute path="/productInfo" layout={DrawerLayout} component={ProductInfo} />
                <AuthRoute path="/varusers" layout={DrawerLayout} component={VarUsersPage} />
                <AuthRoute path="/marketplaces" layout={DrawerLayout} component={MarketplacesPage} />
                <AuthRoute path="/domains" layout={DrawerLayout} component={DomainsPage} />
                <AuthRoute path="/customers" layout={DrawerLayout} component={CustomersPage} />
                <AuthRoute path="/products" layout={DrawerLayout} component={ProductsPage} />
                <AuthRoute path="/hardwareSalesInfo" layout={DrawerLayout} component={HardwareSalesInfoPage} />
                <AuthRoute path="/subscriptions" layout={DrawerLayout} component={SubscriptionsPage} />
                <AuthRoute path="/rechargeCards" layout={DrawerLayout} component={RechargeCardsPage} />
                <AuthRoute path="/gateways" layout={DrawerLayout} component={GatewaysPage} />
                <AuthRoute path="/gatewaysArtifacts" layout={DrawerLayout} component={GatewaysArtifactsPage} />
                <AuthRoute path="/gatewaysDeployments" layout={DrawerLayout} component={GatewaysDeploymentsPage} />
                <AuthRoute path="/activationRequests" layout={DrawerLayout} component={ActivationRequestsPage} />
                <AuthRoute path="/rechargeRequests" layout={DrawerLayout} component={RechargeRequestsPage} />
                <AuthRoute path="/activation/wallet" layout={DrawerLayout} component={OrdersStatus} />
                <AuthRoute path="/status" layout={DrawerLayout} component={ServiceStatus} />
                <AuthRoute path="/users" layout={DrawerLayout} component={UsersPage} />
              </Switch>
            </div>
          </Router>
        </GoogleOAuthProvider>
    );
  }
}
