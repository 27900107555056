import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import MomentUtils from '@date-io/moment';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import * as RechargesActions from '../../redux/activations/recharges.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from '../../redux/store';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import { IWalletInDTO } from '../../repository/jago/model/input/IWalletInDTO';
import { ICustomerPlantsRequestParamsDTO, ICustomerRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import ReduxLanguage from "../ReduxLanguage";
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  iconCustomer: {
    marginRight: 5,
    fontSize: 25,
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IRechargeNewFormState {
  isCustomer: boolean;
  customerSearchInput: string;
  customerSelected: ICustomerInDTO;
  customersLoading: boolean;
  plantSelected: IPlantInDTO;
  plantsLoading: boolean;
  walletSelected: IWalletInDTO;
  varSearchInput: string;
  varSelected: IVarInDTO;
  varsLoading: boolean;
};

export interface IRechargeNewFormProps extends WithStyles<typeof styles>{
  onCreateNewRecharge: (wallet: IWalletInDTO|undefined, VAR: IVarInDTO|undefined) => void;
  onOpenCustomerInfo: (customer: ICustomerInDTO) => void;
  customers: PaginatedDataDTO<ICustomerInDTO>;
  vars: PaginatedDataDTO<IVarInDTO>;
  wallets: PaginatedDataDTO<IWalletInDTO>;
}

export type ComponentProps = IRechargeNewFormProps & IReduxProps;
class RechargeNewForm extends React.Component<ComponentProps, IRechargeNewFormState> {
  inputFileRefInvoice = null;

  constructor(props: ComponentProps) {
    super(props);
    this.inputFileRefInvoice = React.createRef();

    this.state = {
      isCustomer: true,
      customerSearchInput: "",
      customerSelected: null,
      customersLoading: false,
      plantSelected: null,
      plantsLoading: false,
      walletSelected: null,
      varSearchInput: "",
      varSelected: null,
      varsLoading: false,
    };
  }

  public componentDidMount() {
    const { dispatch } = this.props;
    dispatch<any>(SettingsActions.setSpinnerVisible(false));
  }

  async onCustomerSearchInputChanged(newInputValue: string) {
    const { dispatch } = this.props;
    const { varSelected } = this.state;
    await dispatch<any>(RechargesActions.resetCustomerPlants());
    this.setState({ plantSelected: null })
    this.setState({ walletSelected: null })
    this.setState({ customerSearchInput: newInputValue })
    if (!newInputValue) {
      await dispatch<any>(RechargesActions.resetCustomersSearch());
      this.setState({ customersLoading: false })
      return;
    }
    this.setState({ customersLoading: true })
    const params: ICustomerRequestParamsDTO = { name: newInputValue, varId: varSelected&&varSelected.id?varSelected.id.toString():undefined };
    await dispatch<any>(RechargesActions.fetchCustomersSearch(params));
    this.setState({ customersLoading: false })
  }

  async onCustomerSelected(newValue: string | ICustomerInDTO) {
    const { dispatch } = this.props;
    const newValue_customer = !newValue?null:(newValue as ICustomerInDTO)
    this.setState({ customerSelected: newValue_customer })
    if (newValue_customer && newValue_customer.id) {
      this.setState({ plantsLoading: true })
      const params: ICustomerPlantsRequestParamsDTO = { customerId: newValue_customer.id };
      await dispatch<any>(RechargesActions.fetchCustomerPlants(params));
      this.setState({ plantsLoading: false })
    }
    else {
      await dispatch<any>(RechargesActions.resetCustomerPlants());
      this.setState({ plantSelected: null })
      this.setState({ walletSelected: null })
    }
  }

  async onPlantSelected(newValue: string | IPlantInDTO) {
    const { wallets } = this.props;
    const newValue_plant = !newValue?null:(newValue as IPlantInDTO)
    this.setState({ plantSelected: newValue_plant })
    if (newValue_plant && newValue_plant.id && newValue_plant.walletId && 
      wallets && wallets.content && wallets.content.filter(e=>e.id===newValue_plant.walletId).length>0) {
      this.setState({ walletSelected: wallets.content.filter(e=>e.id===newValue_plant.walletId)[0] })
    }
    else {
      this.setState({ walletSelected: null })
    }
  }

  async onVarSearchInputChanged(newInputValue: string) {
    const { dispatch } = this.props;
    if (!newInputValue) {
      await dispatch<any>(RechargesActions.resetVarsSearch());
      this.setState({ varsLoading: false })
      return;
    }
    this.setState({ varsLoading: true })
    const params: ICustomerRequestParamsDTO = { name: newInputValue };
    await dispatch<any>(RechargesActions.fetchVarsSearch(params));
    this.setState({ varsLoading: false })
  }

  async onVarSelected(newValue: string | IVarInDTO) {
    const { dispatch } = this.props;
    const newValue_VAR = !newValue?null:(newValue as IVarInDTO)
    this.setState({ varSelected: newValue_VAR })
  }

  async onChangeIsCustomer(value?: any) {
    let isCustomer_new = value==="T"
    this.setState({ isCustomer: isCustomer_new })

    this.setState({ varSelected: null })
    this.setState({ customerSelected: null })
    this.setState({ plantSelected: null })
    this.setState({ walletSelected: null })
  }

  async onSubmitForm() {
    const { dispatch } = this.props;
    let { walletSelected, varSelected, isCustomer } = this.state;
    if (!this.canSubmitForm())
      return;
    // Salvo
    await dispatch<any>(RechargesActions.resetCustomerPlants());
    const { onCreateNewRecharge } = this.props;
    onCreateNewRecharge(walletSelected,!isCustomer?varSelected:undefined);
  }

  canSubmitForm() {
    const { walletSelected, varSelected, isCustomer } = this.state;
    return walletSelected&&walletSelected.id&&(isCustomer||(varSelected&&varSelected.id));
  }

  public render() {
    const { classes, wallets, onOpenCustomerInfo, customersSearch, customerPlants, varsSearch } = this.props;
    const { customerSelected, customersLoading, customerSearchInput, plantSelected, plantsLoading, isCustomer, varSearchInput, varsLoading, varSelected } = this.state;

    const plantOptions = wallets&&wallets.content&&customerPlants&&customerPlants.data&&(customerPlants.data as PaginatedDataDTO<IPlantInDTO>).content?
    (customerPlants.data as PaginatedDataDTO<IPlantInDTO>).content.filter(e=>wallets.content.filter(f=>f.id===e.walletId).length>0):[]

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="rechargeBasicDataAccordion"
            >
            <div>
              <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'recharges.basicData'} />}</h2>
            </div>
            </AccordionSummary>
            <AccordionDetails style={{ display:'flex',flexDirection:'column' }}>
              <div style={{display:'flex',flexDirection:'column'}}>
                <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'activations.selectCustomerOrVar'} />}</h5>
                <FormControl component="fieldset" disabled={false} style={{marginBottom:20, marginLeft:10}} required>
                  <RadioGroup value={isCustomer?"T":"F"} 
                    onChange={e => this.onChangeIsCustomer(e.target.value)}>
                    <FormControlLabel value={"T"} control={<Radio />} label="Customer" />
                    <FormControlLabel value={"F"} control={<Radio />} label="VAR" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={{display:'flex',flexDirection:'column'}}>
                {!isCustomer?(
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'recharges.varSearch'} />}</h5>
                    <Autocomplete
                      id="var-search"
                      style={{ width: 400 }}
                      getOptionLabel={(option) => option.name}
                      filterOptions={(x) => (x as IVarInDTO[])}
                      options={
                        varsSearch&&varsSearch.data&&(varsSearch.data as PaginatedDataDTO<IVarInDTO>).content?
                        (varsSearch.data as PaginatedDataDTO<IVarInDTO>).content:[]
                      }
                      autoComplete
                      loading={varsLoading}
                      includeInputInList
                      filterSelectedOptions
                      value={varSelected}
                      noOptionsText={<ReduxLanguage languageKey={varSearchInput?'recharges.varSearchNoMatch':'recharges.varSearchEnterName'} />}
                      onChange={(event, newValue) => {this.setState({ varSelected: !newValue?null:(newValue as IVarInDTO) });}}
                      onInputChange={(event, newInputValue) => {this.onVarSearchInputChanged(newInputValue)}}
                      renderInput={(params) => (
                        <TextField
                          autoComplete='off'
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {varsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                ):null}
                {isCustomer||(varSelected&&varSelected.id)?(
                  <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={isCustomer?'recharges.customerSearch':'recharges.varCustomerSearch'} />}</h5>
                ):null}
                {isCustomer||(varSelected&&varSelected.id)?(
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <Autocomplete
                      id="customer-search"
                      style={{ width: 400 }}
                      getOptionLabel={(option) => option.name}
                      filterOptions={(x) => (x as ICustomerInDTO[])}
                      options={
                        customersSearch&&customersSearch.data&&(customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content?
                        (customersSearch.data as PaginatedDataDTO<ICustomerInDTO>).content:[]
                      }
                      autoComplete
                      loading={customersLoading}
                      includeInputInList
                      filterSelectedOptions
                      value={customerSelected}
                      noOptionsText={<ReduxLanguage languageKey={customerSearchInput?'recharges.customerSearchNoMatch':'recharges.customerSearchEnterName'} />}
                      onChange={(event, newValue) => {this.onCustomerSelected(newValue)}}
                      onInputChange={(event, newInputValue) => {this.onCustomerSearchInputChanged(newInputValue)}}
                      renderInput={(params) => (
                        <TextField
                          autoComplete='off'
                          {...params}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {customersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                    {customerSelected?(
                      <Button
                        variant="contained"
                        className={classes.actionButton}
                        style={{ backgroundColor:'darkcyan', flexShrink:'0'}}
                        onClick={() => {if (customerSelected) onOpenCustomerInfo(customerSelected)}}
                      >
                        {<VisibilityIcon className={classes.icon}/>}
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey={"recharges.seeCustomer"} /></h5>
                      </Button>
                    ):null}
                  </div>
                ):null}
                {customerSelected&&!plantsLoading?(
                  <div style={{marginTop:10}}>
                    <h5 style={{ fontWeight: 'normal' }}>{<ReduxLanguage languageKey={'recharges.plantSearch'} />}</h5>
                    <Select
                      key={`select_modal_select_key`}
                      value={plantSelected}
                      options={plantOptions}
                      isSearchable={false}
                      placeholder={<ReduxLanguage languageKey={'recharges.plantSearchEnterName'} />}
                      onChange={(e) => {this.onPlantSelected(e)}}
                      formatOptionLabel={(o) => <>
                        {wallets.content.filter(e=>e.id===o.walletId).length>0?(
                          "[# " + wallets.content.filter(e=>e.id===o.walletId)[0].id + "] " + wallets.content.filter(e=>e.id===o.walletId)[0].name
                        ):""} - <b>{o.hostname}</b></>}
                      required
                      isOptionSelected={(o) => plantSelected&&plantSelected.id?plantSelected.id === o.id:false}
                      styles={{
                        menu: (styles) => Object.assign(styles, { zIndex: 1000, width: 600 }),
                        control: (styles) => ({ ...styles, minHeight: 55, width: 600 }),
                      }} 
                    />
                  </div>
                ):null}
              </div>
            </AccordionDetails>
          </Accordion>
          
          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:'1'}}>
            <Button
              variant="contained"
              disabled={!this.canSubmitForm()}
              style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', fontWeight: 'bold' }}
              onClick={() => this.onSubmitForm()}
            >
              <ReduxLanguage languageKey="recharges.createRequest" />
            </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    customersSearch: state.recharges.customersSearch,
    customerPlants: state.recharges.customerPlants,
    varsSearch: state.recharges.varsSearch,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(RechargeNewForm));