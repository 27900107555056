import { Card, CardActionArea, Avatar } from "@material-ui/core";
import * as React from "react";
import { IDomainInDTO } from '../../repository/jago/model/input/IDomainInDTO';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { getDomainNameFromHostname } from "../../repository/utils";
import ReduxLanguage from "../ReduxLanguage";
import { Business } from "@material-ui/icons";
import { BLUE } from "../../style/StyleConsts";
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { Roles } from '../../services/PermissionService/PermissionConstants';
import { IPlantInDTO } from "../../repository/jago/model/input/IPlantInDTO";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    position: 'relative',
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 15
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  domainTypeContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  domainTypeInnerContainer: {
    padding: 5,
    backgroundColor: BLUE,
    borderRadius: 5,
  },
  domainTypeText: {
    color: 'white',
    fontWeight: 'bold'
  }
});
export interface IThemePlantRowProps extends WithStyles<typeof styles>{
  plant?: IPlantInDTO;
}

class ThemePlantRow extends React.Component<IThemePlantRowProps, any> {
  public render() {
    const { plant, classes } = this.props;
    return (
      <Card className={classes.rowContainer}>
        <div className={classes.rowBodyContainer} style={{padding:20}}>
          <Avatar className={classes.avatar}>{plant?.hostname?plant.hostname[0]:""}</Avatar>
          <div>
            <h3 className={classes.rowTitle}>#{plant?.appId} - {plant?.hostname}</h3>
          </div>
        </div>
      </Card>
    );
  }
}

export default withStyles(styles)(ThemePlantRow);