
import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import * as PaginationInDTODecoder from '../../codec/paginationInDTODecoder';
import { IHardwareInDTO, ISalesInfoInDTO } from '../../repository/jago/model/input/ISalesInfoInDTO';
import IAddSalesInfoOutDTO from '../../repository/jago/model/output/IAddSalesInfoOutDTO';
import IEditBatchSalesInfoOutDTO from '../../repository/jago/model/output/IEditBatchSalesInfoOutDTO';
import IUnreconciledHardwareOutDTO from '../../repository/jago/model/output/IUnreconciledHardwareOutDTO';
import { ISalesInfoParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import * as SalesInfoAPI from '../../repository/jago/salesInfoAPI';
import { IState } from '../store';
import { ActionsUnion, createAction, IThunkAction } from '../utils';
import SalesActionTypesEnum from './model/SalesActionTypesEnum';

export const SalesActions = {
  saveSalesInfo: createAction<typeof SalesActionTypesEnum.SAVE_SALES_INFO, PaginatedDataDTO<ISalesInfoInDTO>>(SalesActionTypesEnum.SAVE_SALES_INFO),
  saveNotReconciledHardware: createAction<typeof SalesActionTypesEnum.SAVE_UNRECONCILED_HARDWARE, IHardwareInDTO[]>(SalesActionTypesEnum.SAVE_UNRECONCILED_HARDWARE),
};

export function fetchSalesInfo(params: ISalesInfoParamsDTO = { pageSize: 100 }): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      const response = await SalesInfoAPI.fetchSalesInfo(params);
      if (response && response.data) {
        const decodedInfo = PaginationInDTODecoder.decode<ISalesInfoInDTO>(response.data);
        dispatch(SalesActions.saveSalesInfo(decodedInfo));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchNotReconciledHardware(params?: IUnreconciledHardwareOutDTO): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      const response = await SalesInfoAPI.fetchNotReconciledHardware(params);
      if (response && response.data) {
        dispatch(SalesActions.saveNotReconciledHardware(response.data));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function addHardwareSalesInfo(data: IAddSalesInfoOutDTO): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      await SalesInfoAPI.createHardwareSalesInfo(data);
    } catch (error) {
      throw error;
    }
  };
}

export function editBatchHardwareSalesInfo(data: IEditBatchSalesInfoOutDTO): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      await SalesInfoAPI.editBatchHardwareSalesInfo(data);
    } catch (error) {
      throw error;
    }
  };
}

export type SalesActionsType = ActionsUnion<typeof SalesActions>;