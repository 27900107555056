import _ from 'lodash';
import * as amplitude from '@amplitude/analytics-browser';

const AMPLITUDE_API_KEY = '207cd08e0a93e2ea45dfcc84c33c9840';

export function initialize() {
  const plantName = window && window.location && window.location.hostname && window.location.hostname.replace('.jago.cloud', '');
  const isStagingSystem = _.includes(plantName, 'staging') || _.includes(plantName, 'localhost');
  let initOptions = { serverZone: 'EU' as any, minIdLength: 1, logLevel: amplitude.Types.LogLevel.Warn };
  if (isStagingSystem) {
    initOptions = { ...initOptions, logLevel: amplitude.Types.LogLevel.Debug };
  
  }
  amplitude.init(AMPLITUDE_API_KEY, initOptions);
}

export function logEvent(eventType, eventProperties) {
  const event = {
    event_type: eventType,
    event_properties: eventProperties,
  };
  amplitude.track(event)
}

export function reset() {
  amplitude.reset();
}