import React from 'react';

export interface ConfiguredComponentState {
  canRender: boolean;
}

export interface ConfiguredComponentProps {
  style?: React.CSSProperties;
  className?: string;
}

export default function fetchConfig(f?: () => Promise<void> | null) {
  return <P extends object>(Component: React.ComponentType<P>) => {
    class ConfiguredComponent extends React.Component<P & ConfiguredComponentProps, ConfiguredComponentState> {
      constructor(props: P & ConfiguredComponentProps) {
        super(props);
        this.state = {
          canRender: false,
        };
      }

      componentDidMount() {
        this.fetchConf();
      }

      async fetchConf() {
        if (!f) {
          this.setState({ canRender: true });
          return;
        }
        try {
          await f();
          this.setState({ canRender: true });
        } catch (err) { }
      }

      render() {
        const { canRender } = this.state;
        if (canRender) {
          return <Component {...this.props} {...this.state} />;
        } else {
          return null;
        }
      }
    }

    return ConfiguredComponent as any as React.ClassicComponentClass<P & ConfiguredComponentProps>;
  };
}
