import { IconButton, Snackbar } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { GridCloseIcon } from '@mui/x-data-grid';
import * as React from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import ReduxLanguage from '../ReduxLanguage';
import { FileCopyOutlined } from '@material-ui/icons';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});


export interface ICopyToClipboardWrapperState {
  snackIsOpen: boolean;
}

export interface ICopyToClipboardWrapperProps extends WithStyles<typeof styles>{
  children?: React.ReactElement;
  textToCopy?: string;
}

class CopyToClipboardWrapper extends React.Component<ICopyToClipboardWrapperProps, ICopyToClipboardWrapperState> {

  constructor(props: ICopyToClipboardWrapperProps) {
    super(props);
    this.state = {
      snackIsOpen: false,
    }
  }

  onCopy() {
    this.setState({ snackIsOpen: true });
  }

  public render() {
    const { textToCopy } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CopyToClipboard text={textToCopy || ''} onCopy={() => this.onCopy()}>
          <IconButton
            onClick={(e) => { e.stopPropagation(); this.onCopy()}}
            style={{ padding: 2, marginLeft: 3 }}
          >
            <FileCopyOutlined style={{ fontSize: 15 }} />  
          </IconButton>
        </CopyToClipboard>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: 'right',
          }}
          open={this.state.snackIsOpen}
          autoHideDuration={3000}
          onClose={() => this.setState({ snackIsOpen: false })}
          message={<ReduxLanguage languageKey="forms.copiedSuccess" />}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({ snackIsOpen: false })}>
                <GridCloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

export default withStyles(styles)(CopyToClipboardWrapper);