import MomentUtils from '@date-io/moment';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import { IState } from '../../redux/store';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import { IUserRoleOption } from '../../repository/jago/model/input/IUserRoleInDTO';
import { IVarOptionSelectDTO } from '../../repository/jago/model/input/IVarsSearchDTO';
import { RolesWithClusters } from '../../services/PermissionService/PermissionConstants';
import ReduxLanguage from "../ReduxLanguage";
import { RolesPermissionMap } from '../../services/PermissionService/PermissionsParser';
import { groupPermissionsByEntity } from '../../utils/Utils';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import translations from '../../translations/i18next';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  }
});

export interface IUserFormState {
  user: IUserFormDTO;
  errors: Record<keyof IUserFormDTO, boolean>;
  clustersSelected: IClusterInDTO[];
  clustersOptions: IClusterInDTO[];
  selectedVar: IVarOptionSelectDTO;
};

export interface IUserFormProps extends WithStyles<typeof styles>{
  onCreateEditUser: (user: IUserFormDTO) => void;
  user?: IUserFormDTO;
  rolesOptions: IUserRoleOption[];
  varsOptions: IVarOptionSelectDTO[];
  clustersOptions: IClusterInDTO[];
}


type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = IUserFormProps & IReduxProps;
class UserForm extends React.Component<ComponentProps, IUserFormState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      user: props.user ? props.user : {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        roleId: null,
        varId: null,
        clusterIds: [],
      },
      errors: {
        id: false,
        firstName: false,
        lastName: false,
        email: false,
        roleId: false,
        varId: false,
        clusterIds: false,
      },
      clustersSelected: [],
      clustersOptions: [],
      selectedVar: null,
    };
  }

  public componentDidMount() {
    const { user, varsOptions, clustersOptions } = this.props; 
    this.setState({ clustersOptions: structuredClone(this.props.clustersOptions) })
    // Init per caso "edit"
    if (user && user.clusterIds && clustersOptions) {
      // Init clustersSelected
      const clustersSelectedInitial = structuredClone(this.props.clustersOptions.filter(e=>this.props.user.clusterIds.filter(f=>f===e.id).length>0))
      if (clustersSelectedInitial && clustersSelectedInitial.length)
        this.setState({ clustersSelected: clustersSelectedInitial })
    }

    if (user && user.varId && varsOptions) {
      const varSelectedInitial = structuredClone(_.find(varsOptions, option => option.value === user.varId));
      this.setState({ selectedVar: varSelectedInitial })
    }
  }

  onChangeValue(field: keyof IUserFormDTO, mandatory: boolean, value?: any) {
    const { user, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: !value && mandatory ? true : false }
    });
    (user as any)[field] = value;
    this.setState({ user });
  }

  onSubmitForm() {
    const { rolesOptions } = this.props;
    const { user } = this.state;
    if (!this.canSubmitForm())
      return;
    const { onCreateEditUser } = this.props;

    const roleName = user.roleId && rolesOptions.filter(e=>e.id===user.roleId).length ? rolesOptions.filter(e=>e.id===user.roleId)[0].value : undefined
    if (!roleName)
      return

    // Check Clusters
    const roleWithClusters = roleName && _.includes(RolesWithClusters, roleName);
    let clusterIds: number[] = roleWithClusters ? this.state.clustersSelected.map(e => e.id):[];
    user.clusterIds = clusterIds;

    // Check VAR
    const roleWithVAR = roleName && _.includes(roleName, 'VAR');
    let varId = roleWithVAR ? Number(this.state.selectedVar.value) : null;
    user.varId = varId;
    onCreateEditUser(user);
  }

  canSubmitForm() {
    const { rolesOptions } = this.props;
    const { user, clustersSelected } = this.state;

    const roleName = user.roleId && rolesOptions.filter(e=>e.id===user.roleId).length ? rolesOptions.filter(e=>e.id===user.roleId)[0].value : undefined
    if (!roleName)
      return false

    const roleWithClusters = roleName && _.includes(RolesWithClusters, roleName);

    return user.firstName && user.lastName && user.email && user.roleId && 
      (!roleWithClusters || clustersSelected.length );
  }

  public render() {
    const { classes, rolesOptions, varsOptions } = this.props;
    const { user, errors, clustersSelected, clustersOptions, selectedVar } = this.state;
    const isEdit = Boolean(this.props.user)
    const selectedRole = user.roleId && _.find(rolesOptions, e=>e.id === user.roleId);
    const selectedRoleName = selectedRole && selectedRole.value;
    const roleWithClusters = selectedRoleName && _.includes(RolesWithClusters, selectedRoleName);
    const roleWithVAR = selectedRoleName && _.includes(selectedRoleName, 'VAR');
    const permissions = selectedRole && selectedRole.value ? RolesPermissionMap[selectedRole.value] : [];
    const groupedPermissions = groupPermissionsByEntity(permissions);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'users.userFormTitle'} />}</h2>
          <div style={{ flex: 0, marginRight: 10 }}>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'users.userFirstname'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.firstName}
              value={user.firstName}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('firstName', true, e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'users.userLastname'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.lastName}
              value={user.lastName}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('lastName', true, e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'users.userEmail'} />}
              variant="outlined"
              fullWidth
              required
              disabled={isEdit}
              error={errors.email}
              value={user.email}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('email', true, e.target.value)}
            />
            <Select
              key={user.roleId?user.roleId:-1}
              isClearable
              required
              isSearchable={false}
              value={
                user.roleId&&rolesOptions.filter(e=>e.id===user.roleId).length?
                rolesOptions.filter(e=>e.id===user.roleId)[0]:null
              }
              options={rolesOptions}
              placeholder={<><ReduxLanguage languageKey={'users.roleSelection'} /></>}
              formatOptionLabel={(o) => <>{o.id} - {o.name.replaceAll("_"," ")}</>}
              isOptionSelected={(o) => user.roleId===o.id}
              onChange={(e) => this.onChangeValue('roleId', true, e&&e.id?e.id:undefined)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 300, marginLeft: 0, marginRight: 0, marginTop: 20 }),
              }} 
            />
            {roleWithClusters ? (
              <Autocomplete
                id="actionsPlugins"
                options={clustersOptions}
                multiple
                style={{ 
                  marginTop: 20, marginBottom: 10, 
                }}
                getOptionLabel={(o) => `[${o.market}] - ${o.name}`}
                value={clustersSelected}
                disabled={false}
                onChange={(e, values) => {
                  let new_clusters = values ? (values as IClusterInDTO[]).map(cluster => ({ ...cluster })) : [];
                  this.setState({ clustersSelected: [...new_clusters] });
                }}
                renderInput={(params) => <TextField {...params} placeholder='Clusters' variant="outlined" />}
              />
            ) : null}
            {roleWithVAR ? (
              <Autocomplete
                id="actionsPlugins"
                options={varsOptions}
                style={{ 
                  marginTop: 20, marginBottom: 10, 
                }}
                value={selectedVar}
                getOptionLabel={(o) => `${o.name}`}
                disabled={false}
                onChange={(e, value) => {
                  const selectedVar = value ? value as IVarOptionSelectDTO : null;
                  this.setState({ selectedVar });
                }}
                renderInput={(params) => <TextField {...params} placeholder='VAR' variant="outlined" />}
              />
            ) : null}
          </div>
          {selectedRole && groupedPermissions && !_.isEmpty(groupedPermissions) ? (
            <Accordion style={{ marginTop: 15, marginBottom: 10, marginRight: 10 }}>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                id="activationCustomerDataAccordion"
              >
              <div>
                <h3 style={{ fontWeight: 'bold', color: 'gray' }}>{<ReduxLanguage languageKey="forms.permissionsDetails" />}</h3>
              </div>
              </AccordionSummary>
              <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                {_.map(_.keys(groupedPermissions), (permissionKey) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h4 style={{ fontWeight: 'bold', marginTop: 10 }}>{translations.t(`permissions.${permissionKey}`)}</h4>
                    {_.map(groupedPermissions[permissionKey], permission => (
                      <h4 style={{ marginLeft: 20 }}>• {translations.t(`permissions.${permission}`)}</h4>
                    ))}
                  </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ) : null}
          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(UserForm));