
import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';
import _ from 'lodash';
import * as ProductsAPI from '../../repository/jago/productsAPI';
import { IState } from '../store';
import { ActionsUnion, createAction, IThunkAction } from '../utils';
import ProductsActionTypesEnum from './model/ProductsActionTypesEnum';

export const ProductsActions = {
  saveProducts: createAction<typeof ProductsActionTypesEnum.SAVE_PRODUCTS, IProductInDTO[]>(ProductsActionTypesEnum.SAVE_PRODUCTS),
};


export function fetchSofiaProducts(): IThunkAction<void, IState> {
  return async (dispatch) => {
    try {
      const params: PaginationQueryParamsDTO = {
        page: 0,
        pageSize: 500
      };
      const productsResponse = await ProductsAPI.fetchSofiaProducts(params);
      if (productsResponse && productsResponse.data && productsResponse.data.data) {
        const filteredProducts = _.filter(productsResponse.data.data, product => product.serviceType.name === 'LUCKEY' );
        dispatch(ProductsActions.saveProducts(filteredProducts));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export type ProductsActionsType = ActionsUnion<typeof ProductsActions>;