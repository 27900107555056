import * as React from "react";
import MomentUtils from '@date-io/moment';
import { GridFilterInputMultipleValueProps } from '@mui/x-data-grid';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

export interface ComponentProps extends GridFilterInputMultipleValueProps {}

export interface ComponentState {
  iseoSellOutOrderDateFrom: number,
  iseoSellOutOrderDateTo: number,
}

type fields = keyof ComponentState;

class DataGridBetweenInput extends React.Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      iseoSellOutOrderDateFrom: 0,
      iseoSellOutOrderDateTo: 0,
    }
  }

  onFieldChange(field: fields, value?: number) {
    let v = value;
    if (!v) {
      v = 0;
    }
    const { item, applyValue } = this.props;
    this.setState({ [field]: value } as any, () => {
      applyValue({ ...item, value: [this.state.iseoSellOutOrderDateFrom, this.state.iseoSellOutOrderDateTo] });
    });
  }

  render() {
    const { item } = this.props;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <KeyboardDatePicker
          format="DD/MM/YYYY"
          value={(item.value && item.value[0]) || null}
          onChange={(v) => this.onFieldChange('iseoSellOutOrderDateFrom', v?.valueOf())}
        />
        <KeyboardDatePicker
          format="DD/MM/YYYY"
          value={(item.value && item.value[1]) || null}
          onChange={(v) => this.onFieldChange('iseoSellOutOrderDateTo', v?.valueOf())}
        />
    </MuiPickersUtilsProvider>
  )}
}

export default DataGridBetweenInput;
