import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { Card } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, BarElement, PointElement, Title,
  Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import * as React from "react";
import { createWalletRechargeChartData } from '../../../redux/credits/credits.actions';
import { ICreditsInDTO } from '../../../repository/jago/model/input/ICreditsInDTO';
import WalletCreditsList from './WalletCreditsList';
import Utils from '../../../utils/Utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});


export interface IWalletCreditsPageProps extends WithStyles<typeof styles>{
  walletCredits: PaginatedDataDTO<ICreditsInDTO> | undefined;
  onFetchMoreItems: (page: number) => void;
}

class WalletCreditsPage extends React.Component<IWalletCreditsPageProps, any> {

  public render() {
    const { walletCredits, onFetchMoreItems } = this.props;
    
    const data = walletCredits?.content ? createWalletRechargeChartData(walletCredits?.content) : { labels: [], datasets: []};
    const max = walletCredits?.content ? Utils.formatCreditsIn(_.maxBy(walletCredits?.content, value => value.quantity)?.quantity) : 0;
    return (
      <div style={{ zIndex: 100, margin: 10 }}>
        {walletCredits?.content && !_.isEmpty(walletCredits.content) ? (
          <Card style={{ padding: 5 }}>
            <Bar
              data={data}
              height={250}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  },
                  datalabels: {
                    display: true,
                    color: '#3f3f3f',
                    align: 'top',
                    clamp: true,
                    offset: 5
                  },
                },
                scales: {
                  y: {
                    suggestedMax: max ? max + 100 : 0,
                  }
                }
              }}
            />
          </Card>
        ): null}
        <WalletCreditsList
          credits={walletCredits}
          onFetchMoreItems={page => onFetchMoreItems(page)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(WalletCreditsPage);