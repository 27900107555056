import { Card, CardActionArea } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Build, Cast, Group, LockOpen } from '@material-ui/icons';
import * as React from "react";
import { IDomainInfoInDTO } from "../../repository/jago/model/input/IDomainInfoInDTO";
import { GREEN } from '../../style/StyleConsts';
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-evenly'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    margin: 10,
    alignItems: 'center',
    padding: 5,
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: 20,
    paddingRight: 20,
  },
  rowClickableContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 15,
    margin: 10,
    alignItems: 'center',
    width: 'fit-content',
    height: 'fit-content',
  },
  rowInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  rowTitle: {
    fontWeight: 'bold',
  },
  rowIcon: {
    color: '#3f3f3f',
    fontSize: 18,
    marginRight: 0
  },
  rowTitleNumber: {
    fontSize: 17,
  }
});

export interface IDomainInfoViewProps extends WithStyles<typeof styles>{
  domainInfo?: IDomainInfoInDTO;
  installationMode: boolean;
  onGatewaysClick: () => void;
}

class DomainInfoView extends React.Component<IDomainInfoViewProps, any> {
  public render() {
    const { domainInfo, classes, installationMode, onGatewaysClick } = this.props;
    if (!domainInfo) return null;
    return (
      <div className={classes.container}>
      <Card className={classes.rowContainer}>
        <div className={classes.rowInnerContainer}>
          <LockOpen className={classes.rowIcon} />
          <h4 className={classes.rowTitle}><ReduxLanguage languageKey="domains.numberOfLocks" /></h4>
        </div>
        <h4 className={classes.rowTitleNumber}>{domainInfo.numOfLocks}{` (${domainInfo.numOfArgoLocks} Argo - ${domainInfo.numOfVegaLocks} V364)`}</h4>
      </Card>
      <Card className={classes.rowContainer}>
        <div className={classes.rowInnerContainer}>
          <Group className={classes.rowIcon} />
          <h4 className={classes.rowTitle}><ReduxLanguage languageKey="domains.numberOfUsers" /></h4>
        </div>
        <h4 className={classes.rowTitleNumber}>{domainInfo.numOfUsers}</h4>
      </Card>
      <CardActionArea className={classes.rowClickableContainer} onClick={() => onGatewaysClick()}>
      <Card className={classes.rowContainer} style={{ margin: 0 }}>
        <div className={classes.rowInnerContainer}>
          <Cast className={classes.rowIcon} />
          <h4 className={classes.rowTitle}><ReduxLanguage languageKey="domains.numberOfGatewaysLocks" /></h4>
        </div>
        <h4 className={classes.rowTitleNumber}>{domainInfo.numOfGateways}</h4>
      </Card>
      </CardActionArea>
      <Card className={classes.rowContainer}>
        <div className={classes.rowInnerContainer}>
          <Build className={classes.rowIcon} />
          <h4 className={classes.rowTitle}><ReduxLanguage languageKey="domains.installationMode" /></h4>
        </div>
        <h4 className={classes.rowTitleNumber} style={{ fontWeight: 'bold', color: installationMode ? GREEN : 'gray'}}>
          {installationMode ? <ReduxLanguage languageKey="forms.installationModeActive" /> : <ReduxLanguage languageKey="forms.installationModeDisabled" />}
          </h4>
      </Card>
      </div>
    );
  }
}

export default withStyles(styles)(DomainInfoView);