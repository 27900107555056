import { AppBar, Button, Card, IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { Edit, Visibility } from "@material-ui/icons";
import AssignmentIndIcon from '@material-ui/icons/AssignmentIndOutlined';
import CustomersIcon from '@material-ui/icons/GroupOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAddOutlined';
import * as React from "react";
import { ConnectedProps, connect } from "react-redux";
import ReduxLanguage from '../../components/ReduxLanguage';
import { IState } from "../../redux/store";
import { IVarInDTO } from "../../repository/jago/model/input/IVarInDTO";
import VarCustomersSection from "./VarCustomersSection";
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import VarUsersSection from "./VarUsersSection";
import VarOpportunitiesSection from "./VarOpportunitiesSection";
import AbilityProvider from "../../services/PermissionService/AbilityProvider";
import { PERMISSIONS, Roles } from "../../services/PermissionService/PermissionConstants";
import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { IVarOpportunityInDTO } from "../../repository/jago/model/input/IVarOpportunityInDTO";
import Permission from "../Permission/Permission";
import NewspaperIconCustom from "../NewspaperIconCustom";
import { GREEN } from "../../style/StyleConsts";

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IVarDetailsViewProps extends WithStyles<typeof styles>{
  onOpenEditVarForm: () => void;
  onOpenVarDetails: () => void;
  onGoToCustomerDetailsPage?: (customerId: number) => void;
}

export interface IVarDetailsViewState {
  activeTab: number;
}

export type ComponentProps = IVarDetailsViewProps & IReduxProps;
class VarDetailsView extends React.Component<ComponentProps, IVarDetailsViewState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      activeTab: 0,
    }
  }

  public onTabChange(index: number) {
    this.setState({ activeTab: index });
  }

  public render() {
    const { selectedVar, customers, varUsers, opportunities, classes, onOpenEditVarForm, onOpenVarDetails } = this.props;
    const { activeTab } = this.state;
    const VAR = (selectedVar as IVarInDTO)
    const carReadOpportunities = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.OPPORTUNITY_READ);
    return (
      <div className={classes.container}>
        <Card className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <div className={classes.headerTextContainer}>
                <h2 className={classes.title}>{VAR && VAR.name ? `${VAR.id} - ${VAR.name}` : ''}</h2>
                <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_READ}>
                  <IconButton onClick={() => onOpenVarDetails()}>
                    <Visibility style={{ color: '#3f3f3f' }} />
                  </IconButton>
                </Permission>
              </div>
              {VAR.logoFileUrl ? (
                <img src={VAR.logoFileUrl} style={{ height: 'auto', width: 100, marginLeft: 10 }} alt="logo" />
              ) : null}
            </div>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_UPDATE}>
              <IconButton onClick={() => onOpenEditVarForm()}>
                <Edit style={{ color: '#3f3f3f' }} />
              </IconButton>
            </Permission>
          </div>
        </Card>
        <AppBar position="sticky" style={{ backgroundColor: 'white', marginTop: 20 }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            <Tab icon={<CustomersIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="vars.varCustomers" /></h5>} />
            <Tab icon={<PersonAddIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="vars.varUsers" /></h5>} />
            {carReadOpportunities ? <Tab icon={<MonetizationOnOutlinedIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="vars.varOpportunities" /></h5>} /> : null}
          </Tabs>
        </AppBar>
        {activeTab === 0 && 
          <VarCustomersSection
            VAR={VAR}
            customers={customers}
            onGoToCustomerDetailsPage={customerId => this.props.onGoToCustomerDetailsPage(customerId)}
          />
        }
        {activeTab === 1 && 
          <VarUsersSection
            VAR={VAR}
            varUsers={varUsers}
          />
        }
        {activeTab === 2 && 
          <VarOpportunitiesSection
            VAR={VAR}
            opportunities={(opportunities as PaginatedDataDTO<IVarOpportunityInDTO>)}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedVar: state.vars.selectedVar.data,
    customers: state.vars.varCustomers.data,
    varUsers: state.vars.users.data,
    opportunities: state.vars.opportunities.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(VarDetailsView as any));