
import _ from 'lodash';
import { PaginationRestDTO, PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';

export function decode<T>(data: PaginationRestDTO<T>): PaginatedDataDTO<T> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}
