import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import MomentUtils from '@date-io/moment';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField, Card, Switch } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RedoIcon from '@material-ui/icons/Redo';
import { IState } from '../../redux/store';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IPlantThemeFormDTO } from '../../repository/jago/model/input/IPlantThemeFormDTO';
import Utils, { ISEOCompanyInfo } from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";
import { PlantThemeLogoAcceptedExtensions } from '../../codec/plantThemeInDTODecoder';
import * as ModalsActions from '../../redux/modals/modals.actions';
import { ModalTypes } from '../../components/Modals/ModalTypes';
//import CirclePicker from 'react-color';
//import SketchPicker from 'react-color'
import ColorPicker from 'react-pick-color';
import WalletPlantsList from '../Wallets/WalletPlants/WalletPlantsList';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import ThemePlantsList from '../Vars/ThemePlantsList';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
});

export interface IPlantThemeFormState {
  plantTheme: IPlantThemeFormDTO;
  errors: Record<keyof IPlantThemeFormDTO, boolean>;
  logoFileBase64full: string;
};

export interface IPlantThemeFormProps extends WithStyles<typeof styles>{
  onCreateEditPlantTheme: (plantTheme: IPlantThemeFormDTO) => void;
  plantTheme?: IPlantThemeFormDTO;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = IPlantThemeFormProps & IReduxProps;
class PlantThemeForm extends React.Component<ComponentProps, IPlantThemeFormState> {
  
  inputFileRef = null;

  constructor(props: ComponentProps) {
    super(props);

    this.inputFileRef = React.createRef();

    this.state = {
      plantTheme: props.plantTheme ? props.plantTheme : {
        id: null,
        name: '',
        colour: '',
        logoUrl: undefined,
        logoName: undefined,
        varDefault: false,
        logoFileBase64: '',
      },
      errors: {
        id: false,
        name: false,
        colour: false,
        logoUrl: false,
        logoName: false,
        varDefault: false,
        logoFileBase64: false
      },
      logoFileBase64full: '',
    };
  }

  onChangeValue(field: keyof IPlantThemeFormDTO, mandatory: boolean, value?: any) {
    const { plantTheme, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: !value && mandatory ? true : false }
    });
    (plantTheme as any)[field] = value;
    this.setState({ plantTheme });
  }

  onSubmitForm() {
    const { plantTheme } = this.state;
    if (!this.canSubmitForm()) {
      return;
    }
    const { onCreateEditPlantTheme } = this.props;
    onCreateEditPlantTheme(plantTheme);
  }

  canSubmitForm() {
    const { plantTheme } = this.state;
    return plantTheme.name && plantTheme.colour && (plantTheme.logoFileBase64||plantTheme.logoUrl);
  }

  toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:image/png;base64,"
      let resultAsString = (reader.result as string);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve({base64: resultSplitted[1], full: resultAsString})
    };
    reader.onerror = reject;
  });

  async onNewInputFile(newFile: File) {
    const { dispatch } = this.props;
    var extensionOK = false;
    var fileName: string = "";
    var fileExtension: string = "";
    var fileExtSearch: number = newFile&&newFile.name?newFile.name.lastIndexOf("."):-1
    if (fileExtSearch>0&&fileExtSearch<newFile.name.length) {
      fileName = newFile.name.substring(0,fileExtSearch);
      fileExtension = newFile.name.substring(fileExtSearch+1);
      extensionOK = PlantThemeLogoAcceptedExtensions.some(e=>e.toUpperCase()===fileExtension.toUpperCase())
    }
    if (!extensionOK) {
      dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'activations.newFileExtensionError',
        }
      }));
      return;
    }
    this.onNewInputFileConfirmed(newFile)
  }

  async onNewInputFileConfirmed(newFile: File) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.hideModal(`NEWFILE_NAME_MODAL`))
    const { plantTheme } = this.state;
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile===null) return;
    plantTheme.logoFileBase64 = encodedFile.base64;
    const logoFileBase64full = encodedFile.full
    this.setState({ logoFileBase64full });
    plantTheme.logoName = newFile.name;
    this.setState({ plantTheme, logoFileBase64full: logoFileBase64full })
  }

  public render() {
    const { classes, themePlants } = this.props;
    const { plantTheme, errors, logoFileBase64full } = this.state;

    const plants = (themePlants as PaginatedDataDTO<IPlantInDTO>)

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'forms.plantThemeFormTitle'} />}</h2>
          
          <div style={{ flex: 0, marginRight: 10 }}>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.plantThemeName'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.name}
              value={plantTheme.name}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('name', true, e.target.value)}
            />

            <FormGroup row style={{marginTop:20}}>
              <FormControlLabel
                control={
                  <Switch 
                    checked={plantTheme.varDefault} color="primary" 
                    onChange={e => this.onChangeValue('varDefault', true, e.target.checked)} 
                    name="varDefault" 
                  />
                }
                label={<ReduxLanguage languageKey={'vars.plantThemeDefault'} />}
              />
            </FormGroup>

            <input type='file' style={{display: 'none'}} ref={this.inputFileRef} 
              onChange={(e) => {
                if(e.target.files&&e.target.files.length>0) {
                  this.onNewInputFile(e.target.files[0])
                  e.target.value = null
                }
              }}
            />
            <div style={{display:'flex',flexDirection:'row'}}>
              <div>
                <h3 style={{ fontWeight: 'bold', marginTop:30 }}>{<ReduxLanguage languageKey={'vars.plantThemeColour'} />}</h3>
                <ColorPicker hideAlpha={true} color={plantTheme.colour} onChange={color => this.onChangeValue('colour', true, color.hex)} />
              </div>
              <div style={{marginLeft:20,flexGrow:1}}>
                <h3 style={{ fontWeight: 'bold', marginTop:30 }}>{<ReduxLanguage languageKey={'vars.logoFile'} />}</h3>
                {plantTheme.logoFileBase64||plantTheme.logoUrl?(
                  <Card
                    style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center'}} elevation={2}>
                    <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                      {plantTheme.logoName?plantTheme.logoName:"Uploaded logo"}
                    </div>
                    <span style={{flexGrow:'1'}}/>
                    {plantTheme.logoUrl?(
                      <a href={plantTheme.logoUrl}>
                        <Button
                          variant="contained"
                          style={{backgroundColor:'darkcyan'}}
                          className={classes.actionButton}
                        >
                          <CloudDownloadIcon className={classes.icon}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.logoFileDownload" /></h5>
                        </Button>
                      </a>
                    ):null}
                    <Button
                      variant="contained"
                      style={{backgroundColor:'#282c34'}}
                      className={classes.actionButton}
                      onClick={(e) => {
                        if (this.inputFileRef&&this.inputFileRef.current)
                          this.inputFileRef.current.click();
                      }}
                    >
                      <RedoIcon className={classes.icon}/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.logoFileReplace" /></h5>
                    </Button>
                  </Card>
                ):(
                  <Button
                    variant="contained"
                    style={{backgroundColor:'#282c34', marginTop:10}}
                    className={classes.actionButton}
                    onClick={(e) => {
                      if (this.inputFileRef&&this.inputFileRef.current)
                        this.inputFileRef.current.click();
                    }}
                  >
                    <CloudUploadIcon className={classes.icon}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="vars.logoFileAdd" /></h5>
                  </Button>
                )}
                <h6 style={{ fontWeight: 'normal', marginTop:10, marginBottom:20 }}>
                  {<ReduxLanguage languageKey={'vars.logoFile_desc'} />}
                  {PlantThemeLogoAcceptedExtensions.map((extension,index) => (
                    <span>
                      {(index>0?", ":"")+extension}
                    </span>
                  ))}
                  <span>.</span>
                </h6>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',height:180}}>
                  {plantTheme.logoUrl||logoFileBase64full?(
                    <img src={logoFileBase64full?logoFileBase64full:plantTheme.logoUrl} alt="logo" style={{maxWidth:180,maxHeight:180,objectFit:'contain'}} />
                  ):null}
                </div>
              </div>
            </div>
          </div>
          
          {plants&&(plants as PaginatedDataDTO<IPlantInDTO>).content&&(plants as PaginatedDataDTO<IPlantInDTO>).content.length>0?(
            <div>
              <h3 style={{ fontWeight: 'bold', marginTop:30 }}>{<ReduxLanguage languageKey={'vars.plantThemeAssociatedPlants'} />}</h3>
              <div style={{}}>
                <ThemePlantsList
                  plants={plants}
                />
              </div>
            </div>
          ):null}
          

          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 10, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    themePlants: state.vars.themePlants.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(PlantThemeForm));