import MomentUtils from '@date-io/moment';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import _ from 'lodash';
import * as React from "react";
import CreditsIcon from '@material-ui/icons/MonetizationOn';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { IEditSubscriptionOutDTO, ISubscriptionOutDTO } from '../../repository/jago/model/output/ISubscriptionOutDTO';
import Utils from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";
import { SubscriptionProvisioningTypeEnum } from '../../repository/jago/model/input/ISubscriptionProvisioningTypeEnum';
import Select, { SingleValue } from 'react-select';



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});


export interface IEditSubscriptionFormState {
  subscriptionData: IEditSubscriptionOutDTO;
  textInputErrors: { [key: string]: boolean; };
  isProvisioningSelectionDisabled: boolean;
};

export interface IEditSubscriptionFormProps extends WithStyles<typeof styles>{
  subscription: ISubscriptionInDTO;
  onEditSubscription: (subscription: IEditSubscriptionOutDTO) => void;
}

class EditSubscriptionForm extends React.Component<IEditSubscriptionFormProps, IEditSubscriptionFormState> {

  constructor(props: IEditSubscriptionFormProps) {
    super(props);
    this.state = {
      subscriptionData: {
        fixedPrice: Utils.formatCreditsIn(props.subscription.fixedPrice),
        burnRate: Utils.formatCreditsIn(props.subscription.burnRate),
        visible: props.subscription.visible != null ? props.subscription.visible : true,
        provisioningType:  props.subscription.provisioningType != null ? props.subscription.provisioningType : SubscriptionProvisioningTypeEnum.MANUAL,
        requestToQuote:  props.subscription.requestToQuote != null ? props.subscription.requestToQuote : false,
        canBeDeactivated: props.subscription.canBeDeactivated != null ? props.subscription.canBeDeactivated : true,
      },
      isProvisioningSelectionDisabled: props.subscription.requestToQuote != null ? props.subscription.requestToQuote : false,
      textInputErrors: {
        fixedPrice: false,
        burnRate: false,
        visible: false,
        provisioningType: false,
      },
    };
  }
  
  public onSetValue(field: keyof ISubscriptionOutDTO, value: any) {
    const { subscriptionData } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (value && value < 0) {
      this.setErrorForField(field, true);
    }
    (subscriptionData as any)[field] = value;
    this.setState({ subscriptionData });
  }

  public setErrorForField(field: string, hasError: boolean) {
    const { textInputErrors } = this.state;
    textInputErrors[field] = hasError;
    this.setState({ textInputErrors });
  }

  public onSelectProvisioning(provisioning: SingleValue<SubscriptionProvisioningTypeEnum>) {
    const { subscriptionData } = this.state;
    if (provisioning) {
      subscriptionData.provisioningType = provisioning;
    }
    this.setState({ subscriptionData });
  }

  public canSubmitForm() {
    return true;
  }
    

  public onSubmitForm() {
    const { subscriptionData } = this.state;
    const { onEditSubscription } = this.props;
    if (this.canSubmitForm()) {
      onEditSubscription({
        ...subscriptionData,
        fixedPrice: Utils.formatCreditsOut(subscriptionData.fixedPrice),
        burnRate: Utils.formatCreditsOut(subscriptionData.burnRate),
      });
    }
  }

public onSetRequestToQuote(isChecked: boolean) {
  this.onSetValue('requestToQuote', isChecked);
  if (isChecked) {
    this.onSelectProvisioning(SubscriptionProvisioningTypeEnum.MANUAL);
  }
  this.setState({ isProvisioningSelectionDisabled: isChecked });
}

/* Se logic è:
  - Fixed non mostrare maxLock, eleement e users
  - Count users -> mostra solo maxUsers
  - Count Locks -> mostra solo maxLocks
  - Count Elements -> mostra solo maxElements
- Fixed price è il costo di attivazione
- Burn rate è il costo ricorrente
*/

  public render() {
    const { classes, subscription } = this.props;
    const { subscriptionData, textInputErrors, isProvisioningSelectionDisabled } = this.state;
    const provisioningOptions = _.map(SubscriptionProvisioningTypeEnum, provisioningType => ({
      label: <ReduxLanguage languageKey={`forms.${provisioningType}`} />,
      value: provisioningType
    }));
    const selectedProvisioniOption = subscriptionData && subscriptionData.provisioningType ? _.find(provisioningOptions, provisioning => provisioning.value === subscriptionData.provisioningType) : null;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionFixedPrice'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionFixedPriceDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            type={"number"}
            error={textInputErrors.fixedPrice}
            value={subscriptionData?.fixedPrice}
            onChange={e => this.onSetValue('fixedPrice', e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionBurnRate'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionBurnRateDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            type={"number"}
            style={{ marginTop: 10 }}
            error={textInputErrors.burnRate}
            value={subscriptionData?.burnRate}
            onChange={e => this.onSetValue('burnRate', e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          <FormControlLabel
            control={<Checkbox checked={subscriptionData?.visible} onChange={(e, checked) => this.onSetValue('visible', checked)} name="subscriptionVisible" />}
            label={<ReduxLanguage languageKey={'forms.subscriptionVisible'} />}
            style={{ marginTop: 20 }}
          />
          {!Utils.isMainTierSubscription(subscription) ? (
            <>
              <FormControlLabel
                control={<Checkbox checked={subscriptionData?.canBeDeactivated} onChange={(e, checked) => this.onSetValue('canBeDeactivated', checked)} name="canBeDeactivated" />}
                label={<ReduxLanguage languageKey={'forms.subscriptionCanBeDeactivated'} />}
                style={{ marginTop: 20 }}
              />
              <h4 style={{ marginTop: -6, marginBottom: 10 }}><ReduxLanguage languageKey={`forms.subscriptionCanBeDeactivatedDescription`} /></h4>
              <FormControlLabel
                control={<Checkbox checked={subscriptionData?.requestToQuote} onChange={(e, checked) => this.onSetRequestToQuote(checked)} name="requestToQuote" />}
                label={<ReduxLanguage languageKey={'forms.requestToQuote'} />}
              />
              <h4 style={{ marginTop: -6, marginBottom: 10 }}><ReduxLanguage languageKey={`forms.requestToQuoteDescription`} /></h4>
              <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.provisioningType'} />}<span style={{ color: 'red' }}>*</span></h4>
              <Select
                options={provisioningOptions}
                isClearable
                isDisabled={isProvisioningSelectionDisabled}
                value={selectedProvisioniOption}
                placeholder={<ReduxLanguage languageKey={'forms.provisioningType'} />}
                onChange={(e) => this.onSelectProvisioning(e ? e.value : null)}
                styles={{
                  menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                  control: (styles) => ({ ...styles, minHeight: 55, marginTop: 10, marginBottom: 20 }),
                }} 
              />
              {selectedProvisioniOption && selectedProvisioniOption.value ? (
                <h4 style={{ marginTop: -10, marginBottom: 10 }}><ReduxLanguage languageKey={`forms.${selectedProvisioniOption.value}_Description`} /></h4>
              ) : null}
            </>
          )
          : null}
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(EditSubscriptionForm);