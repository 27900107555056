import { setLanguageWithMoment } from '../../translations/utils';
import { IState } from '../store';
import { ActionsUnion, createAction, IThunkAction } from '../utils';
import SettingsActionTypesEnum from './model/SettingsActionTypesEnum';
import * as LuckeySofiaSiteAPI from '../../repository/jago/LuckeySofiaSiteAPI';
import _ from 'lodash';
import { decodeUnicode } from '../../utils/Utils';
import moment from 'moment';

export const SettingsActions = {
  setLanguage: createAction<typeof SettingsActionTypesEnum.SET_LANGUAGE, string>(SettingsActionTypesEnum.SET_LANGUAGE),
  setSpinnerVisible: createAction<typeof SettingsActionTypesEnum.SET_SPINNER_VISIBLE, boolean>(SettingsActionTypesEnum.SET_SPINNER_VISIBLE),
  saveWhatsNewContent: createAction<typeof SettingsActionTypesEnum.SAVE_WHATSNEW_CONTENT, any[]>(SettingsActionTypesEnum.SAVE_WHATSNEW_CONTENT),
  saveWhatsNewHasUpdate: createAction<typeof SettingsActionTypesEnum.SAVE_WHATSNEW_HASUPDATE, boolean>(SettingsActionTypesEnum.SAVE_WHATSNEW_HASUPDATE),
};

export function saveLanguageSettings(language: string): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    setLanguageWithMoment(language);
    dispatch(SettingsActions.setLanguage(language));
  };
}

export function setSpinnerVisible(isVisible: boolean): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    dispatch(SettingsActions.setSpinnerVisible(isVisible));
  };
}

export function resetWhatsNewHasUpdate(): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    dispatch(SettingsActions.saveWhatsNewHasUpdate(false));
  };
}

export function fetchWhatsNewArticles() {
  return async (dispatch, getState) => {
    try {
      const languagePrefix = "en"
      const content = await LuckeySofiaSiteAPI.fetchWhatsNewArticles(languagePrefix);
      if (content && content.data) {
        const whatsNewItemsContent = content.data;
        const whatsNewArticles = _.map(whatsNewItemsContent, (itemContent) => {
          const previewImage = itemContent && itemContent['_embedded'] && itemContent['_embedded']['wp:featuredmedia'] && itemContent['_embedded']['wp:featuredmedia'][0] ? itemContent['_embedded']['wp:featuredmedia'][0].source_url : null;
          return {
            ...itemContent,
            preview: itemContent && itemContent.excerpt && itemContent.excerpt.rendered ? itemContent.excerpt.rendered : undefined,
            previewImage,
            renderedTitle: itemContent && itemContent.title && itemContent.title.rendered ? decodeUnicode(itemContent.title.rendered) : '',
            renderedContent: itemContent && itemContent.content && itemContent.content.rendered ? itemContent.content.rendered : '',
          };
        });
        dispatch(SettingsActions.saveWhatsNewContent(whatsNewArticles));
        const lastUpdate = _.first(whatsNewArticles) && _.first(whatsNewArticles).date;
        if (lastUpdate) {
          const whatNewStoredLastUpdate = localStorage.getItem('lastWhatsNewUpdate');
          const mustRead = whatNewStoredLastUpdate === 'undefined' || whatNewStoredLastUpdate === null|| moment(lastUpdate).isAfter(whatNewStoredLastUpdate);
          dispatch(SettingsActions.saveWhatsNewHasUpdate(mustRead));
        }
        return content;
      }
      return [];
    } catch (error) {
      return [];
    }
  };
}


export type SettingsActionsType = ActionsUnion<typeof SettingsActions>;
