import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { IProductInDTO } from './model/input/IProductInDTO';
import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import PaginationDeprecatedRestDTO from './model/input/PaginationDeprecatedRestDTO';

export function fetchSofiaProducts(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationDeprecatedRestDTO<IProductInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/products', config);
}