import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import IAddSalesInfoOutDTO from '../../repository/jago/model/output/IAddSalesInfoOutDTO';
import ReduxLanguage from "../ReduxLanguage";
import translations from '../../translations/i18next';
import { IHardwareInDTO, ISalesInfoInDTO } from '../../repository/jago/model/input/ISalesInfoInDTO';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  idDateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
});

export interface IAddSalesInfoFormState {
  salesInfo: IAddSalesInfoOutDTO;
  errors: Record<keyof IAddSalesInfoOutDTO, boolean>;
};

export interface IAddSalesInfoFormProps extends WithStyles<typeof styles>{
  onSubmit: (data: IAddSalesInfoOutDTO) => void;
  selectedHardware: IHardwareInDTO[];
  salesInfoToEdit?: ISalesInfoInDTO;
}

class AddSalesInfoForm extends React.Component<IAddSalesInfoFormProps, IAddSalesInfoFormState> {

  constructor(props: IAddSalesInfoFormProps) {
    super(props);
    const { selectedHardware, salesInfoToEdit } = props;
    const selectedSmartlocks = _.filter(selectedHardware, { type: 'smartlock' });
    const selectedHypergates = _.filter(selectedHardware, { type: 'gateway' });
    const selectedAtlas = _.filter(selectedHardware, { type: 'atlas' });
    this.state = {
      salesInfo: {
        smartLockIds: _.map(selectedSmartlocks, s => s.id),
        gatewayIds: _.map(selectedHypergates, s => s.id),
        atlasIds: _.map(selectedAtlas, s => s.id),
        sfdcId: salesInfoToEdit?.sfdcId || '',
        iseoSellOutOrderId: salesInfoToEdit?.iseoSellOutOrderId || '',
        iseoSellOutOrderDate: salesInfoToEdit?.iseoSellOutOrderDate || 0,
        iseoSellOutInvoiceId: salesInfoToEdit?.iseoSellOutInvoiceId || '',
        iseoSellOutInvoiceDate: salesInfoToEdit?.iseoSellOutInvoiceDate || 0,
        customerReference: salesInfoToEdit?.customerReference || '',
      },
      errors: {
        smartLockIds: false,
        gatewayIds: false,
        atlasIds: false,
        sfdcId: false,
        iseoSellOutOrderId: false,
        iseoSellOutOrderDate: false,
        iseoSellOutInvoiceId: false,
        iseoSellOutInvoiceDate: false,
        customerReference: false,
      }
    };
  }
  
  onChangeField(field: keyof IAddSalesInfoOutDTO, value?: string | number) {
    const { salesInfo } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (value && value < 0) {
      this.setErrorForField(field, true);
    }

    (salesInfo as any)[field] = value;
    this.setState({ salesInfo });
  }

  setErrorForField(field: keyof IAddSalesInfoOutDTO, hasError: boolean) {
    const { errors } = this.state;
    errors[field] = hasError;
    this.setState({ errors });
  }

  onSubmitForm() {
    const { salesInfo } = this.state;
    const { onSubmit } = this.props;

    onSubmit(salesInfo);
  }

  canSubmitForm() {
    const { salesInfo } = this.state;
    if (!salesInfo.sfdcId || !salesInfo.customerReference) {
      return false;
    }
    return true;
  }

  public render() {
    const { classes } = this.props;
    const { salesInfo, errors } = this.state;

    const canSubmitForm = this.canSubmitForm();

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'sales.sfdcId'} />}
            variant="outlined"
            fullWidth
            required
            error={errors.sfdcId}
            value={salesInfo.sfdcId}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeField('sfdcId', e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'sales.sofiaCustRef'} />}
            variant="outlined"
            fullWidth
            required
            error={errors.customerReference}
            value={salesInfo.customerReference}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeField('customerReference', e.target.value)}
          />
          <div>
            <h4 className={classes.inputTitle}><ReduxLanguage languageKey="sales.iseoSelloutOrder" /></h4>
            <div className={classes.idDateContainer}>
              <TextField
                id="outlined-basic"
                label={<ReduxLanguage languageKey={'sales.iseoSelloutOrderId'} />}
                variant="outlined"
                fullWidth
                required
                error={errors.iseoSellOutOrderId}
                value={salesInfo.iseoSellOutOrderId}
                style={{ marginRight: 10 }}
                onChange={e => this.onChangeField('iseoSellOutOrderId', e.target.value)}
              />
              <KeyboardDatePicker
                inputVariant="outlined"
                variant="dialog"
                margin="normal"
                autoOk
                clearable
                format="DD/MM/YYYY"
                error={errors.iseoSellOutOrderDate}
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={salesInfo.iseoSellOutOrderDate ? moment(salesInfo.iseoSellOutOrderDate) : null}
                onChange={(value) => this.onChangeField('iseoSellOutOrderDate', value?.valueOf())}
                style={{ width: '100%', margin: 0, marginLeft: 10 }}
              />
            </div>
          </div>
          <div>
            <h4 className={classes.inputTitle}><ReduxLanguage languageKey="sales.iseoSelloutInvoice" /></h4>
            <div className={classes.idDateContainer}>
              <TextField
                id="outlined-basic"
                label={<ReduxLanguage languageKey={'sales.iseoSelloutInvoiceId'} />}
                variant="outlined"
                fullWidth
                required
                error={errors.iseoSellOutInvoiceId}
                value={salesInfo.iseoSellOutInvoiceId}
                style={{ marginRight: 10 }}
                onChange={e => this.onChangeField('iseoSellOutInvoiceId', e.target.value)}
              />
              <KeyboardDatePicker
                inputVariant="outlined"
                variant="dialog"
                margin="normal"
                autoOk
                clearable
                format="DD/MM/YYYY"
                error={errors.iseoSellOutInvoiceDate}
                placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                value={salesInfo.iseoSellOutInvoiceDate ? moment(salesInfo.iseoSellOutInvoiceDate) : null}
                onChange={(value) => this.onChangeField('iseoSellOutInvoiceDate', value?.valueOf())}
                style={{ width: '100%', margin: 0, marginLeft: 10 }}
              />
            </div>
          </div>
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="sales.addSalesInfo" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(AddSalesInfoForm);