//
// ──────────────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: N O R M A L   R O U T E   C O M P O N E N T : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────────────────
//


import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';

export interface IAppRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  layout?: React.ComponentType<any>;
}

const AppRoute: FunctionComponent<IAppRouteProps> = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const page = <Component {...props} />;
      if (Layout) {
        return (
        <Layout>
          {page}
        </Layout>
        );
      } else {
        return page;
      }
    }}
  />
);

export default AppRoute;
