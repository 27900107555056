
import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { IVarInDTO } from '../repository/jago/model/input/IVarInDTO';
import { IVarFormDTO } from '../repository/jago/model/input/IVarFormDTO';
import { IVarOutDTO } from '../repository/jago/model/output/IVarOutDTO';
import IClusterInDTO from '../repository/jago/model/input/IClusterInDTO';
import IMarketplaceInDTO from '../repository/jago/model/input/IMarketplaceInDTO';
import { IKamInDTO } from '../repository/jago/model/input/IKamInDTO';

export const VarFileAcceptedExtensions: string[] = ["pdf"]
export const VarFileName: string = "agreement"
export const VarLogoFileAcceptedExtensions: string[] = ['png', 'jpeg', 'jpg']
export const VarLogoFileName: string = "logo"

export function decode(data: PaginationRestDTO<IVarInDTO>): PaginatedDataDTO<IVarInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export function decodeKam(data: PaginationRestDTO<IKamInDTO>): PaginatedDataDTO<IKamInDTO> {
  const pagination: PaginationDTO = _.omit(data, 'content');
  return {
    content: data.content,
    pagination,
  };
}

export function decodeVarContentToForm(data: IVarInDTO, clusters: IClusterInDTO[], marketplaces: IMarketplaceInDTO[], kams: IKamInDTO[]): IVarFormDTO {
  let foundCluster = clusters.filter(e=>e.id===data.clusterId).length>0?clusters.filter(e=>e.id===data.clusterId)[0]:null
  let foundMarketplace = marketplaces.filter(e=>e.id===data.marketplaceId).length>0?marketplaces.filter(e=>e.id===data.marketplaceId)[0]:null
  let foundKams = kams.filter(e=>e.email===data.kamEmail)
  return {
    id: data.id,
    name: data.name,
    address: data.address,
    contactEmail: data.contactEmail,
    contactPhone: data.contactPhone,
    cluster: foundCluster,
    marketplace: foundMarketplace,
    company: data.company,
    customerIds: data.customerIds,
    sfcdId: data.sfcdId,
    companyName: data.companyName,
    vat: data.vat,
    commercialRefName: data.commercialRefName,
    commercialRefEmail: data.commercialRefEmail,
    commercialRefPhone: data.commercialRefPhone,
    techRefName: data.techRefName,
    techRefEmail: data.techRefEmail,
    techRefPhone: data.techRefPhone,
    agreementFileUrl: data.agreementFileUrl,
    agreementFileName: null,
    agreementFileBase64: null,
    supportEmail: data.supportEmail,
    supportHours: data.supportHours,
    kam: foundKams.length>0?foundKams[0]:undefined,
    city: data.city,
    country: data.country,
    website: data.website,
    shortDescription: data.shortDescription,
    visibleOnSofiaWebsite: data.visibleOnSofiaWebsite,
    lastTrainingDate: data.lastTrainingDate,
    logoFileName: null,
    logoBase64: null,
    logoFileUrl: data.logoFileUrl,
  };
}

export function encodeVarFromForm(data: IVarFormDTO): IVarOutDTO {
    let retVal: IVarOutDTO = {
      name: data.name,
      address: data.address,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      customerIds: data.customerIds,
      sfcdId: data.sfcdId,
      companyName: data.companyName,
      vat: data.vat,
      commercialRefName: data.commercialRefName,
      commercialRefEmail: data.commercialRefEmail,
      commercialRefPhone: data.commercialRefPhone,
      techRefName: data.techRefName,
      techRefEmail: data.techRefEmail,
      techRefPhone: data.techRefPhone,
      agreementFileName: data.agreementFileName,
      agreementFileBase64: data.agreementFileBase64,
      supportEmail: data.supportEmail,
      supportHours: data.supportHours,
      city: data.city,
      country: data.country,
      website: data.website,
      shortDescription: data.shortDescription,
      visibleOnSofiaWebsite: data.visibleOnSofiaWebsite,
      lastTrainingDate: data.lastTrainingDate,
      logoFileName: data.logoFileName,
      logoBase64: data.logoBase64,
    };
    if (data.cluster&&data.cluster.id)
      retVal.clusterId = data.cluster.id;
    if (data.marketplace&&data.marketplace.id)
      retVal.marketplaceId = data.marketplace.id;
    if (data.company)
      retVal.company = data.company;
    if (data.kam && data.kam.email)
      retVal.kamEmail = data.kam.email;
    return retVal;
}

export function encodeVarInFromOut(data: IVarInDTO): IVarOutDTO {
    return {
      name: data.name,
      address: data.address,
      contactEmail: data.contactEmail,
      contactPhone: data.contactPhone,
      clusterId: data.clusterId,
      marketplaceId: data.marketplaceId,
      company: data.company,
      customerIds: data.customerIds,
      sfcdId: data.sfcdId,
      companyName: data.companyName,
      vat: data.vat,
      commercialRefName: data.commercialRefName,
      commercialRefEmail: data.commercialRefEmail,
      commercialRefPhone: data.commercialRefPhone,
      techRefName: data.techRefName,
      techRefEmail: data.techRefEmail,
      techRefPhone: data.techRefPhone,
      agreementFileName: null,
      agreementFileBase64: null,
      supportEmail: data.supportEmail,
      supportHours: data.supportHours,
      kamEmail: data.kamEmail,
      city: data.city,
      country: data.country,
      website: data.website,
      shortDescription: data.shortDescription,
      visibleOnSofiaWebsite: data.visibleOnSofiaWebsite,
      lastTrainingDate: data.lastTrainingDate,
      logoFileName: null,
      logoBase64: null,
    };
}