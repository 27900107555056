
import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { IRechargeFormDTO } from '../repository/jago/model/input/IRechargeFormDTO';
import { IRechargeInDTO } from '../repository/jago/model/input/IRechargeInDTO';
import { IRechargeOutDTO } from '../repository/jago/model/output/IRechargeOutDTO';

export function decode(data: PaginationRestDTO<IRechargeInDTO>): PaginatedDataDTO<IRechargeInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export enum RechargeStatusOptions {
  TO_BE_MANAGED = 'TO_BE_MANAGED',
  TAKEN_CHARGE = 'TAKEN_CHARGE',
  REQUEST_TO_DELETE = 'REQUEST_TO_DELETE',
  SELLOUT_ORDER_GENERATED = 'SELLOUT_ORDER_GENERATED',
  REJECTED = 'REJECTED',
  SCRATCHCARD_CREATED = 'SCRATCHCARD_CREATED',
  INVOICED = 'INVOICED',
  WALLET_RECHARGED = 'WALLET_RECHARGED',
}

export const RechargeFileAcceptedExtensions: string[] = ["pdf","txt","doc","docx","xls","xlsx","csv","png","jpg","jpeg","bmp"]

export const RechargeInvoiceFileName: string = "invoice__file"

export interface IRechargeFile {
  name: string;
  contentBase64?: string;
  url?: string;
}

export function decodeRechargeContentToForm(data: IRechargeInDTO): IRechargeFormDTO {
  return {
    scratchcardId: data.walletRegistry?data.walletRegistry.scratchcardId:null,
    varId: data.walletRegistry?data.walletRegistry.varId:null,
    varName: data.walletRegistry?data.walletRegistry.varName:null,
    mode: data.mode,
    status: data.status,
    rejectedNotes: data.rejectedNotes,
    deletedNotes: data.deletedNotes,
    rejectedInvoiceNotes: data.rejectedInvoiceNotes,
    sfdcId: data.sfdcId,
    walletId: data.walletId,
    customerId: data.customerId,
    creditsSuggested: data.creditsSuggested?Math.ceil(data.creditsSuggested/100):0,
    creditsConfirmed: data.creditsConfirmed?Math.ceil(data.creditsConfirmed/100):0,
    odsMail: data.odsMail,
    salesOrderConfirmationNumber: data.walletRegistry?data.walletRegistry.salesOrderConfirmationNumber:"",
    purchaseOrderNumberSofia: data.walletRegistry?data.walletRegistry.purchaseOrderNumberSofia:"",
    custRefID: data.walletRegistry?data.walletRegistry.custRefID:"",
    invoiceNumber: data.walletRegistry?data.walletRegistry.invoiceNumber:"",
    orderContactEmail: data.walletRegistry?data.walletRegistry.orderContactEmail:"",
    documents: data.documents?Object.keys(data.documents).map((key: string) => {return {name:key, url:data.documents[key]}}):[],
    documentsOUT: {},
  };
}

export function encodeRechargeFromForm(data: IRechargeFormDTO): IRechargeOutDTO {
    let retVal: IRechargeOutDTO = {
      mode: data.mode,
      rejectedNotes: data.rejectedNotes,
      deletedNotes: data.deletedNotes,
      rejectedInvoiceNotes: data.rejectedInvoiceNotes,
      sfdcId: data.sfdcId,
      walletId: data.walletId,
      customerId: data.customerId,
      creditsSuggested: data.creditsSuggested?data.creditsSuggested*100:0,
      creditsConfirmed: data.creditsConfirmed?data.creditsConfirmed*100:0,
      odsMail: data.odsMail,
      walletRegistry: {
        varId: data.varId,
        varName: data.varName,
        scratchcardId: data.scratchcardId,
        salesOrderConfirmationNumber: data.salesOrderConfirmationNumber,
        purchaseOrderNumberSofia: data.purchaseOrderNumberSofia,
        custRefID: data.custRefID,
        invoiceNumber: data.invoiceNumber,
        orderContactEmail: data.orderContactEmail,
      },
      documents:data.documentsOUT,
    };
    return retVal;
}

export function encodeRechargeInFromOut(data: IRechargeInDTO): IRechargeOutDTO {
    return {
      mode: data.mode,
      rejectedNotes: data.rejectedNotes,
      deletedNotes: data.deletedNotes,
      rejectedInvoiceNotes: data.rejectedInvoiceNotes,
      sfdcId: data.sfdcId,
      walletId: data.walletId,
      customerId: data.customerId,
      creditsSuggested: data.creditsSuggested,
      creditsConfirmed: data.creditsConfirmed,
      odsMail: data.odsMail,
      walletRegistry: {
        varId: data.walletRegistry?data.walletRegistry.varId:null,
        varName: data.walletRegistry?data.walletRegistry.varName:null,
        scratchcardId: data.walletRegistry?data.walletRegistry.scratchcardId:null,
        salesOrderConfirmationNumber: data.walletRegistry?data.walletRegistry.salesOrderConfirmationNumber:"",
        purchaseOrderNumberSofia: data.walletRegistry?data.walletRegistry.purchaseOrderNumberSofia:"",
        custRefID: data.walletRegistry?data.walletRegistry.custRefID:"",
        invoiceNumber: data.walletRegistry?data.walletRegistry.invoiceNumber:"",
        orderContactEmail: data.walletRegistry?data.walletRegistry.orderContactEmail:"",
      },
      documents:{}
    };
}