import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import luckeyLogo from '../../assets/logos/logoVashoWhite.png';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});

export interface ISpinnerProps extends WithStyles<typeof styles> {}

const Spinner: FunctionComponent<ISpinnerProps> = ({ classes }) => (
  <div className={classes.container}>
    <img src={luckeyLogo} alt="Luckey" className={classes.logo} />
    <CircularProgress className={classes.circularProgress} />
    <h4 className={classes.title}><ReduxLanguage languageKey="forms.spinnerLoading" /></h4>
  </div>
);

export default withStyles(styles)(Spinner);
