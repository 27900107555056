import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import ConsumptionIcon from '@material-ui/icons/MoneyOffOutlined';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IWalletUsageInDTO } from '../../../repository/jago/model/input/IWalletUsageInDTO';
import ListEmptyView from '../../ListEmptyView';
import WalletUsageRow from './WalletUsageRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IWalletConsumptionListProps extends WithStyles<typeof styles>{
  walletUsage: PaginatedDataDTO<IWalletUsageInDTO> | undefined;
  onFetchMoreItems: (page: number) => void;
}

class WalletConsumptionList extends React.Component<IWalletConsumptionListProps, any> {

  public render() {
    const { walletUsage, onFetchMoreItems } = this.props;
    
    const walletUsageData = walletUsage?.content;
    const walletSubscriptionsPagination = !_.isEmpty(walletUsage?.pagination) ? (walletUsage?.pagination as PaginationDTO) : null;
    const currentPage = walletSubscriptionsPagination ? walletSubscriptionsPagination.number : 0;
    const isLastPage = walletSubscriptionsPagination ? walletSubscriptionsPagination.last : true;
    return (
      <div style={{ zIndex: 100, marginTop: 20 }}>
        {!walletUsage || (walletUsage && walletUsage.content && _.isEmpty(walletUsage.content)) ? (
          <ListEmptyView
            icon={<ConsumptionIcon style={{ fontSize: 40 }} />}
            titleKeyMessage="credits.noWalletUsageFoundTitle"
            descriptionKeyMessage="credits.noWalletUsageFoundDescription"
          />
        ): (
          <InfiniteScroll
            dataLength={_.size(walletUsageData)} //This is important field to render the next data
            next={() => onFetchMoreItems(currentPage + 1)}
            hasMore={!isLastPage}
            loader={<h4>Loading...</h4>}
          >
            {_.map(walletUsageData, walletUsage => (
              <WalletUsageRow key={walletUsage.id} walletUsage={walletUsage} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(WalletConsumptionList);