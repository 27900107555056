import { Button, Divider, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import ReduxLanguage from '../ReduxLanguage';
import { IModalViewProps } from './ModalTypes';
import { RED } from '../../style/StyleConsts';
import { Close } from '@material-ui/icons';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface ContentModalProps {
  titleMessageKey?: string;
  successMessageKey: string;
  confirmMessageKey?: string;
  cancelMessageKey?: string;
  fullScreen?: boolean,
  fullWidth?: boolean,
  title?: string;
  icon?: JSX.Element;
  content: JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export default class ContentModal extends React.Component<IModalViewProps<ContentModalProps>> {

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { content, cancelMessageKey, onConfirm, fullScreen, fullWidth, title, confirmMessageKey } = this.props.modalProps;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition} fullWidth={fullWidth} fullScreen={fullScreen}>
        {title ? (
          <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 15, paddingBottom: 0 }}>
            <h1 style={{ fontWeight: 'bold' }}>{title}</h1>
            <IconButton onClick={() => this.handleClose()}>
              <Close />
            </IconButton>
          </div>
          <Divider style={{ marginTop: 15 }} />
          </div>
        ) : null}
        <div style={{ padding: 20,  flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          {content}
          {onConfirm ? (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20, backgroundColor: RED, width: 250, borderRadius: 100 }}
              onClick={() => onConfirm()}
            >
            <ReduxLanguage languageKey={confirmMessageKey || 'forms.save'}/>
          </Button>
        ) : null}
        {!title ? (
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, backgroundColor: RED, width: 250, borderRadius: 100 }}
            onClick={() => this.handleClose()}
          >
            <ReduxLanguage languageKey={cancelMessageKey || 'forms.close'}/>
          </Button>
        ) : null}
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const {hideModal, modalProps} = this.props;
    hideModal();
    if (modalProps && modalProps.onCancel) {
      modalProps.onCancel();
    }
  }
}
