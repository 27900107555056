import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { getDomainNameFromHostname } from '../repository/utils';
import { ICardInDTO } from './../repository/jago/model/input/ICardInDTO';
import { ICreditsInDTO } from './../repository/jago/model/input/ICreditsInDTO';
import { IDomainInDTO } from './../repository/jago/model/input/IDomainInDTO';
import { ISubscriptionInDTO } from './../repository/jago/model/input/ISubscriptionInDTO';
import { IWalletFormDTO } from './../repository/jago/model/input/IWalletFormDTO';
import { IWalletInDTO } from './../repository/jago/model/input/IWalletInDTO';
import { IWalletSubscriptionInDTO } from './../repository/jago/model/input/IWalletSubscriptionInDTO';
import { IWalletUsageInDTO } from './../repository/jago/model/input/IWalletUsageInDTO';

export function decode(data: PaginationRestDTO<IWalletInDTO>): PaginatedDataDTO<IWalletInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}


export function decodeCredits(data: PaginationRestDTO<ICreditsInDTO>): PaginatedDataDTO<ICreditsInDTO> {
  const content: ICreditsInDTO[] = data.content.map(encodedData => encodedData);
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content,
      pagination,
    };
}

export function decodeSubscriptions(data: PaginationRestDTO<ISubscriptionInDTO>): PaginatedDataDTO<ISubscriptionInDTO> {
  const content: ISubscriptionInDTO[] = data.content.map(encodedData => encodedData);
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content,
      pagination,
    };
}

export function decodeWalletSubscriptions(data: PaginationRestDTO<IWalletSubscriptionInDTO>): PaginatedDataDTO<IWalletSubscriptionInDTO> {
  const content: IWalletSubscriptionInDTO[] = data.content.map(encodedData => encodedData);
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content,
      pagination,
    };
}


export function decodeCards(data: PaginationRestDTO<ICardInDTO>): PaginatedDataDTO<ICardInDTO> {
  const content: ICardInDTO[] = data.content.map(encodedData => encodedData);
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content,
      pagination,
    };
}

export function decodeWalletPlants(data: PaginationRestDTO<IDomainInDTO>): PaginatedDataDTO<IDomainInDTO> {
  const content: IDomainInDTO[] = data.content.map(encodedData => decodeWalletPlantContent(encodedData));
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content,
      pagination,
    };
}

export function decodeCreditsUsage(data: PaginationRestDTO<IWalletUsageInDTO>): PaginatedDataDTO<IWalletUsageInDTO> {
  const content: IWalletUsageInDTO[] = data.content.map(encodedData => encodedData);
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content,
      pagination,
    };
}

export function decodeWalletContentToForm(data: IWalletInDTO): IWalletFormDTO {
  return {
    name: data.name,
    notes: data.notes,
    status: data.status,
  }
}

export function decodeWalletPlantContent(data: IDomainInDTO) {
  return {
    ...data,
    name: getDomainNameFromHostname(data.hostname),
    cloudCredits: true,
  }
}