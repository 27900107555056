import SeamlessImmutable from 'seamless-immutable';
import SettingsActionTypesEnum from './model/SettingsActionTypesEnum';
import { SettingsActionsType } from './settings.actions';
import i18next from 'i18next';


export interface ISettingsInterface {
  language: string;
  isSpinnerVisible: boolean;
  whatsNew: {
    content: any[] | [];
    hasUpdate: false;
  },
}

const initialState = SeamlessImmutable<ISettingsInterface>({
  language: i18next.language,
  isSpinnerVisible: false,
  whatsNew: {
    content: [],
    hasUpdate: false,
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<ISettingsInterface> = initialState, action: SettingsActionsType) {
  switch (action.type) {
    case SettingsActionTypesEnum.SET_LANGUAGE:
      return state.set('language', action.payload);
    case SettingsActionTypesEnum.SET_SPINNER_VISIBLE:
      return state.set('isSpinnerVisible', action.payload);
    case SettingsActionTypesEnum.SAVE_WHATSNEW_CONTENT:
      return state.setIn(['whatsNew', 'content'], action.payload);
    case SettingsActionTypesEnum.SAVE_WHATSNEW_HASUPDATE:
      return state.setIn(['whatsNew', 'hasUpdate'], action.payload);
    default:
      return state;
  }
}
