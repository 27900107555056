import _ from 'lodash';

export default class AbilityHelper {

  userRoles = [];
  userPermissions = [];

  setUserRole(roles: string[]) {
    this.userRoles = roles;
  }

  setUserPermissions(permissions: string[]) {
    this.userPermissions = permissions;
  }

  hasRoles(roles: string[]) {
    if (!this.userRoles) return false;
    return _.some(this.userRoles, userRole=>_.includes(roles, userRole));
  }

  hasPermission(permission: string) {
    if (!this.userPermissions.length) return false;
    return _.includes(this.userPermissions, permission);
  }

  hasPermissions(permissions: string[]) {
    if (!this.userPermissions.length) return false;
    const missingPermissions = _.difference(this.userPermissions, permissions);
    return _.isEmpty(missingPermissions);
  }
}
