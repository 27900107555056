import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import React, { ReactNode } from 'react';
import { IModalViewProps, ModalTypes } from './ModalTypes';
import ReduxLanguage from '../ReduxLanguage';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import * as ModalsActions from '../../redux/modals/modals.actions';
import WarningIcon from '@material-ui/icons/Warning';


export interface OperationalViewState {
  isOpen: boolean;
}

export interface OperationalViewModalProps {
  content: ReactNode;
  overflowDisabled?: boolean;
  onClose?: () => void;
  titleMessageKey?: string;
};

const styles = createStyles({
  paper: {
    overflowY: 'unset',
  },
});

interface ComponentProps extends WithStyles<typeof styles>, IModalViewProps<OperationalViewModalProps> {}

class OperationalViewModalWP extends React.Component<ComponentProps, OperationalViewState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  
  componentDidMount() {
    // For animation purpose
    setTimeout(() => this.setState({ isOpen: true }), 100);
  }

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { classes } = this.props;
    const { content, titleMessageKey, overflowDisabled } = this.props.modalProps;
    const { isOpen } = this.state;
    return (
      <Drawer PaperProps={overflowDisabled ? {className: classes.paper} : undefined} anchor="right" open={isOpen} onClose={() => this.handleCloseBackdropClick()} transitionDuration={{ enter: 300, exit: 300 }}>
        <div style={{ width: '60vw', height: '100vh', overflowY:'hidden' }}>
          <div style={{ backgroundColor: '#3f3f3f', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10, maxHeight: 67 }}>
            <h2 style={{  fontWeight: 'bold', color: 'white' }}>{<ReduxLanguage languageKey={titleMessageKey || 'forms.details'} />}</h2>
            <IconButton onClick={() => this.handleClose()}>
              <CloseIcon style={{ color: 'white', marginRight: 10 }} />
            </IconButton>
          </div>
          <Divider />
          <div style={{height: 'calc(100vh - 87px)', overflowY:'auto'}}>
            {content}
          </div>
          <div style={{minHeight:25}}></div>
        </div>
      </Drawer>
    );
  }

  private handleClose = (): void => {
    const { hideModal } = this.props;
    // For animation purpose
    this.setState({ isOpen: false });
    setTimeout(() => {
      hideModal();
      if (this.props.modalProps && this.props.modalProps.onClose) {
        this.props.modalProps.onClose();
      }
    }, 350)
  }

  private handleCloseBackdropClick = (): void => {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.hideModal(`CLOSEFORM_CONFIRM_MODAL`));
    const { hideModal } = this.props;
    // For animation purpose
    this.setState({ isOpen: false });
    setTimeout(() => {
      hideModal();
      if (this.props.modalProps && this.props.modalProps.onClose) {
        this.props.modalProps.onClose();
      }
    }, 350)
  }
}

export default withStyles(styles)(OperationalViewModalWP);