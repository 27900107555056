import SeamlessImmutable from 'seamless-immutable';
import DomainsActionTypesEnum from './model/DomainsActionTypesEnum';
import { IDomainInDTO } from '../../repository/jago/model/input/IDomainInDTO';
import { DomainsActionsType } from './domains.actions';
import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { IDomainDetailedDTO } from '../../repository/jago/model/input/IDomainDetailedDTO';


export interface IDomainsInterface {
  domains: PaginatedDataDTO<IDomainInDTO> | {};
  selectedDetailedDomain: IDomainDetailedDTO | {};
}

const initialState = SeamlessImmutable<IDomainsInterface>({
  domains: {},
  selectedDetailedDomain: {},
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IDomainsInterface> = initialState, action: DomainsActionsType) {
  switch (action.type) {
    case DomainsActionTypesEnum.SAVE_DOMAINS:
      return state.set('domains', action.payload);
    case DomainsActionTypesEnum.SET_SELECTED_DETAILED_DOMAIN:
      return state.set('selectedDetailedDomain', action.payload);
    default:
      return state;
  }
}
