import MomentUtils from '@date-io/moment';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import Select, { SingleValue } from 'react-select';
import CreditsIcon from '@material-ui/icons/MonetizationOn';
import { SubscriptionLogicEnum } from '../../repository/jago/model/input/ISubscriptionLogicEnum';
import { ISubscriptionResourceEnum } from '../../repository/jago/model/input/ISubscriptionResourceEnum';
import { ISubscriptionOutDTO } from '../../repository/jago/model/output/ISubscriptionOutDTO';
import Utils from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";
import { SubscriptionProvisioningTypeEnum } from '../../repository/jago/model/input/ISubscriptionProvisioningTypeEnum';


const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});


export interface INewPluginSubscriptionFormState {
  subscriptionData: ISubscriptionOutDTO;
  textInputErrors: { [key: string]: boolean; };
  isProvisioningSelectionDisabled: boolean;
};

export interface INewPluginSubscriptionFormProps extends WithStyles<typeof styles>{
  onCreatePluginSubscription: (subscription: ISubscriptionOutDTO) => void;
}

class NewPluginSubscriptionForm extends React.Component<INewPluginSubscriptionFormProps, INewPluginSubscriptionFormState> {

  constructor(props: INewPluginSubscriptionFormProps) {
    super(props);
    this.state = {
      subscriptionData: {
        name: '',
        sku: '',
        fixedPrice: 0,
        burnRate: 0,
        logic: SubscriptionLogicEnum.FIXED,
        maxLocks: 0,
        maxElements: 0,
        maxUsers: 0,
        nextTier: 0,
        prevTier: 0,
        resource: '',
        mainTier: 0,
        visible: true,
        provisioningType: SubscriptionProvisioningTypeEnum.MANUAL,
        requestToQuote: true,
        canBeDeactivated: true,
      },
      isProvisioningSelectionDisabled: true,
      textInputErrors: {
        name: false,
        fixedPrice: false,
        burnRate: false,
        sku: false,
        logic: false,
        maxLocks: false,
        maxElements: false,
        maxUsers: false,
        resource: false,
        mainTier: false,
      },
    };
  }
  
  public onSetValue(field: keyof ISubscriptionOutDTO, value: any) {
    const { subscriptionData } = this.state;
    if (value == null || value === '') {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (value && value < 0) {
      this.setErrorForField(field, true);
    }
    (subscriptionData as any)[field] = value;
    this.setState({ subscriptionData });
  }

  public onSelectResource(resource: SingleValue<ISubscriptionResourceEnum>) {
    const { subscriptionData } = this.state;
    subscriptionData.resource = resource ? resource : undefined;
    this.setState({ subscriptionData });
  }

  public onSelectProvisioning(provisioning: SingleValue<SubscriptionProvisioningTypeEnum>) {
    const { subscriptionData } = this.state;
    if (provisioning) {
      subscriptionData.provisioningType = provisioning;
    }
    this.setState({ subscriptionData });
  }

  public onSelectTier(tierId: number) {
    const { subscriptionData } = this.state;
    if (!tierId || tierId === 0) {
      this.setErrorForField('mainTier', true);
    }
    subscriptionData.mainTier = tierId;
    this.setState({ subscriptionData });
  }

  public setErrorForField(field: string, hasError: boolean) {
    const { textInputErrors } = this.state;
    textInputErrors[field] = hasError;
    this.setState({ textInputErrors });
  }

  public canSubmitForm() {
    const { subscriptionData } = this.state;
    if (!subscriptionData.name || !subscriptionData.sku || !subscriptionData.resource) {
      return false;
    }
    return true;
  }
    

  public onSubmitForm() {
    const { subscriptionData } = this.state;
    const { onCreatePluginSubscription } = this.props;
    if (this.canSubmitForm()) {
      onCreatePluginSubscription({
        ...subscriptionData,
        fixedPrice: Utils.formatCreditsOut(subscriptionData.fixedPrice),
        burnRate: Utils.formatCreditsOut(subscriptionData.burnRate),
      });
    }
  }

  public onSetRequestToQuote(isChecked: boolean) {
    this.onSetValue('requestToQuote', isChecked);
    if (isChecked) {
      this.onSelectProvisioning(SubscriptionProvisioningTypeEnum.MANUAL);
    }
    this.setState({ isProvisioningSelectionDisabled: isChecked });
  }

/* Se logic è:
  - Fixed non mostrare maxLock, eleement e users
  - Count users -> mostra solo maxUsers
  - Count Locks -> mostra solo maxLocks
  - Count Elements -> mostra solo maxElements
- Fixed price è il costo di attivazione
- Burn rate è il costo ricorrente
*/

  public render() {
    const { classes } = this.props;
    const { subscriptionData, textInputErrors, isProvisioningSelectionDisabled } = this.state;
    const provisioningOptions = _.map(SubscriptionProvisioningTypeEnum, provisioningType => ({
      label: <ReduxLanguage languageKey={`forms.${provisioningType}`} />,
      value: provisioningType
    }));
    const selectedProvisioniOption = subscriptionData && subscriptionData.provisioningType ? _.find(provisioningOptions, provisioning => provisioning.value === subscriptionData.provisioningType) : null;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <input autoComplete="new-password" style={{ display: 'none' }} />
          <h4 style={{ marginTop: 0, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionName'} />}<span style={{ color: 'red' }}>*</span></h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            error={textInputErrors.name}
            value={subscriptionData?.name}
            onChange={e => this.onSetValue('name', e.target.value)}
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.sku'} />}<span style={{ color: 'red' }}>*</span></h4>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            error={textInputErrors.sku}
            value={subscriptionData?.sku}
            onChange={e => this.onSetValue('sku', e.target.value)}
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionResource'} />}<span style={{ color: 'red' }}>*</span></h4>
          <Select
            options={_.map(ISubscriptionResourceEnum, (v) => ({ value: v}))}
            isClearable
            placeholder={<ReduxLanguage languageKey={'forms.subscriptionResource'} />}
            getOptionLabel={(v) => v.value}  
            onChange={(e) => this.onSelectResource(e ? e.value : null)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, marginTop: 10, marginBottom: 20 }),
            }} 
          />
          <FormControlLabel
            control={<Checkbox checked={subscriptionData?.visible} onChange={(e, checked) => this.onSetValue('visible', checked)} name="subscriptionVisible" />}
            label={<ReduxLanguage languageKey={'forms.subscriptionVisible'} />}
          />
          <>
            <FormControlLabel
              control={<Checkbox checked={subscriptionData?.canBeDeactivated} onChange={(e, checked) => this.onSetValue('canBeDeactivated', checked)} name="canBeDeactivated" />}
              label={<ReduxLanguage languageKey={'forms.subscriptionCanBeDeactivated'} />}
            />
            <h4 style={{ marginTop: -6, marginBottom: 10 }}><ReduxLanguage languageKey={`forms.subscriptionCanBeDeactivatedDescription`} /></h4>
          </>
          <FormControlLabel
            control={<Checkbox checked={subscriptionData?.requestToQuote} onChange={(e, checked) => this.onSetRequestToQuote(checked)} name="requestToQuote" />}
            label={<ReduxLanguage languageKey={'forms.requestToQuote'} />}
          />
          <h4 style={{ marginTop: -6, marginBottom: 10 }}><ReduxLanguage languageKey={`forms.requestToQuoteDescription`} /></h4>
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.provisioningType'} />}<span style={{ color: 'red' }}>*</span></h4>
          <Select
            options={provisioningOptions}
            isClearable
            isDisabled={isProvisioningSelectionDisabled}
            value={selectedProvisioniOption}
            placeholder={<ReduxLanguage languageKey={'forms.provisioningType'} />}
            onChange={(e) => this.onSelectProvisioning(e ? e.value : null)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, marginTop: 10, marginBottom: 20 }),
            }} 
          />
          {selectedProvisioniOption && selectedProvisioniOption.value ? (
            <h4 style={{ marginTop: -10, marginBottom: 10 }}><ReduxLanguage languageKey={`forms.${selectedProvisioniOption.value}_Description`} /></h4>
          ) : null}
          
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionFixedPrice'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionFixedPriceDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            style={{ marginTop: 10 }}
            type={"number"}
            error={textInputErrors.fixedPrice}
            value={subscriptionData?.fixedPrice}
            onChange={e => this.onSetValue('fixedPrice', e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.subscriptionBurnRate'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0 }}>{<ReduxLanguage languageKey={'forms.subscriptionBurnRateDescription'} />}</h5>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            required
            type={"number"}
            style={{ marginTop: 10 }}
            error={textInputErrors.burnRate}
            value={subscriptionData?.burnRate}
            onChange={e => this.onSetValue('burnRate', e.target.value)}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <CreditsIcon />
                </InputAdornment>
              }
            }
          />
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(NewPluginSubscriptionForm);