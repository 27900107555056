import { IStorageRepository } from './IStorageRepository';

export class LocalStorageRepository implements IStorageRepository {
    readKey<T>(key: string, emptyResult: T): T {
        const data = localStorage.getItem(key);
        if (data == null) {
            return emptyResult;
        }

        return JSON.parse(data);
    }

    storeKey<T>(key: string, value: T): void {
        const data = JSON.stringify(value);
        localStorage.setItem(key, data);
    }

    hasKey(key: string): boolean {
        const data = localStorage.getItem(key);
        return data !== null;
    }

    async hasKeyAsync(key: string): Promise<boolean> {
        return this.hasKey(key);
    }

    async readKeyAsync<T>(key: string, emptyResult: T): Promise<T> {
        return this.readKey(key, emptyResult);
    }

    async storeKeyAsync<T>(key: string, value: T): Promise<void> {
        return this.storeKey(key, value);
    }

    clearKey(key: string): void {
        return localStorage.removeItem(key);
    }

    async clearKeyAsync(key: string): Promise<void> {
        return this.clearKey(key);
    }
}
