import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/PersonOutline';
import moment from "moment";
import * as React from "react";
import { IVarOpportunityHistoryRowDTO } from "../../repository/jago/model/input/IVarOpportunityInDTO";
import ReduxLanguage from "../ReduxLanguage";
import { Divider } from '@material-ui/core';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    position: 'relative',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 15
  },
  rowTitle: {
    fontWeight: 'bold',
    margin: 0,
  },
  rowDescription: {
    fontWeight: 'normal',
    margin: 0,
  }
});
export interface IVarOpportunityHistoryRowProps extends WithStyles<typeof styles>{
  history: IVarOpportunityHistoryRowDTO;
}

class VarOpportunityHistoryRow extends React.Component<IVarOpportunityHistoryRowProps, any> {
  public render() {
    const { history, classes } = this.props;
    return (
      <div>
      <div className={classes.rowContainer}>
        <div>
          <h2 className={classes.rowTitle}><ReduxLanguage languageKey={`vars.${history.action}`} /></h2>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
            <UserIcon style={{ fontSize: 15, marginRight: 5, marginTop: 2 }} />
            <h3 className={classes.rowDescription}>{history.user}</h3>
          </div>
        </div>
        {history.time ? <h4>{moment(history.time).format('LLL')}</h4> : null}
      </div>
      <Divider />
      </div>
    );
  }
}

export default withStyles(styles)(VarOpportunityHistoryRow);