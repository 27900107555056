import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import * as React from "react";
import { ICreditsInDTO } from "../../../repository/jago/model/input/ICreditsInDTO";
import Utils from '../../../utils/Utils';
import ReduxLanguage from "../../ReduxLanguage";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    padding: 15,
    backgroundColor: 'white',
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowBodyText: {
    fontWeight: 'normal',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    fontSize: 15
  },
  accordionDetailsBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

export interface IWalletCreditRowProps extends WithStyles<typeof styles>{
  credit?: ICreditsInDTO;
}

class WalletCreditRow extends React.Component<IWalletCreditRowProps, any> {
  public render() {
    const { credit, classes } = this.props;
    return (
      <div className={classes.rowContainer}>
        <div className={classes.rowHeader}>
          <h3 className={classes.rowTitle}>{<ReduxLanguage languageKey="credits.rechargeRowTitle" />}</h3>
          <h4 className={classes.rowBodyText} style={{ marginLeft: 3 }}>{moment(credit?.createdAt).format('LL')}</h4>
        </div>
        <div className={classes.rowBodyContainer}>
          <h4 className={classes.rowBodyText} style={{ marginLeft: 3 }}>{<ReduxLanguage languageKey="credits.rechargeCreditsDescription" languageProps={{ credits: Utils.formatCreditsIn(credit?.quantity) }}/>}</h4>
        </div>
        {credit && credit.card ? (
          <Accordion style={{ marginTop: 10, zIndex: 20 }} elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h4 className={classes.rowBodyText} style={{ margin: 0 }}>{<ReduxLanguage languageKey="credits.rechargeCardDetails" />}</h4>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetailsBody}>
              <div>
                <h4 style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="credits.rechargeCardCode" /></h4>
                <h4>{credit?.card.code}</h4>
              </div>
              <div>
                <h4 style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.iseoSalesOrderReference" /></h4>
                <h4>{credit?.card.iseoSalesOrderReference}</h4>
              </div>
              <div>
                <h4 style={{ fontWeight: 'bold' }}><ReduxLanguage languageKey="forms.cardNotes" /></h4>
                <h4>{credit?.card.notes}</h4>
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(WalletCreditRow);