import { Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/PersonOutlined';
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IState } from "../../redux/store";
import MarketplaceSubscriptionsSection from './MarketplaceSubscriptionsSection';

const styles = createStyles({
  container: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 20,
    padding: 10,
    borderLeft: '3px solid #0D47A1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

type IReduxProps = ConnectedProps<typeof connector>;

export interface IMarketplaceDetailsViewProps extends WithStyles<typeof styles>{
}

export type ComponentProps = IMarketplaceDetailsViewProps & IReduxProps;
class MarketplaceDetailsView extends React.Component<ComponentProps, any> {
  public render() {
    const { selectedMarketplace, classes } = this.props;
    return (
      <div className={classes.container}>
        <Card className={classes.headerContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <UserIcon className={classes.icon} />
              <div className={classes.headerTextContainer}>
                <h3 className={classes.title}>{selectedMarketplace && selectedMarketplace.name ? selectedMarketplace.name : ''}</h3>
              </div>
            </div>
          </div>
        </Card>
        <MarketplaceSubscriptionsSection
          marketplace={selectedMarketplace}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    selectedMarketplace: state.credits.marketplaces.selectedMarketplace,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(MarketplaceDetailsView as any));