import { Card, Divider, List, ListItem, Popper } from '@material-ui/core';
import CaretIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IState } from '../redux/store';
import ReduxLanguage from './ReduxLanguage';
import * as SettingsActions from '../redux/settings/settings.actions';
import { LanguageTypes } from '../translations/LanguageTypes';
import { LocalStorageRepository } from '../services/StorageService/LocalStorageRepository';

type IReduxProps = ConnectedProps<typeof connector>;

export interface ILanguageDetailEntry {
  flag: string;
  lang: string;
  l20n: string;
  backend: string;
  helpCenterLanguage: string;
  storeLanguagePrefix: string;
}

const LANGUAGE_DETAILS = [
  { flag: 'United-Kingdom', lang: LanguageTypes.ENGLISH },
  { flag: 'Italy', lang: LanguageTypes.ITALIAN },
];

export interface ILanguageSelectorProps extends IReduxProps, RouteComponentProps<any> {
}
export interface ILanguageSelectorState {
  selectedFlag: string;
  isOpened: boolean;
  anchorEl: any;
  currentlyHovering: boolean;
}
export class LanguageSelector extends React.Component<ILanguageSelectorProps, ILanguageSelectorState> {

  constructor(props: ILanguageSelectorProps) {
    super(props);
    
    const storage = new LocalStorageRepository();
    let savedLanguage = storage.readKey<string>('language', undefined);
    const foundFlag = savedLanguage&&LANGUAGE_DETAILS.filter(e=>e.lang===savedLanguage).length>0?LANGUAGE_DETAILS.filter(e=>e.lang===savedLanguage)[0].flag:undefined

    this.state = {
      selectedFlag: foundFlag?foundFlag:'United-Kingdom',
      isOpened: false,
      anchorEl: null,
      currentlyHovering: false,
    }
  }

  handleSelect(language: ILanguageDetailEntry) {
    const { dispatch } = this.props;
    
    this.setState({ selectedFlag: language.flag });
    dispatch<any>(SettingsActions.saveLanguageSettings(language.lang));
    const storage = new LocalStorageRepository();
    storage.storeKey('language', language.lang);

    this.setState({ isOpened: false });
  }

  render() {
    const {  } = this.props;
    const { selectedFlag, isOpened, anchorEl, currentlyHovering } = this.state;

    const flagIcon = (
      <img src={`/imgs/app/flags/flags/flat/32/${selectedFlag}.png`} width='28' height='28' />
    );
    return (
      <div
        onMouseEnter={(e) => this.setState({ anchorEl: e.currentTarget, isOpened: true }) }
        onMouseLeave={(e) => this.setState({ anchorEl: null, isOpened: false })}
        style={{marginRight:30, marginBottom:5}}
      >
        <div
          aria-controls="language-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ anchorEl: e.currentTarget, isOpened: true })}
        >
          <div style={{ display:'flex', flexDirection:'row', alignItems:'center', marginBottom: 5 }}>
            {flagIcon}
            <CaretIcon style={{ color: 'white', fontSize: 20, marginLeft: 10 }} />
          </div>
        </div>
        <Popper
          open={isOpened}
          anchorEl={anchorEl}
          disablePortal={true}
          placement='bottom-end'
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'viewport',
            }
          }}
          style={{ zIndex: 9999, minWidth: 350, paddingTop: 10 }}
        >
          <Card style={{ backgroundColor: 'white' }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem key='flag-header' style={{ backgroundColor: '#f0f0f0', marginTop: -10, height: 30 }}>
                <h5 style={{ margin: 0 }}><ReduxLanguage languageKey={"languages.menuHeading"}/></h5>
                </ListItem>
              <Divider />
              {_.map(_.keys(LANGUAGE_DETAILS), (key, i) => (
                <ListItem
                  key={i}
                  button
                  onClick={(flag) => this.handleSelect(LANGUAGE_DETAILS[key])}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                    <img src={`/imgs/app/flags/flags/flat/32/${LANGUAGE_DETAILS[key].flag}.png`} width='25' />
                    <h4 style={{ fontSize: 14, margin: 0, color: '#3f3f3f', marginLeft: 10, marginTop: 2 }}><ReduxLanguage languageKey={`languages.${LANGUAGE_DETAILS[key].lang}`}/></h4>
                  </div>
                </ListItem>
              ))}
            </List>
          </Card>
        </Popper>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
  };
}

const connector = connect(mapStateToProps);

export default connector(withRouter(LanguageSelector));