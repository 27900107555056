import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import React, { ReactNode } from 'react';
import { IModalViewProps } from './ModalTypes';
import ReduxLanguage from '../ReduxLanguage';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';


export interface OperationalViewState {
  isOpen: boolean;
}

export interface OperationalViewModalProps {
  content: ReactNode;
  overflowDisabled?: boolean;
  onClose?: () => void;
  titleMessageKey?: string;
  subtitleMessageKey?: string;
  titleLinkText?: string;
  titleLinkUrl?: string;
};

const styles = createStyles({
  paper: {
    overflowY: 'unset',
  },
});

interface ComponentProps extends WithStyles<typeof styles>, IModalViewProps<OperationalViewModalProps> {}

class OperationalViewModal extends React.Component<ComponentProps, OperationalViewState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  
  componentDidMount() {
    // For animation purpose
    setTimeout(() => this.setState({ isOpen: true }), 100);
  }

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { classes } = this.props;
    const { content, titleMessageKey, subtitleMessageKey, overflowDisabled, titleLinkText, titleLinkUrl } = this.props.modalProps;
    const { isOpen } = this.state;
    return (
      <Drawer PaperProps={overflowDisabled ? {className: classes.paper} : undefined} anchor="right" open={isOpen} onClose={() => this.handleClose()} transitionDuration={{ enter: 300, exit: 300 }}>
        <div style={{ width: '60vw', height: '100vh' }}>
          <div style={{ backgroundColor: '#3f3f3f', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 10, maxHeight: 67 }}>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
              <div>
                <h2 style={{  fontWeight: 'bold', color: 'white' }}>{<ReduxLanguage languageKey={titleMessageKey || 'forms.details'} />}</h2>
                {subtitleMessageKey?(
                  <h3 style={{  fontWeight: 'bold', color: 'white', marginLeft:20 }}>{<ReduxLanguage languageKey={subtitleMessageKey} />}</h3>
                ):null}
              </div>
              {titleLinkText && titleLinkUrl?(
                <h2 
                  style={{ fontWeight: 'bold', color: 'white', fontSize:'0.8em', marginLeft:20, textDecoration:'underline', cursor:'pointer'}}
                  onClick={()=>{window.open(titleLinkUrl)}}
                >
                  {titleLinkText}
                </h2>
              ):null}
            </div>
            <IconButton onClick={() => this.handleClose()}>
              <CloseIcon style={{ color: 'white', marginRight: 10 }} />
            </IconButton>
          </div>
          
          <Divider />
          <div style={{height: 'calc(100vh - 67px)'}}>
            {content}
          </div>
        </div>
      </Drawer>
    );
  }

  private handleClose = (): void => {
    const { hideModal } = this.props;
    // For animation purpose
    this.setState({ isOpen: false });
    setTimeout(() => {
      hideModal();
      if (this.props.modalProps && this.props.modalProps.onClose) {
        this.props.modalProps.onClose();
      }
    }, 350)
  }
}

export default withStyles(styles)(OperationalViewModal);