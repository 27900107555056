import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IState } from '../redux/store';
import translations from "../translations/i18next";

type IReduxProps = ConnectedProps<typeof connector>;

interface IReduxLanguageProps {
  languageKey: string;
  languageProps?: any;
}

export type ComponentProps = IReduxLanguageProps & IReduxProps;

const ReduxLanguage: React.FunctionComponent<ComponentProps> = props => {
  const { languageKey, languageProps } = props;
  return <>{translations.t(languageKey, languageProps)}</>;
};

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language
  };
}

const connector = connect(mapStateToProps);
export default connector(ReduxLanguage);
