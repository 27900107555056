
export enum IGatewayDeviceTypeEnum {
  HYPERGATE_PRO = 'HYPERGATE_PRO',
  HYPERGATE_ISEO = 'HYPERGATE_ISEO',
};

export default interface IGatewayInDTO {
  id: number
  name: string;
  deviceId: string;
  lastConnectionDate: number;
  deviceOs: string;
  deviceModel: string;
  deviceVersion: string;
  hostName: string;
  deviceType: IGatewayDeviceTypeEnum;
  swVersion: string;
  kernelVersion: string;
  iseoSdkVersion: string;
}
