import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import SeamlessImmutable from 'seamless-immutable';
import { ProductInfoActionsType } from './productinfo.actions';
import ProductInfoActionTypesEnum from './model/ProductInfoActionTypesEnum';
import { IProductInfoPostInDTO } from '../../repository/jago/model/input/IProductInfoPostInDTO';

export interface IProductInfoInterface {
  posts: {
    data: IProductInfoPostInDTO[];
  },
  selectedPost: {
    data: IProductInfoPostInDTO | {};
  },
}

const initialState = SeamlessImmutable<IProductInfoInterface>({
  posts: {
    data: []
  },
  selectedPost: {
    data: {}
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IProductInfoInterface> = initialState, action: ProductInfoActionsType) {
  switch (action.type) {
    case ProductInfoActionTypesEnum.SAVE_POSTS:
      return state.setIn(['posts', 'data'], action.payload);
    case ProductInfoActionTypesEnum.SET_SELECTED_POST:
      return state.setIn(['selectedPost', 'data'], action.payload);
    default:
      return state;
  }
}
