import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as React from "react";
import WarningIcon from '@material-ui/icons/Warning';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as CreditsActions from '../../../redux/credits/credits.actions';
import * as VarsActions from '../../../redux/vars/vars.actions';
import { DomainsActions } from '../../../redux/domains/domains.actions';
import * as DomainActions from '../../../redux/domains/domains.actions';
import * as UserActions from '../../../redux/user/user.actions';
import * as ModalsActions from '../../../redux/modals/modals.actions';
import * as SettingsActions from '../../../redux/settings/settings.actions';
import { IState } from '../../../redux/store';
import { IDomainDetailedDTO } from '../../../repository/jago/model/input/IDomainDetailedDTO';
import { IDomainInDTO } from '../../../repository/jago/model/input/IDomainInDTO';
import { IDomainUserInDTO } from '../../../repository/jago/model/input/IDomainUserInDTO';
import { IWalletDetailedDTO } from '../../../repository/jago/model/input/IWalletDetailedDTO';
import { IDomainUserOutDTO } from '../../../repository/jago/model/output/IDomainUserOutDTO';
import { IWalletPlantsRequestParamsDTO } from '../../../repository/jago/model/output/RequestParamsDTOs';
import { getDomainFrontendAddressFromHostname } from '../../../repository/utils';
import { PERMISSIONS, SUPPORT_AGENT_READ_ONLY_EMAIL } from '../../../services/PermissionService/PermissionConstants';
import DomainDetailsView from '../../Domains/DomainDetailsView';
import UserImpersonateForm from '../../Domains/DomainUsers/UserImpersonateForm';
import NewDomainUserForm from '../../Forms/NewDomainUserForm';
import { ModalTypes } from '../../Modals/ModalTypes';
import WalletPlantsList from '../WalletPlants/WalletPlantsList';
import AbilityProvider from '../../../services/PermissionService/AbilityProvider';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});


type IWalletPlantsPageReduxProps = ConnectedProps<typeof connector>;

export interface IWalletPlantsPageProps extends WithStyles<typeof styles>{
  wallet: IWalletDetailedDTO;
  onOpenWalletPlantForm: () => void;
}

export type ComponentProps = IWalletPlantsPageProps & IWalletPlantsPageReduxProps & RouteComponentProps<any>;

class WalletPlantsPage extends React.Component<ComponentProps, any> {

  public onDeletePlantRequest(plant: IDomainDetailedDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_PLANT_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.domainDeletionTitle',
        successMessageKey: 'domains.domainDeletionConfirm',
        confirmMessageKey: 'domains.sendDeletionRequest',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeletePlantConfirm(plant),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_PLANT_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onDeletePlantConfirm(plant: IDomainDetailedDTO) {
    const { dispatch } = this.props;
    try {
      const domainName = plant.hostname
      dispatch<any>(ModalsActions.hideModal(`DELETE_PLANT_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(UserActions.openCloseDomainTicket(domainName));
      dispatch<any>(ModalsActions.hideModal('DOMAIN_DETAILS_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.domainDeletionSuccess',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_DELETION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteDomainRequestError',
        }
      }));
    }
  }

  public onEnableIrisRequest(plant: IDomainDetailedDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`ENABLE_IRIS_PLANT_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'domains.domainIrisEnable',
        successMessageKey: 'domains.domainIrisEnableConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEnableIrisConfirm(plant),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`ENABLE_IRIS_PLANT_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onEnableIrisConfirm(plant: IDomainDetailedDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(ModalsActions.hideModal(`ENABLE_IRIS_PLANT_CONFIRM_MODAL`));
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(DomainActions.enableIris(plant.id));
      dispatch<any>(ModalsActions.hideModal('DOMAIN_DETAILS_MODAL'));
      dispatch<any>(CreditsActions.setSelecteWallet({}))
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_DOMAIN_MODAL'));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    } catch (error) {
      console.log(error)
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_DELETION`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.enableIrisDomainRequestError',
        }
      }));
    }
  }
  
  public async onOpenPlantDetails(plant: IDomainInDTO) {
    const { dispatch, history } = this.props;
    try {
      const canReadConfig = AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_CONFIG)
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      dispatch<any>(DomainsActions.setSelectedDetailedDomain(plant));
      if (canReadConfig) {
        await dispatch<any>(CreditsActions.fetchWalletPlantConfiguration());
      }
      if (AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_MANAGER_UPDATE))
        await dispatch<any>(CreditsActions.fetchWalletPlantUsers());
      if (AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.DOMAIN_INSTALLER_UPDATE))
        await dispatch<any>(CreditsActions.fetchWalletPlantInstallers());
      await dispatch<any>(CreditsActions.fetchWalletPlantInfo());
      await dispatch<any>(CreditsActions.fetchWalletPlantAdditionalInfo());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      const { plant: detailedPlant } = this.props;
      dispatch<any>(ModalsActions.showModal(`DOMAIN_DETAILS_MODAL`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          onClose: () => dispatch<any>(DomainsActions.setSelectedDetailedDomain({})),
          content: (
          <div>
            <DomainDetailsView
              detailedDomain={detailedPlant}
              onSaveConfig={(config: string) => this.updatePlantConfiguration(config)}
              onImpersonateUser={(user: IDomainUserInDTO) => this.impersonateDomainUserRequest(user)}
              onAppenDomainUsers={(page: number) => this.appenDomainUsers(page)}
              onNewUserClick={() => this.onNewUserClick()}
              onNewInstallerClick={() => this.onNewInstallerClick()}
              onDeletePlantRequest={() => this.onDeletePlantRequest((detailedPlant) as IDomainDetailedDTO)}
              onUpdateAddionalInfo={value => this.onUpdateAddionalInfo(value)}
              onGatewaysClick={() => {
                if (plant.hostname) {
                  dispatch<any>(ModalsActions.hideModal(`DOMAIN_DETAILS_MODAL`));
                  history.push(`/gateways?domain=${plant.hostname}`);
                }
              }}
              onSavePlantTheme={(plantId: number, plantThemeId: number, addOrRemove: boolean) => {this.onSavePlantTheme(plantId,plantThemeId,addOrRemove)}}
            />
          </div>),
          titleMessageKey: 'domains.domainDetails',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_DETAILS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.getDomainDetailsError',
        }
      }));
    }
  }

  public async updatePlantConfiguration(config: string) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.updateWalletPlantConfiguration({ data: config }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.domainConfigModifySuccess',
        }
      }));
      await dispatch<any>(CreditsActions.fetchWalletPlantConfiguration());
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CONFIG_UPDATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.updateDomainConfigError',
        }
      }));
    }
  }

  public async impersonateDomainUserRequest(user: IDomainUserInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`IMPERSONATE_DOMAIN_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONTENT_MODAL,
      modalProps: {
        content: (
        <UserImpersonateForm
          user={user}
          onImpersonateReadyOnly={(duration) => this.impersonateDomainUser(SUPPORT_AGENT_READ_ONLY_EMAIL, duration)}
          onImpersonateUser={(duration) => this.impersonateDomainUser(user.email, duration)}
        />
        ),
      }
    }));
  }

  public async impersonateDomainUser(userEmail: string, duration?: number) {
    const { dispatch, plant } = this.props;
    const selectedPlant = plant as IDomainDetailedDTO;
      try {
        dispatch<any>(SettingsActions.setSpinnerVisible(true));
        const userToken = await dispatch<any>(CreditsActions.impersonateDomainUser(userEmail, duration));
        const frontendURL = getDomainFrontendAddressFromHostname(selectedPlant?.hostname);
        window.open(`https://${frontendURL}?p=${userToken}`);
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
          modalType: ModalTypes.SUCCESS_MODAL,
          modalProps: {
            titleMessageKey: 'domains.impersonateSuccess',
          }
        }));
      } catch (error) {
        dispatch<any>(SettingsActions.setSpinnerVisible(false));
        dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_IMPERSONATE`, {
          modalType: ModalTypes.ERROR_MODAL,
          modalProps: {
            titleMessageKey: 'errors.error',
            errorMessageKey: 'errors.impersonateDomainUserError',
          }
        }));
    }
  }

  public async onUpdateAddionalInfo(note: string) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const outDTO = {
        note,
      }
      await dispatch<any>(CreditsActions.updateWalletPlantAdditionalInfo(outDTO));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'domains.additionalInfoSavedSuccessfully',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_IMPERSONATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.errorSaveAdditionalInfo',
        }
      }));
    }
  }

  onNewUserClick() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_NEW_USER_DOMAIN_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewDomainUserForm
            roleId={1}
            onCreateNewUser={(user: IDomainUserOutDTO) => this.onCreateUserFormDomain(user)}
          />
        ),
        titleMessageKey: 'forms.newUser',
      }
    }));
  }

  onNewInstallerClick() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`OPERATIONAL_NEW_USER_DOMAIN_MODAL`, {
      modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
      modalProps: {
        content: (
          <NewDomainUserForm
            roleId={2}
            onCreateNewUser={(user: IDomainUserOutDTO) => this.onCreateUserFormDomain(user)}
          />
        ),
        titleMessageKey: 'forms.newInstaller',
      }
    }));
  }

  async onCreateUserFormDomain(user: IDomainUserOutDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createNewUserForPlant(user));
      await dispatch<any>(CreditsActions.fetchWalletPlantUsers());
      await dispatch<any>(CreditsActions.fetchWalletPlantInstallers());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('OPERATIONAL_NEW_USER_DOMAIN_MODAL'));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_ADD_USER`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.addUserDomainError',
        }
      }));
    }
  }

  public async onSavePlantTheme(plantId: number, plantThemeId: number, addOrRemove: boolean) {
    const { dispatch, wallet } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      if (addOrRemove)
        await dispatch<any>(VarsActions.addPlantThemeToPlant(plantId,plantThemeId));
      else
        await dispatch<any>(VarsActions.removePlantThemeFromPlant(plantId,plantThemeId));
      dispatch<any>(ModalsActions.hideModal('DOMAIN_DETAILS_MODAL'));
      await dispatch<any>(CreditsActions.fetchCustomerWalletPlants({ walletId: wallet.id, page: 0, pageSize: 50 }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`SUCCESS_MODAL`, {
        modalType: ModalTypes.SUCCESS_MODAL,
        modalProps: {
          titleMessageKey: 'vars.plantThemeModifySuccess',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_DOMAIN_CONFIG_UPDATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.updatePlantThemeError',
        }
      }));
    }
  }

  public async appenDomainUsers(page: number) {
    const { dispatch } = this.props;
    await dispatch<any>(CreditsActions.fetchWalletPlantUsers({ page }));
  }

  public onAppendWalletPlants(page: number) {
    const { dispatch, wallet } = this.props;
    const params: IWalletPlantsRequestParamsDTO = { walletId: wallet.id, page };
    dispatch<any>(CreditsActions.appendCustomerWalletPlants(params));
  }


  public render() {
    const { wallet, onOpenWalletPlantForm } = this.props;
    return (
      <div style={{ zIndex: 100, margin: 10, marginTop: 0 }}>
        <WalletPlantsList
          walletPlants={wallet.plants}
          onOpenPlantDetails={plant => this.onOpenPlantDetails(plant)}
          onFetchMoreItems={page => this.onAppendWalletPlants(page)}
          onNewWalletPlant={() => onOpenWalletPlantForm()}
          onEnableIrisRequest={(domain) => this.onEnableIrisRequest(domain)}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    wallet: state.credits.wallets.selectedWallet,
    plant: state.domains.selectedDetailedDomain
  };
}

const connector = connect(mapStateToProps);

const WalletPageWithRouter = withRouter(WalletPlantsPage);
export default connector(withStyles(styles)(WalletPageWithRouter as any));