import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import ConfigComponent from '../components/Config/ConfigComponent';
import BaseModal from '../components/Modals/BaseModal';
import SpinnerOverlay from '../components/Spinner/SpinnerOverlay';
import AppRouterWithConfig from './AppRouterWithConfig';

export interface IAppRouterProps {
  store: Store;
}
export default class AppRouter extends React.Component<IAppRouterProps> {
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <ConfigComponent>
          <AppRouterWithConfig />
          <BaseModal />
          <SpinnerOverlay />
        </ConfigComponent>
      </Provider>
    );
  }
}
