import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import MomentUtils from '@date-io/moment';
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-select';
import { IState } from '../../redux/store';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { ICustomerFormDTO } from '../../repository/jago/model/input/ICustomerFormDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import Utils, { CountryInfo, ISEOCompanyInfo } from '../../utils/Utils';
import ReduxLanguage from "../ReduxLanguage";
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  }
});

export interface ICustomerFormState {
  customer: ICustomerFormDTO;
  ISEOCompanies: ISEOCompanyInfo[];
  countries: CountryInfo[];
  errors: Record<keyof ICustomerFormDTO, boolean>;
};

export interface ICustomerFormProps extends WithStyles<typeof styles>{
  onCreateNewCustomer: (customer: ICustomerFormDTO) => void;
  customer?: ICustomerFormDTO;
  forcedVar?: IVarInDTO;
  forcedCluster?: IClusterInDTO;
  isVar: boolean;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = ICustomerFormProps & IReduxProps;
class CustomerForm extends React.Component<ComponentProps, ICustomerFormState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      ISEOCompanies: Utils.getAvailableISEOCompanies(),
      countries: Utils.getAvailableCountries(),
      customer: props.customer ? props.customer : {
        name: '',
        marketplace: null,
        vat: '',
        fiscalCode: '',
        country: '',
        address: '',
        city: '',
        crmId: '',
        email: '',
        certifiedEmail: '',
        phone: '',
        cluster: props.forcedCluster?props.forcedCluster:null,
        company: '',
        postalCode: '',
        principalSubDivision: '',
        VAR: props.forcedVar?props.forcedVar:null,
      },
      errors: {
        name: false,
        marketplace: false,
        vat: false,
        fiscalCode: false,
        country: false,
        address: false,
        city: false,
        crmId: false,
        email: false,
        certifiedEmail: false,
        phone: false,
        cluster: false,
        company: false,
        postalCode: false,
        principalSubDivision: false,
        VAR: false,
      },
    };
  }

  onChangeValue(field: keyof ICustomerFormDTO, mandatory: boolean, value?: any) {
    const { customer, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: !value && mandatory ? true : false }
    });
    (customer as any)[field] = value;
    this.setState({ customer });
  }

  onSubmitForm() {
    const { customer } = this.state;
    if (!this.canSubmitForm()) {
      return;
    }
    const { onCreateNewCustomer } = this.props;
    onCreateNewCustomer(customer);
  }

  canSubmitForm() {
    const { customer } = this.state;
    const { isVar } = this.props;
    return customer.name && customer.vat && customer.country && 
    (isVar || (!_.isEmpty(customer.marketplace) && !_.isEmpty(customer.cluster) && customer.crmId && customer.company));
  }

  public render() {
    const { classes, marketplaces, clusters, vars, customer: customerToEdit, isVar } = this.props;
    const { customer, errors, ISEOCompanies, countries } = this.state;

    const canEdit = (customerToEdit && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.CUSTOMER_UPDATE)) || 
      (!customerToEdit && AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.CUSTOMER_CREATE))

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'forms.customerFormTitle'} />}</h2>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="customerDataAccordion"
            >
              <div>
                <h2 style={{ fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.customerData'} />}</h2>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ flex: 1, marginRight: 10 }}>
                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerName'} />}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={!canEdit}
                  error={errors.name}
                  value={customer.name}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('name', true, e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerVat'} />}
                  variant="outlined"
                  fullWidth
                  required
                  disabled={!canEdit}
                  error={errors.vat}
                  value={customer.vat}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('vat', true, e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerFiscalCode'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.fiscalCode}
                  value={customer.fiscalCode}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('fiscalCode', false, e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerEmail'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.email}
                  value={customer.email}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('email', false, e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerCertifiedEmail'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.certifiedEmail}
                  value={customer.certifiedEmail}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('certifiedEmail', false, e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerPhone'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.phone}
                  value={customer.phone}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('phone', false, e.target.value)}
                />
              </div>
              <div style={{ flex: 1, marginLeft: 10 }}>
                <Select
                  options={countries}
                  isClearable
                  isDisabled={!canEdit}
                  value={_.find(countries, { value: customer.country })}
                  formatOptionLabel={(o) => <>{o.value}</>}
                  isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
                  placeholder={<><ReduxLanguage languageKey={'forms.customerCountry'} /> *</>}
                  onChange={(data) => this.onChangeValue('country', true, data?.value)}
                  required
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                    control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                  }} 
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerAddress'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.address}
                  value={customer.address}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('address', false, e.target.value)}
                />
                
                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerCity'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.city}
                  value={customer.city}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('city', false, e.target.value)}
                />
                
                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerPostalCode'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.postalCode}
                  value={customer.postalCode}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('postalCode', false, e.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'forms.customerPrincipalSubDivision'} />}
                  variant="outlined"
                  fullWidth
                  disabled={!canEdit}
                  error={errors.principalSubDivision}
                  value={customer.principalSubDivision}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('principalSubDivision', false, e.target.value)}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          
          {!isVar &&
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'forms.customerCrmId'} />}
              variant="outlined"
              fullWidth
              required
              disabled={!canEdit}
              error={errors.crmId}
              value={customer.crmId}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('crmId', true, e.target.value)}
            />
          }
          
          {!isVar &&
            <Select
              options={(marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content}
              isClearable
              getOptionLabel={o => o.name}
              value={customer.marketplace}
              isDisabled={customerToEdit ? true : false}
              isOptionSelected={(o) => customer.marketplace?.id === o.id}
              placeholder={<><ReduxLanguage languageKey={'forms.customerMarketplace'} /> *</>}
              onChange={(mktplace) => this.onChangeValue('marketplace', true, mktplace)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
              }} 
            />
          }

          {!isVar &&
            <Select
              options={(clusters as PaginatedDataDTO<IClusterInDTO>).content}
              isClearable
              formatOptionLabel={(o) => <>[{o.market}] - <b>{o.name}</b></>}
              value={customer.cluster}
              isDisabled={customerToEdit || !canEdit ? true : false}
              isOptionSelected={(o) => customer.cluster?.id === o.id}
              placeholder={<><ReduxLanguage languageKey={'forms.customerCluster'} /> *</>}
              onChange={(data) => this.onChangeValue('cluster', true, data)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
              }} 
            />
          }

          {!isVar &&
            <Select
              options={ISEOCompanies}
              isClearable
              isDisabled={!canEdit}
              value={_.find(ISEOCompanies, { name: customer.company })}
              formatOptionLabel={(o) => <>[{o.country}] - <b>{o.name}</b></>}
              isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
              placeholder={<><ReduxLanguage languageKey={'forms.customerCompany'} /> *</>}
              onChange={(data) => this.onChangeValue('company', true, data?.name)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
              }} 
            />
          }

          {!isVar &&
            <Select
              options={(vars as PaginatedDataDTO<IVarInDTO>).content}
              isClearable
              isDisabled={!canEdit}
              formatOptionLabel={(o) => <>{o.name}</>}
              value={customer.VAR}
              isOptionSelected={(o) => customer.VAR?.id === o.id}
              placeholder={<><ReduxLanguage languageKey={'forms.customerVar'} /></>}
              onChange={(data) => this.onChangeValue('VAR', true, data)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
              }} 
            />
          }

          {!canEdit?null:(
            <Button
              variant="contained"
              disabled={!this.canSubmitForm()}
              style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
              onClick={() => this.onSubmitForm()}
            >
              <ReduxLanguage languageKey="forms.save" />
            </Button>
          )}
          
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    marketplaces: state.credits.marketplaces.data,
    clusters: state.credits.clusters.data,
    vars: state.vars.vars.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(CustomerForm));