import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CancelOutlined } from '@material-ui/icons';
import React from 'react';
import Select from 'react-select';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IUserRoleInDTO } from '../../repository/jago/model/input/IUserRoleInDTO';
import { IUsersSearchDTO } from '../../repository/jago/model/input/IUsersSearchDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    paddingLeft: 0,
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IUsersSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IUsersSearchDTO) => void;
  onResetSearch: () => void;
  onUpdateSearchValues: (values: Record<keyof IUsersSearchDTO, string>) => void;
  onCreateNewEntityButtonClick: () => void;
  totalElements: string | number;
  searchValues: Record<keyof IUsersSearchDTO, string>;
  rolesOptions: IUserRoleInDTO[];
  clustersOptions: IClusterInDTO[];
  varsOptions: IVarInDTO[];
}

export interface IUsersSearchBarState {
  roleSelected: IUserRoleInDTO | undefined;
  clusterSelected: IClusterInDTO | undefined;
  varSelected: IVarInDTO | undefined;
}


class UsersSearchBar extends React.Component<IUsersSearchBarProps, IUsersSearchBarState> {
  
  constructor(props: IUsersSearchBarProps) {
    super(props);
    this.state = {
      roleSelected: undefined,
      clusterSelected: undefined,
      varSelected: undefined,
    }
  }
  public onSetSearchValue(field: keyof IUsersSearchDTO, value: string) {
    const { onUpdateSearchValues, searchValues } = this.props;
    searchValues[field] = value;
    onUpdateSearchValues(searchValues);
    this.onSearch();
  }

  public onSearch() {
    const { onSearch, searchValues } = this.props;
    onSearch(searchValues);
  }


  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({ roleSelected: undefined, clusterSelected: undefined, varSelected: undefined });
    onResetSearch();
  }

  public onSelectRole(role: IUserRoleInDTO) {
    this.onSetSearchValue('roleIds', role?.id.toString());
    this.setState({ roleSelected: role });
  }

  public onSelectCluster(cluster: IClusterInDTO) {
    this.onSetSearchValue('clusterIds', cluster?.id.toString());
    this.setState({ clusterSelected: cluster });
  }

  public onSelectVar(VAR: IVarInDTO) {
    this.onSetSearchValue('varId', VAR?.id.toString());
    this.setState({ varSelected: VAR });
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick, searchValues, rolesOptions, clustersOptions, varsOptions } = this.props;
    const { roleSelected, clusterSelected, varSelected } = this.state;
    return (
      <div className={classes.container}>
        <div style={{ marginLeft: 0, display: 'flex' }}>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey="users.search" /> }
            variant="outlined"
            fullWidth
            type="search"
            style={{ marginLeft: 10, height: 50 }}
            value={searchValues.search}
            onChange={e => this.onSetSearchValue('search', e.target.value)}
          />
          <Select
            key={`my_unique_select_key__${JSON.stringify(roleSelected?roleSelected:-1)}`}
            isClearable
            isSearchable={false}
            value={roleSelected}
            options={rolesOptions}
            placeholder={<><ReduxLanguage languageKey={'users.roleSelection'} /></>}
            formatOptionLabel={(o) => o.name}
            isOptionSelected={(o) => roleSelected?.id === o.id}
            onChange={(e) => this.onSelectRole(e)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 300, marginLeft: 10, marginRight: 10, maxWidth:300 }),
            }} 
          />
          <Select
            key={`my_unique_select_key__${JSON.stringify(clusterSelected?clusterSelected:-2)}`}
            isClearable
            isSearchable={false}
            value={clusterSelected}
            options={clustersOptions}
            placeholder={<><ReduxLanguage languageKey={'users.clusterSelection'} /></>}
            formatOptionLabel={(o) => <>{o.name}</>}
            isOptionSelected={(o) => clusterSelected?.id === o.id}
            onChange={(e) => this.onSelectCluster(e)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10, maxWidth:200 }),
            }} 
          />
          <Select
            key={`my_unique_select_key__${JSON.stringify(varSelected?varSelected:-3)}`}
            isClearable
            isSearchable={false}
            value={varSelected}
            options={varsOptions}
            placeholder={<><ReduxLanguage languageKey={'users.varSelection'} /></>}
            formatOptionLabel={(o) => <>{o.name}</>}
            isOptionSelected={(o) => varSelected?.id === o.id}
            onChange={(e) => this.onSelectVar(e)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10, maxWidth:200 }),
            }} 
          />
          <Button
            variant="contained"
            style={{ backgroundColor: 'red', height: 40, color: 'white', marginTop: 7, marginLeft: 20, fontWeight: 'bold'  }}
            onClick={() => this.onResetFields()}
          >
            <CancelOutlined style={{ color: 'white' }} />
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: '#5AC0B1', color: 'white',  marginTop: 7, height: 40, marginLeft: 20, fontSize: 13, fontWeight: 'bold'  }}
            onClick={() => onCreateNewEntityButtonClick()}
          >
            <ReduxLanguage languageKey="users.newUser" />
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(UsersSearchBar);
