import MomentUtils from '@date-io/moment';
import { Button, Card, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { IWalletSubscriptionOutDTO } from '../../repository/jago/model/output/IWalletSubscriptionOutDTO';
import SubscriptionCostDetails from '../MarketplaceSubscriptions/SubscriptionCostDetails';
import ReduxLanguage from "../ReduxLanguage";



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  creditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
});


export interface IWalletSubscriptionFromState {
  subscriptionData: IWalletSubscriptionOutDTO;
};

export interface IWalletSubscriptionFromProps extends WithStyles<typeof styles>{
  onCreateWalletSubscription: (subscription: IWalletSubscriptionOutDTO) => void;
  subscriptions: ISubscriptionInDTO[];
  blackListedSubscriptions?: ISubscriptionInDTO[];
}

class WalletSubscriptionFrom extends React.Component<IWalletSubscriptionFromProps, IWalletSubscriptionFromState> {

  constructor(props: IWalletSubscriptionFromProps) {
    super(props);
    this.state = {
      subscriptionData: {
        subscriptionId: 0,
      },
    };
  }
  
  public onSelectTier(subscriptionId: number) {
    const { subscriptionData } = this.state;
    subscriptionData.subscriptionId = subscriptionId;
    this.setState({ subscriptionData });
  }


  public canSubmitForm() {
    const { subscriptionData } = this.state;
    if (!subscriptionData.subscriptionId) {
      return false;
    }
    return true;
  }
    

  public onSubmitForm() {
    const { subscriptionData } = this.state;
    const { onCreateWalletSubscription } = this.props;
    if (this.canSubmitForm()) {
      onCreateWalletSubscription(subscriptionData);
    }
  }


  public render() {
    const { classes, subscriptions, blackListedSubscriptions } = this.props;
    const { subscriptionData } = this.state;
    const selectedSubscription = subscriptionData && subscriptionData.subscriptionId ? _.find(subscriptions, tier => tier.id === subscriptionData.subscriptionId) : null;
    
    let filteredSubscriptions = subscriptions;
    if (blackListedSubscriptions) {
      filteredSubscriptions = _.differenceBy(subscriptions, blackListedSubscriptions, 'id');
    }

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <input autoComplete="new-password" style={{ display: 'none' }} />
          <h4 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3, fontWeight: 'bold' }}>{<ReduxLanguage languageKey={'forms.walletSelectSubscriptionTitle'} />}<span style={{ color: 'red' }}>*</span></h4>
          <h5 style={{ margin: 0, marginBottom: 10 }}>{<ReduxLanguage languageKey={'forms.walletSelectSubscriptionDescription'} />}</h5>
          <Autocomplete
            id="selectTier"
            options={filteredSubscriptions}
            getOptionLabel={(option) => `${option.name} (SKU: ${option.sku})`}
            onChange={(e, newOption) => {
              const subscriptionId = typeof newOption !== "string" && newOption.id ? newOption.id : 0;
              this.onSelectTier(subscriptionId);
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
          />
          {selectedSubscription ? (
            <div style={{ marginTop: 10 }}>
              <Card style={{ padding: 5 }}>
                <SubscriptionCostDetails
                  subscription={selectedSubscription}
                />
              </Card>
            </div>
          ) : null}
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(WalletSubscriptionFrom);