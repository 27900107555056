import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formTitle: {
    marginBottom: 20,
    color: 'gray'
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 25,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IOTPLoginFormState {
  email?: string;
  code?: string;
  emailError: boolean;
  codeError: boolean;
};

export interface IOTPLoginFormProps extends WithStyles<typeof styles>{
  onSubmitEmail: (email: string) => void;
  onSubmitCode: (code: string) => void;
  onResetOtpLoginPhase: () => void;
  otpLoginPhase: string;
}

class OTPEmailLoginForm extends React.Component<IOTPLoginFormProps, IOTPLoginFormState> {

  constructor(props: IOTPLoginFormProps) {
    super(props);
    this.state = {
      email: '',
      code: '',
      emailError: false,
      codeError: false,
    };
  }
  
  public onChangeEmail(email: string) {
    this.setState({ emailError: !this.isEmailValid(email), email });
  }

  isEmailValid(email?: string): boolean {
    if (!email) {
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  canSubmitEmailForm() {
    const { emailError, email } = this.state;
    return !emailError && email;
  }

  canSubmitCodeForm() {
    const { codeError, code } = this.state;
    return !codeError && code;
  }

  onSubmitEmail() {
    const { onSubmitEmail } = this.props;
    const { email } = this.state;
    if (email) {
      onSubmitEmail(email);
    }
  }

  public onChangeCode(code: string) {
    this.setState({ codeError: !code, code });
  }

  onSubmitCode() {
    const { onSubmitCode } = this.props;
    const { code } = this.state;
    if (code) {
      onSubmitCode(code);
    }
  }

  render() {
    const { classes, otpLoginPhase, onResetOtpLoginPhase } = this.props;
    const { email, emailError, code, codeError } = this.state;
    const canSubmitEmailForm = this.canSubmitEmailForm();
    const canSubmitCodeForm = this.canSubmitCodeForm();
    return (
      <div className={classes.container}>
        {otpLoginPhase === 'START' ? (
          <>
            <h3 className={classes.formTitle}>
              <ReduxLanguage languageKey={'forms.insertEmailOTPLoginForm'} /><br />
            </h3>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'forms.email'} />}
              variant="outlined"
              fullWidth
              required
              error={emailError}
              value={email}
              onChange={e => this.onChangeEmail(e.target.value)}
            />
            <Button
              variant="contained"
              disabled={!canSubmitEmailForm}
              style={{ width: '100%', opacity: canSubmitEmailForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 25, fontWeight: 'bold', height: 45  }}
              onClick={() => this.onSubmitEmail()}
            >
              <ReduxLanguage languageKey="login.sendEmail" />
            </Button>
        </>
        ) : (
          <div>
            <h3 className={classes.formTitle} style={{ marginBottom: 0 }}>
              <ReduxLanguage languageKey={'forms.insertOTPOTPLoginForm'} />.
            </h3>
            <h3 className={classes.formTitle}>
              <ReduxLanguage languageKey={'forms.insertOTPOTPLoginFormDisclaimer'}/>
            </h3>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'forms.otp'} />}
              variant="outlined"
              fullWidth
              required
              error={codeError}
              value={code}
              onChange={e => this.onChangeCode(e.target.value)}
            />
            <Button
              variant="contained"
              disabled={!canSubmitCodeForm}
              style={{ width: '100%', opacity: canSubmitCodeForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 25, fontWeight: 'bold', height: 45  }}
              onClick={() => this.onSubmitCode()}
            >
              <ReduxLanguage languageKey="login.login" />
            </Button>
            <Button
              variant="contained"
              style={{ width: '100%', backgroundColor: '#888888', color: 'white', marginTop: 20, fontWeight: 'bold', height: 45  }}
              onClick={() => onResetOtpLoginPhase()}
            >
              <ReduxLanguage languageKey="login.changeEmail" />
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(OTPEmailLoginForm);
