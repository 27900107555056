enum UserActionTypesEnum {
  SAVE_USER_DATA = 'SAVE_USER_DATA',
  SET_USER_AUTHENTICATION = 'SET_USER_AUTHENTICATION',
  SAVE_USERS = 'SAVE_USERS',
  SAVE_USERS_ROLES = 'SAVE_USERS_ROLES',
  SET_SELECTED_USER = 'SET_SELECTED_USER',
  RESET_USER_UDATA = 'RESET_USER_UDATA',
}

export default UserActionTypesEnum;
