import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { PaginationRestDTO } from '@bottega52/commons-pagination';
import { IVarInDTO } from './model/input/IVarInDTO';
import { IVarOutDTO } from './model/output/IVarOutDTO';
import { IPlantThemeInDTO } from './model/input/IPlantThemeInDTO';
import { IPlantThemeOutDTO } from './model/output/IPlantThemeOutDTO';
import { IUserInDTO } from './model/input/IUserInDTO';
import { IUserOutDTO } from './model/output/IUserOutDTO';
import { IVarOpportunityOutDTO } from './model/output/IVarOpportunityOutDTO';
import { IVarOpportunityInDTO } from './model/input/IVarOpportunityInDTO';

export function fetchVars(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IVarInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/var/admin', config);
}

export function editVarAdmin(varId: number, data: IVarOutDTO): Promise<AxiosResponse<IVarInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${varId}/admin`, data);
}

export function editVar(varId: number, data: IVarOutDTO): Promise<AxiosResponse<IVarInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var`, data);
}

export function createNewVar(data: IVarOutDTO): Promise<AxiosResponse<IVarInDTO>> {
  return JagoAxiosInstance.post('api/v2/var/admin', data);
}

export function fetchVar(): Promise<AxiosResponse<IVarInDTO>> {
  const config: AxiosRequestConfig = {
    
  };
  return JagoAxiosInstance.get('api/v2/var', config);
}

export function fetchPlantThemes(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IPlantThemeInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/var/plantTheme', config);
}

export function editPlantTheme(plantThemeId: number, data: IPlantThemeOutDTO): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/plantTheme/${plantThemeId}`, data);
}

export function createNewPlantTheme(data: IPlantThemeOutDTO): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.post('api/v2/var/plantTheme', data);
}

export function deletePlantTheme(plantThemeId: number): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/var/plantTheme/${plantThemeId}`);
}

export function addPlantThemeToPlant(plantId: number, plantThemeId: number): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/plantTheme/${plantThemeId}/plant/${plantId}`);
}

export function removePlantThemeFromPlant(plantId: number, plantThemeId: number): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/var/plantTheme/${plantThemeId}/plant/${plantId}`);
}

export function fetchVarUsers(varId: number, params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IUserInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/var/${varId}/users`, config);
}

export function createNewVarUser(varId: number, data: IUserOutDTO): Promise<AxiosResponse<IUserInDTO>> {
  return JagoAxiosInstance.post(`api/v2/var/${varId}/users`, data);
}

export function editVarUser(varId: number, varUserId: number, data: IUserOutDTO): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${varId}/users/${varUserId}`, data);
}

export function deleteVarUser(varId: number, varUserId: number): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/var/${varId}/users/${varUserId}`)
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        const payload = error.response.data;
        return Promise.resolve({
          data: payload,
          status: 400,
          statusText: "Bad Request",
          headers: error.response.headers,
          config: error.config,
        });
      }
      throw error;
    });
}

export function fetchOpportunities(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IVarOpportunityInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/var/opportunities`, config);
}

export function createVarOpportunity(customerId: number, data: IVarOpportunityOutDTO): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.post(`api/v2/var/${customerId}/opportunities`, data);
}

export function editVarOpportunity(customerId: number, opportunityId: number, data: IVarOpportunityOutDTO): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}`, data);
}

export function editVarOpportunityAdmin(customerId: number, opportunityId: number, data: IVarOpportunityOutDTO): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/admin`, data);
}

export function upgradeVarOpportunity(customerId: number, opportunityId: number): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/upgrade`, {});
}

export function approveVarOpportunity(customerId: number, opportunityId: number): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/approve`, {});
}

export function rejectVarOpportunity(customerId: number, opportunityId: number): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/reject`, {});
}

export function syncSfdcidVarOpportunity(customerId: number, opportunityId: number): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/sfdcId`, {});
}

export function deleteVarOpportunity(customerId: number, opportunityId: number): Promise<AxiosResponse<IPlantThemeInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/var/${customerId}/opportunities/${opportunityId}`);
}

export function concludeVarOpportunity(customerId: number, opportunityId: number, status: string): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/conclude?status=${status}`);
}

export function changeVarOpportunityOwner(customerId: number, opportunityId: number, varUserId: number): Promise<AxiosResponse<IVarOpportunityInDTO>> {
  return JagoAxiosInstance.put(`api/v2/var/${customerId}/opportunities/${opportunityId}/varUser`, {userId:varUserId});
}

export function exportOpportunitiesCSV(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<Blob>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/var/opportunities/export`, { responseType: 'blob' });
}