import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import translations from '../../translations/i18next';
import ReduxLanguage from '../ReduxLanguage';
import { IVarUsersSearchDTO } from '../../repository/jago/model/input/IVarUsersSearchDTO';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems:'center',
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IActivationStatusOption {
  label: string;
  value: string;
  original: string;
}
export interface IActivationClusterOption {
  label: string;
  value: string;
}

export interface IVarUsersSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IVarUsersSearchDTO) => void;
  onResetSearch: () => void;
  onCreateNewEntityButtonClick: () => void;
}

export interface IVarUsersSearchBarState {
  searchValues: Record<keyof IVarUsersSearchDTO, string>;
}

class VarUsersSearchBar extends React.Component<IVarUsersSearchBarProps, IVarUsersSearchBarState> {

  constructor(props: IVarUsersSearchBarProps) {
    super(props);
    this.state = {
      searchValues: {
        search: ""
      },
    }
  }

  public onSetSearchValue(field: keyof IVarUsersSearchDTO, value: string) {
    const { searchValues } = this.state;
    searchValues[field] = value;
    this.setState({ searchValues });
    this.onSearch()
  }

  public onSearch() {
    const { onSearch } = this.props;
    onSearch(this.state.searchValues);
  }

  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({
        searchValues:{
          search: ""
        },
    });
    onResetSearch();
  }

  public render() {
    const { classes, onCreateNewEntityButtonClick } = this.props;
    const { searchValues } = this.state;
    return (
      <div className={classes.container}>
        <TextField
          id="outlined-basic"
          label={<ReduxLanguage languageKey="forms.search" /> }
          variant="outlined"
          type="search"
          style={{ marginLeft: 0, marginRight: 10 }}
          value={searchValues.search}
          onChange={e => this.onSetSearchValue('search', e.target.value)}
        />
        <span style={{flexGrow:'1'}}></span>
        <Button
          variant="contained"
          style={{ backgroundColor: '#5AC0B1', color: 'white', marginRight: 20, fontWeight: 'bold'  }}
          onClick={() => onCreateNewEntityButtonClick()}
        >
          <ReduxLanguage languageKey="forms.add" />
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(VarUsersSearchBar);
