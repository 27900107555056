import { PaginatedDataDTO, PaginationDTO, PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import 'moment/locale/it';
import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import CardForm from '../../components/Forms/CardForm';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import RechargeCardsTable from '../../components/Parameters/RechargeCardsTable';
import CardSearchBar from '../../components/SearchBar/CardSearchBar';
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import * as ActivationActions from '../../redux/activations/activations.actions';
import { IState } from '../../redux/store';
import * as VarsActions from '../../redux/vars/vars.actions';
import { ICardInDTO } from '../../repository/jago/model/input/ICardInDTO';
import { ICardSearchDTO } from '../../repository/jago/model/input/ICardSearchDTO';
import { ICardOutDTO, IEditCardOutDTO } from '../../repository/jago/model/output/ICardOutDTO';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { VAR_USERS_ROLES } from '../../utils/Utils';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0,
  },
  titleText: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: 'white',
    width: '100%',
    zIndex: 200,
    padding: 10,
    paddingRight: 20,
    borderBottom: '2px solid #5AC0B1',
  }
});

export interface IRechargeCardsPageState {
  pageSize: number;
  isLoading: boolean;
  filters: ICardSearchDTO;
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface IRechargeCardsPageProps extends WithStyles<typeof styles> {
  language: string;
  systemCards: PaginatedDataDTO<ICardInDTO>;
}

export type ComponentProps = IRechargeCardsPageProps & IReduxProps;

class RechargeCardsPage extends React.Component<ComponentProps, IRechargeCardsPageState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      pageSize: 100,
      isLoading: false,
      filters: {
        crmId: null,
        sofiaProjectId: '',
        status: '',
        used: '',
        invoiceId: '',
      }
    }
  }


  async componentDidMount() {
    const { dispatch } = this.props;
    const { pageSize } = this.state; 
    try {
      this.setState({ isLoading: true });
      const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))
      await dispatch<any>(CreditsActions.fetchSystemCards({ page: 0, pageSize }));
      if (!isVar) {
        await dispatch<any>(CreditsActions.fetchWalletsAll());
        this.setState({ isLoading: false });
        try {
          dispatch<any>(VarsActions.fetchVars({page:0, pageSize:500}));
          this.setState({ isLoading: false });
        }
        catch (error) {
          this.setState({ isLoading: false });
        }
      }
      if (AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.KAM_READ)) {
        /* TODO DOPO FIX KAMS
        await dispatch<any>(UserActions.fetchUsersRoles)
        const { usersRoles } = this.props;
        const usersRolesData = (usersRoles as PaginatedDataDTO<IUserRoleInDTO>).content
        const kamRole = _.find(usersRolesData, e=>e.name===Roles.ROLE_VASHO_KAM);
        if (kamRole && kamRole.id) {
          const params: IUserRequestParamsDTO = { 
            roleIds: kamRole.id.toString(),
            page: 0,
            pageSize: 1000,
          };
          await dispatch<any>(UserActions.fetchUsers(params))
        }
        */
        await dispatch<any>(ActivationActions.fetchKams({ page: 0, pageSize: 500 }));
      }
      else {
        /* TODO DOPO FIX KAMS
        await dispatch<any>(UserActions.UserActions.saveUsers({content: [], pagination: {}}));
        */
        await dispatch<any>(ActivationActions.ActivationsActions.saveKams({content: [], pagination: {}}));
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      dispatch<any>(ModalsActions.showModal(`ERROR_OPEN_PARAMETERS`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.openSystemParametersError',
        }
      }));
    }
  }

  async onFetchCards(page: number = 0, pageSize: number = 100) {
    const { dispatch } = this.props;
    const { filters } = this.state;
    const formattedFilters = _.omitBy(filters, filter => filter === '' || filter === null);
    const params: PaginationQueryParamsDTO = { page, pageSize, ...formattedFilters };
    try {
      this.setState({ isLoading: true });
      await dispatch<any>(CreditsActions.fetchSystemCards(params));
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      throw error;
    }
  }

  async onOpenCardForm(cardToEdit?: ICardInDTO) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const marketplacesAvailable = await dispatch<any>(CreditsActions.fetchMarketplaces());
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`CARD_FORM_VIEW`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <CardForm
              card={cardToEdit}
              onSubmit={c => cardToEdit ? this.onEditCardRequest(cardToEdit.id, c) : this.onCreateCardRequest(c)}
              marketplaces={marketplacesAvailable.content}
            />
          ),
          titleMessageKey: cardToEdit ?  'credits.editCard' : 'credits.newCard',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }

  onCreateCardRequest(card: ICardOutDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_CARD_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newCard',
        successMessageKey: 'forms.createCardConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateCardConfirm(card),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_CARD_CONFIRM_MODAL`)),
      }
    }));
  }

  async onCreateCardConfirm(card: ICardOutDTO) {
    const { dispatch, systemCards } = this.props;
    const { pageSize } = this.state;
    const pagination = systemCards.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createSystemCard(card));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('CARD_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_CARD_CONFIRM_MODAL'));
      this.onFetchCards(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCardError',
        }
      }));
    }
  }

  onEditCardRequest(cardId: number, card: IEditCardOutDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`EDIT_CARD_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.editCard',
        successMessageKey: 'forms.editCardConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEditCardConfirm(cardId, card),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`EDIT_CARD_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEditCardConfirm(cardId: number, card: IEditCardOutDTO) {
    const { dispatch, systemCards } = this.props;
    const { pageSize } = this.state;
    const pagination = systemCards.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.editSystemCard(cardId, card));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('CARD_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('EDIT_CARD_CONFIRM_MODAL'));
      this.onFetchCards(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createCardError',
        }
      }));
    }
  }

  onEnableCardRequest(card: ICardInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`ENABLE_CARD_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'forms.enableCardConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onEnableCardConfirm(card),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`ENABLE_CARD_CONFIRM_MODAL`)),
      }
    }));
  }

  async onEnableCardConfirm(card: ICardInDTO) {
    const { dispatch, systemCards } = this.props;
    const { pageSize } = this.state;
    const pagination = systemCards.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.enableSystemCard(card.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('ENABLE_CARD_CONFIRM_MODAL'));
      this.onFetchCards(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CARD_ENABLE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.enableCardError',
        }
      }));
    }
  }

  onDeleteCardRequest(card: ICardInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_CARD_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'forms.warning',
        successMessageKey: 'forms.deleteCardConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onDeleteCardConfirm(card),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`DELETE_CARD_CONFIRM_MODAL`)),
      }
    }));
  }

  async onDeleteCardConfirm(card: ICardInDTO) {
    const { dispatch, systemCards } = this.props;
    const { pageSize } = this.state;
    const pagination = systemCards.pagination as PaginationDTO;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.deleteSystemCard(card.id));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('DELETE_CARD_CONFIRM_MODAL'));
      this.onFetchCards(pagination.number, pageSize);
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_CARD_DELETE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteCardError',
        }
      }));
    }
  }

  onSearchCards(searchValues: ICardSearchDTO) {
    const { pageSize } = this.state;
    const formattedFilters = _.omitBy(searchValues, filter => filter === '' || filter === null);
    this.setState({ filters: { ...formattedFilters } }, async () => {
      this.onFetchCards(0, pageSize);
    });
    
  }

  onResetSearch() {
    const { pageSize } = this.state;
    this.setState({
      filters: {
        crmId: null,
        sofiaProjectId: '',
        status: '',
        used: '',
        invoiceId: '',
      },
    }, async () => {
      await this.onFetchCards(0, pageSize);
    })
  }

  onChangePageSize(pageSize: number) {
    this.setState({ pageSize }, () => {
      this.onFetchCards(0, pageSize);
    });
  }
  
  async onFetchItemsPerPage(page: number) {
    const { pageSize } = this.state;
    this.onFetchCards(page, pageSize);
  }

  render() {
    const {  systemCards, classes } = this.props;
    const { pageSize } = this.state;
    const cardsPagination = !_.isEmpty(systemCards.pagination) ? (systemCards.pagination as PaginationDTO) : null;
    return (
      <div>
        <div className={classes.header}>
          <CardSearchBar
            totalElements={cardsPagination  ? cardsPagination.totalElements : ''}
            onCreateNewEntityButtonClick={() => this.onOpenCardForm()}
            onSearch={(searchValues: ICardSearchDTO) => this.onSearchCards(searchValues)}
            onResetSearch={() => this.onResetSearch()}
          />
        </div>
        <RechargeCardsTable
          cards={systemCards}
          rowsPerPage={pageSize}
          isLoading={this.state.isLoading}
          onFetchItemsPerPage={page => this.onFetchItemsPerPage(page)}
          onChangePageSize={pageSize => this.onChangePageSize(pageSize)}
          onEditCard={(card) => this.onOpenCardForm(card)}
          onDeleteCardRequest={card => this.onDeleteCardRequest(card)}
          onEnableCardRequest={card => this.onEnableCardRequest(card)}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    systemCards: state.credits.systemCards.data,
    selectedMarketplace: state.credits.marketplaces.selectedMarketplace,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(RechargeCardsPage as any));