import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { IActivationInDTO } from './model/input/IActivationInDTO';
import { PaginationRestDTO } from '@bottega52/commons-pagination';
import { IActivationOutDTO } from './model/output/IActivationOutDTO';
import { IKamInDTO } from './model/input/IKamInDTO';

export function fetchActivations(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IActivationInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/plantRequest', config);
}

export function fetchActivation(activationId: number): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.get(`api/v2/plantRequest/${activationId}`);
}

export function deleteActivation(activationId: number): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/plantRequest/${activationId}`);
}

export function deleteActivationAdmin(activationId: number): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/plantRequest/${activationId}/admin`);
}

export function promoteActivation(activationId: number): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.put(`api/v2/plantRequest/${activationId}/promote`);
}

export function invoicedActivation(activationId: number): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.put(`api/v2/plantRequest/${activationId}/invoiced`);
}

export function promoteActivationAdmin(activationId: number, newStatus: string): Promise<AxiosResponse<IActivationInDTO>> {
  const config: AxiosRequestConfig = {
    params: {
        status: newStatus,
    },
  };
  return JagoAxiosInstance.put(`api/v2/plantRequest/${activationId}/admin/promote`,null,config);
}

export function createNewActivation(data: IActivationOutDTO): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.post('api/v2/plantRequest', data);
}

export function editActivation(activationId: number, data: IActivationOutDTO): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.put(`api/v2/plantRequest/${activationId}`, data);
}

export function editActivationAdmin(activationId: number, data: IActivationOutDTO): Promise<AxiosResponse<IActivationInDTO>> {
  return JagoAxiosInstance.put(`api/v2/plantRequest/${activationId}/admin`, data);
}

export function exportActivationsCSV(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<Blob>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/plantRequest/export`, { responseType: 'blob' });
}

export function fetchKams(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IKamInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/credits/users/kams', config);
}