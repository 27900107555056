import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CancelOutlined, Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import { IDomainOptionDTO } from '../../repository/jago/model/input/IDomainOptionDTO';
import { IGatewayDeviceTypeEnum } from '../../repository/jago/model/input/IGatewayInDTO';
import { IGatewayTypeOptionDTO } from '../../repository/jago/model/input/IGatewayTypeOptionDTO';
import { IGatewaysSearchDTO } from '../../repository/jago/model/input/IGatewaysSearchDTO';
import { getDomainNameFromHostname } from '../../repository/utils';
import { BLUE } from '../../style/StyleConsts';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center'
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    marginLeft: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});


export interface IGatewaysSearchBarProps extends WithStyles<typeof styles> {
  onSearch: (searchValues: IGatewaysSearchDTO) => void;
  onResetSearch: () => void;
  onFilterDomains: (value: string) => void;
  onUpdateSearchValues: (values: Record<keyof IGatewaysSearchDTO, string>) => void;
  totalElements: string | number;
  searchValues: Record<keyof IGatewaysSearchDTO, string>;
  domainsOptions: IDomainOptionDTO[];
  preselectedHostname?: string;
}

export interface IGatewaysSearchBarState {
  domainSelected: IDomainOptionDTO | undefined;
  deviceTypeSelected: IGatewayTypeOptionDTO | undefined;
  searchText: string;
}


class GatewaysSearchBar extends React.Component<IGatewaysSearchBarProps, IGatewaysSearchBarState> {
  
  constructor(props: IGatewaysSearchBarProps) {
    super(props);
    this.state = {
      domainSelected: undefined,
      deviceTypeSelected: undefined,
      searchText: '',
    }
  }
  public onSetSearchValue(field: keyof IGatewaysSearchDTO, value: string) {
    const { onUpdateSearchValues, searchValues } = this.props;
    searchValues[field] = value;
    onUpdateSearchValues(searchValues)
  }

  public onSearch() {
    const { onSearch, searchValues } = this.props;
    onSearch(searchValues);
  }


  public onResetFields() {
    const { onResetSearch } = this.props;
    this.setState({ domainSelected: undefined, searchText: '', deviceTypeSelected: undefined });
    onResetSearch();
  }

  public onSelectDomain(domain: IDomainOptionDTO) {
    if (domain) {
      this.onSetSearchValue('hostname', domain.value.hostname);
      this.setState({ domainSelected: domain });
    } else {
      this.onSetSearchValue('hostname', '');
      this.setState({ domainSelected: undefined });
    }
  }

  public onSelectDeviceType(deviceType: IGatewayTypeOptionDTO) {
    this.onSetSearchValue('deviceType', deviceType && deviceType.value ? deviceType.value : '');
    this.setState({ deviceTypeSelected: deviceType });
  } 

  onFetchValues(value: string) {
    const { onFilterDomains } = this.props;
    onFilterDomains(value)
  }

  public render() {
    const { classes, totalElements, searchValues, preselectedHostname, domainsOptions } = this.props;
    const { domainSelected, searchText, deviceTypeSelected } = this.state;
    const deviceTypeOptions = _.map(IGatewayDeviceTypeEnum, deviceType => ({
      label: <ReduxLanguage languageKey={`gateways.${deviceType}`} />,
      value: deviceType
    }));
    const debouncedSearch = _.debounce((text) => this.onFetchValues(text), 500);
    return (
      <div className={classes.container}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginLeft: 0, display: 'flex' }}>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey="gateways.gatewayName" /> }
              variant="outlined"
              fullWidth
              type="search"
              style={{ marginRight: 10, height: 50 }}
              value={searchValues.name}
              onChange={e => this.onSetSearchValue('name', e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey="gateways.serialNumber" /> }
              variant="outlined"
              fullWidth
              type="search"
              style={{ marginRight: 10, height: 50 }}
              value={searchValues.deviceId}
              onChange={e => this.onSetSearchValue('deviceId', e.target.value)}
            />
          </div>
          <div style={{ marginLeft: 0, display: 'flex', marginTop: 20 }}>
            <TextField
              id="outlined-basic"
              label={<ReduxLanguage languageKey="gateways.gatewayVersion" /> }
              variant="outlined"
              fullWidth
              type="search"
              style={{ marginRight: 10, height: 50 }}
              value={searchValues.swVersion}
              onChange={e => this.onSetSearchValue('swVersion', e.target.value)}
            />
            <Select
              isClearable
              key={`my_unique_select_key__${deviceTypeSelected}`}
              value={deviceTypeSelected}
              options={deviceTypeOptions}
              placeholder={<ReduxLanguage languageKey={'forms.deviceType'} />}
              onChange={(e) => this.onSelectDeviceType(e)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55, minWidth: 200, marginLeft: 10, marginRight: 10 }),
              }} 
            />
            <Autocomplete
              options={domainsOptions}
              isClearable
              autoComplete
              inputValue={domainSelected ? domainSelected.label : (searchText || getDomainNameFromHostname(preselectedHostname))}
              value={domainSelected}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} variant="outlined" style={{ width: 250 }} label={<ReduxLanguage languageKey="forms.domainName" />}/>}
              placeholder={<ReduxLanguage languageKey={'forms.selectProduct'} />}
              onInputChange={(event, newInputValue) => { this.setState({ searchText: newInputValue }); debouncedSearch(newInputValue); }}
              onChange={(e, newValue) => this.onSelectDomain(newValue)}
              styles={{
                menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                control: (styles) => ({ ...styles, minHeight: 55 }),
              }} 
            />
          </div>
        </div>
          <Button
            variant="contained"
            style={{ backgroundColor: BLUE, color: 'white', marginLeft: 20, fontWeight: 'bold', height: 45  }}
            onClick={() => this.onSearch()}
          >
            <Search style={{ color: 'white' }} />
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: 'red', color: 'white', marginLeft: 20, fontWeight: 'bold', height: 45}}
            onClick={() => this.onResetFields()}
          >
            <CancelOutlined style={{ color: 'white' }} />
          </Button>
        </div>
    );
  }
}

export default withStyles(styles)(GatewaysSearchBar);
