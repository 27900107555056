enum CreditsActionTypesEnum {
  SAVE_CLUSTERS = 'SAVE_CLUSTERS',
  SAVE_MARKETPLACES = 'SAVE_MARKETPLACES',
  SET_SELECTED_MARKETPLACE = 'SET_SELECTED_MARKETPLACE',
  SAVE_CUSTOMERS = 'SAVE_CUSTOMERS',
  SAVE_VAR_CUSTOMERS = 'SAVE_VAR_CUSTOMERS',
  SET_SELECTED_DETAILED_CUSTOMER = 'SET_SELECTED_DETAILED_CUSTOMER',
  SAVE_WALLETS = 'SAVE_WALLETS',
  APPEND_WALLET = 'APPEND_WALLET',
  UPDATE_WALLET = 'UPDATE_WALLET',
  SET_SELECTED_WALLET = 'SET_SELECTED_WALLET',
  SAVE_MARKETPLACE_SUBSCRIPTIONS = 'SAVE_MARKETPLACE_SUBSCRIPTIONS',
  SET_SELECTED_MARKETPLACE_MAIN_TIER = 'SET_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTION',
  SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS = 'SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS',
  SAVE_SYSTEM_CARDS = 'SAVE_SYSTEM_CARDS',
  SET_SELECTED_WALLET_PLANT = 'SET_SELECTED_WALLET_PLANT',
  SAVE_WALLETS_ALL = 'SAVE_WALLETS_ALL',
  SAVE_CUSTOMERS_SEARCH = 'SAVE_CUSTOMERS_SEARCH',
  SAVE_CUSTOMER_PLANTS = 'SAVE_CUSTOMER_PLANTS',
  SAVE_ALL_DOMAINS = 'SAVE_ALL_DOMAINS',
}

export default CreditsActionTypesEnum;
