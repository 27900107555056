enum VarsActionTypesEnum {
  SAVE_VARS = 'SAVE_VARS',
  SET_SELECTED_VAR = 'SET_SELECTED_VAR',
  SAVE_VAR_CUSTOMERS = 'SAVE_VAR_CUSTOMERS',
  SAVE_VAR = 'SAVE_VAR',
  SAVE_PLANT_THEMES = 'SAVE_PLANT_THEMES',
  SET_SELECTED_PLANT_THEME = 'SET_SELECTED_PLANT_THEME',
  SAVE_THEME_PLANTS = 'SAVE_THEME_PLANTS',
  SAVE_VAR_USERS = 'SAVE_VAR_USERS',
  SAVE_VAR_OPPORTUNITIES = 'SAVE_VAR_OPPORTUNITIES',
  SAVE_CUSTOMERS_SEARCH = 'SAVE_CUSTOMERS_SEARCH',
}

export default VarsActionTypesEnum;
