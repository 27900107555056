
import { PaginatedDataDTO, PaginationDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import { IVarOpportunityInDTO } from '../repository/jago/model/input/IVarOpportunityInDTO';
import { IVarOpportunityFormDTO } from '../repository/jago/model/input/IVarOpportunityFormDTO';
import { IVarOpportunityOutDTO } from '../repository/jago/model/output/IVarOpportunityOutDTO';

export function decode(data: PaginationRestDTO<IVarOpportunityInDTO>): PaginatedDataDTO<IVarOpportunityInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}

export enum VarOpportunityStatusOptions {
  DRAFT = 'DRAFT',
  ON_APPROVAL = 'ON_APPROVAL',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  WON = 'WON',
  LOST = 'LOST',
}

export interface IVarOpportunityFile {
  name: string;
  contentBase64?: string;
  url?: string;
}

export function decodeVarOpportunityContentToForm(data: IVarOpportunityInDTO): IVarOpportunityFormDTO {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    notes: data.notes,
    value: data.value,
    currency: data.currency,
    iseoCrmId: data.iseoCrmId,
    status: data.status,
    varId: data.varId,
    varUserId: data.varUserId,
    varUserEmail: data.varUserEmail,
    customerId: data.customerId,
    customerName: data.customerName,
    varName: data.varName,
    documents: data.documents?Object.keys(data.documents).map((key: string) => {return {name:key, url:data.documents[key]}}):[],
    documentsOUT: {},
    segmentType: data.segmentType,
    history: data.history,
  };
}

export function encodeVarOpportunityFromForm(data: IVarOpportunityFormDTO, isVar: boolean): IVarOpportunityOutDTO {
    let retVal: IVarOpportunityOutDTO = {
      name: data.name,
      description: data.description,
      notes: isVar?undefined:data.notes,
      value: data.value,
      currency: data.currency,
      iseoCrmId: isVar?undefined:data.iseoCrmId,
      status: isVar?undefined:data.status,
      documents:data.documentsOUT,
      segmentType: data.segmentType,
    };
    return retVal;
}

export function encodeVarOpportunityInFromOut(data: IVarOpportunityInDTO): IVarOpportunityOutDTO {
    return {
      name: data.name,
      description: data.description?data.description:"",
      notes: data.notes?data.notes:"",
      value: data.value,
      currency: data.currency,
      iseoCrmId: data.iseoCrmId?data.iseoCrmId:"",
      status: data.status,
      documents:{},
      segmentType: data.segmentType,
    };
}