import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import _ from 'lodash';
import * as GatewaysDecoder from '../../codec/gatewaysInDTODecoder';
import * as GatewaysAPI from '../../repository/jago/gatewaysAPI';
import { IGatewayDetailedDTO } from '../../repository/jago/model/input/IGatewayDetailedDTO';
import IGatewayInDTO from '../../repository/jago/model/input/IGatewayInDTO';
import { IGatewaysArtifactsRequestParamsDTO, IGatewaysDeploymentsRequestParamsDTO, IGatewaysRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { IState } from '../store';
import { ActionsUnion, IThunkAction, createAction } from '../utils';
import GatewaysActionTypesEnum from './model/GatewaysActionTypesEnum';
import { IGatewayArtifactInDTO } from '../../repository/jago/model/input/IGatewayArtifactInDTO';
import { IGatewayDeploymentInDTO } from '../../repository/jago/model/input/IGatewayDeploymentInDTO';
import { IGatewayDeploymentOutDTO } from '../../repository/jago/model/output/IGatewayDeploymentOutDTO';
import { IGatewayDeploymentDetailedInDTO } from '../../repository/jago/model/input/IGatewayDeploymentDetailedInDTO';

export const GatewaysActions = {
  saveGateways: createAction<typeof GatewaysActionTypesEnum.SAVE_GATEWAYS, PaginatedDataDTO<IGatewayInDTO>>(GatewaysActionTypesEnum.SAVE_GATEWAYS),
  saveGatewaysArtifacts: createAction<typeof GatewaysActionTypesEnum.SAVE_GATEWAYS_ARTIFACTS, PaginatedDataDTO<IGatewayArtifactInDTO>>(GatewaysActionTypesEnum.SAVE_GATEWAYS_ARTIFACTS),
  saveGatewaysDeployments: createAction<typeof GatewaysActionTypesEnum.SAVE_GATEWAYS_DEPLOYMENTS, PaginatedDataDTO<IGatewayDeploymentInDTO>>(GatewaysActionTypesEnum.SAVE_GATEWAYS_DEPLOYMENTS),
  setSelectedDetailedGateway: createAction<typeof GatewaysActionTypesEnum.SET_SELECTED_DETAILED_GATEWAY, IGatewayDetailedDTO | {}>(GatewaysActionTypesEnum.SET_SELECTED_DETAILED_GATEWAY),
  setSelectedDetailedDeployment: createAction<typeof GatewaysActionTypesEnum.SET_SELECTED_DETAILED_DEPLOYMENT, IGatewayDeploymentDetailedInDTO | {}>(GatewaysActionTypesEnum.SET_SELECTED_DETAILED_DEPLOYMENT),
};


export function fetchSofiaGateways(params?: IGatewaysRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const gatewaysResponse = await GatewaysAPI.fetchGateways(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decode(gatewaysResponse.data);
        dispatch(GatewaysActions.saveGateways(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendSofiaGateways(params?: IGatewaysRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { gateways } = getState().gateways;
      if (_.isEmpty(gateways)) return;
      const stateGateways = gateways as PaginatedDataDTO<IGatewayInDTO>;
      const gatewaysResponse = await GatewaysAPI.fetchGateways(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decode(gatewaysResponse.data);
        const gatewaysToSave : PaginatedDataDTO<IGatewayInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateGateways.content,
            ...decodedData.content
          ],
        }
        dispatch(GatewaysActions.saveGateways(gatewaysToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaGatewaysSearch(params?: IGatewaysRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const gatewaysResponse = await GatewaysAPI.fetchGateways(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decode(gatewaysResponse.data);
        return decodedData.content;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaArtifactsSearch(params?: IGatewaysArtifactsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const artifactsResponse = await GatewaysAPI.fetchGatewaysArtifats(params);
      if (artifactsResponse && artifactsResponse.data) {
        const decodedData = GatewaysDecoder.decodeArtifacts(artifactsResponse.data);
        return decodedData.content;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaGatewaysArtifacts(params?: IGatewaysArtifactsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const gatewaysResponse = await GatewaysAPI.fetchGatewaysArtifats(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decodeArtifacts(gatewaysResponse.data);
        dispatch(GatewaysActions.saveGatewaysArtifacts(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendSofiaGatewaysArtifacts(params?: IGatewaysArtifactsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { artifacts } = getState().gateways;
      if (_.isEmpty(artifacts)) return;
      const stateArtifacts = artifacts as PaginatedDataDTO<IGatewayArtifactInDTO>;
      const gatewaysResponse = await GatewaysAPI.fetchGatewaysArtifats(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decodeArtifacts(gatewaysResponse.data);
        const artifactsToSave : PaginatedDataDTO<IGatewayArtifactInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateArtifacts.content,
            ...decodedData.content
          ],
        }
        dispatch(GatewaysActions.saveGatewaysArtifacts(artifactsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSofiaGatewaysDeployments(params?: IGatewaysDeploymentsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const gatewaysResponse = await GatewaysAPI.fetchGatewaysDeployments(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decodeDeployments(gatewaysResponse.data);
        dispatch(GatewaysActions.saveGatewaysDeployments(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendSofiaGatewaysDeployments(params?: IGatewaysDeploymentsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { deployments } = getState().gateways;
      if (_.isEmpty(deployments)) return;
      const stateDeployments = deployments as PaginatedDataDTO<IGatewayDeploymentInDTO>;
      const gatewaysResponse = await GatewaysAPI.fetchGatewaysDeployments(params);
      if (gatewaysResponse && gatewaysResponse.data) {
        const decodedData = GatewaysDecoder.decodeDeployments(gatewaysResponse.data);
        const deploymentsToSave : PaginatedDataDTO<IGatewayDeploymentInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateDeployments.content,
            ...decodedData.content
          ],
        }
        dispatch(GatewaysActions.saveGatewaysDeployments(deploymentsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}


export function createDeployment(deployment: IGatewayDeploymentOutDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const gatewayDeploymentResponse = await GatewaysAPI.createGatewaysDeployment(deployment);
      if (gatewayDeploymentResponse && gatewayDeploymentResponse.data) {
        return gatewayDeploymentResponse.data;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchDeploymentDetails(deployment: IGatewayDeploymentInDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const gatewayDeploymentDetailedResponse = await GatewaysAPI.fetchDeploymentDetails(deployment.id, { pageSize: 200 });
      if (gatewayDeploymentDetailedResponse && gatewayDeploymentDetailedResponse.data) {
        const deploy = {
          ...deployment,
          gateways: gatewayDeploymentDetailedResponse.data,

        }
        dispatch(GatewaysActions.setSelectedDetailedDeployment(deploy));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export type GatewaysActionsType = ActionsUnion<typeof GatewaysActions>;

