import MomentUtils from '@date-io/moment';
import { Button } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import * as React from "react";
import { IExportWalletUsageCSVParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 15,
  },
  formTitle: {
    marginBottom: 25,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 25,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  dateContainer: {
    display: 'flex',
  },
});

export interface IExportUsagesCSVFormState {
  data: IExportWalletUsageCSVParamsDTO;
  errors: Record<keyof IExportWalletUsageCSVParamsDTO, boolean>;
};

export interface IExportUsagesCSVFormProps extends WithStyles<typeof styles>{
  onSubmit: (code: IExportWalletUsageCSVParamsDTO) => void;
}

class ExportUsagesCSVForm extends React.Component<IExportUsagesCSVFormProps, IExportUsagesCSVFormState> {

  constructor(props: IExportUsagesCSVFormProps) {
    super(props);
    this.state = {
      data: {
        fromDate: 0,
        toDate: 0,
      },
      errors: {
        fromDate: false,
        toDate: false,
      }
    };
  }
  
  onChangeField(field: keyof IExportWalletUsageCSVParamsDTO, value?: string | number) {
    const { data } = this.state;
    if (!value) {
      this.setErrorForField(field, true);
    } else {
      this.setErrorForField(field, false);
    }
    if (value && value < 0) {
      this.setErrorForField(field, true);
    }

    (data as any)[field] = value;
    this.setState({ data });
  }

  setErrorForField(field: keyof IExportWalletUsageCSVParamsDTO, hasError: boolean) {
    const { errors } = this.state;
    errors[field] = hasError;
    this.setState({ errors });
  }

  canSubmitForm() {
    return true;
  }

  onSubmitForm() {
    const { onSubmit } = this.props;
    const { data } = this.state;
    if (data) {
      onSubmit(data);
    }
  }

  render() {
    const { classes } = this.props;
    const { data, errors } = this.state;
    const canSubmitForm = this.canSubmitForm();
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <div className={classes.dateContainer}>
            <div>
              <h4><ReduxLanguage languageKey='forms.pickDateFrom' /></h4>
              <KeyboardDatePicker
                inputVariant="outlined"
                variant="dialog"
                margin="normal"
                autoOk
                clearable
                format="DD/MM/YYYY"
                error={errors.fromDate}
                placeholder="(dd/mm/yyyy)"
                value={data.fromDate ? moment(data.fromDate).startOf('day').add(5, 'hours').valueOf() : null}
                onChange={(value) => this.onChangeField('fromDate', value?.startOf('day').add(5, 'hours').valueOf())}
                style={{ marginRight: 10 }}
              />
            </div>
            <div>
              <h4><ReduxLanguage languageKey='forms.pickDateTo' /></h4>
              <KeyboardDatePicker
                inputVariant="outlined"
                variant="dialog"
                margin="normal"
                autoOk
                clearable
                format="DD/MM/YYYY"
                error={errors.toDate}
                placeholder="(dd/mm/yyyy)"
                value={data.toDate ? moment(data.toDate).endOf('day').valueOf() : null}
                onChange={(value) => this.onChangeField('toDate', value?.endOf('day').valueOf())}
              />
            </div>
          </div>

          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 25, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="credits.exportWalletsUsageCSV" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(ExportUsagesCSVForm);
