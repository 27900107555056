import { PaginatedDataDTO } from "@bottega52/commons-pagination";
import { Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import * as React from "react";
import { connect, ConnectedProps } from 'react-redux';
import * as CreditsActions from '../../redux/credits/credits.actions';
import { IState } from '../../redux/store';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { IEditSubscriptionOutDTO } from '../../repository/jago/model/output/ISubscriptionOutDTO';
import { IMarketplacesRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import SystemSubscriptionsList from '../MarketplaceSubscriptions/SystemSubscriptionsList';

const styles = createStyles({
  container: {
    display: 'flex',
    marginTop: 20,
    flexDirection: 'column',
  },
  cardContainer: {
    padding: 10,
    borderLeft: '3px solid #5AC0B1',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
});

export interface ICustomerWalletSectionState {
}

type IReduxProps = ConnectedProps<typeof connector>;

export interface ICustomerWalletSectionProps extends WithStyles<typeof styles> {
  language: string;
  subscription: PaginatedDataDTO<ISubscriptionInDTO>;
  marketplace: IMarketplaceInDTO;
  onEditSubscription: (subscription: IEditSubscriptionOutDTO) => void;
}

export type ComponentProps = ICustomerWalletSectionProps & IReduxProps;

class MarketplaceSubscriptionsSection extends React.Component<ComponentProps, ICustomerWalletSectionState> {

  public onAppendSubscriptions(page: number) {
    const { dispatch, marketplace } = this.props;
    const params: IMarketplacesRequestParamsDTO = { page };
    dispatch<any>(CreditsActions.appendMarketplaceSubscriptions(marketplace.id, params));
  }
  
  public render() {
    const { subscriptions, classes, onEditSubscription } = this.props;
    return (
      <div className={classes.container}>
        <Card className={classes.cardContainer}>
          <div className={classes.header}>
            <div className={classes.headerInner}>
              <WalletIcon className={classes.icon} />
              
            </div>
          </div>
          <SystemSubscriptionsList
            subscriptions={subscriptions}
            onEditSubcription={onEditSubscription}
            onFetchMoreItems={page => this.onAppendSubscriptions(page)}
          />
        </Card>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    subscriptions: state.credits.marketplaceSubscriptions.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(MarketplaceSubscriptionsSection as any));