import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import SeamlessImmutable from 'seamless-immutable';
import { IGatewayDetailedDTO } from '../../repository/jago/model/input/IGatewayDetailedDTO';
import IGatewayInDTO from '../../repository/jago/model/input/IGatewayInDTO';
import { GatewaysActionsType } from './gateways.actions';
import GatewaysActionTypesEnum from './model/GatewaysActionTypesEnum';
import { IGatewayArtifactInDTO } from '../../repository/jago/model/input/IGatewayArtifactInDTO';
import { IGatewayDeploymentInDTO } from '../../repository/jago/model/input/IGatewayDeploymentInDTO';
import { IGatewayDeploymentDetailedInDTO } from '../../repository/jago/model/input/IGatewayDeploymentDetailedInDTO';


export interface IGatewaysInterface {
  gateways: PaginatedDataDTO<IGatewayInDTO> | {};
  artifacts: PaginatedDataDTO<IGatewayArtifactInDTO> | {};
  deployments: PaginatedDataDTO<IGatewayDeploymentInDTO> | {};
  selectedDetailedGateway: IGatewayDetailedDTO | {};
  selectedDetailedDeployment: IGatewayDeploymentDetailedInDTO | {};
}

const initialState = SeamlessImmutable<IGatewaysInterface>({
  gateways: {},
  artifacts: {},
  deployments: {},
  selectedDetailedGateway: {},
  selectedDetailedDeployment: {},
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state: SeamlessImmutable.ImmutableObject<IGatewaysInterface> = initialState, action: GatewaysActionsType) {
  switch (action.type) {
    case GatewaysActionTypesEnum.SAVE_GATEWAYS:
      return state.set('gateways', action.payload);
    case GatewaysActionTypesEnum.SAVE_GATEWAYS_ARTIFACTS:
      return state.set('artifacts', action.payload);
    case GatewaysActionTypesEnum.SAVE_GATEWAYS_DEPLOYMENTS:
      return state.set('deployments', action.payload);
    case GatewaysActionTypesEnum.SET_SELECTED_DETAILED_GATEWAY:
      return state.set('selectedDetailedGateway', action.payload);
    case GatewaysActionTypesEnum.SET_SELECTED_DETAILED_DEPLOYMENT:
      return state.set('selectedDetailedDeployment', action.payload);
    default:
      return state;
  }
}
