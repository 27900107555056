import MomentUtils from '@date-io/moment';
import { Button } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import moment from 'moment';
import * as React from "react";
import Select from 'react-select';
import { IProductInDTO } from '../../repository/jago/model/input/IProductInDTO';
import { getProductOptions } from "../../repository/utils";
import translations from '../../translations/i18next';
import ReduxLanguage from "../ReduxLanguage";



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IAddPluginFormState {
  product?: IProductInDTO;
};

export interface IAddPluginFormProps extends WithStyles<typeof styles>{
  onAddNewPlugin: (product: IProductInDTO) => void;
  products: IProductInDTO[];
}

class AddPluginForm extends React.Component<IAddPluginFormProps, IAddPluginFormState> {
  constructor(props: IAddPluginFormProps) {
    super(props);
    this.state = {
      product: undefined,
    };
  }

  public onSelectProduct(product: IProductInDTO) {
    this.setState({ product });
  }


  public onSubmitForm() {
    const { product } = this.state;
    const { onAddNewPlugin } = this.props;
    if (product && !_.isEmpty(product)) {
      onAddNewPlugin(product as IProductInDTO);
    }
  }

  public render() {
    const { classes, products } = this.props;
    const { product } = this.state;
    const productOptions = getProductOptions(products);
    const canSubmitForm = product && !_.isEmpty(product);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'domains.extendDomainDescription'} />}</h2>
          <div className={classes.inputTitleContainer}>
            <h4 className={classes.inputTitle}>{translations.t('domains.productTitle')}</h4>
            <h5 style={{color: 'red' }}>*</h5>
          </div>
          <Select
            options={productOptions}
            isClearable
            placeholder={<ReduxLanguage languageKey={'forms.selectProduct'} />}
            onChange={(e) => this.onSelectProduct(e!.value)}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55 }),
            }} 
          />
          {/* <h4 className={classes.inputTitle}>{'Data di inizio licenza'}</h4>
          <KeyboardDatePicker
            inputVariant="outlined"
            variant="dialog"
            margin="normal"
            autoOk
            clearable
            format="DD/MM/YYYY"
            value={selectedStartDate}
            onChange={(value) => this.onSelectDate(value)}
            style={{ width: '100%', margin: 0 }}
          /> */}
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.confirm" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(AddPluginForm);