
import _ from 'lodash';
import { IDomainInDTO } from '../repository/jago/model/input/IDomainInDTO';
import { PaginationRestDTO, PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';

export function decode(data: PaginationRestDTO<IDomainInDTO>): PaginatedDataDTO<IDomainInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}
