import React from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import * as ModalActions from "../../redux/modals/modals.actions";
import { IModalsInterface } from '../../redux/modals/modals.reducers';
import { IState } from '../../redux/store';
import { modalComponents } from './ModalConstants';

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = IModalsInterface & IReduxProps;

class BaseModal extends React.Component<ComponentProps> {

  render() {
    const { data: modals, dispatch } = this.props;
    if (!_.size(modals)) {
      return null;
    }

    const components = _.map(modals, (modal) => {
      if (!modal.id || !modal.data) return null;

      const { modalType, modalProps } = modal.data;
      const Component = modalComponents[modalType];

      return <Component key={modal.id} modalProps={modalProps} dispatch={dispatch} hideModal={() => dispatch<any>(ModalActions.hideModal(modal.id))} />;
    });

    return (_.size(components) ? <div>{components}</div> : null);
  }
}

function mapStateToProps(state: IState): IModalsInterface {
  return {
    ...state.modals,
  };
}

const connector = connect(mapStateToProps);

export default connector(BaseModal);
