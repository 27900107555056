import { DispatchProp } from "react-redux";

export interface ModalDataProps<T> {
  modalType: string;
  modalProps?: T;
}

export interface ModalProps<T> {
  id: string;
  data: ModalDataProps<T>;
}


export enum ModalTypes {
  ERROR_MODAL = 'ERROR_MODAL',
  WARNING_MODAL = 'WARNING_MODAL',
  OPERATIONAL_VIEW_MODAL = 'OPERATIONAL_VIEW_MODAL',
  OPERATIONAL_VIEW_MODAL_BACKDROP = 'OPERATIONAL_VIEW_MODAL_BACKDROP',
  OPERATIONAL_VIEW_MODAL_WP = 'OPERATIONAL_VIEW_MODAL_WP',
  SUCCESS_MODAL = 'SUCCESS_MODAL',
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  CONTENT_MODAL = 'CONTENT_MODAL',
  TEXTFIELD_MODAL = 'TEXTFIELD_MODAL',
  SELECT_MODAL = 'SELECT_MODAL',
  SUCCESS_ALERT = 'SUCCESS_ALERT',
}

export interface IModalViewProps<T> extends DispatchProp {
  hideModal: () => void;
  modalProps?: T;
}