import MomentUtils from '@date-io/moment';
import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import { IWalletRechargeFormDTO } from '../../repository/jago/model/input/IWalletRechargeFormDTO';
import translations from '../../translations/i18next';
import ReduxLanguage from "../ReduxLanguage";



const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IWalletRechargeFormState {
  card: IWalletRechargeFormDTO;
  cardCodeValue?: string;
  textInputHasError: boolean;
};

export interface IWalletRechargeFormProps extends WithStyles<typeof styles>{
  onRechargeWallet: (card: IWalletRechargeFormDTO) => void;
}

class WalletRechargeForm extends React.Component<IWalletRechargeFormProps, IWalletRechargeFormState> {

  constructor(props: IWalletRechargeFormProps) {
    super(props);
    this.state = {
      card: {
        cardCode: '',
      },
      cardCodeValue: undefined,
      textInputHasError: false,
    };
  }
  
  public onChangeText(text:string) {
    const { card } = this.state;
    if (text) {
      this.setState({ textInputHasError: false });
      this.setState({ cardCodeValue: text });
      card.cardCode = text;
    } else {
      this.setState({ textInputHasError: true });
      this.setState({ cardCodeValue: '' });
      card.cardCode = '';
    }
  }

  public onSubmitForm() {
    const { card } = this.state;
    if (!card.cardCode) {
      this.setState({ textInputHasError: true });
      return;
    }
    const { onRechargeWallet } = this.props;
    onRechargeWallet(card);
  }

  public render() {
    const { classes } = this.props;
    const { cardCodeValue, textInputHasError } = this.state;
    const canSubmitForm = cardCodeValue;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h3 className={classes.formTitle}>{<ReduxLanguage languageKey={'forms.rechargeWalletFormDescription'} />}</h3>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'forms.cardCode'} />}
            variant="outlined"
            fullWidth
            required
            error={textInputHasError}
            value={cardCodeValue}
            onChange={e => this.onChangeText(e.target.value)}
          />
          {textInputHasError ? <h4 className={classes.errorText}>{`${translations.t('forms.required')}`}</h4> : null}
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 20, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.recharge" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(WalletRechargeForm);