import MomentUtils from '@date-io/moment';
import { Button, TextField } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import { IMarketplaceFormDTO } from '../../repository/jago/model/input/IMarketplaceFormDTO';
import translations from '../../translations/i18next';
import ReduxLanguage from "../ReduxLanguage";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  }
});

export interface IMarketplaceFormState {
  marketplace: IMarketplaceFormDTO;
  customerNameValue?: string;
  textInputHasError: boolean;
};

export interface IMarketplaceFormProps extends WithStyles<typeof styles>{
  onCreateNewMarketplace: (customer: IMarketplaceFormDTO) => void;
  marketplace?: IMarketplaceFormDTO;
}

class MarketplaceForm extends React.Component<IMarketplaceFormProps, IMarketplaceFormState> {

  constructor(props: IMarketplaceFormProps) {
    super(props);
    this.state = {
      marketplace: {
        name: props.marketplace && props.marketplace.name ? props.marketplace.name : '',
      },
      textInputHasError: false,
    };
  }
  
  public onChangeCustomerNameText(text:string) {
    const { marketplace } = this.state;
    if (text) {
      this.setState({ textInputHasError: false });
      this.setState({ customerNameValue: text });
      marketplace.name = text;
    } else {
      this.setState({ textInputHasError: true });
      this.setState({ customerNameValue: '' });
      marketplace.name = '';
    }
  }

  public onSubmitForm() {
    const { marketplace } = this.state;
    if (!marketplace.name) {
      this.setState({ textInputHasError: true });
      return;
    }
    const { onCreateNewMarketplace } = this.props;
    onCreateNewMarketplace(marketplace);
  }

  public render() {
    const { classes } = this.props;
    const { marketplace, textInputHasError } = this.state;
    const canSubmitForm = marketplace.name;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'forms.marketplaceFormTitle'} />}</h2>
          <TextField
            id="outlined-basic"
            label={<ReduxLanguage languageKey={'forms.marketplaceName'} />}
            variant="outlined"
            fullWidth
            required
            error={textInputHasError}
            value={marketplace.name}
            style={{ marginTop: 20 }}
            onChange={e => this.onChangeCustomerNameText(e.target.value)}
          />
          {textInputHasError ? <h4 className={classes.errorText}>{`${translations.t('forms.invalidMarketplaceName')}`}</h4> : null}
          <Button
            variant="contained"
            disabled={!canSubmitForm}
            style={{ width: '100%', opacity: canSubmitForm ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(MarketplaceForm);
