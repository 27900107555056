import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import MomentUtils from '@date-io/moment';
import { Button, Card, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RedoIcon from '@material-ui/icons/Redo';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import _ from 'lodash';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import Select from 'react-select';
import { VarFileAcceptedExtensions, VarFileName, VarLogoFileAcceptedExtensions, VarLogoFileName } from '../../codec/varInDTODecoder';
import { ModalTypes } from '../../components/Modals/ModalTypes';
import * as ModalsActions from '../../redux/modals/modals.actions';
import { IState } from '../../redux/store';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { IVarFormDTO } from '../../repository/jago/model/input/IVarFormDTO';
import { BLUE } from '../../style/StyleConsts';
import ReduxLanguage from "../ReduxLanguage";
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import Utils, { ISEOCompanyInfo } from '../../utils/Utils';
import { Autocomplete } from '@material-ui/lab';
import { IKamInDTO } from '../../repository/jago/model/input/IKamInDTO';
import translations from '../../translations/i18next';
import Permission from '../Permission/Permission';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS } from '../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  },
  actionButton: {
    width: 'fit-content',
    color: 'white',
    marginLeft: 20,
    padding: 4,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold'
  },
  actionButtonText: {
    margin: 0,
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  iconCustomer: {
    marginRight: 5,
    fontSize: 25,
  },
  dateInput: {
    height: 33
  }
});

export interface IVarFormState {
  VAR: IVarFormDTO;
  ISEOCompanies: ISEOCompanyInfo[];
  errors: Record<keyof IVarFormDTO, boolean>;
};

export interface IVarFormProps extends WithStyles<typeof styles>{
  onCreateEditVar: (VAR: IVarFormDTO) => void;
  VAR?: IVarFormDTO;
  isVar?: boolean;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = IVarFormProps & IReduxProps;
class VarForm extends React.Component<ComponentProps, IVarFormState> {
  inputFile = null;
  inputLogoFile = null;

  constructor(props: ComponentProps) {
    super(props);
    this.inputFile = React.createRef();
    this.inputLogoFile = React.createRef();
    this.state = {
      ISEOCompanies: Utils.getAvailableISEOCompanies(),
      VAR: props.VAR ? props.VAR : {
        id: null,
        name: '',
        address: '',
        contactEmail: '',
        contactPhone: '',
        cluster: null,
        marketplace: null,
        company: '',
        customerIds: [],
        sfcdId: '',
        companyName: '',
        vat: '',
        commercialRefName: '',
        commercialRefEmail: '',
        commercialRefPhone: '',
        techRefName: '',
        techRefEmail: '',
        techRefPhone: '',
        agreementFileUrl: '',
        agreementFileName: '',
        agreementFileBase64: '',
        supportEmail: '',
        supportHours: '',
        city: '',
        country: '',
        website: '',
        shortDescription: '',
        visibleOnSofiaWebsite: null,
        lastTrainingDate: null,
        kam: null,
        logoBase64: '',
        logoFileName: '',
        logoFileUrl: '',
      },
      errors: {
        id: null,
        name: false,
        address: false,
        contactEmail: false,
        contactPhone: false,
        cluster: false,
        marketplace: false,
        company: false,
        customerIds: false,
        sfcdId: false,
        companyName: false,
        vat: false,
        commercialRefName: false,
        commercialRefEmail: false,
        commercialRefPhone: false,
        techRefName: false,
        techRefEmail: false,
        techRefPhone: false,
        agreementFileUrl: false,
        agreementFileName: false,
        agreementFileBase64: false,
        supportEmail: false,
        supportHours: false,
        kam: false,
        city: false,
        country: false,
        website: false,
        shortDescription: false,
        visibleOnSofiaWebsite: false,
        lastTrainingDate: false,
        logoBase64: false,
        logoFileName: false,
        logoFileUrl: false,
      },
    };
  }

  onChangeValue(field: keyof IVarFormDTO, mandatory: boolean, value?: any) {
    const { VAR, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: !value && mandatory ? true : false }
    });
    (VAR as any)[field] = value;
    this.setState({ VAR });
  }

  onSubmitForm() {
    const { VAR } = this.state;
    if (!this.canSubmitForm()) {
      return;
    }
    const { onCreateEditVar } = this.props;
    onCreateEditVar(VAR);
  }

  toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Rimuovo la prima parte, i.e. "data:application/pdf;base64,"
      let resultAsString = (reader.result as string);
      let resultSplitted = resultAsString.split(',');
      if (resultSplitted.length>1)
        resolve(resultSplitted[1])
    };
    reader.onerror = reject;
  });

  async onNewInputFile(newFile: File, field: string) {
    const { dispatch } = this.props;
    var extensionOK = false;
    debugger
    const extensionFileAccepted = field === 'logo' ? VarLogoFileAcceptedExtensions : VarFileAcceptedExtensions;
    const VarFileNameFinal = field === 'logo' ? VarLogoFileName : VarFileName;
    var fileName: string = "";
    var fileExtension: string = "";
    var fileExtSearch: number =  newFile && newFile.name ? newFile.name.lastIndexOf(".") : -1;
    if (fileExtSearch > 0 && fileExtSearch < newFile.name.length) {
      fileName = newFile.name.substring(0,fileExtSearch);
      fileExtension = newFile.name.substring(fileExtSearch+1);
      extensionOK = extensionFileAccepted.some(e=> e.toUpperCase() === fileExtension.toUpperCase())
    }
    if (!extensionOK) {
      dispatch<any>(ModalsActions.showModal(`NEWFILE_EXTENSION_ERROR_MODAL`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'vars.newFileExtensionError',
        }
      }));
      return;
    }
    this.onNewInputFileConfirmed(newFile, VarFileNameFinal, fileExtension, field)
  }

  async onNewInputFileConfirmed(newFile: File, newName: string, newExtension: string, field: string) {
    const { dispatch } = this.props;
    const { VAR } = this.state;
    debugger
    dispatch<any>(ModalsActions.hideModal(`NEWFILE_NAME_MODAL`))
    let encodedFile = null;
    try {encodedFile = await this.toBase64(newFile)} catch(e) {return}
    if (encodedFile === null) return;
    if (field === 'logo') {
      VAR.logoFileName = (newName+"."+newExtension)
      VAR.logoBase64 = encodedFile;
    } else {
      VAR.agreementFileName = (newName+"."+newExtension)
      VAR.agreementFileBase64 = encodedFile;
    }
    this.setState({ VAR })
  }

  canSubmitForm() {
    const { isVar } = this.props;
    const { VAR } = this.state;
    return VAR.name && VAR.companyName && VAR.contactEmail && VAR.vat && 
      (isVar || (
        VAR.kam && VAR.kam.email &&
        VAR.cluster && VAR.cluster.id && VAR.marketplace && VAR.marketplace.id && VAR.company && VAR.sfcdId && VAR.companyName && VAR.supportEmail && VAR.supportHours
      ));
  }

  public render() {
    const { classes, clusters, marketplaces, isVar } = this.props;
    const { kams } = this.props; // TODO DOPO FIX KAMS --> const { users } = this.props;
    const { VAR, errors, ISEOCompanies } = this.state;
    
    const isEdit = Boolean(this.props.VAR)
    console.log(VAR)

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <div style={{ flex: 0, marginRight: 10 }}>
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varCompanyName'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.name}
              value={VAR.name}
              style={{ marginTop: 20 }}
              onChange={e => {
                this.onChangeValue('name', true, e.target.value);
                this.onChangeValue('companyName', true, e.target.value);
              }}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varVat'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.vat}
              value={VAR.vat}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('vat', true, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'forms.email'} />}
              variant="outlined"
              fullWidth
              required
              error={errors.contactEmail}
              value={VAR.contactEmail}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('contactEmail', true, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varPhone'} />}
              variant="outlined"
              fullWidth
              error={errors.contactPhone}
              value={VAR.contactPhone}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('contactPhone', false, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varShortDescription'} />}
              variant="outlined"
              fullWidth
              error={errors.shortDescription}
              value={VAR.shortDescription}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('shortDescription', false, e.target.value)}
            />
            <div style={{ flex: 1 }}>
              <div style={{ marginLeft: 0 }}>
                <h3 style={{ marginTop: 20, marginLeft: 0, marginBottom: 3 }}>{<ReduxLanguage languageKey={'vars.varLastTrainingData'} />}</h3>
                <KeyboardDatePicker
                  disableToolbar
                  style={{ width: '100%' }}
                  variant="dialog"
                  format="DD/MM/YYYY"
                  margin="dense"
                  inputVariant="outlined"
                  inputProps={{
                    className: classes.dateInput
                  }}
                  clearable
                  id="date-picker-inline"
                  placeholder={translations.t('forms.pickDate') + ' (dd/mm/yyyy)'}
                  value={VAR?.lastTrainingDate ? moment(VAR?.lastTrainingDate) : null}
                  onChange={e => this.onChangeValue('lastTrainingDate', false, e?.valueOf())}
                />
              </div>
            </div>
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varAddress'} />}
              variant="outlined"
              fullWidth
              error={errors.address}
              value={VAR.address}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('address', false, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varCity'} />}
              variant="outlined"
              fullWidth
              error={errors.city}
              value={VAR.city}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('city', false, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varCountry'} />}
              variant="outlined"
              fullWidth
              error={errors.country}
              value={VAR.country}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('country', false, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varWebsite'} />}
              variant="outlined"
              fullWidth
              error={errors.website}
              value={VAR.website}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('website', false, e.target.value)}
            />
            <input type='file' style={{ display: 'none' }} ref={this.inputLogoFile} 
              onChange={(e) => {
                if(e.target.files && e.target.files.length > 0) {
                  this.onNewInputFile(e.target.files[0], 'logo')
                  e.target.value = null
                }
              }}
            />
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.VAR_UPDATE_LOGO}>
              <div>
                <h3 style={{ fontWeight: 'bold', marginTop: 20 }}>{<ReduxLanguage languageKey={'vars.varLogo'} />}</h3>
                {(VAR.logoFileUrl || VAR.logoFileName)  ? (
                  <Card
                    style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10, marginBottom: 30}} elevation={2}>
                    {VAR.logoBase64 ? (
                      <img src={`data:image/png;base64, ${VAR.logoBase64}`} style={{ height: 60 }} alt="logo" />
                    ) : null}
                    {VAR.logoFileUrl ? (
                      <img src={VAR.logoFileUrl} style={{ height: 60 }} alt="logo" />
                    ) : null}
                    <span style={{flexGrow:1}}/>
                    {VAR.logoFileUrl ? (
                      <a href={VAR.logoFileUrl}>
                        <Button
                          variant="contained"
                          style={{backgroundColor:'darkcyan', marginBottom: 10 }}
                          className={classes.actionButton}
                        >
                          <CloudDownloadIcon className={classes.icon}/>
                          <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesDownload" /></h5>
                        </Button>
                      </a>
                    ): null}
                    {isVar ? null : (
                      <Button
                        variant="contained"
                        style={{backgroundColor:'#282c34', marginBottom: 10 }}
                        className={classes.actionButton}
                        onClick={(e) => {
                          if (this.inputLogoFile && this.inputLogoFile.current)
                            this.inputLogoFile.current.click();
                        }}
                      >
                        <RedoIcon className={classes.icon}/>
                        <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesReplace" /></h5>
                      </Button>
                    )}
                  </Card>
                ) : (
                  <Button
                    variant="contained"
                    style={{backgroundColor:'#282c34', marginTop:10, marginBottom: 10 }}
                    className={classes.actionButton}
                    onClick={(e) => {
                        if (this.inputLogoFile && this.inputLogoFile.current)
                          this.inputLogoFile.current.click();
                    }}
                  >
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesAdd" /></h5>
                  </Button>
                )}
              </div>
            </Permission>
            <div style={{ marginTop: 20 }}>
              <FormControlLabel
                control={<Checkbox checked={VAR?.visibleOnSofiaWebsite} onChange={(e, checked) => this.onChangeValue('visibleOnSofiaWebsite', false, checked)} name="visibleOnSofiaWebsite" />}
                label={<ReduxLanguage languageKey={'vars.varVisibleOnSofiaWebsite'} />}
              />
            </div>
            {isVar ? null : (
              <div style={{ marginTop: 30 }}>
                <h3 className={classes.formTitle}>{<ReduxLanguage languageKey={'forms.varFormAdminSection'} />}</h3>
                <Select
                  options={(clusters as PaginatedDataDTO<IClusterInDTO>).content}
                  isClearable
                  formatOptionLabel={(o) => <>[{o.market}] - <b>{o.name}</b></>}
                  value={VAR.cluster}
                  required
                  isOptionSelected={(o) => VAR.cluster?.id === o.id}
                  placeholder={<><ReduxLanguage languageKey={'vars.varCluster'} /> *</>}
                  onChange={(data) => this.onChangeValue('cluster', true, data)}
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                    control: (styles) => ({ ...styles, minHeight: 55, marginTop: 0 }),
                  }} 
                />
                <Select
                  options={(marketplaces as PaginatedDataDTO<IMarketplaceInDTO>).content}
                  isClearable
                  getOptionLabel={o => o.name}
                  value={VAR.marketplace}
                  required
                  isDisabled={isEdit}
                  isOptionSelected={(o) => VAR.marketplace?.id === o.id}
                  placeholder={<><ReduxLanguage languageKey={'forms.customerMarketplace'} /> *</>}
                  onChange={(data) => this.onChangeValue('marketplace', true, data)}
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                    control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                  }} 
                />
                <Select
                  options={ISEOCompanies}
                  isClearable
                  value={_.find(ISEOCompanies, { name: VAR.company })}
                  formatOptionLabel={(o) => <>[{o.country}] - <b>{o.name}</b></>}
                  isOptionSelected={(o, selectedValues) => _.findIndex(selectedValues, (sv) => sv === o) >= 0}
                  placeholder={<><ReduxLanguage languageKey={'forms.customerCompany'} /> *</>}
                  onChange={(data) => this.onChangeValue('company', true, data?.name)}
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                    control: (styles) => ({ ...styles, minHeight: 55, marginTop: 20 }),
                  }} 
                />
                <Autocomplete
                  options={(kams as PaginatedDataDTO<IKamInDTO>).content /* // TODO DOPO FIX KAMS --> (users as PaginatedDataDTO<IKamInDTO>).content */}
                  isClearable
                  autoComplete
                  value={VAR.kam}
                  disabled={false}
                  getOptionLabel={(option) => option.email}
                  renderInput={(params) => <TextField {...params} variant="outlined" style={{ margin: '10px 0px', marginTop: 20 }} label={<ReduxLanguage languageKey={'activations.keyAccountManager'} />}/>}
                  placeholder={<><ReduxLanguage languageKey={'activations.formEnterAnswer'} /> *</>}
                  onChange={(e, data) => this.onChangeValue('kam', true, data)}
                  styles={{
                    menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
                    control: (styles) => ({ ...styles, minHeight: 55 })
                  }} 
                />
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={<><ReduxLanguage languageKey={'vars.varSfdcid'} /> *</>}
                  variant="outlined"
                  fullWidth
                  error={errors.sfcdId}
                  value={VAR.sfcdId}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('sfcdId', false, e.target.value)}
                />
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'vars.varSupportEmail'} />}
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.supportEmail}
                  value={VAR.supportEmail}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('supportEmail', true, e.target.value)}
                />
                <TextField
                  autoComplete='off'
                  id="outlined-basic"
                  label={<ReduxLanguage languageKey={'vars.varSupportHours'} />}
                  variant="outlined"
                  fullWidth
                  required
                  error={errors.supportHours}
                  value={VAR.supportHours}
                  style={{ marginTop: 20 }}
                  onChange={e => this.onChangeValue('supportHours', true, e.target.value)}
                />
              </div>
            )}
            <h3 style={{ marginTop: 30, marginBottom: 20, fontWeight: 'bold' }}><ReduxLanguage languageKey='vars.varCommercialContact' /></h3>
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varNameComm'} />}
              variant="outlined"
              fullWidth
              error={errors.commercialRefName}
              value={VAR.commercialRefName}
              style={{ marginTop: 0 }}
              onChange={e => this.onChangeValue('commercialRefName', true, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varEmailComm'} />}
              variant="outlined"
              fullWidth
              error={errors.commercialRefEmail}
              value={VAR.commercialRefEmail}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('commercialRefEmail', false, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varPhoneComm'} />}
              variant="outlined"
              fullWidth
              error={errors.commercialRefPhone}
              value={VAR.commercialRefPhone}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('commercialRefPhone', false, e.target.value)}
            />
            <h3 style={{ marginTop: 30, marginBottom: 20, fontWeight: 'bold' }}><ReduxLanguage languageKey='vars.varTechContact' /></h3>
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varNameTech'} />}
              variant="outlined"
              fullWidth
              error={errors.techRefName}
              value={VAR.techRefName}
              style={{ marginTop: 0 }}
              onChange={e => this.onChangeValue('techRefName', true, e.target.value)}
            />
            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varEmailTech'} />}
              variant="outlined"
              fullWidth
              error={errors.techRefEmail}
              value={VAR.techRefEmail}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('techRefEmail', false, e.target.value)}
            />

            <TextField
              autoComplete='off'
              id="outlined-basic"
              label={<ReduxLanguage languageKey={'vars.varPhoneTech'} />}
              variant="outlined"
              fullWidth
              error={errors.techRefPhone}
              value={VAR.techRefPhone}
              style={{ marginTop: 20 }}
              onChange={e => this.onChangeValue('techRefPhone', false, e.target.value)}
            />
            <input type='file' style={{display: 'none'}} ref={this.inputFile} 
              onChange={(e) => {
                if(e.target.files&&e.target.files.length>0) {
                  this.onNewInputFile(e.target.files[0], 'agreement')
                  e.target.value = null
                }
              }}
            />
            <h3 style={{ fontWeight: 'bold', marginTop: 60 }}>{<ReduxLanguage languageKey={'vars.agreementFile'} />}</h3>
            {VAR.agreementFileUrl || VAR.agreementFileName ? (
              <Card
                style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10, marginBottom: 30}} elevation={2}>
                <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                  {"PDF"}
                </div>
                <span style={{flexGrow:1}}/>
                {VAR.agreementFileUrl?(
                  <a href={VAR.agreementFileUrl}>
                    <Button
                      variant="contained"
                      style={{backgroundColor:'darkcyan'}}
                      className={classes.actionButton}
                    >
                      <CloudDownloadIcon className={classes.icon}/>
                      <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesDownload" /></h5>
                    </Button>
                  </a>
                ):null}
                {isVar||(!VAR.agreementFileName&&!VAR.agreementFileUrl)?null:(
                  <Button
                    variant="contained"
                    style={{backgroundColor:'#282c34'}}
                    className={classes.actionButton}
                    onClick={(e) => {
                      if (this.inputFile&&this.inputFile.current)
                        this.inputFile.current.click();
                    }}
                  >
                    <RedoIcon className={classes.icon}/>
                    <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesReplace" /></h5>
                  </Button>
                )}
              </Card>
            ):(!isVar?(
              <Button
                variant="contained"
                style={{backgroundColor:'#282c34', marginTop:10, marginBottom: 30}}
                className={classes.actionButton}
                onClick={(e) => {
                    if (this.inputFile&&this.inputFile.current)
                      this.inputFile.current.click();
                }}
              >
                <CloudUploadIcon className={classes.icon}/>
                <h5 className={classes.actionButtonText}><ReduxLanguage languageKey="recharges.selloutFilesAdd" /></h5>
              </Button>
            ):(
              <Card
                style={{padding:10,backgroundColor:'white',display:'flex',flexDirection:'row', alignItems:'center',marginTop:10, marginBottom: 30}} elevation={2}>
                <div style={{fontWeight:'bold',maxWidth:'70%',overflow:'hidden',textOverflow:'ellipsis',fontSize:'0.8em'}}>
                  {"File not found"}
                </div>
                <span style={{flexGrow:1}}/>
              </Card>
            ))}
          </div>
          <div style={{ flex: 1, marginRight: 10 }}>
          </div>
          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-20px',marginRight:'-20px',zIndex:1}}>
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', borderRadius: 0, height: 45, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.save" />
          </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    clusters: state.credits.clusters.data,
    marketplaces: state.credits.marketplaces.data,
    kams: state.activations.kams.data,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(VarForm));