import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { DomainDisabled } from "@material-ui/icons";
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IWalletCustomerDTO } from '../../repository/jago/model/input/IWalletCustomerDTO';
import ListEmptyView from "../ListEmptyView";
import WalletRow from './WalletRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IWalletsListProps extends WithStyles<typeof styles>{
  wallets: PaginatedDataDTO<IWalletCustomerDTO>;
  onRowClick: (wallets: IWalletCustomerDTO) => void;
  onFetchMoreItems: (page: number) => void;
}

class WalletsList extends React.Component<IWalletsListProps, any> {

  public render() {
    const { wallets, onRowClick, onFetchMoreItems } = this.props;
    
    const walletsData = wallets.content;
    const walletsPagination = !_.isEmpty(wallets.pagination) ? (wallets.pagination as PaginationDTO) : null;
    const currentPage = walletsPagination ? walletsPagination.number : 0;
    const isLastPage = walletsPagination ? walletsPagination.last : true;
    return (
      <div style={{ zIndex: 100, marginTop: 20 }}>
        {!wallets || (wallets && wallets.content && _.isEmpty(wallets.content)) ? (
          <ListEmptyView
            icon={<DomainDisabled style={{ fontSize: 40 }} />}
            titleKeyMessage="credits.noWalletFoundTitle"
            descriptionKeyMessage="credits.noWalletFoundDescription"
          />
        ): (
          <div>
          <InfiniteScroll
            dataLength={_.size(walletsData)} //This is important field to render the next data
            next={() => onFetchMoreItems(currentPage + 1)}
            hasMore={!isLastPage}
            loader={<h4>Loading...</h4>}
          >
            {_.map(walletsData, wallet => (
              <WalletRow key={wallet.id} wallet={wallet} onClick={() => onRowClick(wallet)} />
            ))}
          </InfiniteScroll>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(WalletsList);