import { AppBar, Button, Tab, Tabs } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { IState } from '../../../redux/store';
import { IWalletDetailedDTO } from '../../../repository/jago/model/input/IWalletDetailedDTO';
import { IWalletSubscriptionInDTO } from '../../../repository/jago/model/input/IWalletSubscriptionInDTO';
import { ORANGE } from '../../../style/StyleConsts';
import Utils from '../../../utils/Utils';
import ReduxLanguage from '../../ReduxLanguage';
import WalletSubscriptionsList from "./WalletSubscriptionsList";
import AbilityProvider from '../../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../../services/PermissionService/PermissionConstants';
import Permission from "../../Permission/Permission";

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerContainer: {
    backgroundColor: '#f1f1f1'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderLeft: '3px solid #0D47A1',
    marginRight: 20,
    marginLeft: 10,
    marginTop: 20,
    padding: 10,
    zIndex: 500
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerCreditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  titleText: {
    fontWeight: 'bold',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    marginTop: 0,
    paddingTop: 0,
    height: 70,
    borderBottom: '2px solid #5AC0B1',
  },
  creditIcon: {
    color: ORANGE
  },
  viewsContainer: {
    overflow: 'auto'
  }
});

type IWalletSubscriptionsPageReduxProps = ConnectedProps<typeof connector>;

export interface IWalletSubscriptionsPageProps extends WithStyles<typeof styles>, IWalletSubscriptionsPageReduxProps {
  onAssociateMainTierToWallet: () => void;
  onAssociatePluginToWallet: () => void;
  onDeleteWalletSubscription: (s: IWalletSubscriptionInDTO) => void;
}

class WalletSubscriptionsPage extends React.Component<IWalletSubscriptionsPageProps, any> {

  constructor(props: IWalletSubscriptionsPageProps) {
    super(props);
    this.state = {
      activeTab: 0,
    }
  }

  public onTabChange(index: number) {
    this.setState({ activeTab: index });
  }
  
  public render() {
    const { wallet, classes, onAssociateMainTierToWallet, onAssociatePluginToWallet, onDeleteWalletSubscription } = this.props;
    const { activeTab } = this.state;
    const [mainTier, plugins] = _.partition(wallet.subscriptions?.content, s => Utils.isWalletMainTierSubscription(s));

    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
        <AppBar position="sticky" style={{ backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            centered
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            <Tab label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="credits.tierSubscription" /></h5>} />
            <Tab label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="credits.pluginAndService" /></h5>} />
          </Tabs>
        </AppBar>
        {activeTab === 0 ? (
          <div className={classes.sectionHeader}>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_SUBSCRIPTION_UPDATE}>
              <Button
                onClick={onAssociateMainTierToWallet}
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', margin: 'auto', marginLeft: 10, width: 'fit-content', fontSize: 11, color: 'white' }}>
                  <ReduxLanguage languageKey={mainTier.length > 0 ? "credits.editWalletSubscription" : "credits.newWalletSubscription"} />
              </Button>
            </Permission>
          </div>
         ) : null}
        {activeTab === 1 && mainTier.length > 0 ? (
          <div className={classes.sectionHeader}>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_SUBSCRIPTION_UPDATE}>
              <Button
                onClick={onAssociatePluginToWallet}
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', margin: 'auto', marginLeft: 10, width: 'fit-content', fontSize: 11, color: 'white' }}>
                  <ReduxLanguage languageKey="credits.newWalletPluginSubscription" />
              </Button>
            </Permission>
          </div>
         ) : null}
        </div> {/* header end */}
        <div className={classes.viewsContainer}>
          <WalletSubscriptionsList
            walletSubscriptions={activeTab === 0 ? mainTier : plugins}
            onDeleteWalletSubscription={AbilityProvider.getAbilityHelper().hasPermission(PERMISSIONS.WALLET_SUBSCRIPTION_UPDATE)?onDeleteWalletSubscription:null}
          />
        </div>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    wallet: state.credits.wallets.selectedWallet as IWalletDetailedDTO,
    selectedCustomer: state.credits.customers.selectedDetailedCustomer,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles (styles)(WalletSubscriptionsPage));