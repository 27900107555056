import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import SubscriptionsIcon from '@material-ui/icons/BookmarkBorder';
import _ from 'lodash';
import * as React from "react";
import { IWalletSubscriptionInDTO } from '../../../repository/jago/model/input/IWalletSubscriptionInDTO';
import ListEmptyView from '../../ListEmptyView';
import WalletSubscriptionRow from './WalletSubscriptionRow';

const styles = createStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderBottom: '1px solid #4DB6AC',
    height: 65
  },
  
});

export interface IWalletSubscriptionsListProps extends WithStyles<typeof styles>{
  walletSubscriptions: IWalletSubscriptionInDTO[];
  onDeleteWalletSubscription?: (s: IWalletSubscriptionInDTO) => void;
}

class WalletSubscriptionsList extends React.Component<IWalletSubscriptionsListProps, any> {

  public render() {
    const { walletSubscriptions, onDeleteWalletSubscription } = this.props;
    
    return (
      <div style={{ zIndex: 100, margin: 10, marginLeft: 0 }}>
        {!walletSubscriptions || walletSubscriptions.length === 0 ? (
          <ListEmptyView
            icon={<SubscriptionsIcon style={{ fontSize: 40 }} />}
            titleKeyMessage="credits.noWalletSubscriptionFoundTitle"
            descriptionKeyMessage="credits.noWalletSubscriptionFoundDescription"
          />
        ): (_.map(walletSubscriptions, walletSubscription => (
              <WalletSubscriptionRow
                key={walletSubscription.id}
                walletSubscription={walletSubscription}
                onDeleteWalletSubscription={onDeleteWalletSubscription ? () => onDeleteWalletSubscription(walletSubscription) : undefined}
              />
            ))
        )}
      </div>
    );
  }
}

export default withStyles(styles)(WalletSubscriptionsList);