import { AppBar, Button, Card, IconButton, Tab, Tabs, Tooltip } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Edit, FireplaceOutlined, Refresh } from "@material-ui/icons";
import WalletIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import LicenseIcon from "@material-ui/icons/BookmarkBorder";
import PlantIcon from '@material-ui/icons/Domain';
import HistoryIcon from '@material-ui/icons/ListAlt';
import CreditsIcon from '@material-ui/icons/MonetizationOn';
import ConsumptionIcon from '@material-ui/icons/MoneyOffOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import moment from "moment";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import * as CreditsActions from '../../redux/credits/credits.actions';
import * as ModalsActions from '../../redux/modals/modals.actions';
import * as SettingsActions from '../../redux/settings/settings.actions';
import { IState } from "../../redux/store";
import { IWalletDetailedDTO } from "../../repository/jago/model/input/IWalletDetailedDTO";
import { IWalletRechargeFormDTO } from "../../repository/jago/model/input/IWalletRechargeFormDTO";
import { IWalletSubscriptionInDTO } from '../../repository/jago/model/input/IWalletSubscriptionInDTO';
import { IWalletPlantOutDTO } from "../../repository/jago/model/output/IWalletPlantOutDTO";
import { IWalletSubscriptionOutDTO } from "../../repository/jago/model/output/IWalletSubscriptionOutDTO";
import { IOrderDirectionEnum, ISubscriptionsRequestParamsDTO, IWalletCreditsRequestParamsDTO, IWalletSubscriptionsRequestParamsDTO, IWalletUsageRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { PERMISSIONS, Roles } from '../../services/PermissionService/PermissionConstants';
import { ORANGE, RED } from "../../style/StyleConsts";
import Utils, { calculateNextRechargeDate, VAR_USERS_ROLES } from '../../utils/Utils';
import WalletPlantForm from "../Forms/WalletPlantForm";
import WalletRechargeForm from "../Forms/WalletRechargeForm";
import WalletSubscriptionFrom from "../Forms/WalletSubscriptionFrom";
import { ModalTypes } from "../Modals/ModalTypes";
import Permission from "../Permission/Permission";
import ReduxLanguage from "../ReduxLanguage";
import WalletConsumptionPage from "./WalletCredits/WalletConsumptionPage";
import WalletCreditsPage from "./WalletCredits/WalletCreditsPage";
import WalletPlantsPage from "./WalletCredits/WalletPlantsPage";
import WalletSubscriptionsPage from './WalletCredits/WalletSubscriptionsPage';
import WalletCreditsSearchBar, { IWalletCreditsSearchFilterDTO } from "./WalletCreditsSearchBar";
import WalletStatusView from "./WalletStatusView";
import FireIconCustom from "../Icons/FireIconCustom";

const styles = createStyles({
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    paddingBottom: 50,
  },
  headerContainer: {
    marginTop: 0,
    backgroundColor: '#f1f1f1'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderLeft: '3px solid #0D47A1',
    marginRight: 20,
    marginLeft: 10,
    marginTop: 20,
    padding: 10,
    zIndex: 500
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleWithEditContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  headerCreditsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
    fontSize: 25,
  },
  title: {
    fontWeight: 'bold'
  },
  titleText: {
    fontWeight: 'bold',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    marginTop: 0,
    paddingTop: 0,
    height: 70,
    borderBottom: '2px solid #5AC0B1',
  },
  creditIcon: {
    color: ORANGE
  },
  viewsContainer: {
    overflow: 'auto'
  }
});

type IWalletDetailsPageReduxProps = ConnectedProps<typeof connector>;

export interface IWalletDetailsPageProps extends WithStyles<typeof styles>, IWalletDetailsPageReduxProps {
  onEditWallet: (wallet: IWalletDetailedDTO) => void;
  onDeleteWalletRequest: (wallet: IWalletDetailedDTO) => void;
}

class WalletDetailsPage extends React.Component<IWalletDetailsPageProps, any> {

  constructor(props: IWalletDetailsPageProps) {
    super(props);
    this.state = {
      activeTab: 0,
    }
  }

  public onOpenRechargeWalletForm() {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`RECHARGE_WALLET_FORM_MODAL`, {
      modalType: ModalTypes.CONTENT_MODAL,
      modalProps: {
        content: (
        <WalletRechargeForm
          onRechargeWallet={card => this.onRechargeWalletRequest(card)}
        />
        ),
      }
    }));
  }

  onRechargeWalletRequest(card: IWalletRechargeFormDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`RECHARGE_WALLET_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.rechargeWallet',
        successMessageKey: 'forms.rechargeWalletConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onRechargeWalletConfirm(card),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`RECHARGE_WALLET_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onRechargeWalletConfirm(card: IWalletRechargeFormDTO) {
    const { dispatch, wallet } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.rechargeCustomerWallet(wallet.id, card));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('RECHARGE_WALLET_FORM_MODAL'));
      dispatch<any>(ModalsActions.hideModal('RECHARGE_WALLET_CONFIRM_MODAL'));
      dispatch<any>(CreditsActions.fetchCustomerWalletCredits({ walletId: wallet.id, page: 0, pageSize: 50 }));
      dispatch<any>(CreditsActions.fetchCustomerWalletSubscriptions({ walletId: wallet.id, page: 0, pageSize: 200 }));
      dispatch<any>(CreditsActions.fetchCustomerWalletAvailableCredits(wallet.id));
    } catch (error: any) {
      let errorMessageKey = 'errors.rechargeWalletError';
      let titleMessageKey = 'errors.error';
      if (error && error.response && error.response.data && error.response.data.code === 11806) {
        titleMessageKey = 'errors.walletActionErrorNotEnoughCreditsTitle';
        errorMessageKey = 'errors.walletActionErrorNotEnoughCredits';
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_WALLET_RECHARGE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey,
          errorMessageKey,
        }
      }));
    }
  }

  public onTabChange(index: number) {
    this.setState({ activeTab: index });
  }

  public onAppendCredits(page: number) {
    const { dispatch, wallet } = this.props;
    const params: IWalletCreditsRequestParamsDTO = { walletId: wallet.id, page };
    dispatch<any>(CreditsActions.appendCustomerWalletCredits(params));
  }

  public onAppendSubscriptions(page: number) {
    const { dispatch, wallet } = this.props;
    const params: IWalletSubscriptionsRequestParamsDTO = { walletId: wallet.id, page };
    dispatch<any>(CreditsActions.appendCustomerWalletSubscriptions(params));
  }

  public onAppendWalletUsage(page: number) {
    const { dispatch, wallet } = this.props;
    const params: IWalletUsageRequestParamsDTO = { walletId: wallet.id, page };
    dispatch<any>(CreditsActions.appendCustomerWalletUsage(params));
  }

  public async onOpenSubscriptionWalletForm() {
    const { dispatch, selectedCustomer,wallet } = this.props;
    if (!selectedCustomer) {
      return;
    }
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const params: ISubscriptionsRequestParamsDTO = {
        page: 0,
        pageSize: 200,
        resource: 'DOMAIN',
      }
      const subscriptionsAvailable = await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptionsRawData(selectedCustomer.marketplace.id, params));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`WALLET_SUBSCRIPTION_FORM_VIEW`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <WalletSubscriptionFrom
              onCreateWalletSubscription={subscription => this.onCreateWalletSubscriptionRequest(subscription, 'DOMAIN')}
              subscriptions={subscriptionsAvailable}
              blackListedSubscriptions={_.map(wallet.subscriptions?.content, (v) => v.subscription)}
            />
          ),
          titleMessageKey: 'credits.newWalletSubscription',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }
  
  public async onOpenPluginWalletForm() {
    const { dispatch, selectedCustomer, wallet } = this.props;
    if (!selectedCustomer) {
      return;
    }
    const mainTier = _.find(wallet.subscriptions?.content, s => Utils.isWalletMainTierSubscription(s));
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      const params: ISubscriptionsRequestParamsDTO = {
        page: 0,
        pageSize: 200,
      }
      const fixedSubscriptionsAvailable = await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptionsRawData(selectedCustomer.marketplace.id, params));
      let mainTierSubscriptionsAvailable = [];
      if (mainTier) {
        mainTierSubscriptionsAvailable = await dispatch<any>(CreditsActions.fetchMarketplaceSubscriptionsRawData(selectedCustomer.marketplace.id, { pageSize: 200, mainTier: mainTier.subscription.id }));
      }
      const servicesAvailable = _.filter(fixedSubscriptionsAvailable, (s) => !Utils.isMainTierSubscription(s));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`WALLET_SUBSCRIPTION_FORM_VIEW`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <WalletSubscriptionFrom
              onCreateWalletSubscription={subscription => this.onCreateWalletSubscriptionRequest(subscription, 'PLUGIN')}
              subscriptions={[...servicesAvailable, ...mainTierSubscriptionsAvailable]}
              blackListedSubscriptions={_.map(wallet.subscriptions?.content, (v) => v.subscription)}
            />
          ),
          titleMessageKey: 'credits.newWalletPluginSubscription',
        }
      }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
    }
  }
  
  public onCreateWalletSubscriptionRequest(subscription: IWalletSubscriptionOutDTO, resource: string) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_WALLET_SUBSCRIPTION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newWalletSubscription',
        successMessageKey: 'forms.createWalletSubscriptionConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateWalletSubscriptionConfirm(subscription, resource),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_WALLET_SUBSCRIPTION_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateWalletSubscriptionConfirm(subscription: IWalletSubscriptionOutDTO, resource: string) {
    const { dispatch, wallet } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      if (_.find(wallet.subscriptions?.content, (s) => Utils.isWalletMainTierSubscription(s)) && resource === 'DOMAIN') {
        await dispatch<any>(CreditsActions.editWalletMainTierSubscription(wallet.id, subscription));
      } else {
        await dispatch<any>(CreditsActions.createNewWalletSubscription(wallet.id, subscription));
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('WALLET_SUBSCRIPTION_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_WALLET_SUBSCRIPTION_CONFIRM_MODAL'));
      dispatch<any>(CreditsActions.fetchCustomerWalletSubscriptions({ walletId: wallet.id, page: 0, pageSize: 200 }));
      dispatch<any>(CreditsActions.fetchCustomerWalletAvailableCredits(wallet.id));
    } catch (error: any) {
      let titleMessageKey = 'errors.error';
      let errorMessageKey = 'errors.createWalletSubscriptionError';
      if (error && error.response && error.response.data && error.response.data.code === 11806) {
        titleMessageKey = 'errors.walletActionErrorNotEnoughCreditsTitle';
        errorMessageKey = 'errors.walletActionErrorNotEnoughCredits';
      }
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_SUBSCRIPTION_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey,
          errorMessageKey,
        }
      }));
    }
  }

  public async onOpenWalletPlantForm() {
    const { dispatch, wallet } = this.props;
    try {
      dispatch<any>(ModalsActions.showModal(`WALLET_PLANT_FORM_VIEW`, {
        modalType: ModalTypes.OPERATIONAL_VIEW_MODAL,
        modalProps: {
          content: (
            <WalletPlantForm
              walletId={wallet.id}
              onSaveWalletPlatData={plantData => this.onCreateWalletPlantRequest(plantData)}
            />
          ),
          titleMessageKey: 'credits.newWalletPlant',
        }
      }));
    } catch (error) {
      console.log(error);
    }
  }

  public onCreateWalletPlantRequest(plantData: IWalletPlantOutDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`CREATE_WALLET_PLANT_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.newWalletPlant',
        successMessageKey: 'forms.createWalletPlantConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.onCreateWalletPlantConfirm(plantData),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_WALLET_PLANT_CONFIRM_MODAL`)),
      }
    }));
  }

  public async onCreateWalletPlantConfirm(plantData: IWalletPlantOutDTO) {
    const { dispatch, wallet } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.createNewWalletPlant(plantData));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.hideModal('WALLET_PLANT_FORM_VIEW'));
      dispatch<any>(ModalsActions.hideModal('CREATE_WALLET_PLANT_CONFIRM_MODAL'));
      dispatch<any>(CreditsActions.fetchCustomerWalletPlants({ walletId: wallet.id, page: 0, pageSize: 50 }));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PLANT_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.createWalletPlantError',
        }
      }));
    }
  }

  public async onRefreshWalletDetails() {
    const { dispatch, wallet } = this.props;
    try {
      await dispatch<any>(CreditsActions.fetchCustomerWalletAvailableCredits(wallet.id));
      await dispatch<any>(CreditsActions.fetchCustomerWalletSubscriptions({ walletId: wallet.id, page: 0, pageSize: 200 }));
    } catch (error) {
    }
  }

  public onFilterData(searchData: IWalletCreditsSearchFilterDTO) {
    const { dispatch, wallet } = this.props;
    const { activeTab } = this.state;
    const { fromDate, toDate } = searchData;
    const filterFromDate = fromDate || moment().startOf('month').valueOf();
    const filterToDate = toDate || moment().endOf('month').valueOf();
    switch (activeTab) {
      case 2: {
        dispatch<any>(CreditsActions.fetchCustomerWalletCredits({ walletId: wallet.id, orderDir: IOrderDirectionEnum.DESC, page: 0, pageSize: 50 }));
        break;
      }
      case 3: {
        dispatch<any>(CreditsActions.fetchCustomerWalletUsage({ walletId: wallet.id, orderDir: IOrderDirectionEnum.DESC, fromDate: filterFromDate, toDate: filterToDate, page: 0, pageSize: 50 }));
        break;
      }
    }
  }

  onExportWalletCredits() {
    const { dispatch } = this.props;
    dispatch<any>(CreditsActions.exportWalletCreditsData());
  }

  onExportWalletUsageData() {
    const { dispatch } = this.props;
    dispatch<any>(CreditsActions.exportWalletUsageData());
  }

  editWalletData() {

  }

  async deleteWalletSubscription(subscription: IWalletSubscriptionInDTO) {
    const { dispatch, wallet } = this.props;
    try {
      dispatch<any>(SettingsActions.setSpinnerVisible(true));
      await dispatch<any>(CreditsActions.deleteCustomerWalletSubscription(wallet.id, subscription.id));
      await dispatch<any>(CreditsActions.fetchCustomerWalletSubscriptions({ walletId: wallet.id, page: 0, pageSize: 200 }));
      dispatch<any>(SettingsActions.setSpinnerVisible(false));

      dispatch<any>(ModalsActions.hideModal('DELETE_WALLET_SUBSCRIPTION_CONFIRM_MODAL'));
    } catch (error) {
      dispatch<any>(SettingsActions.setSpinnerVisible(false));
      dispatch<any>(ModalsActions.showModal(`ERROR_PLANT_CREATE`, {
        modalType: ModalTypes.ERROR_MODAL,
        modalProps: {
          titleMessageKey: 'errors.error',
          errorMessageKey: 'errors.deleteWalletSubscriptionError',
        }
      }));
    }
  }

  showDeleteWalletSubscriptionConfirm(subscription: IWalletSubscriptionInDTO) {
    const { dispatch } = this.props;
    dispatch<any>(ModalsActions.showModal(`DELETE_WALLET_SUBSCRIPTION_CONFIRM_MODAL`, {
      modalType: ModalTypes.CONFIRM_MODAL,
      modalProps: {
        icon: <WarningIcon style={{ color: 'orange', fontSize: 50 }} />,
        titleMessageKey: 'credits.deleteWalletSubscription',
        successMessageKey: 'forms.deleteWalletSubscriptionConfirm',
        confirmMessageKey: 'forms.confirm',
        cancelMessageKey: 'forms.cancel',
        onConfirm: () => this.deleteWalletSubscription(subscription),
        onCancel: () => dispatch<any>(ModalsActions.hideModal(`CREATE_WALLET_SUBSCRIPTION_CONFIRM_MODAL`)),
      }
    }));
  }

  
  public render() {
    const { wallet, classes, onEditWallet, onDeleteWalletRequest } = this.props;
    const { activeTab } = this.state;

    const isVar = AbilityProvider.getAbilityHelper().hasRoles(VAR_USERS_ROLES.map(e=>{return e.name}))

    const canCreateNewDomain = (
        AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_SOFIA_ADMIN])
      ) || (
        AbilityProvider.getAbilityHelper().hasRoles([Roles.ROLE_VASHO_VAR_ADMIN]) && 
        wallet.plants && wallet.plants.content!==undefined && Array.isArray(wallet.plants.content) && !wallet.plants.content.length
      )

    const var_needCreditsToCreateDomain = (
      isVar && 
      canCreateNewDomain && 
      !wallet.availableCredits
    )

    const nextRechargeDate = calculateNextRechargeDate(Utils.formatCreditsIn(wallet.availableCredits), wallet.burnRateDaily);

    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Card className={classes.header}>
            <div className={classes.headerInner}>
              <div className={classes.titleWithEditContainer}>
                <div className={classes.headerTitleContainer}>
                  <WalletIcon className={classes.icon} />
                  <h2 className={classes.title}>{wallet && wallet.name ? wallet.name : ''}</h2>
                  <WalletStatusView status={wallet?.status} />
                </div>
                <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_UPDATE}>
                  <IconButton onClick={() => onEditWallet(wallet)} style={{ marginLeft: 10 }}>
                    <Edit />
                  </IconButton>
                </Permission>
              </div>
              <div className={classes.headerTitleContainer}>
                <h4 className={classes.title}><ReduxLanguage languageKey="forms.contactEmail" />: {wallet && wallet.contactEmail ? wallet.contactEmail : 'N/A'}</h4>
              </div>
              <div className={classes.headerCreditsContainer}>
                <CreditsIcon className={classes.creditIcon} />
                <h3>{Utils.formatCreditsIn(wallet.availableCredits)}</h3>
                <FireIconCustom className={""} style={{ marginLeft: 10, width: 25, color: ORANGE }} />
                <h3><ReduxLanguage languageKey="forms.montlyBurnRate" languageProps={{ credits: wallet.burnRate }} /></h3>
                <IconButton onClick={() => this.onRefreshWalletDetails()} style={{ padding: 5, marginTop: 2 }}>
                  <Refresh style={{ color: '#3f3f3f' }} />
                </IconButton>
              </div>
              {wallet.burnRate && wallet.burnRate !== 0 ? <h4 style={{ fontWeight: 'normal', fontSize: 12, marginTop: 0 }}><ReduxLanguage languageKey="forms.nextRechargeForecast" key={wallet.burnRate} languageProps={{ date: moment(nextRechargeDate).format('LL') }} /> ({moment(nextRechargeDate).fromNow()})</h4> : null}
            </div>
            <span style={{flexGrow:1}}></span>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_DELETE_REQUEST}>
              <Button
                variant="contained"
                style={{ backgroundColor: RED, fontSize: 11, fontWeight:'bold', color: 'white', marginRight: 30, marginLeft: 60 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteWalletRequest(wallet)
                }}
              >
                <ReduxLanguage languageKey="forms.deleteRequest" />
              </Button>
            </Permission>
            <Permission abilityHelper={AbilityProvider.getAbilityHelper()} permission={PERMISSIONS.WALLET_RECHARGE}>
              <Button
                variant="contained"
                style={{ fontWeight: 'bold', backgroundColor: '#5AC0B1', fontSize: 11, color: 'white', marginRight: 15 }}
                onClick={() => this.onOpenRechargeWalletForm()}
              >
                <ReduxLanguage languageKey="credits.rechargeWallet" />
              </Button>
            </Permission>
          </Card>
        <AppBar position="sticky" style={{ backgroundColor: 'white', marginTop: 20 }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            style={{ backgroundColor: 'white', width: '100%' }}
            onChange={(e, index) => this.onTabChange(index)}
          >
            <Tab icon={<PlantIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="credits.walletPlants" /></h5>} />
            <Tab icon={<LicenseIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="credits.walletSubscriptions" /></h5>} />
            <Tab icon={<HistoryIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="credits.walletRechargeHistory" /></h5>} />
            <Tab icon={<ConsumptionIcon style={{ fontSize: 25 }} />} label={<h5 style={{ margin: 0 }}><ReduxLanguage languageKey="credits.walletConsumption" /></h5>} />
          </Tabs>
        </AppBar>
        {activeTab === 0 ? (
          <div className={classes.sectionHeader}>
            {canCreateNewDomain?(
              <Tooltip title={var_needCreditsToCreateDomain?<ReduxLanguage languageKey="credits.needCreditsToCreatePlant" />:""} placement="right" arrow>
                <Button
                  onClick={var_needCreditsToCreateDomain? null : () => this.onOpenWalletPlantForm()}
                  variant="contained"
                  style={{ fontWeight: 'bold', backgroundColor: var_needCreditsToCreateDomain?'#888888':'#5AC0B1', 
                    margin: 'auto', marginLeft: 10, width: 'fit-content', fontSize: 11, color: 'white' }}>
                    <ReduxLanguage languageKey="credits.newWalletPlant" />
                </Button>
              </Tooltip>
            ):null}
          </div>
         ) : null}
         {activeTab === 2 ? (
          <div className={classes.sectionHeader} style={{ height: 90 }}>
            <WalletCreditsSearchBar
              onExportValues={() => this.onExportWalletCredits()}
              onSearch={searchData => this.onFilterData(searchData)}
            />
          </div>
         ) : null}
         {activeTab === 3 ? (
          <div className={classes.sectionHeader} style={{ height: 90 }}>
            <WalletCreditsSearchBar
              onExportValues={() => this.onExportWalletUsageData()}
              onSearch={searchData => this.onFilterData(searchData)}
            />
          </div>
         ) : null}
        </div> {/* header end */}
        <div className={classes.viewsContainer}>
          {activeTab === 0 ? (
            <WalletPlantsPage
              onOpenWalletPlantForm={() => this.onOpenWalletPlantForm()}
            />
            ) : null}
          {activeTab === 1 ? (
            <WalletSubscriptionsPage
              onAssociateMainTierToWallet={() => this.onOpenSubscriptionWalletForm()}
              onAssociatePluginToWallet={() => this.onOpenPluginWalletForm()}
              onDeleteWalletSubscription={(s) => this.showDeleteWalletSubscriptionConfirm(s)}
            />
            ) : null}
          {activeTab === 2 ? (
            <WalletCreditsPage
              walletCredits={wallet.credits}
              onFetchMoreItems={page => this.onAppendCredits(page)}
            />
            ) : null}
          {activeTab === 3 ? (
            <WalletConsumptionPage
              walletCreditUsages={wallet.usage}
              onFetchMoreItems={page => this.onAppendWalletUsage(page)}
            />
            ) : null}
        </div>
      </div>
    );
  }
}


function mapStateToProps(state: IState) {
  return {
    language: state.settings.language,
    wallet: state.credits.wallets.selectedWallet as IWalletDetailedDTO,
    selectedCustomer: state.credits.customers.selectedDetailedCustomer,
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(WalletDetailsPage));