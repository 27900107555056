import { PaginatedDataDTO } from '@bottega52/commons-pagination';
import * as FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as ActivationDecoder from '../../codec/activationInDTODecoder';
import * as ClusterDecoder from '../../codec/clusterInDTODecoder';
import * as CustomerCodec from '../../codec/customerDTOCodec';
import * as MarketplaceDecoder from '../../codec/marketplaceInDTODecoder';
import * as WalletDecoder from '../../codec/walletInDTODecoder';
import * as ActivationsAPI from '../../repository/jago/activationsAPI';
import * as CreditsAPI from '../../repository/jago/creditsAPI';
import * as VarsAPI from '../../repository/jago/varsAPI';
import * as VarCodec from '../../codec/varInDTODecoder';
import { IActivationInDTO } from '../../repository/jago/model/input/IActivationInDTO';
import { IActivationNewFormDTO } from '../../repository/jago/model/input/IActivationNewFormDTO';
import IClusterInDTO from '../../repository/jago/model/input/IClusterInDTO';
import { ICustomerInDTO } from '../../repository/jago/model/input/ICustomerInDTO';
import IMarketplaceInDTO from '../../repository/jago/model/input/IMarketplaceInDTO';
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import { IActivationOutDTO } from '../../repository/jago/model/output/IActivationOutDTO';
import { IClusterRequestParamsDTO, ICustomerRequestParamsDTO, IMarketplacesRequestParamsDTO, ISubscriptionsRequestParamsDTO } from '../../repository/jago/model/output/RequestParamsDTOs';
import { IState } from '../store';
import { ActionsUnion, IThunkAction, createAction } from "../utils";
import ActivationsActionTypesEnum from "./model/ActivationsActionTypesEnum";
import { IKamInDTO } from '../../repository/jago/model/input/IKamInDTO';
import { IVarInDTO } from '../../repository/jago/model/input/IVarInDTO';


export const ActivationsActions = {
  saveClusters: createAction<typeof ActivationsActionTypesEnum.SAVE_CLUSTERS, PaginatedDataDTO<IClusterInDTO>>(ActivationsActionTypesEnum.SAVE_CLUSTERS),
  saveActivations: createAction<typeof ActivationsActionTypesEnum.SAVE_ACTIVATIONS, PaginatedDataDTO<IActivationInDTO>>(ActivationsActionTypesEnum.SAVE_ACTIVATIONS),
  saveActivationDetailed: createAction<typeof ActivationsActionTypesEnum.SAVE_ACTIVATION_DETAILED, IActivationInDTO>(ActivationsActionTypesEnum.SAVE_ACTIVATION_DETAILED),
  saveMarketPlaces: createAction<typeof ActivationsActionTypesEnum.SAVE_MARKETPLACES, PaginatedDataDTO<IMarketplaceInDTO>>(ActivationsActionTypesEnum.SAVE_MARKETPLACES),
  saveMarketplaceSubscriptions: createAction<typeof ActivationsActionTypesEnum.SAVE_MARKETPLACE_SUBSCRIPTIONS, PaginatedDataDTO<ISubscriptionInDTO>>(ActivationsActionTypesEnum.SAVE_MARKETPLACE_SUBSCRIPTIONS),
  setSelectedMarketplaceMainTier: createAction<typeof ActivationsActionTypesEnum.SET_SELECTED_MARKETPLACE_MAIN_TIER, ISubscriptionInDTO | {}>(ActivationsActionTypesEnum.SET_SELECTED_MARKETPLACE_MAIN_TIER),
  saveSelectedMarketplaceMainTierSubscriptions: createAction<typeof ActivationsActionTypesEnum.SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS, PaginatedDataDTO<ISubscriptionInDTO>>(ActivationsActionTypesEnum.SAVE_SELECTED_MARKETPLACE_MAIN_TIER_SUBSCRIPTIONS),
  saveCustomersSearch: createAction<typeof ActivationsActionTypesEnum.SAVE_CUSTOMERS_SEARCH, PaginatedDataDTO<ICustomerInDTO> | {}>(ActivationsActionTypesEnum.SAVE_CUSTOMERS_SEARCH),
  saveVarsSearch: createAction<typeof ActivationsActionTypesEnum.SAVE_VARS_SEARCH, PaginatedDataDTO<IVarInDTO> | {}>(ActivationsActionTypesEnum.SAVE_VARS_SEARCH),
  saveKams: createAction<typeof ActivationsActionTypesEnum.SAVE_KAMS, PaginatedDataDTO<IKamInDTO>>(ActivationsActionTypesEnum.SAVE_KAMS),
};

export type ActivationsActionsType = ActionsUnion<typeof ActivationsActions>;

export function fetchActivations(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IActivationInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await ActivationsAPI.fetchActivations(params);
      if (response && response.data) {
        const decodedData = ActivationDecoder.decode(response.data);
        dispatch(ActivationsActions.saveActivations(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchActivation(activationId: number): IThunkAction<Promise<IActivationInDTO>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await ActivationsAPI.fetchActivation(activationId);
      if (response && response.data) {
        dispatch(ActivationsActions.saveActivationDetailed(response.data));
        return response.data;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchClusters(params: IClusterRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IMarketplaceInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await CreditsAPI.fetchClusters(params);
      if (response && response.data) {
        const decodedData = ClusterDecoder.decode(response.data);
        dispatch(ActivationsActions.saveClusters(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function appendActivations(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const { activations: { data } } = getState().activations;
      if (_.isEmpty(data)) return;
      const stateActivations = data as PaginatedDataDTO<IActivationInDTO>;
      const activationsResponse = await ActivationsAPI.fetchActivations(params);
      if (activationsResponse && activationsResponse.data) {
        const decodedData = ActivationDecoder.decode(activationsResponse.data);
        const activationsToSave : PaginatedDataDTO<IActivationInDTO> = {
          pagination: decodedData.pagination,
          content: [
            ...stateActivations.content,
            ...decodedData.content
          ],
        }
        dispatch(ActivationsActions.saveActivations(activationsToSave));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function deleteActivation(activationId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteActivationResponse = await ActivationsAPI.deleteActivation(activationId);
      if (deleteActivationResponse && deleteActivationResponse.data) {
        return deleteActivationResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteActivationAdmin(activationId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const deleteActivationResponse = await ActivationsAPI.deleteActivationAdmin(activationId);
      if (deleteActivationResponse && deleteActivationResponse.data) {
        return deleteActivationResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function promoteActivation(activationId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const promoteActivationResponse = await ActivationsAPI.promoteActivation(activationId);
      if (promoteActivationResponse && promoteActivationResponse.data) {
        return promoteActivationResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function invoicedActivation(activationId: number): IThunkAction<void, IState> {
  return async () => {
    try {
      const invoicedActivationResponse = await ActivationsAPI.invoicedActivation(activationId);
      if (invoicedActivationResponse && invoicedActivationResponse.data) {
        return invoicedActivationResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function promoteActivationAdmin(activationId: number, newStatus: string): IThunkAction<void, IState> {
  return async () => {
    try {
      const promoteActivationResponse = await ActivationsAPI.promoteActivationAdmin(activationId,newStatus);
      if (promoteActivationResponse && promoteActivationResponse.data) {
        return promoteActivationResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function createNewActivation(newActivationData: IActivationNewFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newActivation: IActivationOutDTO = ActivationDecoder.encodeActivationFromForm(newActivationData);
      const createCustomerResponse = await ActivationsAPI.createNewActivation(newActivation);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editActivation(activationId: number, newActivationData: IActivationNewFormDTO): IThunkAction<void, IState> {
  return async () => {
    try {
      const newActivation: IActivationOutDTO = ActivationDecoder.encodeActivationFromForm(newActivationData);
      const createCustomerResponse = await ActivationsAPI.editActivation(activationId, newActivation);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function editActivationAdmin(activationId: number, newActivationData: IActivationOutDTO|null, newActivationDataFromForm: IActivationNewFormDTO|null): IThunkAction<void, IState> {
  return async () => {
    try {
      const newActivation: IActivationOutDTO = newActivationDataFromForm!==null?ActivationDecoder.encodeActivationFromForm(newActivationDataFromForm):
      newActivationData!==null?newActivationData:null;
      if (newActivation===null) return null;
      const createCustomerResponse = await ActivationsAPI.editActivationAdmin(activationId, newActivation);
      if (createCustomerResponse && createCustomerResponse.data) {
        return createCustomerResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportActivationsCSV(params: IMarketplacesRequestParamsDTO = {}) {
  return async () => {
    const response = await ActivationsAPI.exportActivationsCSV(params);
    FileSaver.saveAs(response.data, `export-activations-${moment().toISOString()}.csv`);
  }
}

export function fetchMarketplaces(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 500 }): IThunkAction<Promise<PaginatedDataDTO<IMarketplaceInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await CreditsAPI.fetchMarketplaces(params);
      if (response && response.data) {
        const decodedData = MarketplaceDecoder.decode(response.data);
        dispatch(ActivationsActions.saveMarketPlaces(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchMarketplaceSubscriptions(marketplaceIseoId: number, params: ISubscriptionsRequestParamsDTO = { page: 0, pageSize: 500, version: 2 }): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceIseoId, params);
      if (subscriptionsResponse && subscriptionsResponse.data) {
        const decodedData = WalletDecoder.decodeSubscriptions(subscriptionsResponse.data);
        dispatch(ActivationsActions.saveMarketplaceSubscriptions(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchMarketplaceMainTierSubscriptions(marketplaceId: number, mainTierId: number, params?: ISubscriptionsRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    let formattedParams: ISubscriptionsRequestParamsDTO = {
      mainTier: mainTierId,
    };
    if (params) {
      formattedParams = { ...params, ...formattedParams }
    }
    try {
      const subscriptionsResponse = await CreditsAPI.fetchMarketplaceSubscriptions(marketplaceId, formattedParams);
      if (subscriptionsResponse && subscriptionsResponse.data) {
        const decodedData = WalletDecoder.decodeSubscriptions(subscriptionsResponse.data);
        dispatch(ActivationsActions.saveSelectedMarketplaceMainTierSubscriptions(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function fetchCustomersSearch(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const customersResponse = await CreditsAPI.fetchCustomers(params);
      if (customersResponse && customersResponse.data) {
        const decodedData = CustomerCodec.decode(customersResponse.data);
        dispatch(ActivationsActions.saveCustomersSearch(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetCustomersSearch(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(ActivationsActions.saveCustomersSearch({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchVarsSearch(params?: ICustomerRequestParamsDTO): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const varsResponse = await VarsAPI.fetchVars(params);
      if (varsResponse && varsResponse.data) {
        const decodedData = VarCodec.decode(varsResponse.data);
        dispatch(ActivationsActions.saveVarsSearch(decodedData));
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}

export function resetVarsSearch(): IThunkAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      dispatch(ActivationsActions.saveVarsSearch({}));
    } catch (error) {
      throw error;
    }
  };
}

export function fetchKams(params: IMarketplacesRequestParamsDTO = { page: 0, pageSize: 200 }): IThunkAction<Promise<PaginatedDataDTO<IKamInDTO>>, IState> {
  return async (dispatch, getState) => {
    try {
      const response = await ActivationsAPI.fetchKams(params);
      if (response && response.data) {
        const decodedData = ActivationDecoder.decodeKam(response.data);
        dispatch(ActivationsActions.saveKams(decodedData));
        return decodedData;
      } else {
        throw new Error();
      }
    } catch (error) {
      throw error;
    }
  };
}