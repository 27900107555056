import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import PlantsIcon from '@material-ui/icons/DomainDisabled';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ListEmptyView from '../ListEmptyView';
import AbilityProvider from '../../services/PermissionService/AbilityProvider';
import { Roles } from '../../services/PermissionService/PermissionConstants';
import { IPlantInDTO } from '../../repository/jago/model/input/IPlantInDTO';
import ThemePlantRow from './ThemePlantRow';

const styles = createStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderBottom: '1px solid #4DB6AC',
    height: 65
  },
  
});

export interface IThemePlantsListProps extends WithStyles<typeof styles>{
  plants: PaginatedDataDTO<IPlantInDTO> | undefined;
}

class ThemePlantsList extends React.Component<IThemePlantsListProps, any> {

  public render() {
    const { plants } = this.props;

    return (
      <div style={{ margin: 0, marginTop: 10 }}>
        {!plants || (plants && plants.content && _.isEmpty(plants.content)) ? (
          null
        ): (
          <InfiniteScroll
            dataLength={_.size(plants.content)} //This is important field to render the next data
            next={() => {}}
            hasMore={false}
            loader={<></>}
          >
            {_.map(plants.content, plant => (
              <ThemePlantRow key={plant.id} plant={plant}/>
            ))}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ThemePlantsList);