import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { PaginationQueryParamsDTO, PaginationRestDTO } from '@bottega52/commons-pagination';
import { IHardwareInDTO, ISalesInfoInDTO } from './model/input/ISalesInfoInDTO';
import IAddSalesInfoOutDTO from './model/output/IAddSalesInfoOutDTO';
import IEditBatchSalesInfoOutDTO from './model/output/IEditBatchSalesInfoOutDTO';
import IUnreconciledHardwareOutDTO from './model/output/IUnreconciledHardwareOutDTO';

export function fetchSalesInfo(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<ISalesInfoInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/salesInfo', config);
}

export function fetchNotReconciledHardware(params?: IUnreconciledHardwareOutDTO): Promise<AxiosResponse<IHardwareInDTO[]>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/salesInfo/hardware', config);
}

export function createHardwareSalesInfo(data: IAddSalesInfoOutDTO): Promise<AxiosResponse<ISalesInfoInDTO>> {
  return JagoAxiosInstance.post('api/v2/support/salesInfo', data);
}

export function editBatchHardwareSalesInfo(data: IEditBatchSalesInfoOutDTO): Promise<AxiosResponse<ISalesInfoInDTO>> {
  return JagoAxiosInstance.put(`api/v2/support/salesInfo/batchUpdate`, data);
}
