
import _ from 'lodash';
import { PaginationRestDTO, PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import IClusterInDTO from '../repository/jago/model/input/IClusterInDTO';

export function decode(data: PaginationRestDTO<IClusterInDTO>): PaginatedDataDTO<IClusterInDTO> {
    const pagination: PaginationDTO = _.omit(data, 'content');
    return {
      content: data.content,
      pagination,
    };
}
