import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import './index.css';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/it';
import reportWebVitals from './reportWebVitals';
import { configureStore } from './redux/store';
import AppRouter from './routes/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import * as AmplitudeService from './services/AmplitudeService';

const store = configureStore();

AmplitudeService.initialize();

ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
      <AppRouter store={store}/>
      </BrowserRouter>
    </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
