import _ from 'lodash';
import { Card } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from "react";
import { IProductInDTO } from "../../repository/jago/model/input/IProductInDTO";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    padding: 15,
  },
  rowActionBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  avatar: {
    color: 'white',
    marginRight: 10,
    backgroundColor: '#0D47A1',
  },
  logo: {
    width: '20%',
  },
  federatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 5
  },
  icon: {
    fontSize: 15
  }
});

export interface IProductRowProps extends WithStyles<typeof styles>{
  product?: IProductInDTO;
}

class ProductRow extends React.Component<IProductRowProps, any> {
  public render() {
    const { product, classes } = this.props;
    let label = '';
    _.each(product?.resources, resource => {
      if (resource.resource === 'DOMAIN') return;
      label += `${resource.quantity} ${resource.resource} `;
    });
    return (
      <Card className={classes.rowContainer}>
        <div className={classes.rowBodyContainer}>
          <h3 className={classes.rowTitle}>{product?.code}</h3>
        </div>
        <h4>{label}</h4> 
      </Card>
    );
  }
}

export default withStyles(styles)(ProductRow);