import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';
import Select from 'react-select';
import { RED } from '../../style/StyleConsts';
import ReduxLanguage from '../ReduxLanguage';
import { IModalViewProps } from './ModalTypes';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface IOption {
  id: string;
  value: string;
}

export interface SelectModalState {
  selectedValue: IOption;
}

export interface SelectModalProps {
  icon?: JSX.Element;
  fieldLabel: string;
  placeHolder: string;
  initialID: string;
  options: IOption[];
  titleMessageKey?: string;
  successMessageKey: string;
  confirmMessageKey?: string;
  cancelMessageKey?: string;
  onConfirm?: (value: IOption|{}) => void;
  onCancel?: () => void;
};

interface ComponentProps extends IModalViewProps<SelectModalProps> {}

export default class TextfieldModal extends React.Component<ComponentProps, SelectModalState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      selectedValue: props.modalProps.initialID&&props.modalProps.options.filter(e=>e.id===props.modalProps.initialID).length>0?
        props.modalProps.options.filter(e=>e.id===props.modalProps.initialID)[0]:undefined,
    };
  }

  render(): React.ReactNode {
    if (!this.props.modalProps) return;
    const { cancelMessageKey, options, placeHolder, onConfirm, confirmMessageKey, icon, titleMessageKey, successMessageKey } = this.props.modalProps;
    const { selectedValue } = this.state;
    return (
      <Dialog open onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <div style={{ padding: 20, minHeight:500, minWidth: 500, flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {icon ? icon : null}
          <h3 style={{ color: '#3f3f3f' }}>{titleMessageKey?<ReduxLanguage languageKey={titleMessageKey} />:""}</h3>
          <h4 style={{ fontWeight: 100, marginTop: 0, textAlign: 'center' }}>{<ReduxLanguage languageKey={successMessageKey} />}</h4>
          <Select
            key={`select_modal_select_key`}
            value={selectedValue}
            options={options}
            isSearchable={false}
            placeholder={placeHolder?placeHolder:""}
            onChange={(e) => {this.setState({selectedValue:e})}}
            formatOptionLabel={(o) => <><b>{o.value}</b></>}
            required
            isOptionSelected={(o) => selectedValue&&selectedValue.id?selectedValue.id === o.id:false}
            styles={{
              menu: (styles) => Object.assign(styles, { zIndex: 1000 }),
              control: (styles) => ({ ...styles, minHeight: 55, maxHeight:150, minWidth: 350, marginLeft: 10, marginRight: 10 }),
            }} 
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          {onConfirm ? (
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: 20, marginRight: 20, backgroundColor: '#5AC0B1', width: 200, borderRadius: 100 }}
              onClick={() => onConfirm(selectedValue)}
            >
            <ReduxLanguage languageKey={confirmMessageKey || 'forms.save'}/>
          </Button>
        ) : null}
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: 20, backgroundColor: RED, width: 200, borderRadius: 100 }}
            onClick={() => this.handleClose()}
          >
            <ReduxLanguage languageKey={cancelMessageKey || 'forms.close'}/>
          </Button>
          </div>
        </div>
      </Dialog>
    );
  }

  private handleClose = (): void => {
    const {hideModal, modalProps} = this.props;
    hideModal();
    if (modalProps && modalProps.onCancel) {
      modalProps.onCancel();
    }
  }
}
