import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import PlantsIcon from '@material-ui/icons/DomainDisabled';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IDomainInDTO } from '../../../repository/jago/model/input/IDomainInDTO';
import DomainRow from '../../Domains/DomainRow';
import ListEmptyView from '../../ListEmptyView';
import AbilityProvider from '../../../services/PermissionService/AbilityProvider';
import { Roles } from '../../../services/PermissionService/PermissionConstants';

const styles = createStyles({
  header: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderBottom: '1px solid #4DB6AC',
    height: 65
  },
  
});

export interface IWalletPlantsListProps extends WithStyles<typeof styles>{
  walletPlants: PaginatedDataDTO<IDomainInDTO> | undefined;
  onFetchMoreItems: (page: number) => void;
  onOpenPlantDetails: (plant: IDomainInDTO) => void 
  onNewWalletPlant: () => void;
  onEnableIrisRequest: (plant: IDomainInDTO) => void 
}

class WalletPlantsList extends React.Component<IWalletPlantsListProps, any> {

  public render() {
    const { walletPlants, onFetchMoreItems, onOpenPlantDetails, onEnableIrisRequest } = this.props;
    
    const walletPlantsData = walletPlants?.content;
    const walletPlantsPagination = !_.isEmpty(walletPlants?.pagination) ? (walletPlants?.pagination as PaginationDTO) : null;
    const currentPage = walletPlantsPagination ? walletPlantsPagination.number : 0;
    const isLastPage = walletPlantsPagination ? walletPlantsPagination.last : true;

    return (
      <div style={{ zIndex: 100, marginLeft: 0 }}>
        {!walletPlants || (walletPlants && walletPlants.content && _.isEmpty(walletPlants.content)) ? (
          <ListEmptyView
            icon={<PlantsIcon style={{ fontSize: 40 }} />}
            titleKeyMessage="credits.noWalletPlantFoundTitle"
            descriptionKeyMessage="credits.noWalletPlantFoundDescription"
            onNewEntity={null}
          />
        ): (
          <InfiniteScroll
            dataLength={_.size(walletPlantsData)} //This is important field to render the next data
            next={() => onFetchMoreItems(currentPage + 1)}
            hasMore={!isLastPage}
            loader={<h4>Loading...</h4>}
          >
            {_.map(walletPlantsData, walletPlant => (
              <DomainRow key={walletPlant.id} domain={walletPlant} onClick={() => onOpenPlantDetails(walletPlant)} onEnableIrisRequest={() => onEnableIrisRequest(walletPlant)}/>
            ))}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(WalletPlantsList);