import MomentUtils from '@date-io/moment';
import { Button, TextField } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';
import * as React from "react";
import { ConnectedProps, connect } from 'react-redux';
import { IState } from '../../redux/store';
import { IUserFormDTO } from '../../repository/jago/model/input/IUserFormDTO';
import ReduxLanguage from "../ReduxLanguage";
import { ILearningTicketFormDTO } from '../../repository/jago/model/input/ILearningTicketFormDTO';
import { TextareaAutosize } from '@mui/material';
import { ContactSupportOutlined } from '@material-ui/icons';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 20,
    height: '100%',
    flex: 1,
  },
  formTitle: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  errorText: {
    color: 'red'
  },
  inputTitle: {
    marginTop: 20,
    marginBottom: 8,
    fontSize: 18,
    fontWeight: 'bold',
  },
  disabledButton: {
    backgroundColor: 'red'
  },
  inputTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  italicText: {
    fontStyle: 'italic',
  },
  customerDataColumn: {
    flex: 1,
  }
});

export interface ILearningTicketFormState {
  ticket: ILearningTicketFormDTO;
  errors: Record<keyof ILearningTicketFormDTO, boolean>;
};

export interface ILearningTicketFormProps extends WithStyles<typeof styles>{
  onCreateTicket: (values: ILearningTicketFormDTO) => void;
}

type IReduxProps = ConnectedProps<typeof connector>;

export type ComponentProps = ILearningTicketFormProps & IReduxProps;
class LearningTicketForm extends React.Component<ComponentProps, ILearningTicketFormState> {

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      ticket: {
        description: '',
      },
      errors: {
        description: false,
      },
    };
  }

  onChangeValue(field: keyof ILearningTicketFormDTO, mandatory: boolean, value?: any) {
    const { ticket, errors } = this.state;
    this.setState({
      errors: { ...errors, [field]: !value && mandatory ? true : false }
    });
    (ticket as any)[field] = value;
    this.setState({ ticket });
  }

  onSubmitForm() {
    const { ticket } = this.state;
    if (!this.canSubmitForm()) {
      return;
    }
    const { onCreateTicket } = this.props;
    onCreateTicket(ticket);
  }

  canSubmitForm() {
    const { ticket } = this.state;
    return ticket.description && !_.isEmpty(ticket.description);
  }

  public render() {
    const { classes } = this.props;
    const { ticket, errors } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={moment.locale()}>
        <div className={classes.container}>
          <ContactSupportOutlined style={{ fontSize: 40}} />
          <h2 className={classes.formTitle}>{<ReduxLanguage languageKey={'forms.writeUsAQuestionDescription'} />}</h2>
            <TextareaAutosize
              id="outlined-basic"
              variant="outlined"
              fullWidth
              required
              error={errors.description}
              value={ticket.description}
              style={{ marginTop: 0, height: 400, padding: 10 }}
              onChange={e => this.onChangeValue('description', true, e.target.value)}
            />
          <div style={{position:'sticky',bottom:'0',paddingTop:'20px',backgroundColor:'white',marginLeft:'-5px',marginRight:'-5px',zIndex:1}}>
          <Button
            variant="contained"
            disabled={!this.canSubmitForm()}
            style={{ width: '100%', opacity: this.canSubmitForm() ? 1 : 0.5, backgroundColor: '#5AC0B1', color: 'white', marginTop: 50, fontWeight: 'bold'  }}
            onClick={() => this.onSubmitForm()}
          >
            <ReduxLanguage languageKey="forms.send" />
          </Button>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    
  };
}

const connector = connect(mapStateToProps);

export default connector(withStyles(styles)(LearningTicketForm));