import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const ConfigAxiosInstanceConfig: AxiosRequestConfig = {
  baseURL: typeof window !== 'undefined' ? window.location.origin : undefined,
  timeout: 30000,
}
export const ConfigAxiosInstance: AxiosInstance = axios.create(ConfigAxiosInstanceConfig);

export function setRequestInterceptor(interceptor: (config: AxiosRequestConfig) => AxiosRequestConfig): number {
  return ConfigAxiosInstance.interceptors.request.use(interceptor);
}

export function ejectRequestInterceptor(key: number): void {
  return ConfigAxiosInstance.interceptors.request.eject(key);
}
