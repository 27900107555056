import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { PaginationQueryParamsDTO } from '@bottega52/commons-pagination';
import { PaginationRestDTO } from '@bottega52/commons-pagination';
import { IRechargeInDTO } from './model/input/IRechargeInDTO';
import { IRechargeOutDTO } from './model/output/IRechargeOutDTO';
import { IRechargeNewOutDTO } from './model/output/IRechargeNewOutDTO';

export function fetchRecharges(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IRechargeInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/walletRecharge', config);
}

export function fetchRecharge(rechargeId: number): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.get(`api/v2/walletRecharge/${rechargeId}`);
}

export function deleteRecharge(rechargeId: number): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/walletRecharge/${rechargeId}`);
}

export function deleteRechargeAdmin(rechargeId: number): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.delete(`api/v2/walletRecharge/${rechargeId}/admin`);
}

export function takeChargeRecharge(rechargeId: number): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/walletRecharge/${rechargeId}/takenInCharge`);
}

export function promoteRecharge(rechargeId: number): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/walletRecharge/${rechargeId}/selloutOrderGenerated`);
}

export function invoicedRecharge(rechargeId: number): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/walletRecharge/${rechargeId}/invoiced`);
}

export function promoteRechargeAdmin(rechargeId: number, newStatus: string): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/walletRecharge/${rechargeId}/admin/changeStatus?status=${newStatus}`);
}

export function createNewRecharge(data: IRechargeNewOutDTO): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.post('api/v2/walletRecharge', data);
}

export function editRecharge(rechargeId: number, data: IRechargeOutDTO): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/walletRecharge/${rechargeId}`, data);
}

export function editRechargeAdmin(rechargeId: number, data: IRechargeOutDTO): Promise<AxiosResponse<IRechargeInDTO>> {
  return JagoAxiosInstance.put(`api/v2/walletRecharge/${rechargeId}/admin`, data);
}

export function exportRechargesCSV(params?: PaginationQueryParamsDTO): Promise<AxiosResponse<Blob>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get(`api/v2/walletRecharge/export`, { responseType: 'blob' });
}