import { Button, TextField, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { FunctionComponent, ReactElement } from 'react';
import ReduxLanguage from '../ReduxLanguage';

const styles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    minHeight: 50,
  },
  logo: {
    margin: 5,
    marginBottom: 15,
    alignSelf: 'center',
    height: 45,
  },
  text: {
    margin: 0,
    marginBottom: 0,
    fontSize: 20
  },
  innerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  title: {
    color: 'white',
  },
  circularProgress: {
    color: 'white',
  },
});



export interface ISearchBarProps extends WithStyles<typeof styles> {
  searchTextTranslationKey?: string;
  headerTitleTranslationKey?: string;
  icon?: ReactElement;
  newEntityTranslationKey?: string;
  onSearchTextChange?: (text: string) => void;
  onCreateNewEntityButtonClick?: () => void;
  searchDebounceDelay?: number;
  totalElements?: string | number;
}


const SearchBar: FunctionComponent<ISearchBarProps> = ({ classes, headerTitleTranslationKey, icon, newEntityTranslationKey, onCreateNewEntityButtonClick, totalElements, searchTextTranslationKey, onSearchTextChange, searchDebounceDelay }) => {
 
  const debouncedSearch = _.debounce((text) => onSearchTextChange!(text), searchDebounceDelay ? searchDebounceDelay : 500);
  
  return (
    <div className={classes.container}>
      <div className={classes.innerHeader}>
        {headerTitleTranslationKey ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {icon}
            <h3 className={classes.text} style={{ fontWeight: 'bold' }}>
              <ReduxLanguage languageKey={headerTitleTranslationKey} />
            </h3>
          </div>
        ) : null}
      </div>
      {onSearchTextChange ? (
        <TextField
          id="outlined-basic"
          label={searchTextTranslationKey ? <ReduxLanguage languageKey={searchTextTranslationKey} /> : 'Search'}
          variant="outlined"
          size="medium"
          type="search"
          onChange={e => debouncedSearch(e.target.value)}
        />
      ) : null}
      {onCreateNewEntityButtonClick && newEntityTranslationKey ? (
        <Button
          variant="contained"
          style={{ backgroundColor: '#5AC0B1', color: 'white', marginLeft: 20, fontWeight: 'bold'  }}
          onClick={() => onCreateNewEntityButtonClick()}
        >
          <ReduxLanguage languageKey={newEntityTranslationKey} />
        </Button>
      ) : null}
    </div>
  );
}

export default withStyles(styles)(SearchBar);
