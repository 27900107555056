import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { BookOutlined } from "@material-ui/icons";
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IVarOpportunityHistoryRowDTO } from '../../repository/jago/model/input/IVarOpportunityInDTO';
import ListEmptyView from "../ListEmptyView";
import VarOpportunityHistoryRow from './VarOpportunityHistoryRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IVarOpportunityHistoryListProps extends WithStyles<typeof styles>{
  history: IVarOpportunityHistoryRowDTO[];
}

class VarOpportunityHistoryList extends React.Component<IVarOpportunityHistoryListProps, any> {

  public render() {
    const { history: historyData } = this.props;
    return (
      <div style={{ zIndex: 100 }}>
        {!historyData || (historyData && _.isEmpty(historyData)) ? (
          <ListEmptyView
            icon={<BookOutlined style={{ fontSize: 40 }} />}
            titleKeyMessage="vars.noOpportunityHistoryFound"
            descriptionKeyMessage="vars.noOpportunityHistoryFoundDescription"
          />
        ): (
          <InfiniteScroll
            dataLength={_.size(historyData)} //This is important field to render the next data
            next={() => null}
            hasMore={false}
            height={800}
            loader={<h4>Loading...</h4>}
          >
            {_.map(_.orderBy(historyData, 'time', 'desc'), history => (
              <VarOpportunityHistoryRow key={history.time} history={history} />
            ))}
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(VarOpportunityHistoryList);