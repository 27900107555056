import AbilityHelper from './AbilityHelper';

class AbilityProvider {
  abilityHelper = new AbilityHelper();

  getAbilityHelper() {
    return this.abilityHelper;
  }
}

export default new AbilityProvider();
