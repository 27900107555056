import { IDomainInfoInDTO } from './../../repository/jago/model/input/IDomainInfoInDTO';

export const SMART = 'SMART';
export const EASY = 'EASY';
export const STANDARD = 'STANDARD';
export const ENTERPRISE = 'ENTERPRISE';

export function elaborateDomainType(domainInfo: IDomainInfoInDTO) {
  if (domainInfo.numOfArgoLocks === 0 && domainInfo.numOfVegaLocks === 0) {
    return {
      domainType: '',
      domainTypeDescription: '',
    }
  }
  if (domainInfo.numOfVegaLocks > 0) {
    if (domainInfo.numOfArgoLocks > 0) {
      return {
        domainType: ENTERPRISE,
        domainTypeDescription: 'domains.ENTERPRISE',
      }
    }
    return {
      domainType: STANDARD,
      domainTypeDescription: 'domains.STANDARD',
    }
  } else {
    if (domainInfo.numOfGateways > 0) {
      return {
        domainType: SMART,
        domainTypeDescription: 'domains.SMART',
      }
    }
    return {
      domainType: EASY,
      domainTypeDescription: 'domains.EASY',
    }
  }
}