import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IDomainUserInDTO } from '../../../repository/jago/model/input/IDomainUserInDTO';
import ListEmptyView from '../../ListEmptyView';
import DomainUserRow from "../DomainUsers/DomainUserRow";

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface IDomainUsersListProps extends WithStyles<typeof styles>{
  users?: PaginatedDataDTO<IDomainUserInDTO>;
  onRowClick?: (user: IDomainUserInDTO) => void;
  onFetchMoreItems: (page: number) => void;
}

class DomainUsersList extends React.Component<IDomainUsersListProps, any> {

  public render() {
    const { users, onRowClick, onFetchMoreItems } = this.props;
    if (!users || ( users && users.content && _.isEmpty(users.content))) return (
      <ListEmptyView
        titleKeyMessage="domains.noUsersFoundTitle"
        descriptionKeyMessage="domains.noUsersFoundDescription"
      />
    );
    const usersData = users.content;
    const usersPagination = !_.isEmpty(users.pagination) ? (users.pagination as PaginationDTO) : null;
    const currentPage = usersPagination ? usersPagination.number : 0;
    const isLastPage = usersPagination ? usersPagination.last : true;
    return (
      <div style={{ width: '100%'}}>
      <InfiniteScroll
        dataLength={_.size(usersData)} //This is important field to render the next data
        next={() => onFetchMoreItems(currentPage + 1)}
        hasMore={!isLastPage}
        loader={<h4>Loading...</h4>}
      >
        {_.map(usersData, user => (
          <DomainUserRow key={user.id} user={user} onClick={onRowClick ? () => onRowClick(user) : undefined} />
        ))}
      </InfiniteScroll>
      </div>
    );
  }
}

export default withStyles(styles)(DomainUsersList);