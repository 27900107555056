import { PaginatedDataDTO, PaginationDTO } from '@bottega52/commons-pagination';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import SubscriptionsIcon from '@material-ui/icons/ReceiptOutlined';
import _ from 'lodash';
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ISubscriptionInDTO } from '../../repository/jago/model/input/ISubscriptionInDTO';
import Utils from '../../utils/Utils';
import ListEmptyView from "../ListEmptyView";
import SubscriptionRow from './SubscriptionRow';

const styles = createStyles({
  rowContainer: {
    display: 'flex',
    margin: 10,
    flexDirection: 'column'
  },
  rowBodyContainer: {
    padding: 15,
    minHeight: 100,
  },
  rowTitle: {
    fontWeight: 'bold'
  },
  logo: {
    width: '20%',
  },
});

export interface ISystemSubscriptionsListProps extends WithStyles<typeof styles>{
  subscriptions: Partial<PaginatedDataDTO<ISubscriptionInDTO>>;
  onFetchMoreItems: (page: number) => void;
  onRowClick?: (subscription: ISubscriptionInDTO) => void;
  onEditSubcription?: (subscription: ISubscriptionInDTO) => void;
}

class SystemSubscriptionsList extends React.Component<ISystemSubscriptionsListProps, any> {

  public render() {
    const { onFetchMoreItems, subscriptions, onRowClick, onEditSubcription } = this.props;

    const emptyView = <ListEmptyView
      icon={<SubscriptionsIcon style={{ fontSize: 40 }} />}
      titleKeyMessage="credits.noSystemSubscriptionFoundTitle"
      descriptionKeyMessage="credits.noSystemSubscriptionFoundDescription"
    />;

    if (!subscriptions || _.isEmpty(subscriptions)) {
      return emptyView;
    }

    const subscriptionsData = subscriptions.content;
    const subscriptionsPagination = !_.isEmpty(subscriptions.pagination) ? (subscriptions.pagination as PaginationDTO) : null;
    const currentPage = subscriptionsPagination ? subscriptionsPagination.number : 0;
    const isLastPage = subscriptionsPagination ? subscriptionsPagination.last : true;
    return (
      <div style={{ zIndex: 100 }}>
        {(subscriptionsData && _.isEmpty(subscriptionsData)) ?
          emptyView :
          <div>
            <InfiniteScroll
              dataLength={_.size(subscriptionsData)} //This is important field to render the next data
              next={() => onFetchMoreItems(currentPage + 1)}
              hasMore={!isLastPage}
              height={800}
              loader={<h4>Loading...</h4>}
            >
              {_.map(subscriptionsData, subscription => (
                <SubscriptionRow
                  key={subscription.id}
                  subscription={subscription}
                  onEditSubcription={onEditSubcription}
                  onClick={Utils.isMainTierSubscription(subscription) ? onRowClick : undefined}
                />
              ))}
            </InfiniteScroll>
          </div>
        }
      </div>
    );
  }
}

export default withStyles(styles)(SystemSubscriptionsList);