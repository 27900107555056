import { IDomainInfoInDTO } from './model/input/IDomainInfoInDTO';
import { IDomainAdditionalInfoOutDTO } from './model/output/IDomainAdditionalInfoOutDTO';
import qs from 'query-string';
import { JagoAxiosInstance } from './JagoAxiosInstance';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PaginationRestDTO } from '@bottega52/commons-pagination';
import { IDomainInDTO } from './model/input/IDomainInDTO';
import { IDomainConfigInDTO, IDomainSubscriptionInDTO } from './model/input/IDomainConfigInDTO';
import { IDomainConfigOutDTO } from './model/output/IDomainConfigOutDTO';
import { IDomainUserInDTO } from './model/input/IDomainUserInDTO';
import { IDomainUserImpersonateDTO } from './model/input/IDomainUserImpersonateDTO';
import { IDomainOutDTO } from './model/output/IDomainOutDTO';
import { IDomainUserOutDTO } from './model/output/IDomainUserOutDTO';
import { IProductOutDTO } from './model/output/IProductOutDTO';
import { IProductInDTO } from './model/input/IProductInDTO';
import { IDomainRequestParamsDTO, IDomainSubscriptionParamsDTO, IDomainUsersRequestParamsDTO } from './model/output/RequestParamsDTOs';

export function fetchSofiaDomains(params?: IDomainRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IDomainInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
  };
  return JagoAxiosInstance.get('api/v2/support/domains', config);
}

export function fetchSofiaDomainConfiguration(domainHostname: string): Promise<AxiosResponse<IDomainConfigInDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/config`);
}

export function fetchSofiaDomainSubscriptions(domainHostname: string, params?: IDomainSubscriptionParamsDTO): Promise<AxiosResponse<IDomainSubscriptionInDTO[]>> {
  const config: AxiosRequestConfig = {
    params,
    paramsSerializer: (params: any) => qs.stringify(params),
  }
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/subscriptions`, config);
}

export function fetchSofiaDomainUsers(domainHostname: string, params?: IDomainUsersRequestParamsDTO): Promise<AxiosResponse<PaginationRestDTO<IDomainUserInDTO>>> {
  const config: AxiosRequestConfig = {
    params,
    paramsSerializer: (params: any) => qs.stringify(params),
  }
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/users`, config);
}

export function updateSofiaDomainConfiguration(domainHostname: string, data: IDomainConfigOutDTO): Promise<AxiosResponse<IDomainConfigInDTO>> {
  return JagoAxiosInstance.put(`api/v2/support/domains/${domainHostname}/config`, data) ;
}

export function enableIris(plantId: number, status: string): Promise<AxiosResponse<IDomainConfigInDTO>> {
  return JagoAxiosInstance.put(`api/v2/credits/plants/${plantId}`, {enable1ris:status}) ;
}

export function impersonateDomainUser(domainHostname: string, userEmail: string, clientId: string, duration?: number): Promise<AxiosResponse<IDomainUserImpersonateDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/users/${userEmail}/impersonate?clientId=${clientId}&duration=${duration}`) ;
}


export function createNewDomain(data: IDomainOutDTO): Promise<AxiosResponse<IDomainInDTO>> {
  return JagoAxiosInstance.post('api/v2/support/domains', data);
}


export function createUserForDomain(domainHostname: string, data: IDomainUserOutDTO): Promise<AxiosResponse<IDomainUserInDTO>> {
  return JagoAxiosInstance.post(`api/v2/support/domains/${domainHostname}/users`, data);
}

export function addProductToDomain(domainHostname: string, data: IProductOutDTO): Promise<AxiosResponse<IProductInDTO>> {
  return JagoAxiosInstance.post(`api/v2/support/domains/${domainHostname}/products`, data);
}


export function updateSofiaDomainAdditionalInfo(domainHostname: string, data: IDomainAdditionalInfoOutDTO): Promise<AxiosResponse<IDomainAdditionalInfoOutDTO>> {
  return JagoAxiosInstance.post(`api/v2/support/domains/${domainHostname}/additionalInfo`, data) ;
}

export function fetchSofiaDomainAdditionalInfo(domainHostname: string): Promise<AxiosResponse<IDomainAdditionalInfoOutDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/additionalInfo`) ;
}

export function fetchSofiaDomainInfo(domainHostname: string): Promise<AxiosResponse<IDomainInfoInDTO>> {
  return JagoAxiosInstance.get(`api/v2/support/domains/${domainHostname}/info`) ;
}

export function fetchManagersAndSubscriptionCSV(): Promise<AxiosResponse<Blob>> {
  return JagoAxiosInstance.get(`api/v2/support/managers/csv`, { responseType: 'blob' });
}